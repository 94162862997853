import * as React from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import Stt from './Stt';
import Reserve from './Reserve/Reserve';
import Course from './Course';
import Learning from './Learning';
import Jobs from './Jobs';
import Dashboard from './Dashboard';
import FluentPage from './LandingPages/FluentPage';
import Registration from './Registration';
import ResetPasswordInit from './ResetPasswordInit';
import ResetPasswordFinish from './ResetPasswordFinish';
import Autologin from './Autologin';
import Users from './Users';
import Vouchers from './Vouchers';
import LearningPlans from './LearningPlans';
import Webhooks from './Webhooks';
import System from './System';
import AdminUserImport from './AdminUserImport';
import AdminVoucherImport from './AdminVoucherImport';
import LessonEditor from './Editor/LessonEditor/LessonEditor';
import CoursesEditor from './Editor/CoursesEditor/CoursesEditor';
import CourseEditor from './Editor/CourseEditor/CourseEditor';
import SlideEditor from './Editor/SlideEditor/SlideEditor';
import Languages from './Languages';
import Team from './Team';
import Courses from './Courses/Courses';
import AdminSpellCheck from './AdminSpellCheck';

export default class Main extends React.Component<any, any> {

    render(): React.ReactNode {
        return (
            <div>
                <Route exact path='/' component={Home} />
                <Route path='/home' component={Home} />
                <Route path='/enroll' component={Reserve} />
                {/* <Route path='/pricing' component={Pricing} /> */}
                <Route path='/languages' component={Languages} />
                <Route path='/:lang/courses' component={Courses} />
                <Route path='/team' component={Team} />
                <Route path='/fluent-in-3-months' component={FluentPage} />
                <Route path='/:lang/course/:id' render={(props) => (
                    <Course key={props.match.params.lang + '/course/' + props.match.params.id} {...props} />)
                } />
                <Route path='/:lang/learning/:id' component={Learning} />
                <Route path='/signin' component={Login} />
                <Route path='/signout' component={Logout} />
                <Route path='/jobs' component={Jobs} />
                <Route path='/registration' component={Registration} />
                <Route path='/recover-password' component={ResetPasswordInit} />
                <Route path='/reset-password' component={ResetPasswordFinish} />
                <Route path='/autologin' component={Autologin} />
                <PrivateRoute path='/dashboard' component={Dashboard} onlyAdminOrSupport={false} />
                <PrivateRoute path='/stt' component={Stt} />
                <PrivateRoute exact path='/admin/users' component={Users} />
                <PrivateRoute exact path='/admin/vouchers' component={Vouchers} />
                <PrivateRoute exact path='/admin/learningplans' component={LearningPlans} />
                <PrivateRoute exact path='/admin/webhooks' component={Webhooks} />
                <PrivateRoute exact path='/admin/system' component={System} />
                <PrivateRoute exact path='/admin/user-import' component={AdminUserImport} />
                <PrivateRoute exact path='/admin/voucher-import' component={AdminVoucherImport} />
                <PrivateRoute exact path='/admin/spell-check' component={AdminSpellCheck} />
                <PrivateRoute exact path='/editor' component={LessonEditor} onlyAdminOrSupport={false} />
                <PrivateRoute path='/editor/overview/courses' component={CoursesEditor} onlyAdminOrSupport={false} />
                <PrivateRoute exact path='/editor/overview/course/:id' component={CourseEditor} onlyAdminOrSupport={false} />
                <PrivateRoute path='/editor/slides/:dialogueSeqAlpha/:contextId' component={SlideEditor} onlyAdminOrSupport={false} />
            </div>
        );
    }
}
