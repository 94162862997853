import * as React from 'react';

export default class ExrTutorial extends React.PureComponent<any, any> {
    render(): React.ReactNode {
        return (
            <div id='tutorial'>
                <div className='inner'>
                    <header>Tutorial</header>
                    <div className='tut-header'>
                        <div className='tutor-msg left outro'><div>Perfect! Keep going!</div></div>
                        <figure className='tutor-avatar'><img src={this.props.guideImg} alt='Tutor' /></figure>
                    </div>
                    <div className='tut-step'>
                        <div className='tut-text'>
                            <span>Our exercises are built as dialogs.</span>
                            <svg className='tut-arrow' width='65' height='42' xmlns='http://www.w3.org/2000/svg'><path d='M20.786 34.463c.34 1.444.638 2.96.893 4.545a1.941 1.941 0 0 1-1.917 2.249h-.867a2 2 0 0 1-1.86-1.266c-.435-1.103-.811-1.957-1.129-2.562C12.107 30.19 4.913 24.59 0 20.63c4.912-3.961 12.105-9.561 15.903-16.802.317-.604.693-1.458 1.128-2.56A2 2 0 0 1 18.891 0h.867a1.941 1.941 0 0 1 1.916 2.248 64.544 64.544 0 0 1-.823 4.25h40.597C63.41 6.498 65 5.408 65 4.063v32.375c0-1.09-1.29-1.975-2.882-1.975H20.786z' fill='#000' fillRule='evenodd' fillOpacity='.8'/></svg>
                            <div className='tut-action'>
                                <button className='button primary' onClick={this.props.showTutorial}>Got it!</button>
                            </div>
                        </div>
                        <div className='tut-text'>
                            <span>The hints help you come up with a correct answer</span>
                            <svg className='tut-arrow' width='65' height='42' xmlns='http://www.w3.org/2000/svg'><path d='M20.786 34.463c.34 1.444.638 2.96.893 4.545a1.941 1.941 0 0 1-1.917 2.249h-.867a2 2 0 0 1-1.86-1.266c-.435-1.103-.811-1.957-1.129-2.562C12.107 30.19 4.913 24.59 0 20.63c4.912-3.961 12.105-9.561 15.903-16.802.317-.604.693-1.458 1.128-2.56A2 2 0 0 1 18.891 0h.867a1.941 1.941 0 0 1 1.916 2.248 64.544 64.544 0 0 1-.823 4.25h40.597C63.41 6.498 65 5.408 65 4.063v32.375c0-1.09-1.29-1.975-2.882-1.975H20.786z' fill='#000' fillRule='evenodd' fillOpacity='.8'/></svg>
                            <div className='tut-action'>
                                <button className='button primary' onClick={this.props.showTutorial}>Got it!</button>
                            </div>
                        </div>
                        <div className='tut-text'>
                            {this.props.isIframe ? null : (
                                <figure id='tut-mic'>
                                    <svg id='tut-mic-arrow' width='106' height='140' xmlns='http://www.w3.org/2000/svg'><path d='M86.18 37.24c1.6-7.111 4.06-14.033 7.375-20.764C72.3 38.341 58.37 57.774 41.854 81.117c-16.985 24.005-23.646 36.41-33.77 56.73-1.006 2.019-3.102 2.023-4.227 1.78-1.124-.243-4.056-2.313-2.957-4.397 12.665-24.025 25.17-42.031 37.186-58.611C56.08 51.789 73.348 29.652 95.673 8.292c-7.982 3.905-16.155 8.976-22.88 14.243a2.083 2.083 0 0 1-2.569-3.28c9.36-7.33 20.88-14.15 32.067-18.424 1.061-.406 2.27.24 2.626 1.334.099.302.521 2.485.03 3.193-7.234 10.445-12.132 21.374-14.703 32.797a2.083 2.083 0 1 1-4.065-.914z' fill='#4DDCB9' fillRule='nonzero'/></svg>
                                </figure>
                            )}
                            <span>Let's activate your microphone so you can give your answer</span>
                            <div className='tut-action'>
                                <button className='button primary' onClick={this.props.showTutorial}>Got it!</button>
                            </div>
                        </div>
                        <div className='tut-text'>
                            <span>Perfect!<br/>Now say your answer using the hint above</span>
                            <svg className='tut-arrow' width='65' height='42' xmlns='http://www.w3.org/2000/svg'><path d='M20.786 34.463c.34 1.444.638 2.96.893 4.545a1.941 1.941 0 0 1-1.917 2.249h-.867a2 2 0 0 1-1.86-1.266c-.435-1.103-.811-1.957-1.129-2.562C12.107 30.19 4.913 24.59 0 20.63c4.912-3.961 12.105-9.561 15.903-16.802.317-.604.693-1.458 1.128-2.56A2 2 0 0 1 18.891 0h.867a1.941 1.941 0 0 1 1.916 2.248 64.544 64.544 0 0 1-.823 4.25h40.597C63.41 6.498 65 5.408 65 4.063v32.375c0-1.09-1.29-1.975-2.882-1.975H20.786z' fill='#000' fillRule='evenodd' fillOpacity='.8'/></svg>
                            <div className='tut-action'>
                                <button className='button primary' onClick={this.props.showTutorial}>Got it!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
