import * as React from 'react';
import {Link} from 'react-router-dom';
import * as _ from 'underscore';
import * as queryString from 'query-string';
import Select from 'react-select';

import {LanguageService} from '../../../services/language.service';
import Popup from '../../global/popup/Popup'
import {DialogueService} from '../../../services/dialogue.service';
import {SentenceService} from '../../../services/sentence.service';
import {Context} from '../../../models/context.model';
import {Intent} from '../../../models/intent.model';
import {ImageProvider} from '../../../models/image.provider.enum';
import {ImageStorage} from '../../../models/image.storage.enum';
import {Dialogue} from '../../../models/dialogue.model';
import { SpeechService } from '../../../services/speech.service';
import { MagicPlayerService } from '../../../services/magicplayer.service';
import { isNullOrUndefined } from 'util';
import { base64Mp3Prefix, createAndDownloadBlobFile } from '../../../utils/fileUtils';

export default class LessonEditor extends React.Component<any, any> {

    state = {
        originalDialog: null,
        dialog: null,
        language: null,
        message: null,
        serverUpdateInProgress: false,
        createNewDialogueProgress: false,
        activeContextIdx: 0,
        courseName: null
    };

    createSetPopup: Popup;

    componentDidMount(): void {
        this.setState({message: 'No set and dialog specified.'});

        // get the seqAlpha and courseName name from the query string if available
        const values = queryString.parse(this.props.location.search);
        if (values.seqAlpha) {
            setTimeout(() => {
                $('#search-value').val(values.seqAlpha);
                this.findDialogue();
            }, 200);
        }
        if (values.course) {
            this.setState({courseName: values.course});
        }
    }

    dClone = (oldObject: any): any => {
        return JSON.parse(JSON.stringify(oldObject));
    };

    findDialogue = (e?): boolean => {
        if (e) {
            e.preventDefault();
        }
        const dialogueId: string = $('#search-value').val() as string;
        this.reset('Trying to load dialogue ' + dialogueId + ' ... ');
        DialogueService.instance.getDialogue(dialogueId)
            .then((dialogue: Dialogue) => {
                if (dialogue) {
                    const dialog: Dialogue = this.dClone(dialogue);
                    dialog.contexts = dialog.contexts || [];
                    if (dialog.contexts.length < 1) {
                        this.addContext(dialog);
                    } else {
                        _.each(dialog.contexts, (context: Context) => {
                            if (context.intents.length < 1) {
                                this.addIntent(context);
                            } else {
                                _.each(context.intents, (intent: Intent) => {
                                    intent['answersMeta'] = [];
                                    _.each(intent.answers, (answer) => {
                                        const meta = {
                                            value: answer
                                        };
                                        SentenceService.instance.generateAnswers(meta);
                                        intent['answersMeta'].push(meta);
                                    });
                                });
                            }
                        });
                    }
                    this.setState({originalDialog: dialogue, dialog, language: dialog.trgLanguage});
                } else {
                    this.reset('Error when loading dialog.');
                }
            })
            .catch((error) => {
                this.reset('Error when loading dialog.');
            });
        return false;
    };

    reset = (message: string): void => {
        this.setState({originalDialog: null, dialog: null,  language: null, message, serverUpdateInProgress: false, createNewDialogueProgress: false});
    };

    close = (): void => {
        this.setState({dialog: null, language: null, message: 'No dialog specified.', serverUpdateInProgress: false, createNewDialogueProgress: false});
    };

    save = (): void => {
        const {serverUpdateInProgress} = this.state;
        if (serverUpdateInProgress) {
            return;
        }
        this.setState({serverUpdateInProgress: true, message: 'Update is in progress ...'});
        const {dialog} = this.state;
        if (dialog && dialog.contexts) {
            this.getContextsWithThumbs().then(contextsWithThumbs => {
                dialog.contexts = contextsWithThumbs;
                // if (dialog && dialog.contexts) {
                    _.each(dialog.contexts, (context) => this.processContext(context));
                // }
                let valid = true;
                // if (dialog && dialog.contexts) {
                    _.each(dialog.contexts, (context: Context) => {
                        _.each(context.intents, (intent: Intent) => {
                            _.each(intent.answers, (answer: string, idx: number) => {
                                if (!SentenceService.instance.validateSentence(answer)) {
                                    intent['answersMeta'][idx].invalid = true;
                                    valid = false;
                                }
                            });
                        });
                    });
                // }
                if (valid) {
                    DialogueService.instance.saveDialogue(dialog)
                        .then((d) => {
                            this.reset('Update completed successfully.');
                            setTimeout(() => {
                                $('#search-value').val(d.seqAlpha);
                                this.findDialogue();
                            }, 1000);
                        })
                            .catch((error) => {
                            this.reset(error);
                        });
                } else {
                    this.setState({serverUpdateInProgress: false});
                }
            }).catch(err => console.log(err));
        }
    };

    getContextsWithThumbs = async (): Promise<Context[]> => {
        return this.getContextWithThumbsRecursive(this.state.dialog.contexts);
    }

    getContextWithThumbsRecursive = async (contextsLeft: Context[]): Promise<Context[]> => {
        const context = contextsLeft[0];
        if (context != null) {
            try {
                let contextWillBeRefactored: Context;
                if (context.videoSource == 'vimeo') {
                    contextWillBeRefactored = {
                        ...context,
                        thumb: await MagicPlayerService.instance.getVimeoThumb(context.video)
                    }
                } else {
                    contextWillBeRefactored = {
                        ...context,
                        thumb: null
                    }
                }

                contextsLeft.splice(0, 1);
                const contextsRefactored: Context[] = await this.getContextWithThumbsRecursive(contextsLeft);

                return new Promise<Context[]>((resolve, reject) => {
                    resolve([
                        contextWillBeRefactored,
                        ...contextsRefactored
                    ]);
                });
            } catch (err) {
                console.log(err);
                return new Promise<Context[]>((resolve, reject) => {
                    reject('Some error occured when getting thumbnails of the videos. Error: ' + err);
                });
            }

        } else {
            return new Promise<Context[]>((resolve, reject) => {
                resolve([]);
            });
        }
    }

    createNewDialogue = (e?): boolean => {
        if (e) {
            e.preventDefault();
        }
        const {createNewDialogueProgress} = this.state;
        if (createNewDialogueProgress) {
            return;
        }
        this.setState({createNewDialogueProgress: true, message: 'New set is being created ...'});
        const name: string = $('#new-set-name').val() as string;
        const lang = LanguageService.instance.getByISO($('#new-set-language').val() as string);
        const language = lang ? lang.id : null;
        if (name && name.trim().length > 0 && language) {
            const newDialogue = {
                srcName: name,
                trgName: name,
                srcLanguage: language,
                trgLanguage: language
            };
            DialogueService.instance.saveDialogue(newDialogue)
                .then((d) => {
                    this.reset('Dialogue was created successfully.');
                    this.createSetPopup.close();
                    $('#new-set-name').val(null);
                    $('#new-set-language').val(null);
                    setTimeout(() => {
                        $('#search-value').val(d.seqAlpha);
                        this.findDialogue();
                    }, 1000);
                })
                .catch((error) => {
                    this.reset(error);
                });
        } else {
            this.reset('Invalid input. Check your data and try again.');
        }
        return false;
    };

    processContext = (context: Context): void => {
        if (context) {
            if (context.name) {
                context.name = context.name.trim();
            }
            if (context.video) {
                context.video = context.video.trim();
            }
            if (context.intents) {
                _.each(context.intents, (intent) => this.processIntent(intent));
            }
        }
    };

    processIntent = (intent: Intent): void => {
        if (intent.question) {
            intent.question = intent.question.trim();
        }
        if (intent.answers && intent.answers.length > 0) {
            intent.answers = _.chain(intent.answers).filter((answer) => {
                return answer && answer.trim().length > 0;
            }).map((answer) => {
                return answer.trim();
            }).value();
        }
        if (intent.imageHint) {
            intent.imageHint = intent.imageHint.trim();
        }
        if (intent.image && intent.image.reference && intent.image.reference.length > 0) {
            intent.image.reference = intent.image.reference.trim();
            intent.image.provider = intent.image.provider || ImageProvider.MAGICLINGUA;
            intent.image.imageStorage = intent.image.imageStorage || ImageStorage.NONE;
        } else {
            intent.image = null;
        }
    };

    selectContext = (idx: number): void => {
        if (idx !== null) {
            this.setState({
                activeContextIdx: idx
            });
        }
    };

    removeIntent = (i: number): void => {
        if (confirm('Delete intent with all contents?')) {
            const {dialog, activeContextIdx} = this.state;
            const context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
            if (context && context.intents) {
                context.intents.splice(i, 1);
                this.setState({dialog});
            }
        }
    };

    moveIntent = (i: number, isRight: boolean): void => {
        const {dialog, activeContextIdx} = this.state;
        const context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
        if (context) {
            const canMove: boolean = (isRight ? (context.intents[i + 1] ? true : false) : (context.intents[i - 1] ? true : false));
            if (canMove) {
                const movingIntent: Intent = context.intents[i];
                context.intents.splice(i, 1);
                isRight ? context.intents.splice(i + 1, 0, movingIntent) : context.intents.splice(i - 1, 0, movingIntent);
                this.setState({dialog});
            }
        }
    };

    setIntentQuestionValue = (e, i: number): void => {
        const {dialog, activeContextIdx} = this.state;
        const context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
        if (context) {
            const intent = context.intents && context.intents.length > i ? context.intents[i] : null;
            if (intent) {
                intent.question = e.target.value;
                this.setState({dialog});
            }
        }
    };

    setIntentImageUrl = (e, i: number): void => {
        const imageUrl = e.target.value;
        const {dialog, activeContextIdx} = this.state;
        const context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
        if (context) {
            const intent = context.intents && context.intents.length > i ? context.intents[i] : null;
            if (intent) {
                intent.image = intent.image || {};
                if (!intent.image.reference || intent.image.reference !== imageUrl || (intent.image.imageStorage === 'CLOUDINARY' && intent.image.reference.indexOf('cloudinary') < 0)) {
                    intent.image = {};
                }
                intent.image.reference = imageUrl;
                this.setState({dialog});
            }
        }
    };

    setIntentImageHint = (e, i: number): void => {
        const {dialog, activeContextIdx} = this.state;
        const context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
        if (context) {
            const intent = context.intents && context.intents.length > i ? context.intents[i] : null;
            if (intent) {
                intent.imageHint = e.target.value;
                this.setState({dialog});
            }
        }
    };

    removeAnswer = (i: number, a: number): void => {
        const {dialog, activeContextIdx} = this.state;
        const context: Context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
        if (context) {
            const intent: Intent = context.intents && context.intents.length > i ? context.intents[i] : null;
            if (intent) {
                intent.answers = intent.answers || [];
                intent['answersMeta'] = intent['answersMeta'] || [];
                const answerMeta = intent['answersMeta'].length > a ? intent['answersMeta'][a] : null;
                if (answerMeta) {
                    intent.answers.splice(a, 1);
                    intent['answersMeta'].splice(a, 1);
                    this.setState({dialog});
                }
            }
        }
    };

    addAnswer = (e, i: number): void => {
        const {dialog, activeContextIdx} = this.state;
        const context: Context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
        if (context) {
            const intent: Intent = context.intents && context.intents.length > i ? context.intents[i] : null;
            if (intent) {
                intent.answers = intent.answers || [];
                intent['answersMeta'] = intent['answersMeta'] || [];
                const answerMeta = {
                    value: null,
                    invalid: false,
                    answers: [],
                    wrongAnswers: []
                };
                SentenceService.instance.generateAnswers(answerMeta);
                intent.answers.push(answerMeta.value);
                intent['answersMeta'].push(answerMeta);
                this.setState({dialog});
                if (e) {
                    setTimeout(() => {
                        $('#t-' + i + ' > .answers > .answer').find('textarea').last().focus();
                    }, 0);
                }
            }
        }
    };

    onAnswerKeyUp = (e, i: number, a: number): void => {
        const {dialog, activeContextIdx} = this.state;
        const context: Context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
        if (context) {
            const intent: Intent = context.intents && context.intents.length > i ? context.intents[i] : null;
            if (intent) {
                intent.answers = intent.answers || [];
                intent['answersMeta'] = intent['answersMeta'] || [];
                const answerMeta = intent['answersMeta'].length > a ? intent['answersMeta'][a] : null;
                if (answerMeta) {
                    intent.answers[a] = e.target.value;
                    answerMeta.value = e.target.value;
                    answerMeta.invalid = false;
                    SentenceService.instance.generateAnswers(answerMeta);
                    this.setState({dialog});
                }
            }
        }
    };

    addContextToDialog = (): void => {
        this.addContext(this.state.dialog);
        this.setState({dialog: this.state.dialog});
    };

    moveContext = (i: number, isRight: boolean): void => {
        const {dialog} = this.state;
        const movingContext: Context = dialog.contexts[i];
        const canMove: boolean = (isRight ? (dialog.contexts[i + 1] ? true : false) : (dialog.contexts[i - 1] ? true : false));
        if (canMove && movingContext) {
            dialog.contexts.splice(i, 1);
            isRight ? dialog.contexts.splice(i + 1, 0, movingContext) : dialog.contexts.splice(i - 1, 0, movingContext);
            this.setState({dialog});
        }
    };

    addContext = (dialog?: Dialogue): void => {
        if (dialog) {
            dialog.contexts = dialog.contexts || [];
            const context = new Context();
            this.addIntent(context);
            dialog.contexts.push(context);
        }
    };

    addIntentToContext = (): void => {
        const {dialog, activeContextIdx} = this.state;
        const context = dialog.contexts && dialog.contexts.length > activeContextIdx ? dialog.contexts[activeContextIdx] : null;
        if (context) {
            this.addIntent(context);
            this.setState({dialog});
        }
    };

    addIntent = (context?: Context): void => {
        if (context) {
            context.intents = context.intents || [];
            const intent = new Intent();
            intent['answersMeta'] = [];
            context.intents.push(intent);
        }
    };

    languageIdToName = (languageId: string): string => {
        return languageId ? LanguageService.instance.nameById(languageId) : null;
    };

    supportedLanguages = (): string[] => {
        return LanguageService.instance.getSupportedLanguages();
    };

    onClickCreate = (): void => {
        this.createSetPopup.open();
    };

    removeContext = (): void => {
        if (confirm('Delete group with all contents?')) {
            const {dialog, activeContextIdx} = this.state;
            if (dialog && dialog.contexts && dialog.contexts.length > activeContextIdx) {
                dialog.contexts.splice(activeContextIdx, 1);
                this.setState({dialog, activeContextIdx: activeContextIdx - 1});
            }
        }
    };

    changeActiveContextName = (e): void => {
        const {dialog, activeContextIdx} = this.state;
        if (dialog && dialog.contexts && dialog.contexts.length > activeContextIdx) {
            const activeContext = dialog.contexts[activeContextIdx];
            activeContext.name = e.target.value;
            this.setState({dialog});
        }
    };

    changeActiveContextVideo = (e): void => {
        const {dialog, activeContextIdx} = this.state;
        if (dialog && dialog.contexts && dialog.contexts.length > activeContextIdx) {
            const activeContext = dialog.contexts[activeContextIdx];
            activeContext.video = e.target.value;
            this.setState({dialog});
        }
    };

    changeActiveContextVideoSource = (e): void => {
        const {dialog, activeContextIdx} = this.state;
        if (dialog && dialog.contexts && dialog.contexts.length > activeContextIdx) {
            const activeContext: Context = dialog.contexts[activeContextIdx];
            activeContext.videoSource = e.target.value;
            this.setState({dialog});
        }
    };

    changeCategories = (value): void => {
        const {dialog} = this.state;
        dialog.categories = value.map(category => category.value);
        this.setState({dialog});
    }

    tts = (text: string): void => {
        // 1. try to find iso value by id
        // 2. if no language found then maybe the incoming value is already an iso value, trying it as the last resort
        const foundLanguage = LanguageService.instance.isoByName(LanguageService.instance.nameById(this.state.language))
        SpeechService.instance.tts(text, foundLanguage || this.state.language);
    }

    downloadTts = (text: string): void => {
        // 1. try to find iso value by id
        // 2. if no language found then maybe the incoming value is already an iso value, trying it as the last resort
        const foundLanguage = LanguageService.instance.isoByName(LanguageService.instance.nameById(this.state.language))
        SpeechService.instance.getTts(text, foundLanguage || this.state.language)
            .then((result) => {
                createAndDownloadBlobFile(false, result, `${text}.mp3`, base64Mp3Prefix)
            });
    }

    render(): React.ReactNode {
        const {dialog, serverUpdateInProgress, createNewDialogueProgress, courseName} = this.state;
        const activeContext: Context = dialog && dialog.contexts && dialog.contexts[this.state.activeContextIdx];

        // dialogue can have as trgLanguage an ID or a langIso, we need to check both
        const languageId: string = dialog ? dialog.trgLanguage : null;
        const langName = languageId ? this.languageIdToName(languageId) : null;
        const langISO: string = langName ? LanguageService.instance.isoByName(langName) : languageId;

        return (
            <div>
                <Popup id='create-set'
                       className='centered'
                       header='Create new dialogue'
                       hidefooter={true}
                       ref={instance => { this.createSetPopup = instance; }}>
                    <form onSubmit={this.createNewDialogue}>
                        <input type='text' id='new-set-name' name='new-set-name' placeholder='Dialogue name' />
                        <div>
                            <select id='new-set-language' name='new-set-language'>
                            {this.supportedLanguages().map((lang: string, l: number) =>
                                <option value={lang} key={l}>{lang}</option>
                            )}
                            </select>
                        </div>
                        <br/><br/>
                        <div>
                            <button className='button primary' disabled={createNewDialogueProgress}>Create new dialogue</button>
                        </div>
                    </form>
                </Popup>

                <div id='speakset-selection'>
                    <div id='find-set'>
                        <form onSubmit={this.findDialogue}>
                            <input type='text' id='search-value' name='search-value' placeholder='Search by dialogue ID/Seq'/>
                            <button className='button' disabled={serverUpdateInProgress}>Search by ID/Seq</button>
                        </form>
                        {dialog && dialog.seqAlpha &&
                        <div id='selection-buttons'>
                            <Link  className='ghost' to={'/' + langName + '/learning/' + dialog.seqAlpha + '?course=' + (courseName ? courseName : 'noCourseName')}>View</Link>
                        </div>
                        }
                        {dialog && dialog.seqAlpha &&
                        <div id='version'>
                            <span className='secondary-text is-normal-weight'>Version: {dialog.version}</span>
                        </div>
                        }
                    </div>

                    <button onClick={this.onClickCreate} className='ghost' id='new-set'>+ New dialogue</button>
                </div>

                {dialog && !serverUpdateInProgress && !createNewDialogueProgress ?
                    (
                        <div id='speakset-editor'>
                            <header>
                                <div className='header'>
                                    <h1>{dialog.srcName || dialog.trgName}</h1>
                                    <div id='category-selection-area'>
                                        <Select
                                            defaultValue={isNullOrUndefined(dialog.categories)
                                                ? []
                                                : dialog.categories.map(categoryValue => DialogueService.getCategoryByValue(categoryValue, langISO))}
                                            options={DialogueService.CATEGORIES(langISO)}
                                            onChange={this.changeCategories}
                                            isMulti
                                            closeMenuOnSelect={false}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button className='button action-close' onClick={self.close}>close</button>
                                    <button className='button' onClick={this.save}>save</button>
                                </div>
                            </header>

                            <div id='group-tabs'>
                                {dialog.contexts.map((context: Context, i: number) =>
                                    <div className={(this.state.activeContextIdx === i ? 'active' : '')}
                                    onClick={() => {this.selectContext(i); }}
                                    key={i}>
                                        <div>{(i + 1) + '. ' +  (context.name || '')}</div>
                                        <div className='context-id'>{context.id}</div>
                                        <div className='action-move-wrapper'>
                                            <div className='action-move' onClick={() => this.moveContext(i, false)} style={(this.state.activeContextIdx === i ? {display: 'flex'} : {display: 'none'})}> ← </div>
                                            <div className='action-move' onClick={() => this.moveContext(i, true)} style={(this.state.activeContextIdx === i ? {display: 'flex'} : {display: 'none'})}> → </div>
                                        </div>
                                    </div>
                                )}
                                <button className='ghost action-add' onClick={this.addContextToDialog}>+</button>
                            </div>

                            <div id='group-info'>
                                <label>
                                    <span>Group name</span>
                                    <input type='text' value={activeContext.name || ''} onChange={(e) => this.changeActiveContextName(e)} name='group-name' placeholder='Group name' />
                                </label>
                                <label>
                                    <span>Group Video ID</span>
                                    <input id='videoId' type='text' value={activeContext.video || ''} onChange={(e) => this.changeActiveContextVideo(e)} name='group-video' placeholder='Group video id' />

                                    <div className='select-wrapper'>
                                        <select onChange={(e) => this.changeActiveContextVideoSource(e)} name='group-video-source'>
                                            <option value='youtube' selected={activeContext.videoSource === 'youtube' ? true : false}> Youtube </option>
                                            <option value='vimeo' selected={activeContext.videoSource === 'vimeo' ? true : false}> Vimeo </option>
                                        </select>
                                    </div>
                                </label>
                                <label>
                                    <span>Slides</span>
                                    <p>Current: {activeContext.slideGroups.length}</p> <Link to={`/editor/slides/${dialog.seqAlpha}/${activeContext.id}`} target='_blank'>Edit</Link>
                                </label>
                                <div className='action-remove' onClick={() => this.removeContext()}>
                                    <svg width='9' height='12'>
                                        <path d='M3 1V0h3v1h2.662L9 2H0l.393-1H3zM0 3h9l-1 9H1L0 3z' fill='#000' fillRule='evenodd'/>
                                    </svg>
                                </div>
                            </div>

                            <div id='task-cont'>

                                <div id='scroll-cont'>
                                    {activeContext.intents.map((intent: Intent, i: number) =>
                                        <div className='task' id={'t-' + i} key={i}>

                                            <div className='question'>
                                                <label>
                                                    <span>
                                                        Question {i + 1} &nbsp;
                                                        <div className='action-remove' onClick={() => this.removeIntent(i)}>
                                                            <svg width='9' height='12'>
                                                                <path d='M3 1V0h3v1h2.662L9 2H0l.393-1H3zM0 3h9l-1 9H1L0 3z' fill='#000' fillRule='evenodd'/>
                                                            </svg>
                                                        </div>
                                                        <div className='action-move' onClick={() => this.moveIntent(i, false)}> ← </div>
                                                        <div className='action-move' onClick={() => this.moveIntent(i, true)}> → </div>

                                                        <div className='intent-id'>{intent.id}</div>

                                                        <div className='action-download' onClick={() => this.downloadTts(intent.question)}>
                                                            D
                                                        </div>
                                                        <div className='action-sound' onClick={() => this.tts(intent.question)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-volume-2">
                                                                <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                                                                <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
                                                            </svg>
                                                        </div>
                                                    </span>
                                                    <textarea value={intent.question || ''} onChange={(e) => this.setIntentQuestionValue(e, i)} placeholder='Add a question' />
                                                </label>
                                            </div>
                                            <div className='hints'>
                                                <figure style={{backgroundImage: 'url(' + (intent.image && intent.image.reference ? intent.image.reference : '') + ')'}}>
                                                    <figcaption>{intent.imageHint}</figcaption>
                                                </figure>
                                                <div>
                                                    <label>
                                                        <span>Image url</span>
                                                        <input type='text' value={intent.image && intent.image.reference ? intent.image.reference : ''} onChange={(e) => this.setIntentImageUrl(e, i)} placeholder='Add an image url' />
                                                    </label>
                                                    <label>
                                                        <span>Image hint</span>
                                                        <input type='text' value={intent.imageHint || ''} onChange={(e) => this.setIntentImageHint(e, i)} placeholder='Add an image hint' />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='answers'>
                                                <label><span>Possible answers</span></label>
                                                {intent['answersMeta'].map((answer: any, a: number) =>
                                                    <div className='answer' key={a}>
                                                        <label>
                                                            <textarea value={answer.value || ''} className={'input ' + (answer.invalid === true ? 'invalid' : '')} onChange={(e) => this.onAnswerKeyUp(e, i, a)} />
                                                            <div className='action-remove' tabIndex={-1} onClick={() => this.removeAnswer(i, a)}>
                                                                <svg width='9' height='12'>
                                                                    <path d='M3 1V0h3v1h2.662L9 2H0l.393-1H3zM0 3h9l-1 9H1L0 3z' fill='#000' fillRule='evenodd'/>
                                                                </svg>
                                                            </div>
                                                        </label>
                                                    </div>
                                                )}
                                                <div className='answer new'><label><input className='input' onFocus={(e) => this.addAnswer(e, i)} placeholder='Add a new answer'/></label></div>
                                            </div>
                                            <div className='answer-preview'>
                                                <div><label><span>Answers preview</span></label></div>
                                                {intent['answersMeta'].map((answer: any, a: number) =>
                                                    <div className='preview-block' key={a}>
                                                        <div className='preview-correct'>
                                                            {answer.answers.map((correctAnswer: string, ca: number) =>
                                                                <div key={ca}>{correctAnswer}</div>
                                                            )}
                                                        </div>
                                                        <div className='preview-wrong'>
                                                            {answer.wrongAnswers.map((wrongAnswer: string, wa: number) =>
                                                                <div key={wa}>{wrongAnswer}</div>
                                                            )}
                                                        </div>
                                                        <div className='download-sound'>
                                                            {answer.answers.filter((answer: string) => { return answer !== '' }).map((correctAnswer: string, cas: number) =>
                                                                <div className='action-download' onClick={() => this.downloadTts(correctAnswer)} key={cas}>
                                                                    D
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='preview-sound'>
                                                            {answer.answers.filter((answer: string) => { return answer !== '' }).map((correctAnswer: string, cas: number) =>
                                                                <div className='action-sound' onClick={() => this.tts(correctAnswer)} key={cas}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-volume-2">
                                                                        <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                                                                        <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div onClick={this.addIntentToContext} className='add-cont'>
                                        <div>+</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div/>
                    )
                }
            </div>
        );
    }
}
