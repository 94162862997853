import * as React from 'react';
import { Link } from 'react-router-dom';
import Popup from '../../global/popup/Popup';
import { CourseCardForCoursesEditor } from './CourseCard';

import { LanguageService } from '../../../services/language.service';
import { CourseService } from '../../../services/course.service';

import { Course } from '../../../models/course.model';
import { DialogueService } from '../../../services/dialogue.service';
import { AuthService } from '../../../services/auth.service';
import { Dialogue } from '../../../models/dialogue.model';

interface LastLessonData {
    seqAlpha: string;
    title: string;
    //updated: any;
}

interface CoursesEditorState {
    courses: Course[];
    lastLessonsData: LastLessonData[];
    filter: string
}

export default class CoursesEditor extends React.Component<any, CoursesEditorState> {

    createCoursePopup: Popup;

    constructor(props) {
        super(props);

        this.state = {
            courses: [],
            lastLessonsData: null,
            filter: 'active'
        };
    }

    componentDidMount() {
        this.getAllCoursesAndLastUpdatedDialogues();
    }

    getAllCoursesAndLastUpdatedDialogues = async () => {
        try {
            const courses: Course[] = await CourseService.instance.getAllCourses();
            this.setState({ courses });

            // only Admin is allowed to see last updated dialogues
            if (AuthService.instance.isAdmin()) {
                const lastDialogues: Dialogue[] = await DialogueService.instance.getLastUpdatedDialogues(24);
                const lastLessonsData: LastLessonData[] = lastDialogues.map(dialogue => ({
                    seqAlpha: dialogue.seqAlpha,
                    title: dialogue.srcName
                }));
                this.setState({ lastLessonsData });
            }
        } catch (err) {
            console.log(err);
        }
    };

    setFilter = (filter: string) => {
        this.setState({filter});
    }

    supportedLanguages = (): string[] => {
        return LanguageService.instance.getSupportedLanguages();
    };

    onClickCreateNewCourse = (): void => {
        this.createCoursePopup.open();
    };

    createNewCourse = (): void => {};

    shouldShowCourse = (course: Course): boolean => {
        if (AuthService.instance.isAdmin() || AuthService.instance.isSupportAndAllowedToSeeCourse(course.name)) {
            if (this.state.filter === 'archived') {
                return course.archived;
            } else {
                return !!!course.archived;
            }
        }
    }

    render(): React.ReactNode {
        const { lastLessonsData } = this.state;

        return (
            <div id='editor-course-list'>
                <Popup
                    id='editor-course-list-create-course'
                    className='centered'
                    header='Create new course'
                    hidefooter
                    ref={instance => {
                        this.createCoursePopup = instance;
                    }}
                >
                    <b>!!! Create course function is not ready and new course will not be created !!!</b>
                    <form onSubmit={this.createNewCourse}>
                        <input type='text' id='new-course-name' name='new-course-name' placeholder='Course name' />
                        <div>
                            <select id='new-course-language' name='new-course-language'>
                                {this.supportedLanguages().map((lang: string, l: number) => (
                                    <option value={lang} key={l}>
                                        {lang}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <br />
                        <br />
                        <div>
                            <button className='button primary'>Create new course</button>
                        </div>
                    </form>
                </Popup>
                <div id='editor-course-list-inner' className='row'>
                    <div id='editor-all-courses' className='col'>
                        <div id='editor-all-courses-header' className='row'>
                            <h3>Courses</h3>
                            <div className='button-group'>
                                <button className={'ghost ' + (this.state.filter === 'active' ? 'active' : '')} onClick={() => this.setFilter('active')}>Active</button>
                                <button className={'ghost ' + (this.state.filter === 'archived' ? 'active' : '')} onClick={() => this.setFilter('archived')}>Archived</button>
                            </div>
                            {/* disabled because the logic is not finished, currently, we create courses manually
                            <button className='ghost' onClick={this.onClickCreateNewCourse}>
                                + create
                            </button>*/}
                        </div>
                        <div id='editor-all-courses-body' className='col'>
                            {this.state.courses.map((course: Course) => (
                                this.shouldShowCourse(course) && <CourseCardForCoursesEditor course={course} />
                            ))}
                        </div>
                    </div>
                    <div id='editor-last-lessons' className='col'>
                        <div id='editor-last-lessons-header' className='row'>
                            <h3>Last Lessons</h3>
                        </div>
                        <div id='editor-last-lessons-body' className='row'>
                            {AuthService.instance.isAdmin() && lastLessonsData !== null
                                ? lastLessonsData.map(lesson => (
                                      <div className='last-lessons-item'>
                                          <Link to={`/editor?seqAlpha=${lesson.seqAlpha}`} target='_blank'>
                                              {lesson.title}
                                          </Link>
                                      </div>
                                  ))
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
