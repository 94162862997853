export class RecognitionStatus {

    // This event is triggered every time, when channel needs to establish new WS connection with the server
    // Note that there is a difference between JS channel and WS channel. JS channel object can reconnect multiple times
    // to the WS endpoint, re-generating each time it's channelId. This is done within 'start' procedure, which can be
    // initiated on opening JS channgel (after browser audio bootstrapping), or on re-connection upon timeout, or re-connection
    // upon failure... so, any time channel thinks it needs to reconnection to WS listener on server side.
    static MSG_START = 'start';
    // This even is triggered every time "stop" procedure is initiated, which closes connection to WS endpoint.
    // Note that audio may still be coming in from mic, in such case it is being "swallowed" and not sent to server anymore
    static MSG_STOP = 'stop';
    // This event is triggered when new channelId is generated and new connection to WS endpoint is established.
    static MSG_NEW_CHANNEL = 'new channel';
    // This event is triggered when SockJS/Stomp connection is initiated
    static MSG_CONNECT = 'connect';
    // This event is triggered when SockJS/Stomp connection was successfully established
    static MSG_CONNECTED = 'connected';
    // This event is triggered when SockJS/Stomp connection attempt failed, additional data {error: <error>} is provided
    static MSG_NOT_CONNECTED = 'not connected';
    // This event is triggered when browser failed to establish WS connection (through SockJS/Stomp, assigned as a listener: client.ws.onerror)
    // or when browser failed to receive "configuration" response within 4 seconds. "Configuration" request is done when channel is opened (it does
    // some context bootstrapping, like providing language and grammar to STT). Additional data {error: <error>} is provided.
    // This event follows MSG_NOT_CONNECTED.
    static MSG_CONNECTION_FAILURE = 'connection failure';
    // This event is triggered when SockJS/Stomp disconnection is initiated
    static MSG_DISCONNECT = 'disconnect';
    // This event is triggered when SockJS/Stomp disconnection was successfully executed
    static MSG_DISCONNECTED = 'disconnected';
    // Event is triggered when Reconnector is started. Reconnector is started after receiving response response upon successful STT configuration.
    // Reconnector forcefully reconnects WS endpoint after every 60 seconds. It is expected that user should be able to provides the response within 60 seconds
    // and move on to the next intent initiating also new channel... in general. Or if it takes user more than 60 seconds, then we rather refresh WS connection, because
    // also Google forces us to do so.
    static MSG_START_RECONNECTOR = 'start reconnector';
    // Event is triggered when the channel is stopped.
    static MSG_STOP_RECONNECTOR = 'stop reconnector';
    // Event is triggered when reconnector initiated successful reconnection
    static MSG_RECONNECT = 'reconnect';
    // The event is triggered when sending an audio chunk for subscription to the WS listener: /wss/speech/transcribe/<channel>
    // The even contains additional data:
    // request - the raw data which is sent to the listener for subscription
    // firstBuffer - flag, which says whether this is the first chunk sent within this channel
    // byteLength - heuristically estimated the size (in bytes) of the request content
    // srcSampleRate - source sample rate
    // dstSampleRate - destination sample rate
    static MSG_SEND_TRANSCRIBE = 'send transcribe';
    // Event is triggered when channel is subscribing to /topic/speech/transcribed/<channel> when establishing connection to WS endpoint
    static MSG_SUBSCRIBE_TRANSCRIBED = 'subscribe transcribed';
    // Event is triggered when server delivered transcription result to the browser. It contains additional data:
    // response - JSON response from the server
    // results - array of possible transcriptions extracted from response
    // byteLength - heuristically estimated the size (in bytes) of the response content
    static MSG_RECEIVE_TRANSCRIBED = 'receive transcribed';
    // This event is triggered when server responded, but there was no transcribed text to the given audio stream
    // Basically it means that server failed to transcribe the given audio
    static MSG_TRANSCRIBE_EMPTY = 'transcribe empty';
    // Event is triggered when configuration request is sent to /wss/speech/configure/<channel>. It contains additional data:
    // languageCode - language which STT should assume
    // sampleRate - audio samping rate
    // speechContext - STT context/grammar, basically possible/expected answers
    static MSG_SEND_CONFIGURE = 'send configure';
    // Event is triggered when channel is subscribing to /topic/speech/configured/<channel> when establishing connection to WS endpoint
    static MSG_SUBSCRIBE_CONFIGURED = 'subscribe configured';
    // Event is triggered when channel received a message from the subscribed /topic/speech/configured/<channel>. It is expected to be delivered
    // only once in the beginning of the lifecycle of WS channel. This response confirms successful configuration of STT language/context.
    static MSG_RECEIVE_CONFIGURED = 'receive configured';
    // Server closed event - when channel closing was initiated by the server
    static MSG_SERVER_CLOSED = 'server closed';

    static MSG_START_VOICE = 'start voice';
    static MSG_END_VOICE = 'end voice';
    static MSG_START_TRANSCRIBE = 'start transcribe';
    static MSG_END_TRANSCRIBE = 'end transcribe';

    constructor(
        public channelId: string,
        public message: string,
        public data?: any
    ) {
    }
}
