import * as React from 'react';

export default class CountDown extends React.Component<any, any> {

    state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    };

    componentDidMount(): void {
        if (this.props.deadline > new Date()){
            this.initializeClock(this.props.deadline);
        }
    }

    getTimeRemaining = (endtime: Date): object => {
        const t: number = endtime.getTime() - new Date().getTime();
        const seconds: number = Math.floor((t / 1000) % 60);
        const minutes: number = Math.floor((t / 1000 / 60) % 60);
        const hours: number = Math.floor((t / (1000 * 60 * 60)) % 24);
        const days: number = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            'total': t,
            'days': days,
            'hours': hours,
            'minutes': minutes,
            'seconds': seconds
        };
    };

    onEnd = (): void => {
        this.props.onend();
    };

    initializeClock = (endtime: Date): void => {
        const updateClock = () => {
            const t = this.getTimeRemaining(endtime);
            this.setState({
                days: t['days'],
                hours: ('0' + t['hours']).slice(-2),
                minutes: ('0' + t['minutes']).slice(-2),
                seconds: ('0' + t['seconds']).slice(-2)
            });

            if (t['total'] <= 0) {
                clearInterval(timeinterval);
                this.onEnd();
            }
        };
        updateClock();
        const timeinterval = setInterval(updateClock, 1000);
    };

    render() {
        return (
            <div className="countdown">
                <div className={this.props.hideDays? 'hidden' : ''}>
                    <span className="days">{this.state.days}</span>
                    <div className="smalltext">Days</div>
                </div>
                <div>
                    <span className="hours">{this.state.hours}</span>
                    <div className="smalltext">Hours</div>
                </div>
                <div>
                    <span className="minutes">{this.state.minutes}</span>
                    <div className="smalltext">Min</div>
                </div>
                <div>
                    <span className="seconds">{this.state.seconds}</span>
                    <div className="smalltext">Sec</div>
                </div>
            </div>
        );
    }
}
