import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import {AuthService} from "../services/auth.service";

import * as iconClose from '../../../../assets/images/newmagic/icon-close.svg';

@withNamespaces('reset')
export default class ResetPasswordInit extends React.Component<any, any> {

    state = {
        redirectToReferrer: false,
        errorEmailNotExists: null,
        email: null,
        resetSuccess: null
    };

    requestReset = (e): void => {
        e.preventDefault();
        const email: string = $('#email').val() as string;
        this.setState({
            redirectToReferrer: false,
            errorEmailNotExists: null,
            email: email,
            resetSuccess: null
        });
        AuthService.instance.resetPasswordInit(email)
            .then(() => {
                this.setState({ errorEmailNotExists: null, redirectToReferrer: false, resetSuccess: true });
            })
            .catch((error) => {
                this.setState({ errorEmailNotExists: JSON.stringify(error), redirectToReferrer: false, resetSuccess: false })
            });
    };

    render(): React.ReactNode {
        const from = '/';
        const { errorEmailNotExists, email, resetSuccess, redirectToReferrer } = this.state;
        const { t, i18n } = this.props;
        if (redirectToReferrer) {
            return (
                <Redirect to={from}/>
            )
        }
        return (
            <div className="auth-password auth-step">
                <div className="auth-header popup-header">
                    <header>Recover your password</header>
                    <img className="action-close" src={iconClose} />
                </div>
                <div className="inner">
                    <form name="pwForm" onSubmit={this.requestReset} noValidate className={(resetSuccess ? 'success' : '')}>
                        <div>
                            <Link data-step="login" to="/signin">‹ Back to login</Link><br/><br/>
                        </div>
                        <div className="input-row">
                            <input type="email" id="email" name="email" placeholder="Email"/>
                        </div>
                        <div className="input-row submit-row">
                            <div className="info-row">
                                {errorEmailNotExists &&
                                <div><span><div>Sorry, we couldn't find this email address</div> Please check and try again.</span></div>
                                }
                            </div>

                            <button className="button" type="submit">Reset my password</button>
                        </div>
                        {!!resetSuccess &&
                        <div id="success">
                            <div>We've sent a password reset link to <strong>{email}</strong></div>
                            <br/>
                            <div>Please check your email box and its spam folder.</div>
                            <div>The link is valid for the next <strong>24 hours</strong>.</div>
                            <div>If you don't reach your email box in that time just request password reset one more time.</div>
                            <figure>
                                <svg viewBox="0 0 19 13">
                                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
                                       strokeLinecap="round" strokeLinejoin="round">
                                        <g className="check" transform="translate(-536.000000, -259.000000)"
                                           stroke="#fff" strokeWidth="3">
                                            <g id="Welcome" transform="translate(0.000000, 58.000000)">
                                                <path
                                                    d="M549,198 C549,198 548.086348,205.264289 549,212 C545.5,211.313296 542,212 542,212"
                                                    id="Rectangle-299"
                                                    transform="translate(545.500000, 205.000000) rotate(-315.000000) translate(-545.500000, -205.000000) "/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </figure>
                        </div>
                        }
                    </form>
                </div>
            </div>
        );
    }
}
