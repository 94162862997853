import i18n from 'i18next';
import * as LngDetector from 'i18next-browser-languagedetector';

const en = require('./locales/en.json');
// const de = require('./locales/de.json');

i18n
    .use(LngDetector)
    .init({
        // resources: { en, de },
        resources: { en },
        fallbackLng: 'en',
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            // cookieMinutes: 10,
            // cookieDomain: 'myDomain',

            // optional htmlTag with lang attribute, the default is:
            // htmlTag: document.documentElement
        }
    });
export default i18n;
