interface AudioConfig {
    channel: number;
    sampleRate: number;
}

export class AudioSampler {

    public srcConfig: AudioConfig;
    public dstConfig: AudioConfig = {
        channel: 1,
        sampleRate: 16000
    };
    constructor(srcConfig: AudioConfig) {
        this.srcConfig = srcConfig;
    }
    public encode(srcBuffer: Float32Array, header = false): Int8Array {
        // http://soundfile.sapp.org/doc/WaveFormat/
        const self = this;
        const downsampleBuffer = self.downsampleBuffer(
            srcBuffer,
            self.srcConfig.sampleRate,
            self.dstConfig.sampleRate
        );

        if (!downsampleBuffer) {
            return null;
        }

        const audioLength = downsampleBuffer.length * 2;

        if (!header) {
            const b = new ArrayBuffer(audioLength);
            self.floatTo16BitPCM(new DataView(b), 0, downsampleBuffer);
            return (new Int8Array(b));
        }

        const buffer = new ArrayBuffer(44 + audioLength);

        const bitsPerSample = 16;
        const bytesPerSample = bitsPerSample / 8;

        // audio stream length unknown, set to 0
        const fileLength = 0;

        const view = new DataView(buffer);
        // RIFF identifier
        self.SetString(view, 0, 'RIFF');
        // file length
        view.setUint32(4, fileLength, true);
        // RIFF type & Format
        self.SetString(view, 8, 'WAVEfmt ');
        // format chunk length
        view.setUint32(16, 16, true);
        // sample format (raw)
        view.setUint16(20, 1, true);
        // channel count
        view.setUint16(22, self.dstConfig.channel, true);
        // sample rate
        view.setUint32(24, self.dstConfig.sampleRate, true);
        // byte rate (sample rate * block align)
        view.setUint32(28, self.dstConfig.sampleRate * self.dstConfig.channel * bytesPerSample, true);
        // block align (channel count * bytes per sample)
        view.setUint16(32, self.dstConfig.channel * bytesPerSample, true);
        // bits per sample
        view.setUint16(34, bitsPerSample, true);
        // data chunk identifier
        self.SetString(view, 36, 'data');
        // data chunk length
        view.setUint32(40, fileLength, true);
        self.floatTo16BitPCM(view, 44, downsampleBuffer);

        return (new Int8Array(buffer));
    }

    private downsampleBuffer(
        buffer: Float32Array,
        srcRate: number, dstRate: number
    ): Float32Array {
        if (dstRate === srcRate || dstRate > srcRate) {
            return buffer;
        }

        const ratio = srcRate / dstRate;
        const dstLength = Math.round(buffer.length / ratio);
        const dstBuffer = new Float32Array(dstLength);
        let srcOffset = 0;
        let dstOffset = 0;
        while (dstOffset < dstLength) {
            const nextSrcOffset = Math.round((dstOffset + 1) * ratio);
            let accum = 0;
            let count = 0;
            while (srcOffset < nextSrcOffset && srcOffset < buffer.length) {
                accum += buffer[srcOffset++];
                count++;
            }
            dstBuffer[dstOffset++] = accum / count;
        }

        return dstBuffer;
    }

    private SetString(view: DataView, offset: number, str: string): void {
        for (let i = 0; i < str.length; i++) {
            view.setUint8(offset + i, str.charCodeAt(i));
        }
    }

    private floatTo16BitPCM(view: DataView, offset: number, input: Float32Array): void {
        for (let i = 0; i < input.length; i++ , offset += 2) {
            const s = Math.max(-1, Math.min(1, input[i]));
            view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }
    }
}
