import * as uuid from 'uuid';

import { syntax } from '../../../../MagicPlayer/config/syntax';

import { SlideGroup, Slide } from '../../../../../models/slide.model';
import { roundToPrecision } from './functions';

/**
 * Turns
 * 
 * ``` a b c ```
 * 
 * to
 * 
 * ```
 * [a] b c
 * a [b] c
 * a b [c]
 * ```
 */
export const karaokeMacro = (slideGroup: SlideGroup): SlideGroup => {
    const slides: Slide[] = [];
    const startSlide = slideGroup.slides[0];

    const words = slideGroup.slides[0].text.split(' ');

    words.forEach((word, idx) => {
        const mWords = [...words];
        const letters = word.split('');
        const lastLetter = letters[letters.length - 1];

        if (idx !== mWords.length - 1 || !(lastLetter === '.' || lastLetter === '?' || lastLetter === '!')) {
            mWords[idx] = syntax.bold.open + word + syntax.bold.close;
        } else {
            const mWord = letters.slice(0, -1).join('');
            mWords[idx] = syntax.bold.open + mWord + syntax.bold.close + lastLetter;
        }

        slides.push(new Slide({
            id: uuid(),
            from: roundToPrecision(startSlide.from + (slideGroup.end - startSlide.from) * (idx / words.length), 0.1),
            text: mWords.join(' '),
            styles: startSlide.styles
        }))
    });

    slideGroup.slides = slides;

    return slideGroup;
}
