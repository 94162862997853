import * as React from 'react';
import {SystemService} from '../services/system.service';
import {CSSProperties} from "react";

const tableStyle = {
    borderCollapse: 'collapse',
    border: '1px solid black',
    padding: '5px'
} as CSSProperties;

export default class System extends React.Component<any, any> {

    state = {
        leaders: [],
        nodes: []
    };

    componentWillMount(): void {
        SystemService.instance.getAllLeaders()
            .then((leaders) => {
                this.setState({leaders: leaders});
            })
            .catch((error) => {
                console.log('getAllLeaders failed ' + error);
            });

        SystemService.instance.getAllNodes()
            .then((nodes) => {
                this.setState({nodes: nodes});
            })
            .catch((error) => {
                console.log('getAllNodes failed ' + error);
            });
    }

    render(): React.ReactNode {
        const {leaders} = this.state;
        const {nodes} = this.state;

        return (
            <div style={{marginTop: '100px'}}>
                <div><span>Leader details (collection "system_leader"): { leaders.length }</span></div>
                <br/>
                <table style={tableStyle}>
                    <thead style={tableStyle}>
                    <tr style={tableStyle}>
                        <th style={tableStyle}>ID</th>
                        <th style={tableStyle}>Type</th>
                        <th style={tableStyle}>Node</th>
                        <th style={tableStyle}>Version</th>
                        <th style={tableStyle}>Updated</th>
                    </tr>
                    </thead>
                    <tbody style={tableStyle}>
                    {leaders.map((leader, i) => <tr key={i}>
                        <td style={tableStyle}>
                            <p>{leader.id}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{leader.type}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{leader.node}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{leader.version}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{leader.updated}</p>
                        </td>
                    </tr>)
                    }
                    </tbody>
                </table>
                <br/>
                <div><span>Nodes details (collection "system_node"): { nodes.length }</span></div>
                <br/>
                <table style={tableStyle}>
                    <thead style={tableStyle}>
                    <tr style={tableStyle}>
                        <th style={tableStyle}>ID</th>
                        <th style={tableStyle}>Host</th>
                        <th style={tableStyle}>Registered</th>
                        <th style={tableStyle}>LastHeartbeat</th>
                    </tr>
                    </thead>
                    <tbody style={tableStyle}>
                    {nodes.map((node, i) => <tr key={i}>
                        <td style={tableStyle}>
                            <p>{node.id}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{node.host}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{node.registered}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{node.lastHeartbeat}</p>
                        </td>
                    </tr>)
                    }
                    </tbody>
                </table>
                <br/>
            </div>
        );
    }
}
