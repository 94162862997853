import * as Promise from 'promise';
import axios from 'axios/index';
import {AbstractService} from './abstract.service';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';

export class UserService extends AbstractService {

    private static _instance: UserService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    getByLogin(login: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/users/' + login
            )
                .then((response) => {
                    const user = response.data;
                    if (user) {
                        resolve(user);
                    } else {
                        reject('No user found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getByEmail(email: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/users/email/' + email
            )
                .then((response) => {
                    const user = response.data;
                    if (user) {
                        resolve(user);
                    } else {
                        reject('No user found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getByReference(reference: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/users/reference/' + reference
            )
                .then((response) => {
                    const user = response.data;
                    if (user) {
                        resolve(user);
                    } else {
                        reject('No user found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getById(id: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/users/id/' + id
            )
                .then((response) => {
                    const user = response.data;
                    if (user) {
                        resolve(user);
                    } else {
                        reject('No user found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getByReferralCode(referralCode: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/users/referralcode/' + referralCode
            )
                .then((response) => {
                    const user = response.data;
                    if (user) {
                        resolve(user);
                    } else {
                        reject('No user found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    addUsers(data: any): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/users',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject('No data received');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    updateUser(data: any): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.put(
                '/api/users',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject('No data received');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    updateUsersDueToSelectedCourse(data: any): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.patch(
                '/api/users/selectedcourse',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject('No data received');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    didUserEverBuyTutorSessions(): boolean {
        const user: User = this.getUser();

        if (user !== null && user.tutorSessionsCount !== null && user.tutorSessionsCount !== -1) {
            return true;
        } else {
            return false;
        }
    }

    // Returns the **total** amount of tutor sessions left for the given user
    getTutorSessionsCount(): number {
        const user: User = this.getUser();

        if (this.didUserEverBuyTutorSessions()) {
            return user.tutorSessionsCount;
        } else {
            return 0;
        }
    }

    private getUser(): any {
        return AuthService.instance.getUser();
    }
}
