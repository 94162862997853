import * as React from 'react';
import { Link } from 'react-router-dom';

import { SubscriptionPlan } from '../../models/subscription.plan.model';

interface IProps {
    plan: SubscriptionPlan;
    userFirstName: string;
    is10WeeksChallenge: boolean;
}

export class PaymentSuccessful extends React.PureComponent<IProps> {
    render() {
        return (
            <div id='step-confirm'>
                <div className='frame'>
                    <div id='payment-confirmation'>
                        <header>
                            <svg width='55' height='55' viewBox='0 0 55 55' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M26.5 53C11.864 53 0 41.136 0 26.5S11.864 0 26.5 0 53 11.864 53 26.5 41.136 53 26.5 53zm12.859-36.666c-4.96 2.73-9.907 6.288-15.107 12.35-1.86-2.176-4.61-3.839-7.554-5.183-1.242-.566-2.583-.4-3.307.664-.723 1.065-.415 2.494.688 3.192 3.766 2.385 6.038 4.28 8.425 7.65.963 1.359 2.958 1.305 3.976-.05 5.078-6.753 10.404-11.456 15.404-14.709 1.078-.702 1.462-2.095.764-3.176-.697-1.08-2.127-1.378-3.29-.738z'
                                    fill='#FFF'
                                    fillRule='evenodd'
                                />
                            </svg>
                            <div>We received your payment!</div>
                        </header>
                        <p>
                            <strong>Dear {this.props.userFirstName},</strong>
                            <br />
                            <br />
                            congratulations on purchasing “{this.props.plan.description}”!
                            {this.props.is10WeeksChallenge
                                ? ' We will shortly send you an e-mail with all the information you need. '
                                : ' We sent you an email with a confirmation of your purchase. '}
                            If you have any questions, please don’t hesitate to contact us anytime!
                            <br />
                        </p>

                        {!this.props.is10WeeksChallenge && (
                            <div className='alc'>
                                <Link className='button primary' id='goto-dashboard' to='/dashboard'>
                                    Go to my Dashboard
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
