import * as React from 'react';
import { TweenMax, Back, Power0, Power1, Expo, Power4 } from 'gsap';
import { UserAgentService } from '../../services/user.agent.service';
import { Context } from '../../models/context.model';
import { LearnedContext } from '../../models/learned.context.model';
import { TimelineMax } from 'gsap/TimelineMax';
import { SpeechService } from '../../services/speech.service';
import { ChannelType } from '../../services/recognition/stream.channel.factory';

const ID_MODE_OPTIONS_HTTP = 'mode-options-http';
const ID_MODE_OPTIONS_WS = 'mode-options-ws';
const MODE_ACTIVE = 'active';

interface IProps {
    afterClose: VoidFunction
}

export default class Settings extends React.PureComponent<IProps, any> {
    el: HTMLDivElement;

    // This represents the temporarily selected sttMode but it is not saved to the localStorage yet, it will be saved when clicked "save"
    tempSelectedMode: ChannelType = SpeechService.instance.getSttMode();

    // HTML elements to highlight or not highlight when selected
    elModeHttp: HTMLElement;
    elModeWs: HTMLElement;

    componentDidMount(): void {
        this.initMainElements();
        this.initModeElements();
    }

    private initMainElements = () => {
        TweenMax.set(this.el, { autoAlpha: 0, x: 40 });
        TweenMax.set(this.el, { css: { display: 'none' } });
    };

    // Just for styling purposes
    private initModeElements = () => {
        this.elModeHttp = document.getElementById(ID_MODE_OPTIONS_HTTP);
        this.elModeWs = document.getElementById(ID_MODE_OPTIONS_WS);

        // Initially declare Html element of which mode should be highlighted
        if (this.tempSelectedMode === ChannelType.WS) {
            this.elModeWs.classList.add(MODE_ACTIVE);
        } else {
            this.elModeHttp.classList.add(MODE_ACTIVE);
        }
    };

    open = (): void => {
        const tl = new TimelineMax();
        tl.set(this.el, { css: { display: 'block' } }).to(this.el, 0.3, { x: 0, autoAlpha: 1 });
    };

    saveAndClose = async () => {
        this.save();
        await this.close();
        this.props.afterClose();
    }

    closeAndReset = async () => {
        await this.close();
        this.reset();
        this.props.afterClose();
    }

    save = (): void => {
        // save stuff to the localStorage
        SpeechService.instance.setSttMode(this.tempSelectedMode);
    };

    close = (): Promise<void> => {
        return new Promise<void>((resolve) => {
            const tl = new TimelineMax({ onComplete: resolve });
            tl.to(this.el, 0.3, { x: 40, autoAlpha: 0 }).set(this.el, { css: { display: 'none' } });
        });  
    };

    // Find the initial data again and reset the local data and the styling according to that
    reset = (): void => {
        const ct: ChannelType = SpeechService.instance.getSttMode();
        if (ct === ChannelType.WS) {
            this.setSttModeToWs();
        } else {
            this.setSttModeToHttp();
        }
    }

    // Using this custom methods to avoid making the whole component stateful just for some highlighting
    setSttModeToHttp = () => {
        // First change the temporary var
        this.tempSelectedMode = ChannelType.HTTP_MANUAL;

        // Then change the highlight
        // Didn't want to import jquery, because it is heavy and not needed for this that much
        this.elModeWs.classList.remove(MODE_ACTIVE);
        this.elModeHttp.classList.add(MODE_ACTIVE);
    };

    // Using this custom methods to avoid making the whole component stateful just for some highlighting
    setSttModeToWs = () => {
        // First change the temporary var
        this.tempSelectedMode = ChannelType.WS;

        // Then change the highlight
        // Didn't want to import jquery, because it is heavy and not needed for this that much
        this.elModeHttp.classList.remove(MODE_ACTIVE);
        this.elModeWs.classList.add(MODE_ACTIVE);
    };

    render(): React.ReactNode {
        return (
            <div id='learning-settings' ref={c => (this.el = c)}>
                <div className='inner'>
                    <header className='area-header'>Settings</header>

                    <section>
                        <div className='label'>new</div>
                        <header className='section-header'>Dialogue mode</header>
                        <div id='setting-actions' className='button-row'>
                            <button className='button' onClick={this.saveAndClose}>
                                Save
                            </button>
                            <button className='ghost' onClick={this.closeAndReset}>
                                Close
                            </button>
                        </div>

                        <div className='mode-options'>
                            <div id={ID_MODE_OPTIONS_WS} onClick={this.setSttModeToWs}>
                                <svg className='mode-icon' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M35 53a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H17.024c-1.783 3.577-4.061 6.097-6.835 7.56-.157.082-.462.215-.914.398a.577.577 0 0 1-.74-.773c.176-.383.296-.648.36-.798.818-1.907.992-4.036.522-6.386L3 69a2 2 0 0 1-2-2V55a2 2 0 0 1 2-2h32zm23-26a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2l-6.417.001c-.47 2.35-.296 4.48.522 6.386.064.15.184.415.36.798a.575.575 0 0 1-.74.773 11.966 11.966 0 0 1-.914-.399c-2.774-1.462-5.052-3.982-6.835-7.558L26 43a2 2 0 0 1-2-2V29a2 2 0 0 1 2-2zM35 1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H17.024c-1.783 3.577-4.061 6.097-6.835 7.56-.157.082-.462.215-.914.398a.577.577 0 0 1-.74-.773c.176-.383.296-.648.36-.798.818-1.907.992-4.036.522-6.386L3 17a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h32z'
                                        stroke='#CAC3D7'
                                        stroke-width='2'
                                        fill='none'
                                        fill-rule='evenodd'
                                    />
                                </svg>
                                <header>Handsfree</header>
                                <p>
                                    Get used to a non-stop real-life conversations.
                                    <br />
                                    <br />
                                    Yes, they are sometimes stressful and, usually, don’t have an on-off button.
                                </p>
                            </div>
                            <div id={ID_MODE_OPTIONS_HTTP} onClick={this.setSttModeToHttp}>
                                <svg className='mode-icon' xmlns='http://www.w3.org/2000/svg'>
                                    <g fill='none' fill-rule='evenodd'>
                                        <path
                                            d='M13 19.035V25c1.932-.537 3.265-1.173 4-1.908.629-.628 1.208-1.718 1.737-3.269C24.137 21.763 28 26.93 28 33c0 4.403-2.032 8.33-5.21 10.897A3 3 0 0 0 17 45V33a3 3 0 0 0-6 0v13.678C4.71 45.305 0 39.702 0 33c0-7.396 5.735-13.452 13-13.965z'
                                            fill='#CAC3D7'
                                            opacity='.253'
                                        />
                                        <path
                                            d='M14 25a8 8 0 0 1 3.001 15.418L17 33a3 3 0 0 0-6 0v7.418A8.003 8.003 0 0 1 14 25z'
                                            fill='#CAC3D7'
                                            opacity='.558'
                                        />
                                        <path
                                            d='M13.67 30c1.622 0 2.955 1.309 2.955 2.908v18.255c0-.458.007-6.876.019-6.981.167-1.442 1.425-2.581 2.935-2.581 1.621 0 2.954 1.307 2.953 2.909v6.884l.001-.078c.003-.958.01-4.124.02-4.449v-.025c.173-1.362 1.364-2.43 2.793-2.43 1.546 0 2.813 1.245 2.813 2.77v4.213c.002-.48.004-1.69.007-1.731.103-1.43 1.327-2.573 2.807-2.573 1.546 0 2.814 1.245 2.814 2.769l-.033 9.346c-.006 2.552.188 4.83-.555 7.308-1.026 3.42-2.978 7.012-3.433 9.949H12.58c-3.046-6.79-4.084-6.087-5.817-11.234-.886-2.639-1.32-6.38-2.207-8.522-.453-1.095-.988-3.471.005-4.47.823-.83 2.13-.262 2.97.247a6.21 6.21 0 0 1 2.455 2.834c.22.527.727 1.607.727 2.24v-24.65c0-1.596 1.332-2.908 2.957-2.908zM39 1a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H21.024c-1.783 3.577-4.061 6.097-6.835 7.56-.157.082-.462.215-.914.398a.577.577 0 0 1-.74-.773c.176-.383.296-.648.36-.798.818-1.907.992-4.036.522-6.386L7 17a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h32z'
                                            stroke='#CAC3D7'
                                            stroke-width='2'
                                        />
                                    </g>
                                </svg>
                                <header>Click to speak</header>
                                <p>
                                    Decide yourself when you’re ready to speak.
                                    <br />
                                    <br />
                                    For some users, the software will work more stable in this mode.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
