export class Slide {
    public id: string;
    public from: number;
    public text: string;
    public styles: SlideStyles;
    constructor(
        { id, from, text, styles }: { id: string, from: number, text: string, styles: SlideStyles }
    ) {
        this.id = id;
        this.from = from;
        this.text = text;
        this.styles = styles;
    }
}

export class SlideGroup {
    public id: string;
    public slides: Slide[];
    public end: number;
    constructor(
        { id, slides, end }: { id: string, slides: Slide[], end: number }
    ) {
        this.id = id;
        this.slides = slides;
        this.end = end;
    }
}

export class SlideStyles {
    public slideWidth: number;
    constructor(
        { slideWidth }: { slideWidth: number }
    ) {
        this.slideWidth = slideWidth;
    }
}
