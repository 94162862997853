import * as Promise from 'promise';
import axios from 'axios/index';
import {RecognitionResultsFormat} from './recognition.results.format';
import {RecognitionStatus} from './recognition.status';
import {StreamChannel} from './stream.channel';
import {AudioChannelAnalyser} from './audio.channel.analyser';
import {AudioChannelVolumeAnalyser} from './audio.channel.volume.analyser';
import {AudioSampler} from './audio.sampler';

export class HttpStreamChannel extends StreamChannel {

    private audioContext = null;
    private micStream = null;
    private audioChannelAnalyser: AudioChannelAnalyser = null;
    private audioChannelVolumeAnalyser: AudioChannelVolumeAnalyser = null;

    private lang: string;
    private speechContext: Array<string>;
    private recognitionProcessor: (results: RecognitionResultsFormat) => void;
    private statusProcessor: (status: RecognitionStatus) => void;

    public channelId;
    private configObj;

    private sampler: AudioSampler;
    private scriptNode: ScriptProcessorNode;
    private firstbuffer = true;

    private pendingAudioFile: Int8Array = null;

    private auto: boolean = true;

    private static generateUUID() {
        let d = new Date().getTime();
        if (window.performance && typeof window.performance.now === 'function') {
            d += window.performance.now(); // use high-precision timer if available
        }
        /* tslint:disable */
        return 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r&0x3 | 0x8)).toString(16);
        });
        /* tslint:enable */
    }

    private static mapLanguageISO(lang: string): string {
        if (lang) {
            lang = lang.toLowerCase();
            if (lang === 'en') {
                lang = 'en-GB';
            } else if (lang === 'de') {
                lang = 'de-DE';
            } else if (lang === 'es') {
                lang = 'es-ES';
            } else if (lang === 'fr') {
                lang = 'fr-FR';
            } else if (lang === 'it') {
                lang = 'it-IT';
            } else if (lang === 'ru') {
                lang = 'ru-RU';
            } else if (lang === 'ar') {
                lang = 'ar-SA';
            } else if (lang === 'zh') {
                lang = 'zh-CN';
            } else if (lang === 'el') {
                lang = 'el-GR';
            } else if (lang === 'he') {
                lang = 'he-IL';
            } else if (lang === 'hi') {
                lang = 'hi-IN';
            } else if (lang === 'nl') {
                lang = 'nl-NL';
            } else if (lang === 'pl') {
                lang = 'pl-PL';
            } else if (lang === 'pt') {
                lang = 'pt-BR';
            } else if (lang === 'sv') {
                lang = 'sv-SE';
            } else if (lang === 'cs') {
                lang = 'cs-CZ';
            }
            return lang;
        } else {
            return 'en-GB';
        }
    }

    public constructor(
        langISO: string,
        speechContext: Array<string>,
        recognitionProcessor: (results: RecognitionResultsFormat) => void,
        statusProcessor: (status: RecognitionStatus) => void,
        auto: boolean = true
    ) {
        super(langISO, speechContext, recognitionProcessor, statusProcessor);
        this.lang = HttpStreamChannel.mapLanguageISO(langISO);
        this.speechContext = speechContext;
        this.recognitionProcessor = recognitionProcessor;
        this.statusProcessor = statusProcessor;
        this.auto = auto;
    }

    public getChannelId(): string {
        return this.channelId;
    }

    public getDeviceLabel(): string {
        return null; // TODO : !!! not implemented
    }

    public open(): Promise<void> {
        const self = this;
        return new Promise<void>((resolve, reject) => {
            const AudioContext = (<any>window).AudioContext || (<any>window).webkitAudioContext || false;
            if (!AudioContext) {
                reject('Please upgrade your browser');
            } else {
                if (typeof (<any>navigator) === 'undefined' ||
                    typeof (<any>navigator).mediaDevices === 'undefined' ||
                    typeof (<any>navigator).mediaDevices.getUserMedia === 'undefined') {
                    reject('We cannot access your microphone.');
                } else {
                    if (self.audioContext === null) {
                        self.audioContext = new AudioContext();
                    }
                    const mediaPromise = (<any>navigator).mediaDevices.getUserMedia({audio: true});
                    mediaPromise.then((micStream) => {
                        self.micStream = micStream;
                        const microphone = self.audioContext.createMediaStreamSource(self.micStream);
                        self.scriptNode = (() => {
                            let bufferSize = 2048;
                            try {
                                return self.audioContext.createScriptProcessor(bufferSize, 1, 1);
                            } catch (error) {
                                bufferSize = 2048;
                                let audioSampleRate = self.audioContext.sampleRate;
                                while (
                                    bufferSize < 16384 &&
                                    audioSampleRate >= (2 * 16000)
                                    ) {
                                    /* tslint:disable:no-bitwise */
                                    bufferSize <<= 1;
                                    audioSampleRate >>= 1;
                                    /* tslint:enable:no-bitwise */
                                }
                                return self.audioContext.createScriptProcessor(bufferSize, 1, 1);
                            }
                        })();
                        microphone.connect(self.scriptNode);

                        self.audioChannelVolumeAnalyser = new AudioChannelVolumeAnalyser(self.audioContext);
                        self.audioChannelVolumeAnalyser.connect(microphone);

                        self.audioChannelAnalyser = new AudioChannelAnalyser(self.audioContext);
                        self.audioChannelAnalyser.connect(microphone);

                        self.scriptNode.connect(self.audioContext.destination);
                        self.sampler = new AudioSampler({
                            channel: 1,
                            sampleRate: self.scriptNode.context.sampleRate
                        });
                        self.start().then(resolve).catch(reject);
                    }).catch(reject);
                }
            }
        });
    }

    flush(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.transcribe();
            resolve();
        });
    }

    public close(): Promise<void> {
        const self = this;
        return new Promise<void>((resolve, reject) => {
            const finish = () => {
                if (self.audioContext) {
                    self.audioContext.close().then(() => {
                        for (const track of self.micStream.getTracks()) {
                            track.stop();
                        }
                        if (self.audioChannelAnalyser) {
                            self.audioChannelAnalyser.close();
                            self.audioChannelAnalyser = null;
                        }
                        if (self.audioChannelVolumeAnalyser) {
                            self.audioChannelVolumeAnalyser.close();
                            self.audioChannelVolumeAnalyser = null;
                        }
                        self.audioContext = null;
                        self.scriptNode = null;
                        self.lang = null;
                        self.speechContext = null;
                        resolve();
                    }).catch(resolve);
                } else {
                    resolve();
                }
            };
            self.stop().then(finish).catch(finish);
        });
    }

    public getAudioFrequencyData(): Uint8Array {
        return this.audioChannelAnalyser ? this.audioChannelAnalyser.getAudioFrequencyData() : new Uint8Array(0);
    }

    public getAudioVolumeData(): any {
        return this.audioChannelVolumeAnalyser ? this.audioChannelVolumeAnalyser.getAudioVolumeData() : {
            clipped: false,
            volume: 0
        };
    }

    private start(): Promise<void> {
        this.updateStatus(RecognitionStatus.MSG_START);
        const self = this;
        return new Promise<void>((resolve, reject) => {
            self.channelId = HttpStreamChannel.generateUUID();
            self.firstbuffer = true;
            self.updateStatus(RecognitionStatus.MSG_NEW_CHANNEL);
            self.configObj = {
                languageCode: self.lang,
                sampleRate: self.sampler.dstConfig.sampleRate,
                speechContext: self.speechContext
            };
            self.scriptNode.onaudioprocess = (event) => self.processAudioEvent(event);
            resolve();
        });
    }

    public stop(): Promise<void> {
        this.updateStatus(RecognitionStatus.MSG_STOP);
        const self = this;
        return new Promise<void>((resolve, reject) => {
            if (self.scriptNode) {
                self.scriptNode.onaudioprocess = (e) => {
                    e.inputBuffer.getChannelData(0);
                    // console.warn('Audio comes to the closed recognition');
                };
            }
            resolve();
        });
    }

    private processAudioEvent(event: AudioProcessingEvent) {
        const self = this;
        const result = self.audioChannelAnalyser.analyseAudio();
        if (result && result.action) {
            const rawAudioBuffer: Float32Array = event.inputBuffer.getChannelData(0);
            const data: Int8Array = self.sampler.encode(rawAudioBuffer, self.firstbuffer);
            if (self.firstbuffer) {
                self.firstbuffer = false;
            }
            if (result.action === 'stop') {
                if (this.auto) {
                    this.transcribe();
                }
            } else {
                if (result.action === 'start') {
                    self.updateStatus(RecognitionStatus.MSG_START_VOICE);
                }
                if (!self.pendingAudioFile) {
                    self.pendingAudioFile = data;
                } else {
                    self.pendingAudioFile = self.merge(self.pendingAudioFile, data);
                }
            }
        }
    }

    private transcribe() {
        const self = this;
        self.updateStatus(RecognitionStatus.MSG_END_VOICE);
        if (self.pendingAudioFile) {
            const data = self.pendingAudioFile;
            const byteLength = data.byteLength;
            self.updateStatus(RecognitionStatus.MSG_SEND_TRANSCRIBE, {
                request: data,
                firstBuffer: self.firstbuffer,
                srcSampleRate: self.sampler && self.sampler.srcConfig ? self.sampler.srcConfig.sampleRate : 0,
                dstSampleRate: self.sampler && self.sampler.dstConfig ? self.sampler.dstConfig.sampleRate : 0,
                byteLength
            });

            const audio = self.copy(self.pendingAudioFile);
            self.updateStatus(RecognitionStatus.MSG_START_TRANSCRIBE);
            axios.post(
                '/api/speech/stt/transcribe/' + self.channelId,
                {
                    constraints: self.configObj,
                    audio: audio.toString()
                }
            )
                .then((response) => {
                    if (response.data.result === null) {
                        self.updateStatus(RecognitionStatus.MSG_TRANSCRIBE_EMPTY);
                    } else {
                        self.updateStatus(RecognitionStatus.MSG_END_TRANSCRIBE);
                        const result = response && response.data && response.data.result ? response.data.result : null;
                        const results = [];
                        if (result) {
                            for (const alternative of result.alternatives_) {
                                const transcript = alternative && alternative.transcript_ && alternative.transcript_.trim().length > 0
                                    ? alternative.transcript_.trim()
                                    : null;
                                const confidence = alternative.confidence_;
                                if (transcript && transcript.length > 0) {
                                    results.push({ transcript, confidence });
                                }
                            }
                        }
                        self.updateStatus(RecognitionStatus.MSG_RECEIVE_TRANSCRIBED, {
                            response: result,
                            results,
                            byteLength: response && response.data ? JSON.stringify(response.data).length : 0
                        });
                        if (results.length > 0) {
                            self.recognitionProcessor(new RecognitionResultsFormat(results));
                        } else {
                            console.log('TCL: something strange happened');
                            self.updateStatus(RecognitionStatus.MSG_TRANSCRIBE_EMPTY);
                        }
                    }
                })
                .catch((error) => {
                    self.updateStatus(RecognitionStatus.MSG_END_TRANSCRIBE);
                });
            self.pendingAudioFile = null;
            self.firstbuffer = true;
        }
    }

    private updateStatus(message: string, data?: any) {
        const self = this;
        if (self.channelId && self.statusProcessor) {
            // if(console.debug) {
            //    console.debug('Status update:', message);
            // }
            self.statusProcessor(new RecognitionStatus(self.channelId, message, data));
        }
    }

    private merge(a: Int8Array, b: Int8Array): Int8Array {
        const c = new Int8Array(a.length + b.length);
        c.set(a);
        c.set(b, a.length);
        return c;
    }

    private copy(a: Int8Array): Int8Array {
        const c = new Int8Array(a.length);
        c.set(a);
        return c;
    }
}
