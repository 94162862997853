export class AudioChannelAnalyser {
    
    private options = {
        fftSize: 1024,
        smoothingTimeConstant: 0.2,
        minCaptureFreq: 85,         // in Hz
        maxCaptureFreq: 255,        // in Hz
        noiseCaptureDuration: 250, // in ms
        minNoiseLevel: 0.3,         // from 0 to 1
        maxNoiseLevel: 0.7,         // from 0 to 1
        avgNoiseMultiplier: 1.2,
    };
    private baseLevel = 0;
    private voiceScale = 1;
    private activityCounter = 0;
    private activityCounterMin = 0;
    private activityCounterMax = 60;
    private activityCounterThresh = 5;

    private envFreqRange = [];
    private isNoiseCapturing = true;
    private prevVadState = undefined;
    private vadState = false;
    private captureTimeout = null;
    
    private audioContext = null;
    private audioAnalyserChain = null;
    private audioAnalyser = null;
    
    public constructor(
        audioContext
    ) {
        this.audioContext = audioContext;
        this.initAnalyser();
    }
    
    public connect(source) {
        const self = this;
        if (source) {
            source.connect(self.audioAnalyserChain);
        }
    }
    
    public close() {
        const self = this;
        if (self.captureTimeout) {
            clearTimeout(self.captureTimeout);
            self.captureTimeout = null;
        }
        self.audioAnalyser = null;
        self.audioAnalyserChain = null;
    }
    
    public getAudioFrequencyData(): Uint8Array {
        const self = this;
        if (!self.audioAnalyser) {
            return new Uint8Array(0);
        }
        const freqByteData = new Uint8Array(self.audioAnalyser.frequencyBinCount);
        self.audioAnalyser.getByteFrequencyData(freqByteData);
        return freqByteData;
    }
    
    public analyseAudio(): any {
        const self = this;
        return self.monitor();
    }
    
    private initAnalyser() {
        const self = this;
        if (!self.audioAnalyser) {

            self.audioAnalyser = self.audioContext.createAnalyser();
            // self.audioAnalyser.minDecibels = -90;
            // self.audioAnalyser.maxDecibels = -10;
            // self.audioAnalyser.smoothingTimeConstant = 0.85;
            self.audioAnalyser.smoothingTimeConstant = self.options.smoothingTimeConstant;
            self.audioAnalyser.fftSize = self.options.fftSize;

            const makeDistortionCurve = (amount) => {
                const k = typeof amount === 'number' ? amount : 0;
                const n_samples = 44100;
                const curve = new Float32Array(n_samples);
                const deg = Math.PI / 180;
                let i = 0;
                let x;
                for ( ; i < n_samples; ++i ) {
                    x = i * 2 / n_samples - 1;
                    curve[i] = ( 3 + k ) * x * 20 * deg / ( Math.PI + k * Math.abs(x) );
                }
                return curve;
            };
            const distortionNode = self.audioContext.createWaveShaper();
            distortionNode.curve = makeDistortionCurve(0); // no dist with 0

            const gainNode = self.audioContext.createGain();
            gainNode.gain.value = 1; // no change with 1

            const biquadFilterNode = self.audioContext.createBiquadFilter();
            biquadFilterNode.type = 'allpass'; // no change - passes all with allpas

            gainNode.connect(biquadFilterNode);
            biquadFilterNode.connect(distortionNode);
            distortionNode.connect(self.audioAnalyser);

            self.audioAnalyserChain = gainNode; // first node in the chain, which will be connected to the source

            if (self.isNoiseCapturing) {
                self.captureTimeout = setTimeout(() => {
                    self.isNoiseCapturing = false;
                    self.envFreqRange = self.envFreqRange.filter((val) => {
                        return val;
                    }).sort();
                    const averageEnvFreq = self.envFreqRange.length ? self.envFreqRange.reduce((p, c) => Math.min(p, c), 1) : (self.options.minNoiseLevel || 0.1);

                    self.baseLevel = averageEnvFreq * self.options.avgNoiseMultiplier;
                    if (self.options.minNoiseLevel && self.baseLevel < self.options.minNoiseLevel) {
                        self.baseLevel = self.options.minNoiseLevel;
                    }
                    if (self.options.maxNoiseLevel && self.baseLevel > self.options.maxNoiseLevel) {
                        self.baseLevel = self.options.maxNoiseLevel;
                    }
                    self.voiceScale = 1 - self.baseLevel;
                }, self.options.noiseCaptureDuration);
            }
        }
    }

    private monitor(): any {
        const frequencies = new Uint8Array(this.audioAnalyser.frequencyBinCount);
        this.audioAnalyser.getByteFrequencyData(frequencies);

        const average = this.analyserFrequencyAverage(255, this.audioAnalyser, frequencies, this.options.minCaptureFreq, this.options.maxCaptureFreq);
        if (this.isNoiseCapturing) {
            this.envFreqRange.push(average);
            return;
        }

        if (average >= this.baseLevel && this.activityCounter < this.activityCounterMax) {
            this.activityCounter++;
        } else if (average < this.baseLevel && this.activityCounter > this.activityCounterMin) {
            this.activityCounter--;
        }
        this.vadState = this.activityCounter > this.activityCounterThresh;

        let action = 'continue';

        if (this.prevVadState !== this.vadState) {
            action = this.vadState ? 'start' : 'stop';
            this.prevVadState = this.vadState;
        }

        return {
            value: Math.max(0, average - this.baseLevel) / this.voiceScale,
            action
        };
    }

    private analyserFrequencyAverage(div, analyser, frequencies, minHz, maxHz) {
        const sampleRate = analyser.context.sampleRate;
        const binCount = analyser.frequencyBinCount;
        let start = this.frequencyToIndex(minHz, sampleRate, binCount);
        const end = this.frequencyToIndex(maxHz, sampleRate, binCount);
        const count = end - start;
        let sum = 0;
        for (; start < end; start++) {
            sum += frequencies[start] / div;
        }
        return count === 0 ? 0 : (sum / count);
    }

    private frequencyToIndex(frequency: number, sampleRate: number, frequencyBinCount: number) {
        const nyquist = sampleRate / 2;
        const index = Math.round(frequency / nyquist * frequencyBinCount);
        return this.clamp(index, 0, frequencyBinCount)
    }

    private clamp(value: number, min: number, max: number) {
        return min < max
            ? (value < min ? min : value > max ? max : value)
            : (value < max ? max : value > min ? min : value)
    }
}
