import * as Promise from 'promise';
import axios from 'axios/index';
import {AbstractService} from './abstract.service';
import {AuthService} from './auth.service';
import {SubscriptionPlan} from '../models/subscription.plan.model';
import {PurchaseDetails} from '../models/purchase.details.model';
import {PurchaseRequest} from '../models/purchase.request.model';
import {Brand} from './branding.service';

export class PaymentService extends AbstractService {

    private static _instance: PaymentService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    isPremium(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            AuthService.instance.loadUser()
                .then((account) => {
                    if (account && account.productAccess && account.productAccess === 'PREMIUM') {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    reject(this.errorToMessage(error));
                });
        });
    }

    plan(planId: string): Promise<SubscriptionPlan> {
        return new Promise<SubscriptionPlan>((resolve, reject) => {
            axios.get(
                '/api/payment/plan/' + planId,
            )
            .then((response) => {
                const plan: SubscriptionPlan = response.data as SubscriptionPlan;
                if (plan) {
                    resolve(plan);
                } else {
                    reject('No plan received');
                }
            })
            .catch((error) => {
                reject(this.errorToMessage(error));
            });
        });
    }

    plans(monthly?: boolean, yearly?: boolean, brand?: Brand, sale?: string): Promise<SubscriptionPlan[]> {
        const params: any = {};
        if (!!monthly) {
            params.monthly = true;
        }
        if (!!yearly) {
            params.yearly = true;
        }
        if (brand) {
            params.brand = brand.toString().toUpperCase();
        }
        params.sale = sale;

        return new Promise<SubscriptionPlan[]>((resolve, reject) => {
            axios.get(
                '/api/payment/plans',
                {
                    params
                }
            )
            .then((response) => {
                const plans: SubscriptionPlan[] = response.data as SubscriptionPlan[];
                if (plans) {
                    resolve(plans);
                } else {
                    reject('No plans received');
                }
            })
            .catch((error) => {
                reject(this.errorToMessage(error));
            });
        });
    }

    clientToken(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios.get(
                '/api/payment/token',
            )
            .then((response) => {
                const token = response.data;
                if (token && token.client_token) {
                    resolve(token.client_token);
                } else {
                    reject('No token received');
                }
            })
            .catch((error) => {
                reject(this.errorToMessage(error));
            });
        });
    }

    /**
     * Does the payment operation and returns details of the BE purchase.
     * failureReason string should be empty if the operation is completed successfully
     */
    pay(request: PurchaseRequest): Promise<PurchaseDetails> {
        return new Promise<PurchaseDetails>((resolve, reject) => {
            axios.post(
                '/api/payment/pay/dto',
                request
            )
            .then((response) => {
                const purchaseDetails = response.data as PurchaseDetails;

                // If the operation is failed with a "failureReason", don't try to load the user but return the response data to show the converted reason to the user
                // Otherwise user should still get an error from trying to load not existing user but that error doesn't provide any info about the main reason
                if (purchaseDetails.success === false) {
                    purchaseDetails.failureReason = this.convertFailureReasonToReadableText(purchaseDetails.failureReason);
                    resolve(purchaseDetails);
                } else {
                    AuthService.instance.loadUser(true)
                        .then((account) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            reject(this.errorToMessage(error));
                        });
                }
            })
            .catch((error) => {
                reject(this.errorToMessage(error));
            });
        });
    }

    payTest(attrs): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(
                '/api/payment/pay/test',
                attrs
            )
                .then((response) => {
                    AuthService.instance.loadUser(true)
                        .then((account) => {
                            resolve(/*response.data - currently it is void for pay test*/);
                        })
                        .catch((error) => {
                            reject(this.errorToMessage(error));
                        });
                })
                .catch((error) => {
                    reject(this.errorToMessage(error));
                });
        });
    }

    verifyVoucher(voucher: string): Promise<{success: boolean, failureReason: string}> {
        return new Promise<{success: boolean, failureReason: string}>((resolve, reject) => {
            axios.post(
                '/api/payment/pay/verifyvoucher',
                voucher,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Accept': 'application/json'
                    }
                }
            )
                .then((response) => {
                    resolve({
                        success: response.data.success,
                        failureReason: response.data.success === true ? '' : this.convertFailureReasonToReadableText(response.data.failureReason)
                    });
                })
                .catch((error) => {
                    reject(this.errorToMessage(error));
                });
        });
    }

    // verifies reCAPTCHA only for positive amount
    verifyCaptcha(price: number, captcha: string): Promise<{success: boolean, failureReason: string}> {
        return new Promise<{success: boolean, failureReason: string}>((resolve, reject) => {
            if (price > 0) {
                axios.post(
                    '/api/payment/pay/verifycaptcha',
                    captcha,
                    {

                        headers: {
                            'Content-Type': 'text/plain',
                            'Accept': 'application/json'
                        }
                    }
                )
                    .then((response) => {
                        resolve({
                            success: response.data.success,
                            failureReason: response.data.success === true ? '' : this.convertFailureReasonToReadableText(PurchaseFailureReasons.RECAPTCHA_FAILED.valueOf())
                        });
                    })
                    .catch((error) => {
                        reject(this.errorToMessage(error));
                    });
            } else {
                resolve({ success: true, failureReason: '' });
            }
        });
    }

    private convertFailureReasonToReadableText(failureReason: string): string {
        switch (failureReason) {
            case PurchaseFailureReasons.LEARNING_PLAN_NOT_FOUND.valueOf():
                return 'The package you are purchasing does not exist!';
            case PurchaseFailureReasons.SIMILAR_ACTIVE_PRODUCT_SUBSCRIPTION.valueOf():
                return 'You have already an active subscription to the purchased package!';
            case PurchaseFailureReasons.UNKNOWN_PAYMENT_METHOD.valueOf():
                return GENERAL_PAYMENT_FAILURE;
            case PurchaseFailureReasons.RECAPTCHA_FAILED.valueOf():
                return RECAPTCHA_FAILURE;

            case PurchaseFailureReasons.VOUCHER_NOT_FOUND.valueOf():
                return 'Provided voucher code does not exist!';
            case PurchaseFailureReasons.VOUCHER_USED.valueOf():
                return 'Provided voucher code has already been used!';

            // special CVV messages
            case PurchaseFailureReasons.GATEWAY_REJECTED_CVV_NOT_PROVIDED_OR_INCORRECT.valueOf():
            case PurchaseFailureReasons.PROCESSOR_DECLINED_CVV_NOT_PROVIDED_OR_INCORRECT.valueOf():
            case PurchaseFailureReasons.GATEWAY_VALIDATION_FAILED_CVV_NOT_PROVIDED_OR_INCORRECT.valueOf():
                return 'Payment was unsuccessful! CVV is not provided or incorrect. Please provide a valid credit card CVV code(3-digit number).';

            case PurchaseFailureReasons.GATEWAY_REJECTED_FRAUD.valueOf():
                return 'Payment was unsuccessful! Too many payment attempts with the same credit card data within 30 minutes. Please try later or use another credit card.';
            case PurchaseFailureReasons.GATEWAY_REJECTED_DUPLICATE.valueOf():
                return 'Payment was unsuccessful! Duplicated transaction detected.';

            case PurchaseFailureReasons.GATEWAY_VALIDATION_FAILED_PAYMENT_METHOD_OR_CARD_TYPE_NOT_ACCEPTED.valueOf():
                return 'Payment was unsuccessful! Credit card type is not accepted. We accept Visa, MasterCard, Discover and their co-branded credit cards, as well as PayPal.';

            // GATEWAY_VALIDATION_FAILED, PROCESSOR_DECLINED, GATEWAY_REJECTED and PURCHASE_FAILED go here
            default:
                return GENERAL_PAYMENT_FAILURE;
        }
    }
}

export const GENERAL_PAYMENT_FAILURE = 'Payment was unsuccessful! Please try again.';
export const EMAIL_ALREADY_IN_USE = 'Provided e-mail address is already in use.';
export const PAYMENT_NOT_INITIALIZED = 'Payment was not initialized properly! Please reload the page and try again.';
export const RECAPTCHA_FAILURE = 'reCAPTCHA failed! Please reload the page and try again.';

/**
 * Values from BE are lower cased, that's why the initializer values are lower cased
 */
enum PurchaseFailureReasons {

    // general
    LEARNING_PLAN_NOT_FOUND = 'learning_plan_not_found',
    SIMILAR_ACTIVE_PRODUCT_SUBSCRIPTION = 'similar_active_product_subscription',
    UNKNOWN_PAYMENT_METHOD = 'unknown_payment_method',
    RECAPTCHA_FAILED = 'recaptcha_failed',

    // Voucher
    VOUCHER_NOT_FOUND = 'voucher_not_found',
    VOUCHER_USED = 'voucher_used',

    // Rejected by gateway(i.e. Braintree) due to its internal checks
    GATEWAY_REJECTED_CVV_NOT_PROVIDED_OR_INCORRECT = 'gateway_rejected_cvv_not_provided_or_incorrect',
    GATEWAY_REJECTED_FRAUD = 'gateway_rejected_fraud',
    GATEWAY_REJECTED_DUPLICATE = 'gateway_rejected_duplicate',
    GATEWAY_REJECTED = 'gateway_rejected',

    // Processor(i.e. Visa, MC, etc.) declined due to its or issuer bank checks/errors
    PROCESSOR_DECLINED_CVV_NOT_PROVIDED_OR_INCORRECT = 'processor_declined_cvv_not_provided_or_incorrect',
    PROCESSOR_DECLINED = 'processor_declined',

    // Gateway can't process the API call because parameters are invalid
    GATEWAY_VALIDATION_FAILED_PAYMENT_METHOD_OR_CARD_TYPE_NOT_ACCEPTED = 'gateway_validation_failed_payment_method_or_card_type_not_accepted',
    GATEWAY_VALIDATION_FAILED_CVV_NOT_PROVIDED_OR_INCORRECT = 'gateway_validation_failed_cvv_not_provided_or_incorrect',
    GATEWAY_VALIDATION_FAILED = 'gateway_validation_failed',

    // general reason when no particular reason can be defined
    PURCHASE_FAILED = 'purchase_failed'
}
