import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import {AuthService} from '../services/auth.service';
import { Redirect } from 'react-router-dom';

@withNamespaces('logout')
export default class Logout extends React.Component<any, any> {

    componentWillMount(): void {
        AuthService.instance.logout();
    }

    render(): React.ReactNode {
        return (
            <Redirect to='/signin'/>
        );
    }
}
