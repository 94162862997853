import * as React from 'react';
import Courses from '../Courses/Courses';

/**
 * Courses page
 */
const chooseComponent = () => {
    return  (props) => <Courses langISO='es'/>;
};

// Yeah this is not the best pattern but this was the quickest way for now, (To import and use 'Courses' in the 'Home' folder)
// we probably need better "general whitelabel structure" if we are gonna go with more whitelabels.
export default chooseComponent();
