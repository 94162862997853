import * as React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    highlightedTitle: string;
    title: string;
    subtitle: string;
    image: any;
    features: string[];
    price: number;
    enrollPath: string;
}

export class CoursePlanCard extends React.PureComponent<IProps> {
    render() {
        const { highlightedTitle, title, subtitle, image, features, price, enrollPath } = this.props;
        return (
            <div className='plan'>
                <div className='text'>
                    <header>
                        <span>{highlightedTitle}</span>
                        {title}
                        <div className='hint'>{subtitle}</div>
                        <figure>
                            <img src={image} />
                        </figure>
                    </header>
                    <ul>
                        {features.map((feature) => (
                            <li>{feature}</li>
                        ))}
                    </ul>
                    <br />
                    <br />
                </div>
                <div className='prices'>
                    <div className='price'>
                        <span>€ </span>99 instead of{' '}
                        <span
                            className='price-unit'
                            style={{ color: '#FF1E1E', textDecoration: 'line-through' }}
                        >
                            €
                        </span>
                        <span style={{ color: '#FF1E1E', textDecoration: 'line-through' }}> {price}</span>
                    </div>
                </div>
                <div className='hint yearly-hint'>No subscription. Single-time payment</div>
                <Link className='button primary' to={enrollPath}>
                    Enroll now
                </Link>
            </div>
        );
    }
}
