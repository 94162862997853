import * as React from 'react';

import { Slide, SlideGroup } from '../../../models/slide.model';
import { isValidCompletely, SlideEditorConflict } from './functions';

import { MagicPlayer } from '../../MagicPlayer';
import SlideEditorControls from './SlideEditorControls';
import { DialogueService } from '../../../services/dialogue.service';
import { Dialogue } from '../../../models/dialogue.model';
import { Context } from '../../../models/context.model';
import Popup from '../../global/popup/Popup';
import { syntaxPopupContent } from './popupContents/syntaxPopupContent';
import { warningsPopupContent } from './popupContents/warningsPopupContent';

interface VideoPlaybcakData {
    seconds: number;
}

interface SlideEditorState {
    context: Context;
    temporaryVimeoId: string;
    videoPlaybackData: VideoPlaybcakData;
}

export default class SlideEditor extends React.Component<any, SlideEditorState> {
    constructor(props) {
        super(props);
        this.state = {
            context: null,
            temporaryVimeoId: null,
            videoPlaybackData: {
                seconds: 0
            }
        };
    }

    magicPlayer: MagicPlayer;

    componentDidMount() {
        this.getContextFromDb();
    }

    onSlideFocus = (time: number): void => {
        this.magicPlayer.seekTo(time);
        this.magicPlayer.pause();
    };

    async getContextFromDb() {
        const { dialogueSeqAlpha, contextId } = this.props.match.params;
        const context: Context = await DialogueService.instance.getContext(dialogueSeqAlpha, contextId);
        this.setState({ context });
    }

    save = async () => {
        const { dialogueSeqAlpha, contextId } = this.props.match.params;
        const { isValid, conflicts }: { isValid: boolean; conflicts: { slideGroupIdx: number; slideIdx?: number }[] } = isValidCompletely(
            this.state.context.slideGroups
        );
        if (isValid) {
            DialogueService.instance.updateSlideGroups(dialogueSeqAlpha, contextId, this.state.context.slideGroups).then(slideGroups => {
                this.setState(prevState => ({
                    context: { ...prevState.context, slideGroups }
                }));
                alert('saved');
            });
        } else {
            alert('Your input is not valid, please check again.');
        }
    };

    onSlidesChanged = (slideGroups: SlideGroup[]) => {
        this.setState(prevState => ({
            context: { ...prevState.context, slideGroups }
        }));
    };

    onVideoPlaybackChanged = (videoPlaybackData: VideoPlaybcakData) => {
        this.setState({ videoPlaybackData });
    };

    openSyntaxPopup = (): void => {
        this.syntaxPopup.open();
    };

    openWarningsPopup = (): void => {
        this.warningsPopup.open();
    };

    isAllValid = (): void => {
        const { isValid, conflicts }: { isValid: boolean; conflicts: SlideEditorConflict[] } = isValidCompletely(
            this.state.context.slideGroups
        );
        if (isValid) {
            alert('Hooray! Everything is fine :)');
        } else {
            alert('There are some invalid content :( please check your input again');
        }
    };

    syntaxPopup: Popup;
    warningsPopup: Popup;

    render(): React.ReactNode {
        const { context } = this.state;

        return (
            <div id='slide-editor'>
                <Popup
                    id='syntax-popup'
                    className='centered'
                    header='Syntax'
                    hidefooter={true}
                    ref={instance => {
                        this.syntaxPopup = instance;
                    }}
                >
                    {syntaxPopupContent()}
                </Popup>
                <Popup
                    id='warnings-popup'
                    className='centered'
                    header='Warnings'
                    hidefooter={true}
                    ref={instance => {
                        this.warningsPopup = instance;
                    }}
                >
                    {warningsPopupContent()}
                </Popup>
                <div className='row action-bar'>
                    <button onClick={this.openSyntaxPopup} className='ghost action-button-secondary'>
                        Show Syntax
                    </button>
                    <button onClick={this.openWarningsPopup} className='ghost action-button-secondary'>
                        Warnings
                    </button>
                    <button onClick={this.isAllValid} className='ghost action-button-secondary'>
                        Is All Valid?
                    </button>
                    <button onClick={this.save} className='button action-save'>
                        Save
                    </button>
                </div>
                <div className='row editor-area'>
                    {!context ? (
                        'loading...'
                    ) : context.videoSource === 'vimeo' ? (
                        <>
                            <MagicPlayer
                                slideGroups={[...context.slideGroups]}
                                editorMode={true}
                                video={context.video}
                                onVideoEnd={() => {}}
                                onChange={this.onVideoPlaybackChanged}
                                ref={element => {
                                    this.magicPlayer = element;
                                }}
                            />
                            <SlideEditorControls
                                slideGroups={context.slideGroups}
                                onChange={this.onSlidesChanged}
                                save={this.save}
                                videoPlaybackData={this.state.videoPlaybackData}
                                onSlideFocus={this.onSlideFocus}
                            />
                        </>
                    ) : this.state.temporaryVimeoId ? (
                        <>
                            <MagicPlayer
                                slideGroups={[...context.slideGroups]}
                                editorMode={true}
                                video={this.state.temporaryVimeoId}
                                onVideoEnd={() => {}}
                                onChange={this.onVideoPlaybackChanged}
                                ref={element => (this.magicPlayer = element)}
                            />
                            <SlideEditorControls
                                slideGroups={context.slideGroups}
                                onChange={this.onSlidesChanged}
                                save={this.save}
                                videoPlaybackData={this.state.videoPlaybackData}
                                onSlideFocus={this.onSlideFocus}
                            />
                        </>
                    ) : (
                        <span className='warn-youtube'>
                            This video is not hosted at vimeo, you can't edit slides <br /> <br />
                            Or you can enter a vimeo video id here: <br /> <br />
                            <form
                                onSubmit={e =>
                                    this.setState({
                                        temporaryVimeoId: e.target[0].value
                                    })
                                }
                            >
                                <input type='text' /> <br /> <br />
                                <button type='submit' className='button'>
                                    Get Me The Video!
                                </button>{' '}
                                <br /> <br />
                                <span className='warn-youtube-help'>
                                    (Video at the course will not change and slides will not be shown on the course until a vimeo video id
                                    is set in the dialogue editor. Clicking here will only store some slides in the database which won't be
                                    shown for now)
                                </span>
                            </form>
                        </span>
                    )}
                </div>
            </div>
        );
    }
}
