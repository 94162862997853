import * as React from 'react';
import { Link } from 'react-router-dom';
import { CourseService } from '../services/course.service';
import { Course } from '../models/course.model';
import { DialogueService } from '../services/dialogue.service';
import { Dialogue } from '../models/dialogue.model';
import { isNullOrUndefined } from 'util';
import { ThreeDotsLoader } from './global/loaders';

interface AdminSpellCheckProps {}

interface AdminSpellCheckState {
    dialogues: Dialogue[];
    isError: boolean;
}

export default class AdminSpellCheck extends React.Component<AdminSpellCheckProps, AdminSpellCheckState> {
    activeLangISO = 'de';

    state = {
        dialogues: null as Dialogue[],
        isError: false as boolean,
    };

    componentDidMount() {
        this.getDialoguesOfActiveLanguage();
    }

    getDialoguesOfActiveLanguage = (): void => {
        DialogueService.instance
            .getAllDialoguesByLangISO(this.activeLangISO)
            .then((dialogues) => this.setState({ dialogues }))
            .catch((error) => {
                console.error(error);
                this.setState({ isError: true });
            });
    };

    changeActiveLanguage = (langISO: string): void => {
        this.setState({dialogues: null}, () => {
            this.activeLangISO = langISO;
            this.getDialoguesOfActiveLanguage();
        });
    };

    render(): JSX.Element {
        const { dialogues } = this.state;

        if (this.state.isError) return <div>A problem occured</div>;

        return (
            <div id='admin-spell-check' className='frame'>
                <div className='header'>
                    <select name='lang-select' id='lang-select' onChange={(e) => this.changeActiveLanguage(e.target.value)}>
                        <option value='de' selected>
                            de
                        </option>
                        <option value='es'>es</option>
                    </select>
                </div>
                <div className='table'>
                    <div className='titles'>
                        <div>Questions</div>
                        <div>Hint</div>
                        <div>Seq</div>
                        <div>Chapter</div>
                    </div>
                    {isNullOrUndefined(this.state.dialogues) ? (
                        <ThreeDotsLoader show margin='20px 0 0 0' />
                    ) : (
                        dialogues.map((dialogue) =>
                            dialogue.contexts.map((context, contextIdx) =>
                                context.intents.map((intent) => (
                                    <div className='entry'>
                                        <div>{intent.question}</div>
                                        <div>{intent.imageHint}</div>
                                        <div>
                                            <a href={`/editor?seqAlpha=${dialogue.seqAlpha}`} target='_blank'>
                                                {dialogue.seqAlpha}
                                            </a>
                                        </div>
                                        <div>{contextIdx + 1}</div>
                                    </div>
                                ))
                            )
                        )
                    )}
                </div>
            </div>
        );
    }
}
