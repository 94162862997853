import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';

import Popup from '../../global/popup/Popup';
import { UnitRowForCourseEditor } from './UnitRow';

import { LanguageService } from '../../../services/language.service';
import { CourseService } from '../../../services/course.service';
import { DialogueService } from '../../../services/dialogue.service';

import { Dialogue } from '../../../models/dialogue.model';
import { Course, CourseUnit, UnitExercise } from '../../../models/course.model';
import { Context } from '../../../models/context.model';
import { MagicPlayer } from '../../MagicPlayer';
import { NumberOfQuestions } from './NumberOfQuestions';
import { AuthService } from '../../../services/auth.service';

interface CourseEditorFunctions {
    onClickAddNewUnit: () => void;
    addNewUnit: (e?: any) => void;
    unitFunctions: {
        deleteUnit: (unit: CourseUnit) => void;
        editTitle: (newTitle: string, unitIdx: number) => void;
        addNewDialogue: (unit: CourseUnit, e?: any) => boolean;
        exerciseFunctions: {
            editTitle: (dialogue: Dialogue, newTitle: string, unitIdx: number, exerciseIdx: number, exerciseType: string) => void;
            editTitleTr: (dialogue: Dialogue, newTitleTr: string, unitIdx: number, exerciseIdx: number, exerciseType: string) => void;
            editImageOpenPopup: (unitId: string, exerciseId: string, exerciseImg: string) => void;
            editImage: () => void;
            deleteDialogue: (dialogue: Dialogue, exercise: UnitExercise, unitId: string, exerciseType: string) => void;
            changeAvailability: (unitIdx: number, exerciseIdx: number, value: boolean) => void;
            moveRight: (unitIdx: number, exerciseIdx: number) => void;
            moveLeft: (unitIdx: number, exerciseIdx: number) => void;
            moveUp: (unitIdx: number, exerciseIdx: number) => void;
            moveDown: (unitIdx: number, exerciseIdx: number) => void;
            recordNumberOfQuestions: (numberOfQuestions: number) => void;
            contextFunctions: {
                playVimeoPreview: (context: Context) => void;
            };
        };
    };
}

interface CourseEditorState {
    course: Course;
    editSetImageData: {
        unitId: string;
        exerciseId: string;
        exerciseImg: string
    };
    vimeoVideoPreviewContext: Context;
    numberOfQuestions: number;
    userHasAccessToComponent: boolean;
}

/**
 * **How Altering The Course Object Works:**
 *
 * Course is saved automatically after changes, there is no save button
 *
 * At each change, we re-save the whole course object.
 * Therefore, we need to put the save functions in the most parent class, which is `CourseEditor` in this case.
 * We pass the determined functions to the children, but handle the actual operation of "calling services" in `CourseEditor`
 *
 * Also, for some cases we need to work with `DialogueService` too as well as `CourseService`, because `UnitExercise` and `Dialogue` objects need to be in sync.
 */
export default class CourseEditor extends React.Component<any, CourseEditorState> {
    constructor(props) {
        super(props);

        this.state = {
            course: null as Course,
            editSetImageData: null,
            vimeoVideoPreviewContext: null as Context,
            numberOfQuestions: 0 as number,
            userHasAccessToComponent: null as boolean,
        };
    }

    magicPlayer: MagicPlayer = null;
    numberOfQuesitonsEl: NumberOfQuestions;

    componentWillMount(): void {
        if (this.userHasAccessToComponent(this.props.match.params.id)) {
            CourseService.instance.getOneCourse(this.props.match.params.id).then((course) => {
                this.setState((prevState) => ({ course }));
            });
        }
    }

    userHasAccessToComponent = (courseName: string): boolean => {
        const userHasAccessToComponent = AuthService.instance.isAdmin() || AuthService.instance.isSupportAndAllowedToSeeCourse(courseName);
        this.setState({ userHasAccessToComponent });
        return userHasAccessToComponent;
    };

    supportedLanguages = (): string[] => {
        return LanguageService.instance.getSupportedLanguages();
    };

    // These functions are just for create, update, delete operations. Read operations ane handled by the instance functions
    functions: CourseEditorFunctions = {
        onClickAddNewUnit: (): void => {
            this.popups.addNewUnit.open();
        },

        addNewUnit: (e?: any): void => {
            if (e) {
                e.preventDefault();
            }
            const course: Course = this.state.course;
            const newUnit: CourseUnit = {
                id: (
                    course.units.map((unit: CourseUnit) => parseInt(unit.id)).reduce((max: number, cur: number) => Math.max(max, cur)) + 1
                ).toString() as string,
                title: $('#new-unit-title').val() as string,
                exercises: [] as UnitExercise[],
            };
            CourseService.instance
                .addCourseUnit(course.id, newUnit)
                .then((course) => {
                    this.setState({ course: course }, () => {
                        this.popups.addNewUnit.close();
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        unitFunctions: {
            deleteUnit: (unit: CourseUnit): void => {
                const confirmation: string = prompt('To delete the unit, type DELETE with uppercase letters and click OK');
                if (confirmation === 'DELETE') {
                    // Declare variables
                    const course: Course = this.state.course;
                    let updatedCourse: Course;

                    // Call the service
                    CourseService.instance
                        .deleteCourseUnit(course.id, unit)
                        .then((course) => {
                            this.setState({ course: course });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    alert('The unit has --NOT-- been deleted');
                }
            },

            editTitle: (newTitle: string, unitIdx: number): void => {
                const course: Course = this.state.course;
                const unit: CourseUnit = course.units[unitIdx];

                unit.title = newTitle;

                CourseService.instance.updateCourse(course).then((course) => {
                    this.setState({ course: course });
                });
                /*this.setState(
                    prevState => {
                        let course = { ...prevState.course };
                        course.units[unitIdx].title = newTitle;
                        return { course: course };
                    },
                    () => {
                        console.log(this.state.course);
                    }
                );*/
            },

            addNewDialogue: (unit: CourseUnit, e?: any): boolean => {
                // Get the input
                const dialogueType: string = $(`#new-dialogue-type-${unit.id}`).val() as string;
                const name: string = $(`#new-dialogue-name-${unit.id}`).val() as string;

                const language = this.state.course.courseLangISO;

                if (dialogueType == 'Lesson') {
                    // Check the input
                    if (name && name.trim().length > 0 && language) {
                        const newDialogue = {
                            srcName: name,
                            trgName: name,
                            srcLanguage: language,
                            trgLanguage: language,
                        };
                        // Create and save the new dialogue
                        DialogueService.instance.saveDialogue(newDialogue).then((dialogue) => {
                            // Create a UnitExercise with the info from the newly created dialogue
                            const newUnitExercise: UnitExercise = {
                                type: null,
                                title: dialogue.srcName,
                                titleTr: dialogue.srcName,
                                img: null,
                                duration: 10,
                                available: null,
                                seqAlpha: dialogue.seqAlpha,
                            };

                            // Add the newly created UnitExercise to the unit and send to the server
                            CourseService.instance
                                .addUnitExercise(this.state.course.id, unit.id, newUnitExercise)
                                .then((course) => {
                                    this.setState({ course: course });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                    } else {
                        alert('Invalid input. Check your data and try again.');
                    }
                } else if (dialogueType == 'Tutor') {
                    if (name && name.trim().length > 0 && language) {
                        // Create a UnitExercise with the info from the newly created dialogue
                        const newUnitExercise: UnitExercise = {
                            type: 'tutor',
                            title: name,
                            titleTr: name,
                            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538228060/video-chat_p5nzj6.png',
                            duration: 10,
                            available: null,
                            seqAlpha: 'tutor-' + Date.now(),
                        };

                        // Add the newly created UnitExercise to the unit and send to the server
                        CourseService.instance
                            .addUnitExercise(this.state.course.id, unit.id, newUnitExercise)
                            .then((course) => {
                                this.setState({ course: course });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        alert('Invalid input. Check your data and try again.');
                    }
                }

                return false;
            },

            exerciseFunctions: {
                editTitle: (dialogue: Dialogue, newTitle: string, unitIdx: number, exerciseIdx: number, exerciseType: string): void => {
                    // I assume srcName in dialogue is the real name
                    const course: Course = this.state.course;

                    if (exerciseType == null) {
                        // null means lesson
                        DialogueService.instance
                            .saveDialogue({
                                ...dialogue,
                                srcName: newTitle,
                            } as Dialogue)
                            .then((mDialogue) => {
                                const exercise = course.units[unitIdx].exercises[exerciseIdx];
                                exercise.title = mDialogue.srcName;
                                CourseService.instance
                                    .updateCourse(course)
                                    .then((course) => {
                                        this.setState({ course: course });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else if (exerciseType == 'tutor') {
                        const exercise = course.units[unitIdx].exercises[exerciseIdx];
                        exercise.title = newTitle;
                        CourseService.instance
                            .updateCourse(course)
                            .then((course) => {
                                this.setState({ course: course });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                },

                editTitleTr: (dialogue: Dialogue, newTitleTr: string, unitIdx: number, exerciseIdx: number, exerciseType: string): void => {
                    // I assume trgName in dialogue is the translated name
                    const course: Course = this.state.course;

                    if (exerciseType == null) {
                        DialogueService.instance
                            .saveDialogue({
                                ...dialogue,
                                trgName: newTitleTr,
                            } as Dialogue)
                            .then((mDialogue) => {
                                const exercise = course.units[unitIdx].exercises[exerciseIdx];
                                exercise.titleTr = mDialogue.trgName;
                                CourseService.instance
                                    .updateCourse(course)
                                    .then((course) => {
                                        this.setState({ course: course });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else if (exerciseType == 'tutor') {
                        const exercise = course.units[unitIdx].exercises[exerciseIdx];
                        exercise.titleTr = newTitleTr;
                        CourseService.instance
                            .updateCourse(course)
                            .then((course) => {
                                this.setState({ course: course });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                },

                editImageOpenPopup: (unitId: string, exerciseId: string, exerciseImg: string): void => {
                    this.popups.editDialogueImage.open();

                    this.setState(
                        {
                            editSetImageData: {
                                unitId: unitId,
                                exerciseId: exerciseId,
                                exerciseImg: exerciseImg,
                            },
                        },
                        () => {
                            $('#new-dialogue-image').val(this.state.editSetImageData.exerciseImg);
                        }
                    );
                },

                editImage: (): void => {
                    const course: Course = this.state.course;
                    const { unitId, exerciseId } = this.state.editSetImageData;

                    const newExerciseImg: string = $('#new-dialogue-image').val() as string;

                    this.popups.editDialogueImage.close();

                    CourseService.instance
                        .updateExerciseImage(course.id, unitId, exerciseId, newExerciseImg)
                        .then((course) => {
                            this.setState((prevState) => {
                                return { course: course };
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                },

                deleteDialogue: (dialogue: Dialogue, exercise: UnitExercise, unitId: string, exerciseType: string): void => {
                    const confirmation: string = prompt('To delete the lesson, type DELETE with uppercase letters and click OK');
                    if (confirmation === 'DELETE') {
                        // COMMENTED OUT DELETION OF DIALOGUE. DIALOGUE MUST NOT BE REMOVED, ONLY UNIT EXERCISE IS REMOVED BUT ACTUAL CONTENT OF DIALOGUE STAYS IN DB
                        // if (exerciseType == null) {
                        //     // Delete the dialogue
                        //     DialogueService.instance
                        //         .deleteDialogue(dialogue)
                        //         .then(response => {
                        //             if (!response) {
                        //                 console.log('Could not delete the dialogue');
                        //             } else {
                        //                 // Delete the UnitExercise
                        //                 CourseService.instance
                        //                     .deleteUnitExercise(this.state.course.id, unitId, exercise)
                        //                     .then(course => {
                        //                         this.setState({ course: course });
                        //                     })
                        //                     .catch(error => {
                        //                         console.log(error);
                        //                     });
                        //             }
                        //         })
                        //         .catch(error => {
                        //             console.log(error);
                        //         });
                        // } else if (exerciseType == 'tutor') {
                        // Delete the UnitExercise
                        CourseService.instance
                            .deleteUnitExercise(this.state.course.id, unitId, exercise)
                            .then((course) => {
                                this.setState({ course: course });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        // }
                    } else {
                        alert('The lesson has --NOT-- been deleted');
                    }
                },

                changeAvailability: (unitIdx: number, exerciseIdx: number, value: boolean): void => {
                    const course: Course = this.state.course;
                    const exercise: UnitExercise = course.units[unitIdx].exercises[exerciseIdx];

                    exercise.available = value;

                    CourseService.instance
                        .updateCourse(course)
                        .then((course) => {
                            this.setState({ course: course });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                },

                moveRight: (unitIdx: number, exerciseIdx: number): void => {
                    const course: Course = this.state.course;
                    const unit: CourseUnit = course.units[unitIdx];
                    if (unit) {
                        const canMove: boolean = unit.exercises[exerciseIdx + 1] ? true : false;
                        if (canMove) {
                            const movingExercise: UnitExercise = unit.exercises[exerciseIdx];
                            unit.exercises.splice(exerciseIdx, 1);
                            unit.exercises.splice(exerciseIdx + 1, 0, movingExercise);

                            CourseService.instance
                                .updateCourse(course)
                                .then((course) => {
                                    this.setState({ course: course });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }
                },

                moveLeft: (unitIdx: number, exerciseIdx: number): void => {
                    const course: Course = this.state.course;
                    const unit: CourseUnit = course.units[unitIdx];
                    if (unit) {
                        const canMove: boolean = unit.exercises[exerciseIdx - 1] ? true : false;
                        if (canMove) {
                            const movingExercise: UnitExercise = unit.exercises[exerciseIdx];
                            unit.exercises.splice(exerciseIdx, 1);
                            unit.exercises.splice(exerciseIdx - 1, 0, movingExercise);

                            CourseService.instance
                                .updateCourse(course)
                                .then((course) => {
                                    this.setState({ course: course });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }
                },

                moveUp: (unitIdx: number, exerciseIdx: number): void => {
                    const course: Course = this.state.course;
                    const firstUnit: CourseUnit = course.units[unitIdx];

                    let updatedCourse: Course;
                    if (firstUnit) {
                        const canMove: boolean = course.units[unitIdx - 1] ? true : false;
                        if (canMove) {
                            const secondUnit: CourseUnit = course.units[unitIdx - 1];
                            const movingExercise: UnitExercise = firstUnit.exercises[exerciseIdx];
                            firstUnit.exercises.splice(exerciseIdx, 1);
                            secondUnit.exercises.splice(exerciseIdx, 0, movingExercise);

                            CourseService.instance
                                .updateCourse(course)
                                .then((course) => {
                                    this.setState({ course: course });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }
                },

                moveDown: (unitIdx: number, exerciseIdx: number): void => {
                    const course: Course = this.state.course;
                    const firstUnit: CourseUnit = course.units[unitIdx];

                    if (firstUnit) {
                        const canMove: boolean = course.units[unitIdx + 1] ? true : false;
                        if (canMove) {
                            const secondUnit: CourseUnit = course.units[unitIdx + 1];
                            const movingExercise: UnitExercise = firstUnit.exercises[exerciseIdx];
                            firstUnit.exercises.splice(exerciseIdx, 1);
                            secondUnit.exercises.splice(exerciseIdx, 0, movingExercise);

                            CourseService.instance
                                .updateCourse(course)
                                .then((course) => {
                                    this.setState({ course: course });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }
                },

                recordNumberOfQuestions: (numberOfQuestions: number): void => {
                    this.numberOfQuesitonsEl.incrementNum(numberOfQuestions);
                },

                contextFunctions: {
                    playVimeoPreview: (context: Context): void => {
                        this.setState({ vimeoVideoPreviewContext: context }, () => {
                            this.popups.vimeoVideoPreview.open();
                            this.magicPlayer.play();
                        });
                    },
                },
            },
        },
    };

    popups = {
        addNewUnit: null as Popup,
        editDialogueImage: null as Popup,
        vimeoVideoPreview: null as Popup,
    };

    render(): React.ReactNode {
        if (this.state.userHasAccessToComponent !== null && !this.state.userHasAccessToComponent) {
            return <Redirect to={AuthService.instance.getDirectoryByUserPermissions()} />;
        }

        return (
            <div id='editor-course-overview'>
                <Popup
                    id='add-new-unit'
                    className='centered'
                    header='Add New Unit'
                    hidefooter
                    ref={(instance) => {
                        this.popups.addNewUnit = instance;
                    }}
                >
                    <form onSubmit={this.functions.addNewUnit}>
                        <input type='text' id='new-unit-title' name='new-unit-title' placeholder='Title' />
                        <div>
                            <button className='button primary'>Add New Unit</button>
                        </div>
                    </form>
                </Popup>
                <Popup
                    id='edit-dialogue-image'
                    className='centered'
                    header='Edit Set Image'
                    hidefooter
                    ref={(instance) => {
                        this.popups.editDialogueImage = instance;
                    }}
                >
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.functions.unitFunctions.exerciseFunctions.editImage();
                        }}
                    >
                        <input type='text' id='new-dialogue-image' name='new-dialogue-image' />
                        <div>
                            <button className='button primary'>Done!</button>
                        </div>
                    </form>
                </Popup>
                <Popup
                    id='vimeo-video-preview'
                    className='centered'
                    ref={(element) => (this.popups.vimeoVideoPreview = element)}
                    hidefooter
                    header='Vimeo Video Preview'
                    onClosed={() => this.setState({ vimeoVideoPreviewContext: null })}
                >
                    {this.state.vimeoVideoPreviewContext ? (
                        <MagicPlayer
                            slideGroups={this.state.vimeoVideoPreviewContext.slideGroups}
                            editorMode={false}
                            video={this.state.vimeoVideoPreviewContext.video}
                            onVideoEnd={() => {}}
                            ref={(instance) => (this.magicPlayer = instance)}
                        />
                    ) : (
                        'loading..'
                    )}
                </Popup>
                <div id='editor-course-overview-top-bar' className='row'>
                    {!this.state.course ? (
                        'loading..'
                    ) : (
                        <div className='row is-bold'>
                            <div id='editor-top-bar-course-name-wrapper' className='col'>
                                <span className='is-normal-weight'>Course Name</span>
                                <span>{this.state.course.title}</span>
                            </div>
                            <div id='editor-top-bar-course-name-wrapper' className='col'>
                                <span className='secondary-text is-normal-weight'>Link to course</span>
                                <div>
                                    <Link to={`/${this.state.course.courseLang}/course/${this.state.course.name}`}>
                                        {this.state.course.name}
                                    </Link>
                                </div>
                            </div>
                            <div id='editor-top-bar-course-name-wrapper' className='col'>
                                <span className='secondary-text is-normal-weight'>Version</span>
                                <span className='secondary-text is-normal-weight'>{this.state.course.version}</span>
                            </div>
                            <div id='editor-top-bar-course-name-wrapper' className='col'>
                                <span className='secondary-text is-normal-weight'>Archived</span>
                                <span className='secondary-text is-normal-weight'>
                                    {this.state.course.archived === true ? 'true' : 'false'}
                                </span>
                            </div>
                            <div id='editor-top-bar-course-name-wrapper' className='col'>
                                <span className='secondary-text is-normal-weight'>Published</span>
                                <span className='secondary-text is-normal-weight'>
                                    {this.state.course.published === true ? 'true' : 'false'}
                                </span>
                            </div>
                            <div id='editor-top-bar-course-name-wrapper' className='col'>
                                <span className='secondary-text is-normal-weight'>Created</span>
                                <span className='secondary-text is-normal-weight'>{this.state.course.created}</span>
                            </div>
                            <div id='editor-top-bar-course-name-wrapper' className='col'>
                                <span className='secondary-text is-normal-weight'>Updated</span>
                                <span className='secondary-text is-normal-weight'>{this.state.course.updated}</span>
                            </div>
                            <div id='editor-top-bar-course-name-wrapper' className='col'>
                                <span className='secondary-text is-normal-weight'>Total Qs</span>
                                <span className='secondary-text is-normal-weight'>
                                    {' '}
                                    <NumberOfQuestions ref={(instance) => (this.numberOfQuesitonsEl = instance)} />{' '}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div id='editor-course-overview-inner'>
                    <div id='editor-course-units'>
                        {this.state.course
                            ? this.state.course.units.map((unit: CourseUnit, idx: number) => {
                                  return (
                                      <UnitRowForCourseEditor
                                          unit={unit}
                                          idx={idx}
                                          courseName={this.state.course.name}
                                          functions={this.functions.unitFunctions}
                                          language={this.state.course.courseLang}
                                      />
                                  );
                              })
                            : 'loading..'}
                        <button onClick={this.functions.onClickAddNewUnit} className='ghost action-new-unit'>
                            + New Unit
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
