import { Brand, BrandingService } from '../../../services/branding.service';
import CaminoMainFooter from './CaminoMainFooter';

const caminoComponent: any = CaminoMainFooter;

export default (
    BrandingService.instance.chooseComponent<any>(
        new Map([
            [Brand.CAMINO, caminoComponent],
        ]),
        caminoComponent
    )
);
