'use strict';

import { diff_match_patch } from 'diff-match-patch';
import * as qs from 'qs';
import {AbstractService} from './abstract.service';
import * as Promise from 'promise';

export class GoogleOptimiseService extends AbstractService {

    private static _instance: GoogleOptimiseService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    propertyId = 'UA-76341928-1';
    experiment = 'd-X7gH_6QnSkaTQMsbXIkw';
    variation: number = null;

    constructor() {
        super();
    }

    initialize(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const self = this;
            if (self.variation !== 0 && self.variation !== 1) {
                const searchObj = qs.parse(location.search, { ignoreQueryPrefix: true });
                if (searchObj.abvar) {
                    self.variation = parseInt(searchObj.abvar, 10);
                    self.storeVariationInCookie();
                }
                if (self.variation !== 0 && self.variation !== 1) {
                    const variationCookie = self.getCookie('abvar');
                    if (variationCookie) {
                        self.variation = parseInt(variationCookie, 10);
                    }
                }
                if (self.variation !== 0 && self.variation !== 1 && (<any>window).gaData) {
                    const gaData = (<any>window).gaData;
                    if (gaData[self.propertyId] && gaData[self.propertyId].experiments && (self.experiment in gaData[self.propertyId].experiments)) {
                        this.variation = gaData[self.propertyId].experiments[self.experiment];
                        self.storeVariationInCookie();
                    }
                }
                if (self.variation !== 0 && self.variation !== 1) {
                    self.variation = Math.floor(Math.random() * 2);
                    self.storeVariationInCookie();
                }
                if ((<any>window).ga) {
                    (<any>window).ga('set', 'exp', self.experiment + '.' + self.variation);
                }
            }
            resolve();
        });
    }

    getActiveVariation(): number {
        return this.variation;
    }

    activeVariationEquals(variation): boolean {
        return this.variation === variation;
    }

    private storeVariationInCookie() {
        const self = this;
        const now = new Date();
        const exp = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
        this.setCookie('abvar', '' + self.variation, null, exp);
        this.setCookie('abvar', '' + self.variation, '.magiclingua.com', exp);
    }

    private getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    private setCookie(cname: string, cvalue: string, domain: string, exp: Date) {
        const expires = 'expires=' + exp.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + (domain ? ';domain=' + domain : '') + ';path=/';
    }
}
