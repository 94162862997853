import * as Promise from 'promise';
import * as _ from 'underscore';
import {AbstractService} from './abstract.service';
import * as XRegExp from 'xregexp';

export class SentenceService extends AbstractService {

    private static REGEXPS = [
        { regex: new RegExp(/(\[(.*?)\])/y), type: 'critical' }, // []
        { regex: new RegExp(/(\{(.*?)\})/y), type: 'hard-options' }, // {}
        { regex: new RegExp(/(\((.*?)\))/y), type: 'soft-options' }, // ()
        { regex: new RegExp(/(^|[ \n\r\t\s.,'\"\+!?-]+)([^{}<>\[\]\(\) ]+)([ \n\r\t\s.,'\"\+!?-]+|$)/y), type: 'word' }, // normal words
        { regex: new RegExp(/[|.,\/#!¡$%\^&\*;:=\-_`~\?¿]/y), type: 'symbols' }
    ];

    private static readonly PUNCTUATIONS = '[.,!;:\\?\'"]';

    private static _instance: SentenceService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public validateSentence(sentence) {
        if (_.isEmpty(sentence)) {
            return false;
        }
        const specSigns = {};
        const map = {
            '{': 'curly_brackes',
            '}': 'curly_brackes',
            '(': 'round_brackets',
            ')': 'round_brackets',
            '[': 'square_brackets',
            ']': 'square_brackets'
        };
        for (const i in sentence) {
            const sign = sentence[i];
            if (map[sign]) {
                specSigns[map[sign]] = specSigns[map[sign]] ? ++specSigns[map[sign]] : 1;
            }
        }
        for (const i in specSigns) {
            if (specSigns[i] % 2 !== 0) {
                return false;
            }
        }
        return true;
    }

    /**
     * Syntax:
     * "{My parents; They} {are arriving; arrive} [on;in | bla;asa | grammar:1376] (this) Thursday"
     *
     * @param answerObj
     * @returns {any}
     */
    public generateAnswers(answerObj) {
        let answer = null;
        let answerValue = null;
        if (typeof answerObj === 'string' || answerObj instanceof String) {
            answerValue = answerObj;
            answer = {};
        } else if (answerObj) {
            answerValue = answerObj.value;
            answer = answerObj;
        }
        if (_.isEmpty(answerValue)) {
            return;
        }
        const sentence = answerValue.trim();
        if (!this.validateSentence(sentence)) {
            return;
        }
        const {wrongValuesExist, parts} = this.generateParts(sentence);

        answer.answers = [];
        answer.wrongAnswers = [];

        function cartesian() {
            const r = [];
            const arg = arguments;
            const max = arg.length - 1;
            function helper(arr, i) {
                for (let j = 0, l = arg[i].length; j < l; j++) {
                    const a = arr.slice(0); // clone arr
                    a.push(arg[i][j]);
                    if (i === max) {
                        r.push(a);
                    } else {
                        helper(a, i + 1);
                    }
                }
            }
            helper([], 0);
            return r;
        }
        // generate and add correct answers
        const allCorrectValues = [];
        const wrongAnswersPreview = [];
        const correctAnswersPreview = [];
        if (parts && parts.length > 0) {
            for (const i in parts) {
                allCorrectValues.push(parts[i].values);
            }
            const allCorrectAnswers = cartesian.apply(this, allCorrectValues);
            for (const i in allCorrectAnswers) {
                let val = allCorrectAnswers[i].join('').replace(/\s\s+/g, ' ');
                const regex = XRegExp('\\s+(' + SentenceService.PUNCTUATIONS + ')', 'g');
                val = XRegExp.replace(val, regex, '$1').trim();
                correctAnswersPreview.push(val);
            }
            answer.answers = correctAnswersPreview;
            // generate and add wrong answers
            if (wrongValuesExist) {
                let allIncorrerctValues = [];
                for (const i in parts) {
                    const values = parts[i].wrongValues ? parts[i].wrongValues : parts[i].values;
                    allIncorrerctValues.push(values);
                }
                allIncorrerctValues = cartesian.apply(this, allIncorrerctValues);
                for (const i in allIncorrerctValues) {
                    let val = allIncorrerctValues[i].join('').replace(/\s\s+/g, ' ');
                    const regex = XRegExp('\\s+(' + SentenceService.PUNCTUATIONS + ')', 'g');
                    val = XRegExp.replace(val, regex, '$1').trim();
                    wrongAnswersPreview.push(val);
                }
                answer.wrongAnswers = wrongAnswersPreview;
            }
        }
        return answer;
    }

    private generateParts(sentence: string): any {
        let match, matchValue;
        const parts = [];
        let part;
        let wrongValues;
        let grammar;
        let wrongValuesExist = false;
        let previousSentence = null;
        while (sentence.length > 0) {
            if (sentence === previousSentence) {
                break;
            }
            previousSentence = sentence;
            sentence = sentence.trim();
            for (const i in SentenceService.REGEXPS) {
                const rx = SentenceService.REGEXPS[i];
                let matched = true;
                while (matched) {
                    match = sentence.match(rx.regex);
                    matched = !!match;
                    if (!matched) {
                        break;
                    }
                    wrongValues = [];
                    grammar = null;
                    matchValue = match[2] ? match[2] : match[0];

                    if (rx.type === 'hard-options' || rx.type === 'soft-options') {
                        matchValue = _.map(matchValue.split(';'), (p: string) => (p ? p.trim() : p) + ' ');
                        if (rx.type === 'soft-options') {
                            matchValue.push(' ');
                        }
                    } else if (rx.type === 'critical') {
                        matchValue = matchValue.split('|');
                        if (matchValue[1]) {
                            wrongValues = _.map(matchValue[1].split(';'), (p: string) => (p ? p.trim() : p) + ' ');
                        }
                        if (matchValue[2]) {
                            grammar = matchValue[2];
                        }
                        matchValue = _.map(matchValue[0].split(';'), (p: string) => (p ? p.trim() : p) + ' ');
                    } else if (rx.type === 'word' || rx.type === 'symbols') {
                        matchValue = matchValue ? matchValue.trim() : matchValue;
                        matchValue = [matchValue + (rx.type === 'symbols' && matchValue === '¡' ? '' : ' ')];
                    }

                    // create parts
                    part = {type: rx.type, values: matchValue};
                    if (wrongValues.length > 0) {
                        wrongValuesExist = true;
                        part.wrongValues = wrongValues;
                    }
                    if (grammar !== null) {
                        part.grammar = {trigger: matchValue, grammarId: grammar};
                    }

                    // Add brackets back to the critical values
                    if (rx.type === 'critical') {
                        for (const j in part.values) {
                            part.values[j] = '[' + part.values[j].trim() + '] ';
                        }
                    }
                    parts.push(part);
                    sentence = sentence.replace(match[0], '').trim();
                    rx.regex.lastIndex = 0;
                }
            }
        }
        return {wrongValuesExist, parts};
    }
}
