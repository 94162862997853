import * as Promise from 'promise';
import axios from 'axios/index';
import * as PubSub from 'pubsub-js';
import {AbstractService} from './abstract.service';
import { CourseService } from './course.service';
import { UnitExercise } from '../models/course.model';
import { Course } from '../models/course.model';

export class AuthService extends AbstractService {

    private static _instance: AuthService;

    // values correspond 'authority' collection
    private static readonly authorities = {
        SYSTEM: 'ROLE_SYSTEM',
        ROOT: 'ROLE_ROOT',
        ADMIN: 'ROLE_ADMIN',
        SUPPORT: 'ROLE_SUPPORT',
        USER: 'ROLE_USER',
        ANONYMOUS: 'ROLE_ANONYMOUS'
    };

    // user.product_access
    private static readonly permissions = {
        DEFAULT: 'DEFAULT',
        PREMIUM: 'PREMIUM',
        BETA: 'BETA'
    };

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    private user: any;
    private token: any;
    private referrer: any;

    private constructor() {
        super();
        if (this.getToken()) {
            this.setHeader(this.getToken());
        }
    }

    isAuthenticated(): boolean {
        return !!this.user;
    }

    isBetaUser(): boolean {
        return this.user ? this.user.productAccess === AuthService.permissions.BETA : false;
    }

    isAnonymous(): boolean {
        return this.user && this.user.email && this.user.email.endsWith('@anonymous.magiclingua.com');
    }

    isSupport(): boolean {
        return this.user && this.user.authorities.includes(AuthService.authorities.SUPPORT);
    }

    isAdmin(): boolean {
        return this.user && this.user.authorities.includes(AuthService.authorities.ADMIN);
    }

    isSuperUser(): boolean {
        return this.user && (this.isAdmin() || this.isSupport());
    }

    // returns true if user is Support and have no userSettings or no allowedForSupportCourses or allowedForSupportCourses is empty
    //      "no userSettings or no allowedForSupportCourses or allowedForSupportCourses is empty" means a Support user has access to all courses
    // return true if user is Support and the passed course name is in the allowedForSupportCourses list
    // return false in all other cases
    isSupportAndAllowedToSeeCourse(courseName: string): boolean {
        // user is not Support
        if (!this.isSupport()) {
            return false;
        }
        // Support user has access to all courses
        if (!this.user.userSettings || !this.user.userSettings.allowedForSupportCourses || this.user.userSettings.allowedForSupportCourses.length === 0) {
            return true;
        }
        // check the not empty list of course names
        return this.user.userSettings.allowedForSupportCourses.includes(courseName);
    }

    getReference(): string {
        return this.user ? this.user.reference : null;
    }

    // loads User and publishes 'USER_LOADED' event to the topic
    loadUser(force?: boolean): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            if (self.user && !force) {
                PubSub.publish('USER_LOADED', {user: self.user});
                resolve(self.user);
            } else if (self.getToken()) {
                // Make sure header is set
                self.setHeader(self.getToken());
                axios.get(
                    '/api/account'
                )
                .then((response) => {
                    const user = response.data;
                    if (user) {
                        self.user = user;
                        // w.dataLayer.push({userId: user});
                        PubSub.publish('USER_LOADED', {user: self.user});
                        resolve(user);
                    } else {
                        reject('No token received');
                    }
                })
                .catch((error) => {
                    self.setToken(null);
                    reject(self.errorToMessage(error));
                });
            } else {
                reject('Not authenticated');
            }
        });
    }

    getReferralCode(searchObj?: any): string {
        let referralCode = null;
        if (searchObj.referral) {
            referralCode = searchObj.referral;
            localStorage.setItem('referralCode', referralCode);
        } else if (localStorage.getItem('referralCode')) {
            referralCode = localStorage.getItem('referralCode');
        }

        return referralCode;
    }

    resetReferral(): void {
        localStorage.removeItem('referralCode');
    }

    loadUserByReferralCode(referralCode: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            if (self.referrer) {
                resolve(self.referrer);
            } else {
                axios.get(
                    '/api/account/referralcode/' + referralCode
                )
                    .then((response) => {
                        const user = response.data;
                        if (user) {
                            resolve(user);
                        } else {
                            reject('No user found!');
                        }
                    })
                    .catch((error) => {
                        reject(self.errorToMessage(error));
                    });
            }
        });
    }

    // logs in using username and password and loads the user if successfully logged in
    // remembers auth token and loads user
    login(username: string, password: string): Promise<any> {
        const self = this;
        const data = 'username=' +  encodeURIComponent(username.trim()) + '&password='
            + encodeURIComponent(password) + '&grant_type=password&scope=read%20write&' +
            'client_secret=mySecretOAuthSecret&client_id=magiclinguaapp';
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/oauth/token',
                data,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                        'Authorization': 'Basic bWFnaWNsaW5ndWFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA=='
                    }
                }
            )
            .then((response) => {
                const responseData = response.data;
                if (responseData) {
                    const expiredAt = new Date();
                    expiredAt.setSeconds(expiredAt.getSeconds() + responseData.expires_in);
                    responseData.expires_at = expiredAt.getTime();
                    this.setToken(responseData);
                    this.loadUser(true)
                        .then((loadedUser) => {
                            PubSub.publish('SIGNIN', {user: loadedUser});
                            resolve(loadedUser);
                        })
                        .catch((error) => { reject(self.errorToMessage(error)); });
                } else {
                    reject('No token received');
                }
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    refresh() {
        const self = this;
        const token = this.getToken();
        const data = 'refresh_token=' + token.refresh_token + '&grant_type=refresh_token';
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/oauth/token',
                data,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'application/json',
                        'Authorization': 'Basic bWFnaWNsaW5ndWFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA=='
                    }
                }
            )
            .then((response) => {
                const responseData = response.data;
                if (responseData) {
                    const expiredAt = new Date();
                    expiredAt.setSeconds(expiredAt.getSeconds() + responseData.expires_in);
                    responseData.expires_at = expiredAt.getTime();
                    this.setToken(responseData);
                    this.loadUser(true)
                        .then((loadedUser) => {
                            PubSub.publish('SIGNIN', {user: loadedUser});
                            resolve(loadedUser);
                        })
                        .catch((error) => { reject(self.errorToMessage(error)); });
                } else {
                    reject('No token received');
                }
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    loginByToken(token): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/auth/token',
                token,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Accept': 'application/json'
                    }
                }
            )
            .then((response) => {
                const responseData = response.data;
                if (responseData) {
                    const expiredAt = new Date();
                    expiredAt.setSeconds(expiredAt.getSeconds() + responseData.expires_in);
                    responseData.expires_at = expiredAt.getTime();
                    this.setToken(responseData);
                    this.loadUser(true)
                        .then((loadedUser) => {
                            PubSub.publish('SIGNIN', {user: loadedUser});
                            resolve(loadedUser);
                        })
                        .catch((error) => { reject(self.errorToMessage(error)); });
                } else {
                    reject('No token received');
                }
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    /**
     * Logs a user with provided reference by generating new auth token.
     * @param reference an encrypted user id
     */
    loginByReference(reference: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/auth/token/reference',
                reference,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Accept': 'text/plain'
                    }
                }
            )
                .then((response) => {
                    const responseData = response.data;
                    if (responseData) {
                        this.loginByToken(responseData).then(response => {
                            resolve();
                        });
                    } else {
                        reject('No token by reference received');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    logout(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/logout'
            )
            .then((response) => {
                PubSub.publish('SIGNOUT', {});
                this.user = null;
                this.setToken(null);
                this.cleanStorage();
                resolve();
            })
            .catch((error) => {
                PubSub.publish('SIGNOUT', {});
                this.user = null;
                this.setToken(null);
                this.cleanStorage();
                reject();
            });
        });
    }

    hasValidToken() {
        const token = this.getToken();
        return token && token.expires_at && token.expires_at > new Date().getTime();
    }

    hasExpiredToken() {
        const token = this.getToken();
        return token && token.expires_at && token.expires_at <= new Date().getTime();
    }

    getDirectoryByUserPermissions(): string {
        const self = this;

        if (!self.user || self.isAnonymous()) {
            return '/';
        } else {
            if (self.isAdmin()) {
                return '/admin/users';
            }
            if (self.isSupport()) {
                return '/editor/overview/courses';
            }
            return '/dashboard';
        }
    }

    // checks if user purchased this course and the purchase has not yet expired
    // return true if
    //               - logged in user is Super user
    //               - course is public, i.e. public courses can be accessed by not logged in and without purchase users
    //               - course is beta and user is Backer
    //               - "now" is between loaded user_course's activation_start_date and activation_end_date
    // return false if there is no authenticated user (i.e. at least an anonymous user must exist) and in all other cases
    isUserPermittedForThisCourse(courseName: string): boolean {
        const self = this;

        if (!this.isAuthenticated()) {
            return false;
        } else if (self.isSuperUser()) {
            return true;
        } else {
            if (CourseService.instance.isCoursePublic(courseName)) {
                return true;
            } else if (CourseService.instance.isCourseBeta(courseName) && (self.user.productAccess === AuthService.permissions.BETA)) {
                // If the course is beta and the user is a beta user
                return true;
            } else {
                const startDateMs: number = (new Date(CourseService.instance.getLastLoadedUserCourseStart())).getTime();    // ISO time to miliseconds
                const endDateMs: number = (new Date(CourseService.instance.getLastLoadedUserCourseEnd())).getTime();    // ISO time to miliseconds
                if (startDateMs !== null) {    // Maybe undefined??
                    if (startDateMs <= Date.now() && Date.now() <= endDateMs) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        }
    }

    // return true if
    // - user is Super user
    // - or exercise has "free" attribute
    // - or user permitted for this course
    isUserPermittedForThisExercise(courseName: string, exr: UnitExercise): boolean {
        if (this.isSuperUser()) {
            return true;
        }
        return CourseService.instance.isExerciseFreeDemo(exr) ? true : this.isUserPermittedForThisCourse(courseName);
    }

    changePassword(newPassword) {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/account/change_password',
                newPassword,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Accept': 'application/json'
                    }
                }
            )
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    resetPasswordInit(mail) {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/account/reset_password/init',
                mail,
                {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Accept': 'text/plain'
                    }
                }
            )
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    resetPasswordFinish(keyAndPassword) {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/account/reset_password/finish',
                keyAndPassword,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    getDataRequiredByCalendlyIframe(): {firstName: string, lastName: string, email: string} {
        if (this.user) {
            return {
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                email: this.user.email
            };
        } else {
            console.error('User is not loaded yet!');
        }
    }

    doesUserHaveTutorSessions(): boolean {
        return this.user.tutorSessionsCount > 0;
    }

    getUser(): any {
        return this.user;
    }

    private getToken(): any {
        if (!this.token) {
            this.token = JSON.parse(localStorage.getItem('token'));
        }
        return this.token;
    }

    private setToken(token: any) {
        if (token) {
            this.token = token;
            localStorage.setItem('token', JSON.stringify(this.token));
        } else {
            this.token = null;
            localStorage.removeItem('token');
        }
        this.setHeader(this.token);
    }

    private setHeader(token: any) {
        if (token && token.access_token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    }

    private cleanStorage() {
        // localStorage.clear();
        localStorage.removeItem('token');
    }
}
