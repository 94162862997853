import * as React from 'react';
import * as videoChatImg from '../../../../../assets/images/newmagic/course/video-chat.png';
import * as videoChatLockedImg from '../../../../../assets/images/newmagic/course/video-chat-locked.png';
import ExrStarRank from '../global/ExrStarRank';
import {CourseUnit, UnitExercise} from '../../models/course.model';
import {CourseService} from '../../services/course.service';
import {AuthService} from '../../services/auth.service';

export default class CourseUnitLayout extends React.Component<any, any> {

    startExercise = (exr: UnitExercise, event?: any) => {
        this.props.startExercise(exr, event);
    };

    getProgressRank = (prog: number): number => {
        return Math.round(prog * 3 / 100);
    };

    getExrButtonLabel = (exr: UnitExercise): string => {
        if (exr['completed']) {
            return '⟲ Repeat';
        // } else if (this.props.firstStartableExrIndex && exr['exrNr'] === this.props.firstStartableExrIndex.exercise['exrNr'] + 1) {
        //    return 'unlock';
        } else {
            return '▶ &nbsp;Start';
        }
    };

    getExrClass = (exr: UnitExercise): string => {
        const classes: string[] = [];

/*      console.log(exr['exrNr'], this.props.firstStartableExrIndex.exercise['exrNr'], exr.title, '===========================');
        console.log('exr[\'exrNr\'] <= this.props.firstStartableExrIndex.exercise[\'exrNr\']', exr['exrNr'] <= this.props.firstStartableExrIndex.exercise['exrNr']);
        console.log('his.props.firstStartableExrIndex.exercise.type === \'tutor\' && exr[\'exrNr\'] === this.props.firstStartableExrIndex.exercise[\'exrNr\'] + 1 ', this.props.firstStartableExrIndex.exercise.type === 'tutor' && exr['exrNr'] === this.props.firstStartableExrIndex.exercise['exrNr'] + 1);
        console.log('exr.attributes[\'freeDemo\']', exr.attributes['freeDemo']);
        console.log('this.props.isCourseBeta && exr.available', this.props.isCourseBeta && exr.available); */

        if (exr['completed']) {
            classes.push('completed');
        }

        if (this.props.currentExrAlpha === exr.seqAlpha) {
            classes.push('current');
        }

        if (!exr.attributes['freeDemo'] && (!this.props.userIsPermittedToCourse || !exr.available)) {
            classes.push('locked');
        }

        if (exr.type === 'tutor' && exr[CourseService.TRANSIENT_SCHEDULED]) {
            classes.push('scheduled');
        }

        return classes.join(' ');
    };

    getUnitClass = (unit: CourseUnit): string => {
        const classes: string[] = [
            'course-unit'
        ];

        if (this.props.class) {
            classes.push(this.props.class);
        }

        if (this.props.highlightTrial && CourseService.instance.hasUnitFreeDemo(unit)) {
            classes.push('demo-unit');
        }
        return classes.join(' ');
    };

    getImageUrl = (superUser: boolean, img: string): string => {
        if (superUser && img === null) return '';

        const start = img.indexOf('/upload') + 7;
        return img.slice(0, start) + '/c_fill,h_240,w_240' + img.slice(start);
    }

    render(): React.ReactNode {
        const unit: CourseUnit = this.props.unit;
        const unitLabel = CourseService.instance.hasUnitFreeDemo(unit) && !this.props.userIsPermittedToCourse ? 'Free trial' : 'Unit ' + (this.props.index + 1);
        const superUser = AuthService.instance.isSuperUser();

        return (
            <section className={this.getUnitClass(unit)}>
                <header>
                    <h2>
                        <span className='unit-label'>{unitLabel}</span>
                        <div>{unit.title}</div>
                    </h2>
                </header>

                <ul className='exr-path'>
                    {unit.exercises.filter((exr: UnitExercise) => { return exr.available !== null }).map((exr: UnitExercise, i: number) =>
                    (!(exr.type && exr.type === 'tutor') ?

                        /* EXERCISE */
                        <li key={i}>
                            <span className={this.getExrClass(exr)} onClick={(event) => {this.startExercise(exr, event)}}>
                                <figure>
                                    <ExrStarRank rank={this.getProgressRank(exr[CourseService.TRANSIENT_PROGRESS])} />
                                    <span className='line' />
                                    <button className='ghost action-start'>
                                        <span dangerouslySetInnerHTML={{__html: this.getExrButtonLabel(exr)}} />
                                        <svg className='icon-unlock' width='9' height='13' xmlns='http://www.w3.org/2000/svg'><path d='M1.473 5.134L.9 4.142A2.765 2.765 0 0 1 1.91.369a2.764 2.764 0 0 1 3.772 1.012L6.73 3.196l-.836.483-.995-1.723c-.525-.908-1.649-1.245-2.506-.75-.857.495-1.128 1.637-.603 2.545l.798 1.383h4.699c.594 0 1.076.482 1.076 1.076v4.989c0 .594-.482 1.075-1.076 1.075H1.075A1.075 1.075 0 0 1 0 11.2v-4.99c0-.593.481-1.075 1.075-1.075H1.473zM4.73 8.65a.976.976 0 1 0-1.1 0v1.343a.55.55 0 1 0 1.1 0V8.65z' fill='#222' fillRule='nonzero'/></svg>
                                    </button>
                                    <div className='bg'><div style={{backgroundImage: 'url(' + this.getImageUrl(superUser, exr.img) + ')'}} /></div>
                                </figure>
                                <header>{exr.title}</header>
                                <p className='title-tr'>{exr.titleTr}</p>
                                <p>{exr.duration} min.</p>
                            </span>
                        </li>

                        :

                        /* TUTOR */
                        <li className='chat' key={i}>
                        <span className={this.getExrClass(exr) + (this.props.isSchedulingIfThisUnit ? ' scheduling' : '')}>
                            <figure>
                                <span className='line' />
                                <button
                                    className='ghost action-start tutor-schedule'
                                    onClick={(event) => this.props.scheduleSession(unit, event)}
                                    disabled={exr[CourseService.TRANSIENT_SCHEDULED] || this.props.isSchedulingIfThisUnit}
                                >
                                    {!exr.available ? 'Unlock' : 'Schedule'}
                                    <svg className='icon-unlock' width='9' height='13' xmlns='http://www.w3.org/2000/svg'><path d='M1.473 5.134L.9 4.142A2.765 2.765 0 0 1 1.91.369a2.764 2.764 0 0 1 3.772 1.012L6.73 3.196l-.836.483-.995-1.723c-.525-.908-1.649-1.245-2.506-.75-.857.495-1.128 1.637-.603 2.545l.798 1.383h4.699c.594 0 1.076.482 1.076 1.076v4.989c0 .594-.482 1.075-1.076 1.075H1.075A1.075 1.075 0 0 1 0 11.2v-4.99c0-.593.481-1.075 1.075-1.075H1.473zM4.73 8.65a.976.976 0 1 0-1.1 0v1.343a.55.55 0 1 0 1.1 0V8.65z' fill='#222' fillRule='nonzero'/></svg>
                                </button>
                                <img src={(exr.available ? videoChatImg : videoChatLockedImg)} alt='Video chat' />

                                <div className='scheduled-info'>
                                    <span>Scheduled</span>
                                    <svg width='27' height='28' xmlns='http://www.w3.org/2000/svg'><path d='M24.507 2.774h-3.358V1.441C21.15.65 20.495 0 19.697 0a1.45 1.45 0 0 0-1.452 1.441v1.333h-9.49V1.441C8.756.65 8.103 0 7.304 0c-.798 0-1.452.649-1.452 1.441v1.333H2.493A2.487 2.487 0 0 0 0 5.25v20.277A2.487 2.487 0 0 0 2.493 28H22.28C24.883 28 27 25.899 27 23.315V5.249a2.486 2.486 0 0 0-2.493-2.475zM2.493 4.747h3.36a1.45 1.45 0 0 0 2.9 0h9.493a1.45 1.45 0 1 0 2.901 0h3.36a.51.51 0 0 1 .505.502V9.1H1.988V5.25a.51.51 0 0 1 .505-.502zm-.505 20.717V11.043h23.024v10.988h-4.061l-.03 4.012H2.493c-.274 0-.505-.266-.505-.58zm23-1.803a2.723 2.723 0 0 1-2.079 2.29l.016-2.29h2.062zm-5.129-9.232c.28.432.126.99-.306 1.27-2 1.302-4.13 3.182-6.161 5.884-.407.542-1.205.563-1.59.02-.955-1.349-1.864-2.106-3.37-3.06a.903.903 0 0 1-.275-1.277c.289-.426.826-.492 1.322-.265 1.177.537 2.278 1.202 3.022 2.073 2.08-2.425 4.059-3.849 6.043-4.94.465-.256 1.036-.137 1.315.295z' fill='#7ED323' fillRule='nonzero'/></svg>
                                </div>

                                <div className='scheduling-info'>
                                    <span>Please, wait</span>
                                    <div className='loader'/>
                                </div>
                            </figure>
                            <header>Tutor session</header>
                            <p>30 min.</p>
                        </span>
                        </li>

                    )
                    )}
                </ul>
            </section>

        );
    }
}
