import * as React from 'react';
import Player from '@vimeo/player';

import * as playIcon from './icons/play.svg';
import * as pauseIcon from './icons/pause.svg';
import * as restartIcon from './icons/restart.svg';

import { Slide, SlideGroup } from '../../models/slide.model';

import { SlideStyles } from './config/slideStyles.model';

import { SlideContainer } from './SlideContainer';

import { calculateStyles, slideContentParser, secondsToDuration } from './functions';
import { isNullOrUndefined, isString } from 'util';

const INITIAL_SLIDE_STYLES = new SlideStyles({
    video: {
        left: 'initial'
    },
    slide: {
        width: 'initial',
        left: 'initial'
    }
});

interface VideoPlaybackStatus {
    seconds: number;
    percent: number;
    duration: number;
}

interface MagicPlayerProps {
    slideGroups: SlideGroup[];
    editorMode: boolean;
    video: string;
    onVideoEnd: any;
    onChange?: any; // function to call in editor when video playback data changes
}

interface MagicPlayerState {
    videoPaused: boolean;
    videoPlaybackStatus: VideoPlaybackStatus;
    bufferProgress: number;
    slideVisible: boolean;
    currentSlide: Slide;
    videoWidth: number;
    videoHeight: number;
    playerId: string;
    currentStyles: SlideStyles;
}

export default class MagicPlayer extends React.Component<MagicPlayerProps, MagicPlayerState> {
    constructor(props: MagicPlayerProps) {
        super(props);

        const ratio: number = 426 / 240;
        const width: number = this.props.editorMode ? document.body.offsetWidth * (57 / 100) : document.body.offsetWidth * (80 / 100);
        const height: number = Math.floor(width / ratio);

        this.state = {
            videoPaused: true,
            videoPlaybackStatus: {
                seconds: 0,
                percent: 0,
                duration: 0
            },
            bufferProgress: 0,
            slideVisible: false,
            currentSlide: null,
            videoWidth: width,
            videoHeight: height,
            playerId: 'video-editor',
            currentStyles: INITIAL_SLIDE_STYLES
        };
    }

    player: Player;

    componentDidMount() {
        this.initPlayer(this.props.video);
        window.onresize = event => {
            this.flexFont();
        };
    }

    componentWillReceiveProps(nextProps: MagicPlayerProps) {
        if (nextProps.video !== this.props.video) {
            this.initPlayer(nextProps.video);
        }
    }

    componentWillUnmount() {
        this.destroy();
    }

    setPlayerListeners = (): void => {
        this.player.on('timeupdate', (data: VideoPlaybackStatus) => this.timeUpdate(data));
        this.player.on('ended', (data: VideoPlaybackStatus) => this.videoEnded());
        this.player.on('progress', (data: VideoPlaybackStatus) => this.videoBuffer(data));
        this.player.on('bufferstart', (data: VideoPlaybackStatus) => $('.magic-player .video-component').addClass('loading'));
        this.player.on('bufferend', (data: VideoPlaybackStatus) => $('.magic-player .video-component').removeClass('loading'));
    };

    removePlayerListeners = (): void => {
        this.player.off('timeupdate');
        this.player.off('ended');
        this.player.off('progress');
        this.player.off('bufferstart');
        this.player.off('bufferend');
    };

    generatePlayerOptions = (video: string): any => {
        return {
            url: `https://player.vimeo.com/video/${video}?app_id=122963`,
            loop: false,
            controls: false,
            autopause: false
        };
    };

    initPlayer = (video: string): void => {
        this.player = new Player(this.state.playerId, this.generatePlayerOptions(video));
        this.initVideoPlaybackStatus();
        this.buildSlides();
        this.setPlayerListeners();
    };

    initVideoPlaybackStatus = (): void => {
        this.player
            .getDuration()
            .then(duration => {
                // duration = the duration of the video in seconds
                this.setState({
                    videoPlaybackStatus: {
                        seconds: 0,
                        percent: 0,
                        duration: duration
                    }
                });
            })
            .catch(console.error);
    };

    buildSlides() {
        // If the user is not playing the same video as before
        // And slide parsing is done in the slide container in case of editor mode, so don't do it here
        if (!this.props.editorMode && this.isSlidesOfTheVideoUnparsed()) {
            const { slideGroups } = this.props;
            for (let i = 0; i < slideGroups.length; i++) {
                for (let j = 0; j < slideGroups[i].slides.length; j++) {
                    slideGroups[i].slides[j].text = slideContentParser(slideGroups[i].slides[j].text);
                }
            }
        }
    }

    isSlidesOfTheVideoUnparsed = (): boolean => {
        return this.doesVideoHaveSlides() && isString(this.props.slideGroups[0].slides[0].text);
    };

    doesVideoHaveSlides = (): boolean => {
        const { slideGroups } = this.props;
        return (
            !isNullOrUndefined(slideGroups) &&
            !isNullOrUndefined(slideGroups[0]) &&
            !isNullOrUndefined(slideGroups[0].slides) &&
            !isNullOrUndefined(slideGroups[0].slides[0]) &&
            !isNullOrUndefined(slideGroups[0].slides[0].text)
        );
    };

    // This function needs to be called directly or indirectly when we are done with the magic player, that logic is still in progress tho
    destroy = async () => {
        this.removePlayerListeners();
        await this.player.destroy().catch(console.error);
    };

    flexFont() {
        const playerWidth = $('.magic-player .video-component').width();
        $('.slide-content-wrapper')[0].style.fontSize = playerWidth * 0.03 + 'px';
        $('.slide-content-wrapper .slide-word-wrapper .slide-wordlabel').css({
            minWidth: playerWidth * 0.025,
            height: playerWidth * 0.025
        });
    }

    playOrPause() {
        this.player
            .getPaused()
            .then(isPaused => {
                if (isPaused) {
                    this.play();
                } else {
                    this.pause();
                }
            })
            .catch(console.error);
    }

    pause = async () => {
        await this.player.pause();
        this.setState({ videoPaused: true });
    };

    play = async () => {
        await this.player.play();
        this.setState({ videoPaused: false });
    };

    restartSlideStyles() {
        this.setState({
            currentStyles: INITIAL_SLIDE_STYLES,
            currentSlide: null
        });
    }

    setSlide(newSlide: Slide) {
        const slide: Slide = newSlide;

        this.setState({
            slideVisible: slide != null,
            currentSlide: slide,
            currentStyles: slide != null ? calculateStyles(slide.styles.slideWidth) : calculateStyles(0)
        });

        this.flexFont();
    }

    seekTo(seconds: number): void {
        this.player
            .setCurrentTime(seconds)
            .then(secondsActual => {
                // secondsActual = the actual time that the player seeked to
                this.setState({
                    videoPlaybackStatus: {
                        seconds: secondsActual,
                        percent: secondsActual / this.state.videoPlaybackStatus.duration,
                        duration: this.state.videoPlaybackStatus.duration
                    }
                });

                if (this.props.editorMode) {
                    this.props.onChange({ seconds: secondsActual });
                }
            })
            .catch(error => {
                switch (error.name) {
                    case 'RangeError':
                        // the time was less than 0 or greater than the video’s duration
                        break;

                    default:
                        // some other error occurred
                        break;
                }
            });
    }

    getSlide(time) {
        const slideGroups: SlideGroup[] = this.props.slideGroups;
        let slide: Slide = null;

        if (this.state.videoPlaybackStatus.percent < 1) {
            // Go through SlideGroups (consider their first and last slides)
            for (let i: number = 0; i < slideGroups.length; i++) {
                const mSlideGroup: SlideGroup = slideGroups[i];
                const mSlideFirst: Slide = mSlideGroup.slides[0];

                if (time >= mSlideFirst.from && time < mSlideGroup.end) {
                    // Go through Slides and find the correct one
                    for (let j: number = 0; j < mSlideGroup.slides.length; j++) {
                        const mSlide: Slide = mSlideGroup.slides[j];
                        let nextSlide: Slide;

                        if (!mSlideGroup.slides[j + 1]) {
                            slide = mSlide;
                            break;
                        } else {
                            nextSlide = mSlideGroup.slides[j + 1];

                            if (time >= mSlide.from && time < nextSlide.from) {
                                slide = mSlide;
                                break;
                            }
                        }
                    }
                    break;
                }
            }
        }

        return slide;
    }

    videoBuffer(data: VideoPlaybackStatus) {
        this.setState({
            bufferProgress: data.percent * 100
        });
    }

    timeUpdate(data: VideoPlaybackStatus) {
        this.setState({ videoPlaybackStatus: data });

        if (this.props.editorMode) {
            this.props.onChange({ seconds: data.seconds });
        }

        const seconds: number = Number(data.seconds);

        const slide: Slide = this.getSlide(seconds);
        this.setSlide(slide);
    }

    videoEnded() {
        this.setState({ videoPaused: true });
        this.props.onVideoEnd();
    }

    progressBarClick(target, clickPosition) {
        const elStart: number = target.getBoundingClientRect().left;
        const elWidth: number = target.offsetWidth;
        const progressRate: number = (clickPosition - elStart) / elWidth;
        this.seekTo(this.state.videoPlaybackStatus.duration * progressRate);
    }

    getTimeString(duration) {
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);

        const durationString = `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
        return durationString;
    }

    onPlayerKeydown(event) {
        if (event.keyCode === 32) {
            this.playOrPause();
        }
    }

    icons = {
        play: <img src={playIcon} alt='play' />,
        pause: <img src={pauseIcon} alt='pause' />,
        restart: <img src={restartIcon} alt='restart' />
    };

    render() {
        const { bufferProgress } = this.state;
        const videoProgress = this.state.videoPlaybackStatus.percent * 100;

        return (
            <div
                className='magic-player'
                onKeyDown={event => {
                    this.onPlayerKeydown(event);
                }}
                tabIndex={0}
            >
                <div className='video-component'>
                    <div className='player-cover-layer' onClick={() => this.playOrPause()}>
                        <div className='video-loader'>
                            <i />
                            <i />
                            <i />
                            <i />
                        </div>
                    </div>
                    <div id={this.state.playerId} className='video-player' style={this.state.currentStyles.video} />
                    <SlideContainer
                        slideVisible={this.state.slideVisible}
                        text={this.state.currentSlide === null ? '' : this.state.currentSlide.text}
                        editorMode={this.props.editorMode}
                        style={this.state.currentStyles.slide}
                    />
                    <div className='player-controls'>
                        <span className='slide-editor-icon' onClick={() => this.playOrPause()}>
                            {this.state.videoPaused ? this.icons.play : this.icons.pause}
                        </span>
                        <div className='progress'>
                            <span className='progress-overlay' onClick={e => this.progressBarClick(e.target, e.clientX)} />
                            <span className='current' style={{ width: `${videoProgress}%` }} />
                            <span className='buffer' style={{ width: `${bufferProgress}%` }} />
                        </div>
                        <span className='duration'>
                            {this.props.editorMode
                                ? secondsToDuration(this.state.videoPlaybackStatus.seconds) +
                                  ' / ' +
                                  secondsToDuration(this.state.videoPlaybackStatus.duration)
                                : this.getTimeString(this.state.videoPlaybackStatus.seconds) +
                                  ' / ' +
                                  this.getTimeString(this.state.videoPlaybackStatus.duration)}
                        </span>
                        <span className='slide-editor-icon' onClick={() => this.seekTo(0)}>
                            {this.icons.restart}
                        </span>
                    </div>
                </div>

                {this.props.editorMode ? (
                    <div>
                        <br />
                        <span style={{ color: 'white', paddingTop: '3px' }}>
                            {`Seconds: ${this.state.videoPlaybackStatus.seconds.toFixed(2)},
                    Duration: ${this.state.videoPlaybackStatus.duration.toFixed(2)}`}
                        </span>
                    </div>
                ) : null}
            </div>
        );
    }
}
