import * as React from 'react';

interface IProps {}

interface IState {
    num: number;
}

// Just a simple stateful component to keep track of the total question number and show it in ui
// (point is it prevents re-rendering the while editor component but just re-renders a little part on new data, due to that the dialogue data comes in an async way, it is registered async-ly, so there is a need of a state)
export class NumberOfQuestions extends React.Component<IProps, IState> {
    element: HTMLSpanElement;

    state = {
        // number of questions in the whole course
        num: 0
    };

    incrementNum = (incrementBy: number): void => {
        this.setState(prevState => ({ num: prevState.num + incrementBy }));
    };

    render() {
        return <span ref={c => (this.element = c)}>{this.state.num}</span>;
    }
}
