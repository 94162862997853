import * as React from 'react';
import {TweenMax, Back, Power0, Power1, Expo, Power4} from 'gsap';
import {UserAgentService} from '../../services/user.agent.service';
import {Context} from '../../models/context.model';
import {LearnedContext} from '../../models/learned.context.model';
import {TimelineMax} from 'gsap/TimelineMax';

export default class ChapterNavi extends React.PureComponent<any, any> {

    navi: HTMLDivElement;
    vimeoThumbnails = [
        'https://i.vimeocdn.com/video/811079567_236x133.jpg',
        'https://i.vimeocdn.com/video/811090919_236x133.jpg',
        'https://i.vimeocdn.com/video/812159350_236x133.jpg',
        'https://i.vimeocdn.com/video/812192060_236x133.jpg',
        'https://i.vimeocdn.com/video/814899858_236x133.jpg',
        'https://i.vimeocdn.com/video/814900160_236x133.jpg'
    ];

    getChapterClass = (chapter: Context, idx: number): string => {
        if (idx === this.props.currentChapterIdx) {
            return 'active ';
        } else if (this.isChapterCompleted(chapter.id)) {
            return 'completed ';
        }
    };

    getLearnedChapter = (id: string): LearnedContext => {
        let result: LearnedContext = null;
        for (let ctx of this.props.learnedDialogue.contexts) {
            if (ctx.id === id) {
                result = ctx;
                break;
            }
        }
        return result;
    };

    getChapterProgress = (id: string): number => {
        const learnedChapeter: LearnedContext = this.getLearnedChapter(id);
        return learnedChapeter ? learnedChapeter.progress : null;
    };

    isChapterCompleted = (id: string): boolean => {
        let completed: boolean = true;
        const chapter: LearnedContext = this.getLearnedChapter(id);
        if (chapter && chapter.intents && chapter.intents.length > 0) {
            for (let i = 0; i < chapter.intents.length; i++) {
                const intent = chapter.intents[i];
                if (!intent.history || intent.history.length < 1) {
                    completed = false;
                    break;
                }
            }

        }
        return completed;
    };

    onChapterClick = (chapter: Context, i: number): void => {
        if (this.props.isIframe) return;
        this.props.startChapter(i, true);
    };

    open = (): void => {
        if (this.props.isIframe) return;
        const tl = new TimelineMax();
        const inner = $(this.navi).find('.inner');
        tl.to(inner, .3, {yPercent: 100, boxShadow: '0px 20pb 32px rgba(0,0,0,.2)', ease: Expo.easeOut}, 'start');
    };

    close = (): void => {
        if (this.props.isIframe) return;
        const tl = new TimelineMax();
        tl.to($(this.navi).find('.inner'), .3, {yPercent: 0, boxShadow: '0px 20px 32px rgba(0,0,0,0)', ease: Expo.easeOut});
    };

    getVideoThumb = (chapter: Context, i: number): string => {
        return chapter.videoSource === 'vimeo' 
            ? chapter.thumb != null && chapter.thumb != ''
                ? chapter.thumb
                : this.vimeoThumbnails[i % 6]
            : 'https://img.youtube.com/vi/' + chapter.video + '/mqdefault.jpg';
    };

    render(): React.ReactNode {
        const chapters = this.props.chapters;
        return (
            <div id='chapter-navi' ref={c => this.navi = c}>
                <div className='inner' onMouseEnter={this.open} onMouseLeave={this.close}>
                    {chapters.map((chapter, i) =>
                       <div className={'chapter ' + this.getChapterClass(chapter, i)} key={i} onClick={() => { this.onChapterClick(chapter, i)}}>
                           {!UserAgentService.instance.isMobile() &&
                               <figure>
                                   {(this.isChapterCompleted(chapter.id) ?
                                           <span className='check' /> : ''
                                   )}
                                   {(this.isChapterCompleted(chapter.id)    ?
                                           <span className='repeat' /> : ''
                                   )}
                                   <img src={this.getVideoThumb(chapter, i)} />
                               </figure>
                           }
                           {!UserAgentService.instance.isMobile() &&
                               <div>
                                   <span>{'Chapter ' + (i + 1)}</span>
                                   <header>{chapter.name}</header>
                               </div>
                           }
                           <div className='chapter-progress'>
                               <span style={{width: this.getChapterProgress(chapter.id) * 100 + '%'}} />
                           </div>
                       </div>
                    )}
                </div>
            </div>
        );
    }
}
