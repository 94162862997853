import * as React from 'react';
import {SpeechService} from '../services/speech.service';
import {Base64Service} from '../services/base64.service';
import * as beautify from 'js-beautify';
import {SearchType} from '../models/search.type.enum';
import {CSSProperties} from 'react';

export default class Learning extends React.Component<any, any> {

    state = {
        stts: []
    };

    componentDidMount(): void {
        const self = this;
        SpeechService.instance.sttSearch().then((stts) => {
            self.setState({stts});
        });
    }

    search = (): void => {
        const self = this;
        self.setState({stts: []});
        const value: string = $('#search-value').val() as string;
        const type: string = $('#search-type').val() as string;
        if (!value || !type) {
            SpeechService.instance.sttSearch().then((stts: Map<string, any>[]) => {
                self.setState({stts});
            });
        } else {
            SpeechService.instance.sttSearch(SearchType[type], value).then((stts: Map<string, any>[]) => {
                self.setState({stts});
            });
        }
    };

    formatSttResponse = (sttJSON): any => {
        // const sttObj = JSON.parse(Base64Service.instance.decode(sttJSON));
        /* let result = '';
        for (const i in sttObj._alternatives) {
            result += sttObj._alternatives[i].confidence
        }

        return beautify.js_beautify(sttObj); */
        return beautify.js_beautify(Base64Service.instance.decode(sttJSON));
    };

    render(): React.ReactNode {
        const {stts} = this.state;
        console.log(stts[0]);
        return (
            <div style={{padding: '50px'}}>
                <div>
                    <input type='text' id='search-value' name='search-value' placeholder='Search by ...'/>
                    <select id='search-type' name='search-type'>
                        {Object.keys(SearchType).filter(key => !isNaN(Number(SearchType[key]))).map((type, t) =>
                            <option value={type} key={t}>{type}</option>
                        )}
                    </select>
                    <button onClick={this.search}>Search</button>
                </div>
                <br/>
                <br/>
                <table className='stt-results-table'>
                    <thead>
                        <tr>
                            <th>Seq Alpha</th>
                            <th>Question</th>
                            <th>Hint</th>
                            <th>User Answer</th>
                            <th>Accepted Answer</th>
                            <th>Context</th>
                            <th>Correct</th>
                            <th>Audio</th>

                            <th>Json</th>
                            <th>User</th>
                            <th>Channel</th>
                            <th>Dialogue ID</th>
                            <th>Context ID</th>
                            <th>Intent ID</th>
                            <th>Language</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stts.map((stt, i) => <tr key={i}>
                            <td><p>{stt.dialogueSeqAlpha}</p></td>
                            <td><p>{stt.question}</p></td>
                            <td><p>{stt.hint}</p></td>
                            <td className={stt.correct ? 'correct' : 'false'}><p>{stt.userAnswer}</p></td>
                            <td><div dangerouslySetInnerHTML={{__html: stt.acceptedAnswer}} /></td>
                            <td><pre style={{maxHeight: '200px', maxWidth: '400px', overflow: 'auto'}}>{stt.context}</pre></td>
                            <td><p>{'' + stt.correct}</p></td>
                            <td><audio controls><source src={stt.audio}/></audio></td>
                            
                            <td><pre style={{maxHeight: '200px', maxWidth: '400px', overflow: 'auto'}}>{this.formatSttResponse(stt.json)}</pre></td>
                            <td><p style={{maxWidth: '150px', overflow: 'auto'}}>{stt.user}</p></td>
                            <td><p>{stt.channelId}</p></td>
                            <td><p>{stt.dialogueId}</p></td>
                            <td><p>{stt.contextId}</p></td>
                            <td><p>{stt.intentId}</p></td>
                            <td><p>{stt.languageCode}</p></td>
                            <td><p>{stt.created.split('T')[0]}</p></td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        );
    }
}
