import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { EMAIL_ALREADY_IN_USE } from '../../services/payment.service';
import { SubscriptionPlan } from '../../models/subscription.plan.model';
import Checkout from '../Checkout';

interface IProps {
    plan: SubscriptionPlan;
    price: number;
    course: string;
    campaign: string;
    disablePayment: boolean;
    voucherCode: string;
    hasVoucher: boolean;
    voucherPaymentError: string;
    paymentInProgress: boolean;
    handleIsFormValid: VoidFunction;
    reserveWithVoucherCode: (e: any) => void;
    setPaymentInProgress: (isInProgress: boolean) => void;
    preAuth: () => Promise<any>;
    onSuccess: (transactionId: any, paymentMethod: string) => boolean;
}

export class FieldSelectPaymentMethod extends React.PureComponent<IProps> {
    checkoutRef;

    getRecaptchaValue = (): any => {
        return this.checkoutRef.getRecaptchaValue();
    }

    isValid = (): boolean => {
        return this.checkoutRef ? this.checkoutRef.isValid() : true;
    }

    render() {
        if (this.props.hasVoucher) {
            const error = this.props.voucherPaymentError;
            const errorRequiresLoginMessage = error && error === EMAIL_ALREADY_IN_USE;

            return (
                <>
                    {/* Emtpy div will provide spacing for the button for voucher*/}
                    <div/>
                    <div>
                        {error && <span className='error-text'> {error} </span>}
                        {errorRequiresLoginMessage &&
                            <p className={'error-text ' + (error ? '' : 'hidden')}>
                                <span>Please </span>
                                <Link to='/signin'>log in</Link>
                                <span> or use another e-mail.</span>
                            </p>
                        }
                        {error &&
                            <p className={'error-text ' + (error ? '' : 'hidden')}>
                                <span>For support please </span>
                                <a href="mailto:hello@magiclingua.com">contact us</a>
                                <span>.</span>
                            </p>
                        }
                        <br/>
                        <button
                            className={'button primary payment-form-button ' + (this.props.plan ? '' : 'hidden ') + (this.props.paymentInProgress ? 'loading' : '')}
                            disabled={this.props.disablePayment}
                            onClick={this.props.reserveWithVoucherCode}
                        >
                            <span>Get your course</span>
                            <div className='loader'/>
                        </button>
                    </div>
                </>
            );
        } else {
            return (
                <div>
                    <h2>
                        <i>3</i>
                        <span>{ this.props.price > 0 ? 'Select payment method' : 'Finish payment'}</span>
                    </h2>
                    <Checkout
                        ref={instance => this.checkoutRef = instance}
                        plan={this.props.plan}
                        price={this.props.price}
                        course={this.props.course}
                        campaign={this.props.campaign}
                        reserve={true}
                        disablePayment={this.props.disablePayment}
                        setPaymentInProgress={this.props.setPaymentInProgress}
                        preAuth={this.props.preAuth}
                        onSuccess={this.props.onSuccess}
                        handleIsFormValid={this.props.handleIsFormValid}
                    />
                </div>
            );
        }
    }
}
