import * as Promise from 'promise';
import axios from 'axios/index';
import {AbstractService} from './abstract.service';
import {AuthService} from './auth.service';
import {TrackingService} from './tracking.service';
import {Brand} from './branding.service';
import { User } from '../models/user.model';

export enum RegistrationError {
    PASSWORDS_DO_NOT_MATCH = 'passwordsDoNotMatch',
    ERROR_LOGIN_EXISTS = 'errorLoginExists',
    ERROR_EMAIL_EXISTS = 'errorEmailExists',
    INTERNAL_PROCESSING_ERROR = 'error'
}

export class RegistrationService extends AbstractService {

    private static _instance: RegistrationService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    storedUtmParams: any = null;

    fastregByEmail(email, utmParams?) {
        const self = this;
        return self.fastreg({email}, utmParams);
    }

    fastreg(account, utmParams?) {
        account.password = account.password || this.generateUUID();
        account.login = account.login || this.generateUUID();
        account.login = account.login.trim();
        account.email = account.email.trim();

        // For billing information
        if (account.userAddress != null) {
            account.userAddress.country = account.userAddress.country || '';
            account.userAddress.zipCode = account.userAddress.zipCode.trim() || '';
            account.userAddress.city = account.userAddress.city.trim() || '';
            account.userAddress.state = account.userAddress.state.trim() || '';
            account.userAddress.openAddress = account.userAddress.openAddress.trim() || '';
        }

        return this.reg(account, account.password, utmParams);
    }

    reg(account, confirmPassword, utmParams?) {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            if (account.password !== confirmPassword) {
                reject({
                   error: RegistrationError.PASSWORDS_DO_NOT_MATCH,
                   errorDesc: 'Passwords do not match.'
               });
            } else {
                account.login = account.login ? account.login.trim() : self.generateUUID();
                account.login = account.login.replace(/  +/g, ' ');
                account.langKey = account.langKey || 'en';
                account.defaultLearningLanguage = account.defaultLearningLanguage || 'es';
                account.registrationChannel = self.retrieveRegistrationChannel(utmParams);
                account.referredBy = self.retrieveReferredBy();

                // For billing information
                if (account.userAddress != null) {
                    account.userAddress.country = account.userAddress.country || '';
                    account.userAddress.zipCode = account.userAddress.zipCode.trim() || '';
                    account.userAddress.city = account.userAddress.city.trim() || '';
                    account.userAddress.state = account.userAddress.state.trim() || '';
                    account.userAddress.openAddress = account.userAddress.openAddress.trim() || '';
                }

                const processLogout = () => {
                    AuthService.instance.logout();
                    resolve();
                };

                const processLogin = function() {
                    AuthService.instance.login(account.login, account.password).then(() => {
                        resolve();
                    }).catch(() => {
                        processLogout();
                    });
                };

                const processError = function(response) {
                    // return message that can be displayed to the end user
                    if ((response === 'Error 400: "login already in use"') || (response.status === 400 && response.data === 'login already in use')) {
                        reject({
                            error: RegistrationError.ERROR_LOGIN_EXISTS,
                            errorDesc: 'There is an existing account with this e-mail address. Please login or contact our customer support.'
                        });
                    } else if ((response === 'Error 400: "e-mail address already in use"') || (response.status === 400 && response.data === 'e-mail address already in use')) {
                        reject({
                            error: RegistrationError.ERROR_EMAIL_EXISTS,
                            errorDesc: 'There is an existing account with this e-mail address. Please login or contact our customer support.'
                        });
                    } else {
                        reject({
                            error: RegistrationError.INTERNAL_PROCESSING_ERROR,
                            errorDesc: 'Internal processing error happened. Please contact our customer support.'
                        });
                    }
                };

                if (AuthService.instance.isAuthenticated()) {

                    // if there is already some authenticated user then load it from BE

                    AuthService.instance.loadUser(false).then((authedAccount) => {
                        if (authedAccount && authedAccount.email) {

                            // if an already registered 'anonymous' user was loaded then complete the registration for him
                            if (/@anonymous\.magiclingua\.com$/.test(authedAccount.email)) {
                                self.registerAnonymousAccount(account).then(() => {
                                    processLogin();
                                }).catch((response) => {
                                    processError(response);
                                });
                            } else {
                                resolve();
                            }
                        } else {
                            processLogout();
                        }
                    }, () => {
                        processLogout();
                    });

                } else {
                    // there was no authenticated user found then register a new one
                    self.createAccount(account).then(() => {
                        TrackingService.instance.sendGA('user', 'CompleteRegistration', 'email');
                        processLogin();
                    }, (response) => {
                        processError(response);
                    });
                }
            }
        });
    }

    retrieveRegistrationChannel(utmParams?) {
        const self = this;
        utmParams = self.retrieveStoredUtmParams(utmParams);
        let registrationChannel: any = null;
        if (utmParams && (utmParams.utm_source || utmParams.utm_medium || utmParams.utm_campaign || utmParams.utm_content || utmParams.utm_term)) {
            registrationChannel = {
                source: utmParams.utm_source,
                medium: utmParams.utm_medium,
                campaign: utmParams.utm_campaign,
                content: utmParams.utm_content,
                term: utmParams.utm_term
            }
        }
        return registrationChannel;
    }

    retrieveReferredBy() {
        const self = this;
        return self.queryParams().referral;
    }

    // looks up URL attributes
    queryParams() {
        let match = null;
        const pl     = /\+/g;
        const search = /([^&=]+)=?([^&]*)/g;
        const decode = (s) => { return decodeURIComponent(s.replace(pl, ' ')); };
        const query  = window.location.search.substring(1);
        const urlParams: any = {};
        while (match = search.exec(query)) {
            urlParams[decode(match[1])] = decode(match[2]);
        }
        if (urlParams['ref'] && !urlParams['utm_campaign']) {
            urlParams['utm_campaign'] = urlParams['ref'];
        }
        if (urlParams['referral'] ) {
            urlParams['referral'] = urlParams['referral'];
        }
        return urlParams;
    }

    retrieveStoredUtmParams(utmParams?) {
        const self = this;
        utmParams = utmParams || self.queryParams();
        if (utmParams) {
            const utm_source = utmParams.utm_source;
            const utm_medium = utmParams.utm_medium;
            const utm_campaign = utmParams.utm_campaign;
            const utm_content = utmParams.utm_content;
            const utm_term = utmParams.utm_term;
            if (utm_source || utm_medium || utm_campaign || utm_content || utm_term) {
                self.storedUtmParams = {
                    utm_source, utm_medium, utm_campaign, utm_content, utm_term
                };
                localStorage.setItem('utmParams', JSON.stringify(self.storedUtmParams));
            }
        }
        if (!self.storedUtmParams) {
            self.storedUtmParams = localStorage.getItem('utmParams') ? JSON.parse(localStorage.getItem('utmParams')) : null;
        }
        return self.storedUtmParams;
    }

    createTempAccountAndLogin(tempAccount?: any) {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            const l = self.generateUUID();
            const p = self.generateUUID();
            tempAccount = tempAccount || {};
            tempAccount.login = tempAccount.login || 'anonymous' + l;
            tempAccount.email = tempAccount.email || '' + l + '@anonymous.magiclingua.com';
            tempAccount.password = tempAccount.password || '' + p;
            tempAccount.langKey = tempAccount.langKey || 'en';
            tempAccount.defaultLearningLanguage = tempAccount.defaultLearningLanguage || 'es';
            tempAccount.registrationChannel = tempAccount.registrationChannel || self.retrieveRegistrationChannel();
            tempAccount.referredBy = self.retrieveReferredBy();

            self.createAccount(tempAccount).then(() => {
                AuthService.instance.login(tempAccount.login, tempAccount.password).then(() => {
                    resolve();
                }).catch((response) => {
                    reject();
                });
            }).catch((response) => {
                reject();
            });
        });
    }

    // Account is in form of User but due to the current dependencies, didn't change it's type for now
    createAccount(account) {
        const self = this;

        account.platform = 'web';
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/register',
                account
            )
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                AuthService.instance.logout();
                reject(self.errorToMessage(error));
            });
        });
    }

    private registerAnonymousAccount(account) {
        const self = this;

        account.platform = 'web';
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/anonymous',
                account
            )
                .then((response) => {
                    resolve();
                })
                .catch((error) => {
                    AuthService.instance.logout();
                    reject(self.errorToMessage(error));
                });
        });
    }

    private generateUUID() {
        let d = new Date().getTime();
        if (window.performance && typeof window.performance.now === 'function') {
            d += window.performance.now(); // use high-precision timer if available
        }
        /* tslint:disable */
        return 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r&0x3 | 0x8)).toString(16);
        });
    }
}
