import { SlideStyles } from '../config/slideStyles.model';

export const calculateStyles = (
    slideSize: number // by percent
): any => {
    const styles = new SlideStyles({
        video: {
            left: `-${slideSize / 2}%`
        },
        slide: {
            width: `${slideSize}%`,
            left: `${100 - slideSize}%`
        }
    });

    return styles;
};
