import * as React from 'react';
import { AuthService } from '../../services/auth.service';

interface IProps {
    isUserSignedin: boolean;
    handleIsFormValid: VoidFunction;
    onPartialVoucher: (value: string | null) => void;
    hasVoucher: boolean;
    voucherCode: string;
    stepNumber: number;
}

interface IState {
    email: string;
    firstName: string;
    lastName: string;
    password: string;

    firstNameError: boolean;
    lastNameError: boolean;
    emailError: boolean;
    passwordError: boolean;
}

export class FieldEnterPersonalData extends React.Component<IProps, IState> {
    private el: HTMLDivElement;

    state = {
        email: null,
        firstName: null,
        lastName: null,
        password: null,

        firstNameError: false,
        lastNameError: false,
        emailError: false,
        passwordError: false,
    };

    constructor(props) {
        super(props);
        if (this.props.isUserSignedin) {
            AuthService.instance.loadUser().then((user) => {
                this.setState({
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName
                }, this.props.handleIsFormValid);
            });
        }
    }

    private changePassword = (e): void => {
        const password = e.target.value;
        this.setState({
            password,
            passwordError: false
        }, this.props.handleIsFormValid);
    };

    private changeEmail = (e): void => {
        const email = e.target.value;
        this.setState({
            email,
            emailError: false
        }, this.props.handleIsFormValid);
    };

    private changeFirstName = (e): void => {
        const firstName = e.target.value;
        this.setState({
            firstName,
            firstNameError: false
        }, this.props.handleIsFormValid);
    };

    private changeLastName = (e): void => {
        const lastName = e.target.value;
        this.setState({
            lastName,
            lastNameError: false
        }, this.props.handleIsFormValid);
    };

    getUserPassword = (): string => {
        return this.state.password;
    };

    getUserEmail = (): string => {
        return this.state.email;
    };

    getUserFirstName = (): string => {
        return this.state.firstName;
    };

    getUserLastName = (): string => {
        return this.state.lastName;
    };

    isFirstNameValid = (): boolean => {
        return this.state.firstName && this.state.firstName.trim().length > 1;
    };

    isLastNameValid = (): boolean => {
        return this.state.lastName && this.state.lastName.trim().length > 1;
    };

    isEmailValid = (): boolean => {
        return this.state.email && this.state.email.trim().length > 1 && this.isEmailFormatValid(this.state.email.trim());
    };

    isPasswordValid = (): boolean => {
        return this.state.password && this.state.password.trim().length > 7;
    };

    private isEmailFormatValid = (email: string): boolean => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    isValid = (highlightFields?: boolean): boolean => {

        const errors: any = {
            firstNameError: !this.isFirstNameValid(),
            lastNameError: !this.isLastNameValid()
        };

        if (!this.props.isUserSignedin) {
            errors.emailError = !this.isEmailValid();
            errors.passwordError = !this.isPasswordValid();
        }

        if (highlightFields) {
            this.setState(errors);
        }

        let valid: boolean = true;
        Object.keys(errors).forEach(function(e) {
            valid = valid && !errors[e];
        });

        return valid;
    };

    handlePartialVoucherChange = (e) => {
        const value = e.target.value;
        this.props.onPartialVoucher(value || null);
    }

    render() {
        const {
            email: email,
            firstName: firstName,
            lastName: lastName,
            password: password,
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            emailError: emailError,
            passwordError: passwordError,
        } = this.state;
        return (
            <div ref={c => (this.el = c)}>
                <h2>
                <i>{this.props.stepNumber}</i>
                    <span>Enter personal data</span>
                </h2>

                <div className='input-row multiple-parts name-row'>
                    <div className='input-row-part' style={{ flex: 1 }}>
                        <div>First name</div>
                        <input
                            className={firstNameError ? 'error' : ''}
                            type='text'
                            placeholder='First name'
                            required={true}
                            value={firstName || ''}
                            onChange={e => this.changeFirstName(e)}
                            onBlur={() => this.setState({firstNameError: !this.isFirstNameValid()})}
                        />
                    </div>
                    <div className='input-row-part' style={{ flex: 1 }}>
                        <div>Last name</div>
                        <input
                            className={lastNameError ? 'error' : ''}
                            type='text'
                            placeholder='Last name'
                            required={true}
                            value={lastName || ''}
                            onChange={e => this.changeLastName(e)}
                            onBlur={() => this.setState({lastNameError: !this.isLastNameValid()})}
                        />
                    </div>
                </div>
                {!this.props.isUserSignedin && (
                    <>
                        <div className='input-row'>
                            <div>Email</div>
                            <label>
                                <input
                                    className={emailError ? 'error' : ''}
                                    type='email'
                                    id='email'
                                    placeholder='Email address'
                                    value={email || ''}
                                    required={true}
                                    onChange={e => this.changeEmail(e)}
                                    onBlur={() => this.setState({emailError: !this.isEmailValid()})}
                                />
                            </label>
                        </div>

                        <div className='input-row'>
                            <div>
                                Create password
                                <span className={passwordError ? 'error' : ''}> (min. 8 characters)</span>
                            </div>
                            <label>
                                <input
                                    className={passwordError ? 'error' : ''}
                                    type='password'
                                    placeholder='Create password (min. 8 characters)'
                                    value={password || ''}
                                    required={true}
                                    onChange={e => this.changePassword(e)}
                                    onBlur={() => this.setState({passwordError: !this.isPasswordValid()})}
                                />
                            </label>
                        </div>
                    </>
                )}

                {/* either full voucher or partial */}
                {this.props.hasVoucher ? (
                    <div className='input-row valid'>
                        <div>Voucher</div>
                        <label>
                            <input
                                type='text'
                                value={this.props.voucherCode}
                                required={true}
                                onChange={this.handlePartialVoucherChange}
                            />
                        </label>
                    </div>
                ) : <div className='input-row valid'>
                    <div>Voucher</div>
                    <label>
                        <input type='text' onChange={this.handlePartialVoucherChange}/>
                    </label>
                </div>}
            </div>
        );
    }
}
