import {Brand, BrandingService} from '../../services/branding.service';
import CaminoNavigation from './CaminoNavigation';

const caminoComponent: any = CaminoNavigation;

export default (
    BrandingService.instance.chooseComponent<any>(
        new Map([
            [Brand.CAMINO, caminoComponent],
        ]),
        caminoComponent
    )
);
