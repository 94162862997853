import * as Promise from 'promise';
import axios from 'axios/index';
import { AbstractService } from './abstract.service';
import { Dialogue } from '../models/dialogue.model';
import { LearnedDialogue } from '../models/learned.dialogue.model';
import { SubmitLearnedEvent } from '../models/submit.learned.event.model';
import { ResetLearnedProgress } from '../models/reset.learned.progress.model';
import { SubmitLearnedAttempt } from '../models/submit.learned.attempt.model';
import { SlideGroup } from '../models/slide.model';
import { Context } from '../models/context.model';
import { isNullOrUndefined } from 'util';
import { CourseService } from './course.service';
import { Course } from '../models/course.model';

/**
 * **IMPORTANT**: value field is like a "key", so it should not be changed even if we decide to
 * change how the category name is gonna be presented to the user
 *
 * Note: Have VERBEN as a top category, also have a key value and presentable value (like label-value)
 *
 * Another note: There is some `real naming problems, couldn't find a proper solution to them :/
 */
const CATEGORIES: { lang: string, topics: { label: string, options: { label: string, value: string }[] }[] }[] = [
    {
        lang: 'de', topics: [
            {
                label: 'Verbs', options: [
                    { value: 'Conjugation: Regular Verbs', label: 'Conjugation: Regular Verbs' },
                    { value: 'Conjugation: Irregular Verbs', label: 'Conjugation: Irregular Verbs' },
                    { value: 'Conjugation: Separable Verbs', label: 'Conjugation: Separable Verbs' },
                    { value: 'Conjugation: Inseparable Verbs', label: 'Conjugation: Inseparable Verbs' },
                    { value: 'Tenses: Future', label: 'Tenses: Future' },
                    { value: 'Tenses: Present', label: 'Tenses: Present' },
                    { value: 'Tenses: Imperfect', label: 'Tenses: Imperfect' },
                    { value: 'Tenses: Perfect', label: 'Tenses: Perfect' },
                    { value: 'Tenses: Past Continuous', label: 'Tenses: Past Continuous' },
                    { value: 'Modal Verbs: Wollen/Want', label: 'Modal Verbs: Wollen/Want' },
                    { value: 'Modal Verbs: Sollen/Should', label: 'Modal Verbs: Sollen/Should' },
                    { value: 'Modal Verbs: Können/Can', label: 'Modal Verbs: Können/Can' },
                    { value: 'Modal Verbs: Dürfen/Can', label: 'Modal Verbs: Dürfen/Can' },
                    { value: 'Modal Verbs: Müssen/Must', label: 'Modal Verbs: Müssen/Must' },
                    { value: 'Modal Verbs: Subjective', label: 'Modal Verbs: Subjective' },
                    { value: 'Reflexive Verbs: Sich ...', label: 'Reflexive Verbs: Sich ...' },
                    { value: 'Conjunctive: I', label: 'Conjunctive: I' },
                    { value: 'Conjunctive: II', label: 'Conjunctive: II' },
                    { value: 'Passive: Process Passive', label: 'Passive: Process Passive' },
                    { value: 'Passive: State Passive', label: 'Passive: State Passive' },
                    { value: 'Passive: Passive Sentence Forms', label: 'Passive: Passive Sentence Forms' },
                    { value: 'Passive: Passive with Modal Verbs', label: 'Passive: Passive with Modal Verbs' },
                    { value: 'Infinitive with "zu"', label: 'Infinitive with "zu"' },
                    { value: 'Verbs with Prepositions', label: 'Verbs with Prepositions' },
                    { value: 'Noun-Verb Compounds', label: 'Noun-Verb Compounds' },
                ]
            },
            {
                label: 'Nouns',
                options: [
                    { value: 'Nouns: Word Formation', label: 'Nouns: Word Formation' },
                    { value: 'Nouns: Plural', label: 'Nouns: Plural' },
                    { value: 'Genders: Masculine', label: 'Genders: Masculine' },
                    { value: 'Genders: Feminine', label: 'Genders: Feminine' },
                    { value: 'Genders: Neuter', label: 'Genders: Neuter' },
                    { value: 'Cases: Nominative', label: 'Cases: Nominative' },
                    { value: 'Cases: Accusative', label: 'Cases: Accusative' },
                    { value: 'Cases: Dative', label: 'Cases: Dative' },
                    { value: 'Cases: Genitive', label: 'Cases: Genitive' },
                    { value: 'n-declination', label: 'n-declination' },
                    { value: 'Compound Nouns', label: 'Compound Nouns' },
                    { value: 'Nouns: Nominalization', label: 'Nouns: Nominalization' },
                ]
            },
            {
                label: 'Prepositions',
                options: [
                    { value: 'Prepositions with Accusative', label: 'Prepositions with Accusative' },
                    { value: 'Prepositions with Dative', label: 'Prepositions with Dative' },
                    { value: 'Prepositions with Genitive', label: 'Prepositions with Genitive' },
                    { value: 'Local Prepositions: Two-way Prepositions', label: 'Local Prepositions: Two-way Prepositions' },
                    { value: 'Verbs with Prepositions', label: 'Verbs with Prepositions' },
                ]
            },
            {
                label: 'Adjective & Adverbs',
                options: [
                    { value: 'Adjective & Adverbs: General', label: 'Adjective & Adverbs: General' },
                    { value: 'Adjective & Adverbs: Word Formation', label: 'Adjective & Adverbs: Word Formation' },
                    { value: 'Adjective & Adverbs: Position in the Sentence', label: 'Adjective & Adverbs: Position in the Sentence' },
                    { value: 'Adjective: Adjective Declinations', label: 'Adjective: Adjective Declinations' },
                    { value: 'Adjective: Increase', label: 'Adjective: Increase' },
                    { value: 'Adjective: Participle as Adjective', label: 'Adjective: Participle as Adjective' },
                    { value: 'Adverbs: Pronominal Adverbs', label: 'Adverbs: Pronominal Adverbs' },
                    { value: 'Adverbs: Temporal Adverbs', label: 'Adverbs: Temporal Adverbs' },
                    { value: 'Adjective Groups', label: 'Adjective Groups' },
                ]
            },
            {
                label: 'Pronouns',
                options: [
                    { value: 'Emphasized Pronouns: der/die', label: 'Emphasized Pronouns: der/die' },
                    { value: 'Unemphasized Pronouns: er/sie', label: 'Unemphasized Pronouns: er/sie' },
                    { value: 'Possessive Pronouns', label: 'Possessive Pronouns' },
                    { value: 'Indefinite Pronouns: man', label: 'Indefinite Pronouns: man' },
                    { value: 'Indefinite Pronouns: jemand/niemand', label: 'Indefinite Pronouns: jemand/niemand' },
                    { value: 'Indefinite Pronouns: etwas/nichts', label: 'Indefinite Pronouns: etwas/nichts' },
                    { value: 'Prepositional Pronouns: Function', label: 'Prepositional Pronouns: Function' },
                    { value: 'Forms of Prepositional Pronouns: Things & Properties', label: 'Forms of Prepositional Pronouns: Things & Properties' },
                    { value: 'Forms of Prepositional Pronouns: Living Beings', label: 'Forms of Prepositional Pronouns: Living Beings' },
                    { value: 'Forms of Prepositional Pronouns: Institutions', label: 'Forms of Prepositional Pronouns: Institutions' },
                    { value: 'Pronoun "es": as pronoun', label: 'Pronoun "es": as pronoun' },
                    { value: 'Pronoun "es": as impersonal subject or object', label: 'Pronoun "es": as impersonal subject or object' },
                    { value: 'Pronoun "es": as a representative for a subordinate clause or infinitive clause', label: 'Pronoun "es": as a representative for a subordinate clause or infinitive clause' },
                    { value: 'Pronoun "es": emphasis on the subject', label: 'Pronoun "es": emphasis on the subject' },
                    { value: 'Pronoun "es": spoken language', label: 'Pronoun "es": spoken language' },
                ]
            },
            {
                label: 'Articles',
                options: [
                    { value: 'Definite Articles', label: 'Definite Articles' },
                    { value: 'Indefinite Articles', label: 'Indefinite Articles' },
                    { value: 'Null Articles', label: 'Null Articles' },
                    { value: 'Article Words', label: 'Article Words' },
                    { value: 'Possessive Articles', label: 'Possessive Articles' },
                ]
            },
            {
                label: 'Sentence Structure',
                options: [
                    { value: 'Sentence Structure: Subordinate Clauses', label: 'Sentence Structure: Subordinate Clauses' },
                    { value: 'Sentence Structure: Relative Clauses', label: 'Sentence Structure: Relative Clauses' },
                    { value: 'Sentence Structure: Information & Additions', label: 'Sentence Structure: Information & Additions' },
                    { value: 'Sentence Structure: Conjunctions', label: 'Sentence Structure: Conjunctions' },
                    { value: 'Sentence Structure: "nicht"', label: 'Sentence Structure: "nicht"' },
                    { value: 'Question Sentences', label: 'Question Sentences' },
                    { value: 'Imperative Sentences', label: 'Imperative Sentences' },
                    { value: 'Causal Sentences', label: 'Causal Sentences' },
                ]
            },
            {
                label: 'General',
                options: [
                    { value: 'Alphabet', label: 'Alphabet' },
                ]
            },
        ]
    },
    {
        lang: 'es', topics: [
            {
                label: 'Verbs', options: [
                    { value: 'Introduction to Verbs', label: 'Introduction to Verbs' },
                    { value: 'Verbs: Tense/Mood', label: 'Verbs: Tense/Mood' },
                    { value: 'Introduction to Present Tense: -ar Verbs', label: 'Introduction to Present Tense: -ar Verbs' },
                    { value: 'Introduction to Present Tense: -er Verbs', label: 'Introduction to Present Tense: -er Verbs' },
                    { value: 'Introduction to Present Tense: -ir Verbs', label: 'Introduction to Present Tense: -ir Verbs' },
                    { value: 'Verbs: Ser “To Be”', label: 'Verbs: Ser “To Be”' },
                    { value: 'Verbs: Haber “To Have”', label: 'Verbs: Haber “To Have”' },
                    { value: 'Reflexive Verbs', label: 'Reflexive Verbs' },
                    { value: 'Tenses: Preterit', label: 'Tenses: Preterit' },
                    { value: 'Tenses: Present Perfect', label: 'Tenses: Present Perfect' },
                    { value: 'Tenses: Imperfect', label: 'Tenses: Imperfect' },
                    { value: 'Tenses: Preterit vs. Imperfect', label: 'Tenses: Preterit vs. Imperfect' },
                    { value: 'Tenses: Pluperfect', label: 'Tenses: Pluperfect' },
                    { value: 'Tenses: Future', label: 'Tenses: Future' },
                    { value: 'Tenses: Conditional', label: 'Tenses: Conditional' },
                    { value: 'Tenses: Past Conditional', label: 'Tenses: Past Conditional' },
                    { value: 'Verbs: Imperative Mood (commands)', label: 'Verbs: Imperative Mood (commands)' },
                    { value: 'Subjunctive: Present', label: 'Subjunctive: Present' },
                    { value: 'Subjunctive: Past or Imperfect', label: 'Subjunctive: Past or Imperfect' },
                    { value: 'Subjunctive: Present Perfect', label: 'Subjunctive: Present Perfect' },
                    { value: 'Subjunctive: Pluperfect', label: 'Subjunctive: Pluperfect' },
                    { value: 'Tense Sequences', label: 'Tense Sequences' },
                    { value: 'Si Clauses (If Clauses)', label: 'Si Clauses (If Clauses)' },
                    { value: 'Indirect Speech: Present', label: 'Indirect Speech: Present' },
                    { value: 'Indirect Speech: Past', label: 'Indirect Speech: Past' },
                    { value: 'Passive Voice', label: 'Passive Voice' },
                    { value: 'Verbs: Language Contact', label: 'Verbs: Language Contact' },
                ]
            },
            {
                label: 'Nouns', options: [
                    { value: 'Introduction to Nouns', label: 'Introduction to Nouns' },
                    { value: 'Nouns: Number', label: 'Nouns: Number' },
                    { value: 'Nouns: Gender', label: 'Nouns: Gender' },
                ]
            },
            {
                label: 'Prepositions', options: [
                    { value: 'Introduction to Prepositions', label: 'Introduction to Prepositions' },
                    { value: 'Common Prepositions', label: 'Common Prepositions' },
                    { value: 'Prepositions: Por vs. Para', label: 'Prepositions: Por vs. Para' },
                ]
            },
            {
                label: 'Adjectives', options: [
                    { value: 'Introduction to Adjectives', label: 'Introduction to Adjectives' },
                    { value: 'Adjectives: Formation and Placement', label: 'Adjectives: Formation and Placement' },
                    { value: 'Comparisons and Superlatives with Adjectives', label: 'Comparisons and Superlatives with Adjectives' },
                    { value: 'Past Participles as Adjectives', label: 'Past Participles as Adjectives' },
                    { value: 'Adjectives: Todo', label: 'Adjectives: Todo' },
                    { value: 'Indefinite Adjectives', label: 'Indefinite Adjectives' },
                ]
            },
            {
                label: 'Adverbs', options: [
                    { value: 'Introduction to Adverbs', label: 'Introduction to Adverbs' },
                    { value: 'Adverbs: Formation and Placement', label: 'Adverbs: Formation and Placement' },
                    { value: 'Comparative Adverbs', label: 'Comparative Adverbs' },
                ]
            },
            {
                label: 'Pronouns', options: [
                    { value: 'Introduction to Pronouns', label: 'Introduction to Pronouns' },
                    { value: 'Subject Pronouns', label: 'Subject Pronouns' },
                    { value: 'Direct Object Pronouns', label: 'Direct Object Pronouns' },
                    { value: 'Indirect Object Pronouns', label: 'Indirect Object Pronouns' },
                    { value: 'Reflexive Pronouns', label: 'Reflexive Pronouns' },
                    { value: 'Order of Object Pronouns', label: 'Order of Object Pronouns' },
                    { value: 'Relative Pronouns', label: 'Relative Pronouns' },
                    { value: 'Indefinite Pronouns', label: 'Indefinite Pronouns' },
                    { value: 'Demonstrative Pronouns', label: 'Demonstrative Pronouns' },
                    { value: 'Possessive Pronouns', label: 'Possessive Pronouns' },
                ]
            },
            {
                label: 'Conjunctions', options: [
                    { value: 'Introduction to Conjunctions', label: 'Introduction to Conjunctions' },
                    { value: 'Coordinating Conjunctions', label: 'Coordinating Conjunctions' },
                    { value: 'Subordinating Conjunctions', label: 'Subordinating Conjunctions' },
                ]
            },
            {
                label: 'Determiners', options: [
                    { value: 'Introduction to Determiners', label: 'Introduction to Determiners' },
                    { value: 'Definite Articles', label: 'Definite Articles' },
                    { value: 'Indefinite Articles', label: 'Indefinite Articles' },
                    { value: 'Possessive Determiners', label: 'Possessive Determiners' },
                    { value: 'Demonstrative Determiners', label: 'Demonstrative Determiners' },
                    { value: 'Expressions of Quantity', label: 'Expressions of Quantity' },
                ]
            },
            {
                label: 'Negation', options: [
                    { value: 'Introduction to Negation', label: 'Introduction to Negation' },
                    { value: 'Basic Negation: No', label: 'Basic Negation: No' },
                    { value: 'Alternate Forms of Negation', label: 'Alternate Forms of Negation' },
                    { value: 'One-word Negative Sentences', label: 'One-word Negative Sentences' },
                ]
            },
            {
                label: 'General',
                options: [
                    { value: 'Alphabet', label: 'Alphabet' },
                ]
            },
        ]
    },
];

export class DialogueService extends AbstractService {
    private static _instance: DialogueService;

    // We can't access lang by CourseService while in the Editor, so langISO param is for those cases
    public static CATEGORIES(langISO?: string) {
        const currentCourse = CourseService.instance.getCurrentCourse(true);
        const currentCourseLang = currentCourse != null ? (currentCourse as Course).courseLangISO : null;

        const lang = langISO || currentCourseLang;
        return isNullOrUndefined(lang)
            ? []
            : CATEGORIES.find(categories => categories.lang === lang).topics;
    }

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    // We can't access lang by CourseService while in the Editor, so langISO param is for those cases
    public static getCategoryPresentableTextByValue(value: string, langISO?: string) {

        let relatedCategory: { label: string, value: string };
        isNullOrUndefined(langISO)
            ? relatedCategory = this.getCategoryByValue(value)
            : relatedCategory = this.getCategoryByValue(value, langISO)

        return relatedCategory.label;
    }

    // We can't access lang by CourseService while in the Editor, so langISO param is for those cases
    public static getCategoryByValue(value: string, langISO?: string): { label: string, value: string } {

        let categories: { label: string, options: { label: string, value: string }[] }[];
        isNullOrUndefined(langISO)
            ? categories = this.CATEGORIES()
            : categories = this.CATEGORIES(langISO)

        let result: { label: string, value: string };
        categories.forEach(category => {
            const res = category.options.find(option => option.value === value);
            if (res !== undefined) {
                result = res;
                return;
            }
        });

        return result;
    }

    getDialogue(dialogue: string): Promise<Dialogue> {
        const self = this;
        return new Promise<Dialogue>((resolve, reject) => {
            axios
                .get('/api/dialogue/' + dialogue)
                .then(response => {
                    const dialogue: Dialogue = response.data;
                    if (dialogue) {
                        resolve(dialogue);
                    } else {
                        reject('Failed to retrieve Dialogue');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getAllDialoguesByLangISO(langISO: string): Promise<Dialogue[]> {
        const self = this;
        return new Promise<Dialogue[]>((resolve, reject) => {
            axios
                .get(`/api/dialogue?langIso=${langISO}`)
                .then(response => {
                    const dialogues: Dialogue[] = response.data;
                    if (dialogues) {
                        resolve(dialogues);
                    } else {
                        reject('Failed to retrieve Dialogue');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    // Will be used for 'last created dialogues'
    getLastUpdatedDialogues(numberOfDialogues: number): Promise<Dialogue[]> {
        const self = this;
        return new Promise<Dialogue[]>((resolve, reject) => {
            axios
                .get(`/api/dialogue/last-updateds/${numberOfDialogues}`)
                .then(response => {
                    const dialogues: Dialogue[] = response.data;
                    if (dialogues) {
                        resolve(dialogues);
                    } else {
                        reject('Failed to retrieve Dialogues');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    saveDialogue(dialogue: Dialogue): Promise<Dialogue> {
        const self = this;
        return new Promise<Dialogue>((resolve, reject) => {
            axios
                .post('/api/dialogue', dialogue)
                .then(response => {
                    const dialogue: Dialogue = response.data as Dialogue;
                    if (dialogue) {
                        resolve(dialogue);
                    } else {
                        reject('Failed to create Dialogue');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    deleteDialogue(dialogue: Dialogue): Promise<boolean> {
        const self = this;
        return new Promise<boolean>((resolve, reject) => {
            axios
                .delete(`api/dialogue/${dialogue.id}`)
                .then(response => {
                    if (response.data) {
                        resolve(true);
                    } else {
                        reject('Failed to delete the dialogue');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getLearnedDialogue(dialogue: string): Promise<LearnedDialogue> {
        const self = this;
        return new Promise<LearnedDialogue>((resolve, reject) => {
            axios
                .get('/api/dialogue/learned/' + dialogue)
                .then(response => {
                    const dialogue: LearnedDialogue = response.data as LearnedDialogue;
                    if (dialogue) {
                        resolve(dialogue);
                    } else {
                        reject('Failed to retrieve LearnedDialogue');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    submitLearnedAttempt(
        submitLearnedAttempt: SubmitLearnedAttempt
    ): Promise<LearnedDialogue> {
        const self = this;
        return new Promise<LearnedDialogue>((resolve, reject) => {
            axios
                .post('/api/dialogue/learned/attempt', submitLearnedAttempt)
                .then(response => {
                    resolve();
                })
                .catch(error => {
                    console.log(error);
                    reject(self.errorToMessage(error));
                });
        });
    }

    saveLearnedEvent(
        submitLearnedEvent: SubmitLearnedEvent
    ): Promise<LearnedDialogue> {
        const self = this;
        return new Promise<LearnedDialogue>((resolve, reject) => {
            submitLearnedEvent.learnedEvent.started =
                submitLearnedEvent.learnedEvent.started || new Date().getTime();
            submitLearnedEvent.learnedEvent.ended =
                submitLearnedEvent.learnedEvent.ended || new Date().getTime();
            submitLearnedEvent.learnedEvent.failedAttempts =
                submitLearnedEvent.learnedEvent.failedAttempts || 0;
            axios
                .post('/api/dialogue/learned/event', submitLearnedEvent)
                .then(response => {
                    const learnedDialogue: LearnedDialogue = response.data as LearnedDialogue;
                    if (learnedDialogue) {
                        resolve(learnedDialogue);
                    } else {
                        reject('No progress received');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    resetLearnedProgress(
        resetLearnedProgress: ResetLearnedProgress
    ): Promise<LearnedDialogue> {
        const self = this;
        return new Promise<LearnedDialogue>((resolve, reject) => {
            axios
                .post('/api/dialogue/learned/reset', resetLearnedProgress)
                .then(response => {
                    const learnedDialogue: LearnedDialogue = response.data as LearnedDialogue;
                    if (learnedDialogue) {
                        resolve(learnedDialogue);
                    } else {
                        reject('No progress received');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getSlideGroups(dialogueSeqAlpha: string, contextId: string): Promise<SlideGroup[]> {
        const self = this;
        return new Promise<SlideGroup[]>((resolve, reject) => {
            axios
                .get(`/api/dialogue/${dialogueSeqAlpha}/${contextId}/slide-groups`)
                .then(response => {
                    const slideGroups: SlideGroup[] = response.data as SlideGroup[];
                    if (slideGroups) {
                        resolve(slideGroups);
                    } else {
                        reject('Failed to retrieve SlideGroups');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    updateSlideGroups(dialogueSeqAlpha: string, contextId: string, slideGroups: SlideGroup[]): Promise<SlideGroup[]> {
        const self = this;
        return new Promise<SlideGroup[]>((resolve, reject) => {
            axios
                .put(`/api/dialogue/${dialogueSeqAlpha}/${contextId}/slide-groups`, slideGroups)
                .then(response => {
                    const slideGroups: SlideGroup[] = response.data as SlideGroup[];
                    if (slideGroups) {
                        resolve(slideGroups);
                    } else {
                        reject('Failed to update SlideGroups');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getContext(dialogueSeqAlpha: string, contextId: string): Promise<Context> {
        const self = this;
        return new Promise<Context>((resolve, reject) => {
            axios
                .get(`/api/dialogue/${dialogueSeqAlpha}/${contextId}`)
                .then(response => {
                    const context: Context = response.data as Context;
                    if (context) {
                        resolve(context);
                    } else {
                        reject('Failed to get context');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        })
    }
}
