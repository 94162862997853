import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {AuthService} from '../services/auth.service';
import {Redirect} from 'react-router-dom';
import * as qs from 'qs';
import {TrackingService} from '../services/tracking.service';
import {CourseService} from '../services/course.service';
import * as Promise from 'promise';

@withNamespaces('autologin')
export default class Autologin extends React.Component<any, any> {

    state = {
        redirectTo: null
    };

    componentWillMount(): void {
        const searchObj = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const redirect = searchObj.redirect;
        const token = searchObj.token;
        const registered = searchObj.registered;
        const provider = searchObj.provider;
        if (registered && provider) {
            TrackingService.instance.sendGA('user', 'CompleteRegistration', provider);
        }
        AuthService.instance.loginByToken(token)
            .then(() => {
                if (redirect) {
                    this.setState({ redirectTo: redirect });
                } else {
                    (CourseService.instance.getCurrentCourseUrl() as Promise<string>).then(url => {
                        this.setState({ redirectTo: url });
                    });
                }
            })
            .catch((error) => {
                this.setState({ redirectTo: '/signin' })
            });
    }

    render(): React.ReactNode {
        const {redirectTo} = this.state;
        if (redirectTo) {
            return (
                <Redirect to={redirectTo} />
            );
        } else {
            return (
                <div/>
            );
        }
    }
}
