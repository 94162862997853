import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, withRouter} from 'react-router-dom';
import Navigation from './components/Navigation';
import Main from './components/Main';
import {InitService} from './services/init.service';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import {LocalupdateService} from './services/localupdate.service';
import {LocalStorageService} from './services/localstorage.service';
import { UserAgentService } from './services/user.agent.service';

@withRouter
class Body extends React.Component<any, any> {

    state = {
        mainClass: 'default'
    };

    componentWillMount() {
        const self = this;
        const updater = (pathname) => {
            pathname = pathname && pathname.length > 0 ? pathname.substr(1) : pathname;
            const className = !pathname || pathname.length < 1 || pathname === '/' ? 'default' : pathname.replace(new RegExp('/', 'g'), '-');
            self.setState({mainClass: className})
        };
        this.props.history.listen(() => {
            window.scrollTo(0, 0);
            if (this.props.history && this.props.history.location) {
                updater(this.props.history.location.pathname);
            }
        });
        if (this.props.history && this.props.history.location) {
            updater(this.props.history.location.pathname);
        }
        LocalStorageService.instance.setFirstEnteredPath(this.props.location.pathname);

        if (UserAgentService.instance.isAndroid()) {
            this.handleSmartAppBannersForAndroid();
        }
    }

    componentWillUnmount() {
        // Not sure if this works
        LocalStorageService.instance.handleSessionEnd();
    }

    // Google's documentation: https://developers.google.com/web/fundamentals/app-install-banners/native
    handleSmartAppBannersForAndroid() {
        window.addEventListener('beforeinstallprompt', (e: any) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            // Prevent Chrome 76 and later from showing the mini-infobar
            e.preventDefault();

            e.prompt();
        });
    }

    render() {
        return (
            <div id="page-wrapper" className={this.state.mainClass}>
                <Navigation/>
                <Main/>
            </div>
        );
    }
}

class App extends React.Component<any, any> {

    constructor(props) {
        super(props);
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    componentDidMount(): void {
        const w: any = window;
        window.onerror = function(message, file, line, col, error) {
            let msg;
            let obj;

            try {
                msg = JSON.stringify(error);
                obj = JSON.stringify({message, file, line, col});
            } catch (e) {
            }
            console.error('Unhandled exception:' + msg, obj);
            return false;
        };

        window.addEventListener('unhandledrejection', function (e) {
            let msg;
            try {
                msg = JSON.stringify(e);
            } catch (e) {
            }
            console.error('Unhandled rejection: ', msg || e);
        });
    }

    render() {
        return (
            <I18nextProvider i18n={ i18n }>
                <Router>
                    <Body/>
                </Router>
            </I18nextProvider>
        );
    }
}

const Init = () => {
    ReactDOM.render(
        <App/>,
        document.getElementById('root')
    );
};

InitService.instance.load().then(Init).catch((error) => {
    console.error(error);
    // TODO : error handling is needed. What to show when failed to load?
});
