import * as React from 'react';
import { Link } from 'react-router-dom';
import { UserAgentService } from '../../services/user.agent.service';
import { SpeechService } from '../../services/speech.service';
import { TweenMax } from 'gsap/TweenMax';
import { Expo } from 'gsap';

import { SlideGroup, Slide } from '../../models/slide.model';

import { MagicPlayer } from './../MagicPlayer';
import Popup from '../global/popup/Popup';

interface IState {
    isMagicPlayerMounted: boolean;
}

export default class ExerciseIntro extends React.Component<any, IState> {
    state = {
        isMagicPlayerMounted: false
    };

    element: HTMLDivElement;
    // Needed for the parent
    isPlayerDisplayed: boolean = false;

    player = null;
    magicPlayer: MagicPlayer;

    slideGroups: SlideGroup[] = [];

    // To be shown for Safari, if the user did not come from the course screen, i.e. from an iframe direct link
    skipVideoPopup: Popup;

    /**
     * SETTER & GETTER
     */
    setIsPlayerDisplayed = (isPlayerDisplayed: boolean): void => {
        this.isPlayerDisplayed = isPlayerDisplayed;
    };

    getIsPlayerDisplayed = (): boolean => {
        return this.isPlayerDisplayed;
    };

    /**
     * COMMON
     */

    // Check if everything is fine to actually init the player
    start = (): void => {
        this.startAnimation();
        this.props.videoSource !== 'vimeo' ? this.startExerciseIntroForYoutube() : this.startExerciseIntroForVimeo();
        this.setIsPlayerDisplayed(true);
    };

    initPlayer = (): void => {
        this.props.videoSource !== 'vimeo' ? this.initYoutubePlayer() : this.initMagicPlayer();
    };

    onVideoEnd = (): void => {
        UserAgentService.instance.isSafari() && this.props.isIframe
            ? this.skipVideoPopup.open()
            : this.skipVideo();
    };

    skipVideo = () => {
        SpeechService.instance.play();
        this.minimizeVideo().then(() => {
            this.props.onVideoEnd();
        }, null);
    }

    // This also destroys the players as well
    minimizeVideo = async () => {
        this.props.videoSource !== 'vimeo' ? this.minimizeYoutubePlayer() : await this.minimizeMagicPlayer();
        await this.minimizeVideoAnimation();
        this.setIsPlayerDisplayed(false);
    };

    // Animation which will be in action when the component is started
    startAnimation = (): void => {
        const el = $(this.element);
        TweenMax.to(el, 0, { autoAlpha: 1, scale: 1, ease: Expo.easeOut });
        TweenMax.to(el.find('#exr-intro-actions'), 2, {
            autoAlpha: 1,
            ease: Expo.easeOut,
            delay: 6
        });
    };

    minimizeVideoAnimation = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            TweenMax.to($(this.element), 1.2, {
                autoAlpha: 0,
                scale: 1.2,
                ease: Expo.easeOut,
                delay: 0.2,
                onComplete: resolve
            });
        });
    };

    /**
     * YOUTUBE
     */

    startExerciseIntroForYoutube = (): void => {
        const w: any = window;
        if (w.YT && w.YT.Player) {
            this.initPlayer();
        } else {
            w.onYouTubeIframeAPIReady = () => {
                this.initPlayer();
            };
        }
    };

    initYoutubePlayer = (): void => {
        $(this.element)
            .find('#video-cont')
            .append('<div id="video-player"></div>');
        setTimeout(() => {
            const w: any = window;
            this.player = new w.YT.Player('video-player', {
                videoId: this.props.video,
                playerVars: {
                    autoplay: 1,
                    fs: 0,
                    showinfo: 0,
                    iv_load_policy: 3,
                    rel: 0
                },
                events: {
                    onStateChange: this.onPlayerStateChange,
                    onError: this.onError
                }
            });
        }, 100);
    };

    onPlayerStateChange = (event): void => {
        const w: any = window;
        if (event.data == w.YT.PlayerState.ENDED) {
            this.onVideoEnd();
        }
    };

    minimizeYoutubePlayer = (): void => {
        $(this.element)
            .find('#video-cont iframe')
            .remove();
    };

    /**
     * VIMEO
     */

    startExerciseIntroForVimeo = (): void => {
        this.setIsMagicPlayerMounted(true);
        this.initPlayer();
    };

    initMagicPlayer = (): Promise<void> => this.magicPlayer.play();

    minimizeMagicPlayer = async (): Promise<void> => {
        await this.magicPlayer.destroy();
        await this.setIsMagicPlayerMounted(false);
    };

    // restartSlideStyles = (): void => this.magicPlayer.restartSlideStyles();

    setIsMagicPlayerMounted = (isMagicPlayerMounted: boolean): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            this.setState({ isMagicPlayerMounted }, resolve);
        });
    }

    /**
     *  UTILITY
     */

    onError(error) {
        console.error(JSON.stringify(error));
    }

    render() {
        return (
            <>
                <Popup
                    className='centered'
                    ref={instance => { this.skipVideoPopup = instance; }}
                    static={true}
                    hidefooter
                    hideheader
                >
                    <div style={{textAlign: 'center'}}>
                        <button 
                            className='button primary'
                            onClick={() => {
                                this.skipVideoPopup.close(); 
                                this.skipVideo();
                            }}
                        >
                            Start Exercise
                        </button>
                    </div>
                </Popup>
                <div id='exercise-intro' ref={c => (this.element = c)}>
                    <header style={{ paddingBottom: '30px' }}>
                        <div className='video-action' style={this.props.isIframe ? {visibility: 'hidden'} : {visibility: 'visible'}}>
                            {this.props.isCourseEnabled() && (
                                <Link className='action-back ghost' to={this.props.getCourseLink()}>
                                    ‹ Back
                                </Link>
                            )}
                        </div>
                        <div className='exercise-title'>
                            <div style={this.props.isIframe ? {display: 'none'} : {display: 'block'}}>
                                {'Lesson ' + this.props.currentExr.exrNr}
                            </div>
                            <h1>{this.props.currentExr.title + ' - ' + this.props.videoTitle}</h1>
                        </div>
                        <div className='video-action right'>
                            <button className='button ghost action-skip' onClick={this.skipVideo}>
                                {UserAgentService.instance.isMobile() ? 'Skip video' : 'Skip video & start exercise'}
                            </button>
                        </div>
                    </header>
                    {this.props.videoSource !== 'vimeo' ? (
                        <div id='video-cont' />
                    ) : this.state.isMagicPlayerMounted ? (
                        <MagicPlayer
                            slideGroups={this.props.slideGroups}
                            editorMode={false}
                            video={this.props.video}
                            ref={instance => (this.magicPlayer = instance)}
                            onVideoEnd={this.onVideoEnd}
                        />
                    ) : (
                        <div></div>
                    )}
                </div>
            </>
        );
    }
}
