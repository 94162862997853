import * as React from 'react';
import { Link } from 'react-router-dom';
import { Course } from '../../../models/course.model';
import CourseInfoProvider from '../CourseInfoProvider';

import * as iconRunningPerson from '../../../../../../assets/images/newmagic/running-person.svg';
import * as iconVideo from '../../../../../../assets/images/newmagic/video.svg';
import * as iconMic from '../../../../../../assets/images/newmagic/mic-gray.svg';

interface IProps {
    course: Course;
    active: boolean;
}

/**
 * Component will mainly try to use the course object from BE as much as possible.
 * New properties will be added to ICourseInfo as a last resort
 */
const PremiumCourseCard: React.SFC<IProps> = (props) => {
    const course = props.course;
    const courseInfo = CourseInfoProvider.instance.getCourseInfo(course.name);

    const { level, levelText, languageThemeImg } = courseInfo;
    return (
        <div className='course-card premium-course-card'>
            <div className='premium-course-card-top-border' />
            <div className='course-card-inner'>
                <div className='course-card-header'>
                    <div className='col'>
                        <h4>{course.title}</h4>
                        <p className='premium-course-card-level-text'>{levelText}</p>
                        <div className='premium-course-card-level-icons'>
                            <div className={'flag-item ' + (level >= 1 ? course.courseLangISO : 'blank')} />
                            <div className={'flag-item ' + (level >= 2 ? course.courseLangISO : 'blank')} />
                            <div className={'flag-item ' + (level >= 3 ? course.courseLangISO : 'blank')} />
                        </div>
                    </div>
                    <img src={course.teacherIntroImg} alt='card-image' />
                </div>
                <div className='course-card-features'>
                    <div className='course-card-feature'>
                        <img src={iconRunningPerson} alt='icon' />
                        <span>10 weeks</span>
                    </div>
                    <div className='course-card-feature'>
                        <img src={iconVideo} alt='icon' />
                        <span>{`${course.videoLessonsCount}+ video lessons`}</span>
                    </div>
                    <div className='course-card-feature'>
                        <img src={iconMic} alt='icon' />
                        <span>{`${course.speakingExercisesCount}+ dialogues`}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to={`/${course.courseLang}/course/${course.name}`}>
                        <button className='button primary'>
                            {props.active ? 'LEARN NOW' : 'TRY NOW'}
                        </button>
                    </Link>
                    {!props.active && (
                        <Link to={`/${course.courseLang}/course/${course.name}#pricing`}>
                            <button className='button primary'>Book with 50% OFF</button>
                        </Link>
                    )}
                </div>
            </div>
            <div className='card-bg-img' style={{ backgroundImage: `url(${languageThemeImg})` }} />
        </div>
    );
};

export default PremiumCourseCard;
