import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthService } from '../services/auth.service';

const PrivateRoute = ({ component: Component, onlyAdminOrSupport = true, isAnonymousAccepted = false, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            AuthService.instance.isAuthenticated()
                // if the user is authenticated, we should still check if the dir is just for admins, or is just being authenticated enough
                ? !onlyAdminOrSupport
                    // if the dir is not just for admins, check if having an "anonymous" account matters
                    ? isAnonymousAccepted
                        // just being authenticated is enough, continue, doesn't matter if the account is anonymous or not
                        ? proceed(Component, props)
                        : !AuthService.instance.isAnonymous()
                            ? proceed(Component, props)
                            : redirect(props)
                    // if the dir is only for admins, check if the user has the admin or support authority
                    : AuthService.instance.isAdmin() || AuthService.instance.isSupport()
                        ? proceed(Component, props)
                        : redirect(props)
                : redirect(props)
        }
    />
);

const proceed = (Component, props) => {
    return <Component {...props} />;
};

const redirect = props => {
    return (
        <Redirect
            to={{
                pathname: '/signin',
                state: { from: props.location }
            }}
        />
    );
};

export default PrivateRoute;
