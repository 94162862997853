import * as React from 'react';
import {withNamespaces} from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import {AuthService} from '../../services/auth.service';
import {PaymentService} from '../../services/payment.service';
import Popup from '../global/popup/Popup';
import MainFooter from '../global/MainFooter';
import CountDown from '../global/CountDown';
import * as PubSub from "pubsub-js";

import {RegistrationService} from "../../services/registration.service";
import {Brand} from "../../services/branding.service";
import Checkout from '../Checkout';
import * as lity from 'lity';

import * as featureSteps from '../../../../../assets/images/fi3m/feature-steps.png';
import * as ipad from '../../../../../assets/images/fi3m/ipad.png';
import * as iphone from '../../../../../assets/images/fi3m/iphone.png';
import * as laptop from '../../../../../assets/images/fi3m/laptop.png';
import * as iconLevel from '../../../../../assets/images/fi3m/icon-level.png';
import * as iconStart from '../../../../../assets/images/fi3m/icon-start.png';
import * as iconGoal from '../../../../../assets/images/fi3m/icon-goal.png';
import * as iconFormat from '../../../../../assets/images/fi3m/icon-format.png';
import * as topIconsRight from '../../../../../assets/images/fi3m/top-icons-right.png';
import * as topIconsLeft from '../../../../../assets/images/fi3m/top-icons-left.png';
import * as targetClasses from '../../../../../assets/images/fi3m/trg-classes.jpg';
import * as targetTravel from '../../../../../assets/images/fi3m/trg-travel.jpg';
import * as targetApps from '../../../../../assets/images/fi3m/trg-apps.jpg';
import * as targetBeginner from '../../../../../assets/images/fi3m/trg-beginner.jpg';
import * as targetSpeak from '../../../../../assets/images/fi3m/trg-speak.jpg';
import * as seal from '../../../../../assets/images/fi3m/seal.svg';
import * as promoPoster from '../../../../../assets/images/fi3m/promo-poster.jpg';
import * as logoPartner from '../../../../../assets/images/fi3m/logo.jpg';

@withNamespaces('home')
export default class CaminoHome extends React.Component<any, any> {

    state = {
        redirectTo: null,
        plans: [],
        currentOutline: 'beginner',
        offerActive: false,
        outline: {
            "beginner": [
                {
                    "unit": 1,
                    "name": "Guten Tag",
                    "en": "Hello!",
                    "goal": "Learn to start a conversation.",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "2 h"
                },
                {
                    "unit": 2,
                    "name": "Um Hilfe bitten",
                    "en": "Ask for help",
                    "goal": "Learn to ask questions | Definite articles",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "3h"
                },
                {
                    "unit": 3,
                    "name": "Woher kommst du?",
                    "en": "Where are you from?",
                    "goal": "Learn to talk about your origin | Conjugations | Du vs. Sie",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "3h"
                },
                {
                    "unit": 4,
                    "name": "Im Hotel",
                    "en": "In the Hotel",
                    "goal": "Learn the vocabulary and phrases needed during your travels | Indefinite and possessive articles",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "2h"
                },
                {
                    "unit": 5,
                    "name": "Auf dem Markt",
                    "en": "On the market",
                    "goal": "Learn to do groceries | Vegetable and fruits | Numbers | Negative articles | Much & many",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "2h"
                },
                {
                    "unit": 6,
                    "name": "Meine Arbeit",
                    "en": "My work",
                    "goal": "Let's see how to have a chat about your profession | Auxiliary verbs",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "3h"
                },
                {
                    "unit": 7,
                    "name": "Directions",
                    "en": "Directions",
                    "goal": "We'll practice asking for directions and exploring a new city | Modal verbs | Asking questions",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "3h"
                },
                {
                    "unit": 8,
                    "name": "Verkehrsmittel nutzen",
                    "en": "Using transport",
                    "goal": "Find out how to order tickets and use transportation in Germany | Separable verbs",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "3h"
                },
                {
                    "unit": 9,
                    "name": "In der Stadt",
                    "en": "In the city",
                    "goal": "Let's prepare to have a great time in a city | Personal pronouns | Man vs Mann",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "2h"
                },
                {
                    "unit": 10,
                    "name": "Wiederholung",
                    "en": "Review",
                    "goal": "We'll review everything we have learned so far and practice having conversations in lot of different situations",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-3",
                    "time": "2h"
                }
            ],
            "l_intermediate": [
                {
                    "unit": 1,
                    "name": "Ich und du",
                    "en": "Me and You",
                    "goal": "Let's make some plans with a friend | Perfect tense | Conjunction 'weil'",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 2,
                    "name": "Reisen",
                    "en": "Traveln",
                    "goal": "Are you travelling to Germany? Here are the most important phrases you need | Interdependencies | Indefinite pronoun",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 3,
                    "name": "Ämter und Versicherungen",
                    "en": "Bureaucracy",
                    "goal": "Whenever you need to go to a government office, be prepared for 'their language' | Reflexive verbs | Conjunctives",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 4,
                    "name": "Arbeitssuche",
                    "en": "Finding a job",
                    "goal": "Do you want to work in Germany? Great, that's what we like to do best! | Passive present tense | Preteritum",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 5,
                    "name": "Medien",
                    "en": "Media",
                    "goal": "Learn to speak about TV-Shows, Radio and Social Media | Adjective declension",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 6,
                    "name": "Gesundheit",
                    "en": "Health",
                    "goal": "Today we learn to talk about health-related topics | Comparative | Superlative",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 7,
                    "name": "Aus- und Weiterbildung",
                    "en": "Education and training",
                    "goal": "Learn to talk about university and apprenticeship | Word formations with Adjectives",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 8,
                    "name": "Kinder",
                    "en": "Kids",
                    "goal": "Let's talk about children and how children talk. | Diminutives | Indirect questions",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 9,
                    "name": "Rund ums Haus",
                    "en": "Around the house",
                    "goal": "If you want to get along with your new neighbors check this out! | Adjective declensions without the article",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                },
                {
                    "unit": 10,
                    "name": "Wiederholung",
                    "en": "Revision",
                    "goal": "Yay! You've learned a lot, let's make it stick!",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1",
                    "time": "1h"
                }
            ],
            "intermediate": [
                {
                    "unit": 1,
                    "name": "Partnershaft",
                    "en": "Partnership",
                    "goal": "Find out how to talk about partnerships | Plusquamperfekt tense",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 2,
                    "name": "Miteinander",
                    "en": "Cooperation",
                    "goal": "Living in peace and harmony the German way | Subjunctive II unreal conditions",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 3,
                    "name": "Hobbies",
                    "en": "Hobbies",
                    "goal": "Learn to explain your hobbies to anybody | Zero articles",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 4,
                    "name": "Die Leinwand",
                    "en": "Movies and Theatre",
                    "goal": "Get to know German movies, series, actors and famous theater plays | Infinitive clauses",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 5,
                    "name": "Erziehung",
                    "en": "Parenting",
                    "goal": "Parenting done right. Learn how to manage | Declination of adjectives: comparative & superlative",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 6,
                    "name": "Gesellschaft",
                    "en": "Society",
                    "goal": "We learn to talk about topics such as democracy, consumption, and advertising | Relative sentences with relative pronouns",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 7,
                    "name": "Menschen",
                    "en": "People",
                    "goal": "Let's talk about the country and it's people | Passive: Preterite & Perfect",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 8,
                    "name": "Feste",
                    "en": "Festivals",
                    "goal": "Everything you need to prepare yourself for Oktoberfest and other festivals | Advanced adverbs",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 9,
                    "name": "Nachrichten",
                    "en": "News",
                    "goal": "Find out how to have a conversation about recent news | Futur I ",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 10,
                    "name": "Wiederholung",
                    "en": "Revision",
                    "goal": "Yay! You've learned a lot, let's make it stick!",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                }
            ],
            "advanced": [
                {
                    "unit": 1,
                    "name": "Sprechen und Sprachen",
                    "en": "Languages",
                    "goal": "Today we talk about other languages | Modal verbs advanced | Past tenses advanced",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 2,
                    "name": "Unterwegs",
                    "en": "On the go",
                    "goal": "Let's go on an adventure today | Passive | Demonstrative Article",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 3,
                    "name": "Soziales und Politik",
                    "en": "Politics",
                    "goal": "Learn to discuss local and world politics | Verbs with prepositional case | n-Deklination",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 4,
                    "name": "Arbeitswelt",
                    "en": "Corporations",
                    "goal": "Talking about meetings, corporations and preparing you for business lunch | Politeness | Conjunctive moods I and II",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 5,
                    "name": "Bildungssystem",
                    "en": "Education system",
                    "goal": "Understand everything about German educational system | Transformation of prepositional groups into subordinate clauses",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 6,
                    "name": "Trends",
                    "en": "Trends",
                    "goal": "Learn to chat about sharing economy, digitization and other trends | Futur II advanced | noun-verb connections",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 7,
                    "name": "Globalisierung",
                    "en": "Globalization",
                    "goal": "Let's have a talk about globalization | Participles as adjectives | Advanced modals",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 8,
                    "name": "History",
                    "en": "Geschichte",
                    "goal": "Here we learn to talk about important events of the history | concessive clauses",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 9,
                    "name": "Deutsche Künstler",
                    "en": "German artists",
                    "goal": "Get to know the most popular German artists | Alternatives of passive voice",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                },
                {
                    "unit": 10,
                    "name": "Wiederholung",
                    "en": "Revision",
                    "goal": "Yay! You've learned a lot, let's make it stick!",
                    "videos": 25,
                    "exercises": "250+",
                    "sessions": "1-2",
                    "time": "1h"
                }
            ]
        }
    };

    targetSwiper;
    deadline;
    from: string;

    componentWillMount(): void {
        this.startCountdown();

        // retrieve plans before initial rendering because plans' data is needed for the very first rendering
        PaymentService.instance.plans(false, false, undefined, 'fluent-in-3-months')
            .then((plans) => {
                this.setState({plans});
            })
            .catch((error) => {
                console.log('error while loading plans: ' + error)
            });
    }

    componentDidMount(): void {
        const script = document.createElement("script");
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.2.0/js/swiper.min.js";
        script.async = true;
        document.body.appendChild(script);

        const link = document.createElement("link");
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.2.0/css/swiper.min.css';
        link.media = 'all';
        document.body.appendChild(link);

        const w: any = window;
        script.onload = () => {
            var menu = ['Are a total beginner', 'Have visited language classes', 'Have tried some apps', 'Lack speaking practise', 'Like to travel'];
            setTimeout(function(){
                this.targetSwiper = new w.Swiper('#target-learners .swiper-container', {
                    spaceBetween: 0,
                    effect: 'fade',
                    pagination: {
                        el: '.target-tabs',
                        clickable: true,
                        renderBullet: function (index, className) {
                            return '<div class="' + className + '">' + (menu[index]) + '</div>';
                        },
                    },
                });
            },500);
        };

        const featuresHeader = $('#main-features h2');
        const triggerOffset = $('#top h1').offset();

        $(window).scroll(() => {
            const scrollTop = $(window).scrollTop();
            // console.log(scrollTop,  triggerOffset.top);

            if (scrollTop > triggerOffset.top) {
                featuresHeader.addClass('show');
            } else {
                featuresHeader.removeClass('show');
            }
        });
    }

    componentWillUnmount(): void {

        function getTopOffset(selector){
            var elem = $(selector);
            var top =  elem.length? elem.offset().top : null;
            return top;
        }

        // var isSticky = false;
        // var inputBox = $('.templatingLogo + .templateBox .modifyText');
        //
        //
        // var windowHeight = $(window).height();
        // var inputBoxTop = getTopOffset(inputBox);
        // var inputBoxHeight = inputBox.height();
        // var scrollPos = $(window).scrollTop();
        //
        // $( window ).resize(function() {
        //    windowHeight = $(window).height();
        //    inputBoxTop = getTopOffset(inputBox);
        //    inputBoxHeight = inputBox.height();
        // });

        // $(window).scroll(function(){
        //    var scrollTop = $(window).scrollTop();
        //
        //    if ((document.body.getBoundingClientRect()).top > scrollPos) {
        //        inputBox.addClass('offset');
        //        inputBox.removeClass('top');
        //    } else {
        //        inputBox.addClass('top');
        //        inputBox.removeClass('offset');
        //    }
        //    scrollPos = (document.body.getBoundingClientRect()).top;
        //
        //
        //    if(scrollTop >= (inputBoxTop) && !isSticky){
        //        console.log('sticky');
        //        inputBox.addClass('sticky');
        //        if (isMobile) {
        //            $('#modifySubmit').text('Designs anzeigen');
        //        }
        //        isSticky = true;
        //    }
        //
        //    if( scrollTop < (inputBoxTop) && isSticky) {
        //        console.log('unsticky');
        //        inputBox.removeClass('sticky');
        //        $('#modifySubmit').text('Name auf Designs anzeigen');
        //        isSticky = false;
        //    }
        // });
    }

    startCountdown = (): void => {
        if (localStorage.getItem('reserveDeadline')) {
            this.deadline = new Date(Date.parse(localStorage.getItem('reserveDeadline')));
        } else {
            this.deadline = new Date(new Date().getTime() + (24 * 60 * 60 * 1000));
            localStorage.setItem('reserveDeadline', this.deadline.toString());
        }
        if (this.deadline.getTime() > new Date().getTime()) {
            this.setState({offerActive: true});
        } else {
            this.deadline = new Date();
        }
    };

    onOfferEnd = (): void => {
        $('#offer-countdown').remove();
    };

    showOutline = (idx): void => {
        this.setState({
            currentOutline: idx
        });
    };

    showTargetSlide = (idx): void => {
        // console.log(idx);
        this.targetSwiper.slideTo(idx);
    };

    openVideo = (): void => {
        // lity('https://vimeo.com/307226465');
        lity('https://vimeo.com/259154057');
    };
    openUserVideo = (): void => {
        // lity('https://vimeo.com/313799477');
        lity('https://vimeo.com/320186219');
    };

    startPayment = (planId) => {
        if (this.state.offerActive) {
            this.props.history.push('/enroll/?plan=' + planId);
        } else {
            alert("The offer is inactive");
        }
    };

    gotoDemo = (planId) => {
        this.props.history.push('/spanish/course/es-camino-a1');
    };

    scrollTo = (id) => {
        $('html, body').animate({
            scrollTop: $(id).offset().top
        }, 400);
    };

    render(): React.ReactNode {
        const { plans, redirectTo } = this.state;

        if (redirectTo) {
            return (
                <Redirect to={redirectTo} />
            );
        } else {
            return (
                <div id="course-lp">

                    <section id="top">
                        <div>
                            <header id="top-header">
                                <h1>
                                    Speak German in 10 weeks
                                    <svg className="left" width="123" height="45" viewBox="0 0 123 45" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path fill="#333" d="M123 15H0V0h123z"/><path fill="#FF3F00" d="M123 30H0V15h123z"/><path d="M123 43.008c0 1.1-.902 1.992-2.007 1.992H0V30h123v13.008z" fill="#FFDB00"/></g></svg>
                                    <svg className="right" width="123" height="45" viewBox="0 0 123 45" xmlns="http://www.w3.org/2000/svg"><g fill="none"><path fill="#333" d="M123 15H0V0h123z"/><path fill="#FF3F00" d="M123 30H0V15h123z"/><path d="M123 43.008c0 1.1-.902 1.992-2.007 1.992H0V30h123v13.008z" fill="#FFDB00"/></g></svg>
                                </h1>
                                <span>This challenge focuses 100% on preparing you for real-life conversations. You'll speak from day one and feel confident starting conversation with anybody after 10 weeks.</span>
                            </header>

                            <div id="enroll-box">
                                <div id="promo-video" className="hide-mobile" onClick={this.openVideo}>
                                    <svg className="action-play" width="111" height="69" viewBox="0 0 111 69" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M9.422 2.16C25.163.72 40.673 0 55.952 0c15.182 0 30.593.711 46.233 2.133 3.676.335 6.646 3.14 7.188 6.791C110.458 16.22 111 24.102 111 32.574c0 8.888-.597 17.777-1.79 26.667-.487 3.623-3.367 6.455-6.997 6.881C85.848 68.041 70.06 69 54.85 69c-15.133 0-30.262-.95-45.388-2.85-3.51-.44-6.315-3.134-6.895-6.625C.856 49.231 0 39.993 0 31.812c0-7.75.768-15.497 2.304-23.241.69-3.479 3.587-6.088 7.118-6.41z" fill="#000" opacity="1"/>
                                            <path fill="#FFF" d="M68 33L44 48V18z"/>
                                        </g>
                                    </svg>
                                    <p>Learn in 2 minutes how it works</p>

                                    <img src={promoPoster} />
                                </div>

                                <div className="enroll-submit">
                                    <div>
                                        <div>
                                            <header>Get 50% off today</header>
                                            <p>Exclusive offer. Ends soon</p>
                                            <CountDown deadline={this.deadline} onend={this.onOfferEnd} hideDays/>
                                        </div>
                                        <a id="cta-top-reserve" className="button primary" href="/fluent-in-3-months#packages">Reserve your seat</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/*
                        <img id="bg-icons-l" className="hide-mobile" src={topIconsLeft}/>
                        <img id="bg-icons-r" className="hide-mobile" src={topIconsRight}/>
                         */}
                    </section>

                    <section className="main-section" id="main-features">
                        <div className="frame">
                            <header className="main-header alc">
                                <h2>Everything you missed in other apps<br/> to finally start speaking</h2>
                            </header>
                            <div id="feature-wrapper">
                                <div className="feature">
                                    <header>
                                        <figure>
                                            <svg width="50" height="32" viewBox="0 0 50 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" opacity=".977"><rect fill="#6761D7" width="50" height="32" rx="4"/><path fill="#FFF" d="M29 15.5L20 21V10z"/></g></svg>
                                        </figure>
                                        <div>Video lessons with all<br/>the necessary grammar</div>
                                    </header>
                                </div>

                                <div className="feature">
                                    <header>
                                        <figure>
                                            <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg"> <g fill="none" fillRule="evenodd" opacity=".977"> <rect fill="#0EA583" width="38" height="38" rx="19"/> <path d="M18.375 24.1C15.909 23.764 14 21.463 14 18.676c0-.378.28-.684.625-.684s.625.306.625.684c0 2.264 1.679 4.1 3.75 4.1 2.071 0 3.75-1.836 3.75-4.1 0-.378.28-.684.625-.684s.625.306.625.684c0 2.787-1.909 5.088-4.375 5.424v2.213c0 .38-.277.687-.625.687-.345 0-.625-.314-.625-.687V24.1zm-2.06-10.191c0-1.607 1.225-2.909 2.73-2.909 1.51 0 2.733 1.3 2.733 2.909v4.879c0 1.606-1.226 2.909-2.732 2.909-1.509 0-2.732-1.301-2.732-2.91V13.91z" fill="#FFF"/> </g> </svg>
                                        </figure>
                                        <div>Speech recognition to<br/> practise while no one listens</div>
                                    </header>
                                </div>
                                <div className="feature">
                                    <header>
                                        <figure>
                                            <svg width="44" height="29" viewBox="0 0 44 29" xmlns="http://www.w3.org/2000/svg"> <g fill="none" fillRule="evenodd" opacity=".977"> <path d="M36 8.583l6.503-3.725c.48-.275 1.09-.109 1.365.37.086.152.132.323.132.497V23.26c0 .552-.448 1-1 1-.177 0-.35-.047-.504-.136L36 20.333V25c0 2.21-1.79 4-4 4H4c-2.21 0-4-1.79-4-4V4c0-2.21 1.79-4 4-4h28c2.21 0 4 1.79 4 4v4.583z" fill="#CF5EC0"/> <path d="M15.117 21.877c.967-.512 2.073-2.4 2.073-2.4.244-.426.27-.941-.163-1.185 0 0-.736-.613-.841-.769-.483-.713-.464-1.372.202-2.039l3.096-3.096c.667-.666 1.326-.685 2.039-.202.156.106.77.841.77.841.243.434.758.407 1.185.163 0 0 1.887-1.106 2.399-2.073.218-.411.142-.916-.187-1.245l-.684-.684c-2.17-2.17-4.618-1.101-6.787 1.068l-3.964 3.964c-2.168 2.168-3.236 4.617-1.067 6.786l.683.683c.33.33.835.406 1.246.188z" fill="#FFF"/> </g> </svg>
                                        </figure>
                                        <div>1-on-1 video calls<br/> with native speakers</div>
                                    </header>
                                </div>
                            </div>

                            <div id="devices" className="hide-mobile">
                                <div>
                                    <img src={ipad}/>
                                </div>
                                <div>
                                    <img src={laptop}/>
                                </div>
                                <div>
                                    <img src={iphone}/>
                                </div>
                            </div>

                            <img id="feature-steps" className="hide-mobile" src={featureSteps}/>
                        </div>
                    </section>

                    <section className="main-section" id="target-learners">
                        <div className="frame">
                            <header className="main-header">
                                <h2>This challenge is perfect<br/> for you if you...</h2>
                            </header>

                            <div className="swiper-container">

                                <div className="target-tabs"/>

                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div>
                                            <p>
                                                Great! We will guide you from your first ‘Hallo!’ all the way up to being able to work and study in German. You will learn how to handle different situations in real life, such as introducing yourself, talking about your hobbies, going to a doctor or applying for a job.
                                            </p>
                                            <p>
                                                If one of the following sounds familiar, this course is for you:
                                            </p>

                                            <div className="problems">
                                                <div>“I don’t know where to start.”</div>
                                                <div>“I don’t want to memorize vocabulary and grammar, but want to build my own sentences.”</div>
                                                <div>“I’d like to be prepared for having real-life conversations in German.”</div>
                                            </div>
                                        </div>
                                        <figure>
                                            <img src={targetBeginner}/>
                                        </figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <div>
                                            <p>
                                                The time at school is great, but sometimes it can feel like it's too expensive, your vacation is over, you just don’t have the energy to go there after work, or you don't feel comfortable speaking during classes.
                                            </p>
                                            <p>
                                                If one of the following sounds familiar, this course is for you:
                                            </p>
                                            <div className="problems">
                                                <div>“I’d love to stay motivated and keep learning when I cannot visit the school.”</div>
                                                <div>“I don’t always feel comfortable speaking in front of other students.”</div>
                                                <div>“I’d like to practice speaking while no one hears my mistakes.”</div>
                                            </div>
                                        </div>
                                        <figure>
                                            <img src={targetClasses}/>
                                        </figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="slide-body">
                                            <p>
                                                If you have tried some apps or other language learning methods and felt, like many of the users that we have spoken to, that you didn't make any significant progress despite months of learning, then this course from Magiclingua is for you. You will feel like you are making real progress every week.
                                            </p>
                                            <p>
                                                If one of the following sounds familiar, this course is for you:
                                            </p>
                                            <div className="problems">
                                                <div>“Most apps offer very little speaking practice.”</div>
                                                <div>“I need simple and clear grammar explanations. Apps just don’t offer that.”</div>
                                                <div>“Learning vocabulary and grammar is not enough. I want to be able to build my own sentences.”</div>
                                            </div>
                                        </div>
                                        <figure>
                                            <img src={targetApps}/>
                                        </figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <div>
                                            <p>
                                                This course is 100% focused on speaking. You will not fill in blanks, not pick right answers from a list nor memorize vocabulary. You will speak from day one and build your own sentences.
                                            </p>
                                            <p>
                                                If one of the following sounds familiar, this course is for you:
                                            </p>
                                            <div className="problems">
                                                <div>“I know some German vocabulary, but I can not put them easily together to sentences”</div>
                                                <div>“I don’t know anybody I could talk to in German”</div>
                                                <div>“I’m shy and afraid of making mistakes in front of others”</div>
                                            </div>
                                        </div>
                                        <figure>
                                            <img src={targetSpeak}/>
                                        </figure>
                                    </div>
                                    <div className="swiper-slide">
                                        <div>
                                            <p>
                                                Do you want to hang with the locals, ask for directions, order food in a restaurant, absorb the culture? You want to be confident and comfortable speaking the language? Look no further. This course is 100% focussed on preparing you for real-life situations.
                                            </p>

                                            <div className="problems">
                                                <div>“I'd love to get in touch with the locals”</div>
                                                <div>“I don't want to look stupid and lost”</div>
                                                <div>“I wish I could just prepare myself quickly with conversational German for my travels.”</div>
                                            </div>
                                        </div>
                                        <figure>
                                            <img src={targetTravel}/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="quote-section">
                        <div className="frame">
                            <div className="big-quote">
                                <svg className="quotes" width="31" height="22" viewBox="0 0 31 22" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="21.113%" y1="0%" x2="65.666%" y2="100%" id="a"><stop stop-color="#BE92FF" offset="0%"/><stop stop-color="#4DDCB9" offset="100%"/></linearGradient></defs><path d="M72.4 2118c-1.6 0-2.4-.783-2.4-2.348v-8.767c0-5.714 2.56-10.254 8.717-10.88 1.04-.079 1.44.86.72 1.722-2.16 2.504-2.479 4.461-2.559 6.027 0 .47.32.783.8.783h3.679c1.52 0 2.399.86 2.399 2.348v8.767c0 1.565-.88 2.348-2.4 2.348H72.4zm17.243 0c-1.6 0-2.399-.783-2.399-2.348v-8.767c0-5.714 2.56-10.254 8.717-10.88 1.04-.079 1.44.86.72 1.722-2.16 2.504-2.479 4.461-2.559 6.027 0 .47.32.783.8.783H98.6c1.52 0 2.399.86 2.399 2.348v8.767c0 1.565-.88 2.348-2.4 2.348h-8.957z" transform="rotate(180 50.5 1059)" fill="url(#a)" fillRule="evenodd"/></svg>
                                <p>
                                    If your goal is to<br/>
                                    speak German fluently,<br/>
                                    this challenge is a must.
                                </p>
                            </div>
                        </div>
                    </div>

                    <section className="main-section" id="about-course">
                        <div className="frame">
                            <header className="main-header alc">
                                <h2>About the challenge</h2>
                            </header>

                            <div id="main-info">
                                <div>
                                    <img src={iconLevel}/>
                                        <div className="body">
                                            <header>Levels</header>
                                            <p>Beginner, early intermediate</p>
                                        </div>
                                </div>
                                <div>
                                    <img src={iconGoal}/>
                                        <div className="body">
                                            <header>Goal</header>
                                            <p>Have a 15 minute conversation entirely in German</p>
                                        </div>
                                </div>
                                <div>
                                    <img src={iconStart}/>
                                        <div className="body">
                                            <header>Challenge starts</header>
                                            <p>15th October</p>

                                        </div>
                                </div>
                                <div>
                                    <img src={iconFormat}/>
                                        <div className="body">
                                            <header>Format</header>
                                            <p>Self-study and tutoring sessions</p>
                                        </div>
                                </div>
                            </div>

                            <div id="faq">
                                <article>
                                    <header>How does this method work?</header>
                                    <p>This language challenge is 100% focused on speaking and preparing you for real-life conversations. You won't waste time filling in blanks or clicking on pictures. You learn to build your own sentences with ease, and start speaking from day one. Starting today! : )</p>
                                </article>
                                <article>
                                    <header>What will I achieve?</header>
                                    <ul className="goal-list">
                                        <li>
                                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 22.184L9.613 24l-1.51-2.591-2.9.764-.404-2.972-2.972-.404.764-2.9L0 14.387 1.816 12 0 9.613l2.591-1.51-.764-2.9 2.972-.404.404-2.972 2.9.764L9.613 0 12 1.816 14.387 0l1.51 2.591 2.9-.764.404 2.972 2.972.404-.764 2.9L24 9.613 22.184 12 24 14.387l-2.591 1.51.764 2.9-2.972.404-.404 2.972-2.9-.764L14.387 24 12 22.184zm3.737-14.126c-1.854 1.113-3.704 2.564-5.648 5.037-.695-.888-1.724-1.566-2.824-2.114-.464-.231-.966-.164-1.236.27-.27.435-.156 1.018.257 1.303 1.408.972 2.257 1.745 3.15 3.12.36.554 1.105.531 1.486-.02 1.898-2.755 3.89-4.673 5.759-6 .403-.286.546-.854.285-1.295-.26-.44-.795-.562-1.23-.301z" fill="#4DDCB9" fillRule="evenodd"/>
                                            </svg>
                                            You’ll speak fluently without thinking about grammar
                                        </li>
                                        <li>
                                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 22.184L9.613 24l-1.51-2.591-2.9.764-.404-2.972-2.972-.404.764-2.9L0 14.387 1.816 12 0 9.613l2.591-1.51-.764-2.9 2.972-.404.404-2.972 2.9.764L9.613 0 12 1.816 14.387 0l1.51 2.591 2.9-.764.404 2.972 2.972.404-.764 2.9L24 9.613 22.184 12 24 14.387l-2.591 1.51.764 2.9-2.972.404-.404 2.972-2.9-.764L14.387 24 12 22.184zm3.737-14.126c-1.854 1.113-3.704 2.564-5.648 5.037-.695-.888-1.724-1.566-2.824-2.114-.464-.231-.966-.164-1.236.27-.27.435-.156 1.018.257 1.303 1.408.972 2.257 1.745 3.15 3.12.36.554 1.105.531 1.486-.02 1.898-2.755 3.89-4.673 5.759-6 .403-.286.546-.854.285-1.295-.26-.44-.795-.562-1.23-.301z" fill="#4DDCB9" fillRule="evenodd"/>
                                            </svg>
                                            You’ll gain the confidence to start a conversation with anyone
                                        </li>
                                        <li>
                                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 22.184L9.613 24l-1.51-2.591-2.9.764-.404-2.972-2.972-.404.764-2.9L0 14.387 1.816 12 0 9.613l2.591-1.51-.764-2.9 2.972-.404.404-2.972 2.9.764L9.613 0 12 1.816 14.387 0l1.51 2.591 2.9-.764.404 2.972 2.972.404-.764 2.9L24 9.613 22.184 12 24 14.387l-2.591 1.51.764 2.9-2.972.404-.404 2.972-2.9-.764L14.387 24 12 22.184zm3.737-14.126c-1.854 1.113-3.704 2.564-5.648 5.037-.695-.888-1.724-1.566-2.824-2.114-.464-.231-.966-.164-1.236.27-.27.435-.156 1.018.257 1.303 1.408.972 2.257 1.745 3.15 3.12.36.554 1.105.531 1.486-.02 1.898-2.755 3.89-4.673 5.759-6 .403-.286.546-.854.285-1.295-.26-.44-.795-.562-1.23-.301z" fill="#4DDCB9" fillRule="evenodd"/>
                                            </svg>
                                            You’ll perfectly understand real spoken language
                                        </li>
                                    </ul>
                                </article>
                                <article>
                                    <header>What is the challenge?</header>
                                    <p>You'll get each week one unit full of speaking lessons preparing you for a certain situation. Completing them all gives you confidence and experience for the ultimate test: a 15 minutes video-chat with a native German speaker.</p>
                                </article>
                                <article>
                                    <header>What are the 1-on-1 tutoring sessions?</header>
                                    <p>Scheduling a 1-on-1 tutoring session is a great opportunity to see what you are really capable of. You meet fun and friendly native speakers who practice with you what you need help with most to get to the next level. Sessions take place in Skype via video chat, and are 30 minutes each.</p>
                                </article>
                            </div>
                        </div>
                    </section>

                    <section id="course-outline" className="main-section">
                        <div className="frame">
                            <header className="main-header alc">
                                <h2>Challenge outline</h2>

                                {/*<div className="tabs button-group">
                                    <button className={'ghost ' + (this.state.currentOutline === 'beginner'? 'active' : '')} onClick={() => {this.showOutline('beginner')}}>Beginner</button>
                                    <button className={'ghost ' + (this.state.currentOutline === 'intermediate'? 'active' : '')} onClick={() => {this.showOutline('intermediate')}}>Intermediate</button>
                                    <button className={'ghost ' + (this.state.currentOutline === 'advanced'? 'active' : '')} onClick={() => {this.showOutline('advanced')}}>Advanced</button>
                                </div>*/}
                            </header>

                            <div className="outline-table">
                                <div className="outline-header">
                                    <span>Week</span>
                                    <span>Goal</span>
                                    <span className="number">Video<br/> lessons</span>
                                    <span className="number">Dialog<br/> exercises</span>
                                    <span className="number">1-on-1<br/> sessions</span>
                                    <span className="number">Learning<br/> time</span>
                                </div>

                                {this.state.outline[this.state.currentOutline].map((unit, i) =>
                                    <div className="outline-row" key={i}>
                                        <span><i>{"Week " + (i+1)}</i><strong>{unit.en}</strong></span>
                                        <span>{unit.goal}</span>
                                        <span className="number">{unit.videos}</span>
                                        <span className="number">{unit.exercises}</span>
                                        <span className="number">{unit.sessions}</span>
                                        <span className="number">{unit.time}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>


                    <section className="main-section" id="reviews">
                        <div className="frame">
                            <header className="main-header alc">
                                <h2>This is what other students say</h2>
                            </header>

                            <div id="reviews-wrapper">
                                <div className="review-wall">
                                    <div className="review">
                                        <figure>
                                            <img src="https://res.cloudinary.com/magiclingua/image/upload/v1538565862/christi_w5abgx.png"/>
                                        </figure>
                                        <p><strong>Christi Gasperi</strong> I loved it!! The voice recognition worked very well...almost too well as I would correct myself and the voice recognition would pick that up too!! The skype chat was AMAZING!!</p>
                                    </div>
                                    <div className="review">
                                        <figure>
                                            <img src="https://res.cloudinary.com/magiclingua/image/upload/v1538565862/gina_i6ykno.png"/></figure>
                                        <p><strong>Gina Squier</strong> I really like the idea of the follow up session with a tutor. I think this is what's missing from a lot of online platforms/mobile apps and sets your course apart from the others.</p>
                                    </div>
                                    <div className="review">
                                        <figure><img src="https://res.cloudinary.com/magiclingua/image/upload/v1538565862/default-avatar_sbz7e4.svg"/>
                                        </figure>
                                        <p><strong>Steve</strong> I liked it a lot!<br/> The voice recognition worked very well (even with my
                    Scottish accent), and the videos explained things clearly.
                                        </p>
                                    </div>
                                    <div className="review">
                                        <figure><img src="https://res.cloudinary.com/magiclingua/image/upload/v1538565862/default-avatar_sbz7e4.svg"/>
                                        </figure>
                                        <p><strong>Fernando Troche</strong> I've done tapes, various things like pimsleur, learn as you
                    drive, mango, duolingo. I feel they can be fine for some, they try to be interactive, but there
                    is that crucial actual interaction piece I feel they miss. Like someone to talk to.
                                        </p>
                                    </div>

                                </div>

                                <div id="video-reviews">

                                    <div className="vid-review" onClick={this.openUserVideo}>
                                        <header>See how Leo<br/> learns German</header>
                                    </div>

                                    <div className="big-quote">
                                        <svg className="quotes" width="31" height="22" viewBox="0 0 31 22" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="21.113%" y1="0%" x2="65.666%" y2="100%" id="a"><stop stop-color="#BE92FF" offset="0%"/><stop stop-color="#4DDCB9" offset="100%"/></linearGradient></defs><path d="M72.4 2118c-1.6 0-2.4-.783-2.4-2.348v-8.767c0-5.714 2.56-10.254 8.717-10.88 1.04-.079 1.44.86.72 1.722-2.16 2.504-2.479 4.461-2.559 6.027 0 .47.32.783.8.783h3.679c1.52 0 2.399.86 2.399 2.348v8.767c0 1.565-.88 2.348-2.4 2.348H72.4zm17.243 0c-1.6 0-2.399-.783-2.399-2.348v-8.767c0-5.714 2.56-10.254 8.717-10.88 1.04-.079 1.44.86.72 1.722-2.16 2.504-2.479 4.461-2.559 6.027 0 .47.32.783.8.783H98.6c1.52 0 2.399.86 2.399 2.348v8.767c0 1.565-.88 2.348-2.4 2.348h-8.957z" transform="rotate(180 50.5 1059)" fill="url(#a)" fillRule="evenodd"/></svg>
                                        <p>
                                            You’ll move fast and feel real progress literally every day.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*
                    <section className="alc">
                        <button className="button">Try it for free</button>
                    </section>
                    */}

                    <section className="main-section" id="packages">
                        <div className="frame">
                            <header className="main-header alc">
                                <h2>
                                    Reserve your seat today and get 50% off!
                                </h2>
                            </header>

                            {/*
                            <div className="partner-logo">
                                <img src={logoPartner} />
                            </div>
                            */}

                            <div className="countdown-wrapper">
                                <header>Exclusive offer. Ends soon</header>
                                <CountDown deadline={this.deadline} onend={this.onOfferEnd} hideDays/>
                            </div>

                            {/*<div>Choose your package</div>*/}

                            <div id="package-list">

                                <div className="package">
                                    <header>🇩🇪 German challenge</header>
                                    <ul className="package-features">
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>200+ video lessons</p>
                                        </li>
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>2000+ speaking exercises</p>
                                        </li>
                                        <li className="disabled">
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#D5D5D5" fillRule="evenodd"/></svg>
                                            <p>no tutor sessons</p>
                                        </li>
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>Final 15-minute evaluation with a native speaker</p>
                                        </li>
                                    </ul>

                                    <footer>
                                        <div className="package-offer">
                                            <p>The challenge starts on 15th October 2019</p>

                                            <div className="prices">
                                                <div className="strike-through"><span>€</span><span>{plans[0] !== undefined ? plans[0].price : null}</span></div>
                                                <div className="price"><span>€</span><span>{plans[0] !== undefined ? plans[0].discountPrice : 'loading..'}</span></div>
                                            </div>
                                        </div>
                                        <button onClick={() => {this.startPayment(plans[0].id)}} data-package="0" className="button green" id="select-package-0">Reserve your seat</button>
                                    </footer>
                                </div>

                                <div className="package">
                                    <header>🇩🇪 German challenge<br/> + tutors</header>
                                    <ul className="package-features">
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>200+ video lessons</p>
                                        </li>
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>2000+ speaking exercises</p>
                                        </li>
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p><strong>10 one-on-one tutor sessons</strong></p>
                                        </li>
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>Final 15-minute evaluation with a native speaker</p>
                                        </li>
                                    </ul>

                                    <footer>

                                        <div className="package-offer">
                                            <p>The challenge starts on 15th October 2019</p>

                                            <div className="prices">
                                                <div className="strike-through"><span>€</span><span>{plans[1] !== undefined ? plans[1].price : null}</span></div>
                                                <div className="price"><span>€</span><span>{plans[1] !== undefined ? plans[1].discountPrice : 'loading..'}</span></div>
                                            </div>
                                        </div>
                                        <button onClick={() => {this.startPayment(plans[1].id)}} data-package="1" className="button green" id="select-package-1">Reserve your seat</button>
                                    </footer>
                                </div>

                                {/*
                                <div className="package">
                                    <header>German challenge all levels<br/> + tutors</header>
                                    <ul className="package-features">
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>600+ video lessons</p>
                                        </li>
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>6000+ speaking exercises</p>
                                        </li>
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p><strong>30 one-on-one tutor sessons</strong></p>
                                        </li>
                                        <li>
                                            <svg width="18" height="13" viewBox="0 0 18 13" xmlns="http://www.w3.org/2000/svg"><path d="M15.815.217c.698-.416 1.556-.223 1.974.48.418.703.188 1.608-.459 2.064-3 2.115-6.195 5.171-9.242 9.562-.61.88-1.808.915-2.386.031C4.27 10.164 2.907 8.932.647 7.382-.015 6.928-.199 6 .235 5.307c.434-.692 1.239-.8 1.984-.431 1.766.873 3.417 1.954 4.532 3.368 3.12-3.94 6.089-6.253 9.064-8.027z" fill="#4DDCB9" fillRule="evenodd"/></svg>
                                            <p>Final 15-minute evaluation with a native speaker</p>
                                        </li>
                                    </ul>

                                    <footer>

                                        <div className="package-offer">
                                            <p>The challenge starts on 15th October 2019</p>

                                            <div className="prices">
                                                <div className="strike-through"><span>€</span><span>{plans[2] !== undefined ? plans[2].price : null}</span></div>
                                                <div className="price"><span>€</span><span>{plans[2] !== undefined ? plans[2].discountPrice : 'loading..'}</span></div>
                                            </div>
                                        </div>
                                        <button onClick={() => {this.startPayment(plans[2].id)}} className="button green" data-package="2" id="select-package-2">Reserve your seat</button>
                                    </footer>
                                </div>
                                */}
                            </div>
                        </div>
                    </section>

                    <section className="main-section" id="school">
                        <div className="frame">
                            <div className="big-quote">
                                <figure>
                                    <img src="https://res.cloudinary.com/magiclingua/image/upload/v1539471339/guide-de_bvavzw.png" />
                                        <figcaption>Anja<br/>Course Instructor</figcaption>
                                </figure>
                                <p>“This unique offering is the result of many years in-class experience“</p>
                            </div>
                            <p>
                                Anja has produced videos for learning German for many years, and is extremely popular with her students for her funny but clear method. She has worked previously as a certified German teacher. All our tutors are native German speakers, and know exactly how to bring you to the next level.
                            </p>

                            <ul>
                                <li>
                                    <img src={seal} />
                                        <div>Method tested with  thousands of students</div>
                                </li>
                                <li>
                                    <img src={seal} />
                                        <div>Years of teaching experience</div>
                                </li>
                                <li>
                                    <img src={seal} />
                                        <div>Only native German tutors</div>
                                </li>
                            </ul>
                        </div>
                    </section>


                    {/*
                    <section id="teachers" className="main-section alc">
                        <div className="frame">
                            <header className="main-header">
                                <h2>We are Camino Barcelona</h2>
                                <p>A team of experienced and passionate Spanish teachers</p>
                            </header>
                            <div className="big-quote"><p>"We made this unique course for you<br/> and we would love to have you onboard"</p></div>
                        </div>
                    </section>
                     */}

                    <section className="main-section cta-section" id="bottom-cta">
                        <div className="frame">
                            <header className="main-header alc">
                                <h2>So don’t miss this chance!<br/> Claim your discount today!</h2>
                            </header>

                            <div className="cta-actions">
                                <div>
                                    <a id="cta-bottom-reserve" className="button primary" href="/fluent-in-3-months#packages">Reserve today at 50% discount!</a>
                                    <span>Limited places. Ends soon!</span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <MainFooter />
                </div>
            );
        }
    }
}
