// ? In UserDTO format at the backend
export class User {
    constructor(
        // public id?: string,
        public reference?: string,
        public login?: string,
        // public loginLookup?: string,
        public password?: string,
        public firstName?: string,
        public lastName?: string,
        public email?: string,
        public address?: UserAddress,
        public profilePicture?: string,
        // public emailLookup?: string,
        public activated?: boolean,
        public langKey?: string,
        public defaultLearningLanguage?: string,
        // public activationKey?: string,
        // public resetKey?: string,
        // public resetDate?: any,
        // public registrationChannel?: any,
        public brand?: any,
        // public registrationStatus?: any,
        // public lastAccessDate?: any,
        public productAccess?: any,
        // public lastModifiedDate?: any,
        public authorities?: any,
        public userSettings?: any,
        public tutorSessionsCount?: number,
        public referralCode?: string,
        public referredBy?: string,
        public refereeRegCount?: number,
        public refereeBuyCount?: number,
        public credit?: number,
        public createdBy?: string,
        public created?: any,
        public lastModifiedBy?: string,
        public updated?: any
    ) {
    }
}

export class UserAddress {
    public country: string;
    public zipCode: string;
    public city: string;
    public state: string;
    public openAddress: string;
    constructor(
        {
            country, zipCode, city, state, openAddress
        }: {
            country: string, zipCode: string, city: string, state: string, openAddress: string
        }
    ) {
        this.country = country;
        this.zipCode = zipCode;
        this.city = city;
        this.state = state;
        this.openAddress = openAddress;
    }
}
