import * as React from 'react';
import PopupWrapper from './PopupWrapper';
import * as iconClose from '../../../../../../assets/images/newmagic/icon-close.svg';
import * as Promise from 'promise';

export default class Popup extends React.Component<any, any> {

    state = {
        isModal: false
    };

    element;
    popup: PopupWrapper;

    shouldComponentUpdate(nextProps, nextState) {
        return !this.props.static;
    }

    componentDidMount(): void {
        setTimeout(() => {
            $(this.element).find('.action-close').on('click', () => {
                this.close();
            });
        }, 1000);
    }

    open = (isModal: boolean = false): Promise<void> => {
        this.setState({isModal});
        return this.popup.open(isModal);
    };

    close = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            this.popup.close().then(() => {
                if (this.props.onClosed) {
                    this.props.onClosed();
                }
                resolve();
            }).catch(reject);
        });
    };

    onClosed = (): void => {
        if (this.props.onClosed) {
            this.props.onClosed();
        }
    };

    render(): React.ReactNode {
        const bottom = this.props.hidefooter ? '' :
            (
            <div className='bottom'>
                <button className='button primary' onClick={this.close}>
                    {this.props.closeCaption ? this.props.closeCaption : 'Close'}
                </button>
            </div>
            );
        const header = this.props.hideheader ? '' :
            (
            <div className='popup-header'>
                <header>{this.props.header? this.props.header : 'Set a header'}</header>
                <img className='action-close' src={iconClose} />
            </div>
            );

        return (
            <PopupWrapper
                id={this.props.id}
                className={this.props.className + (this.state.isModal ? ' modal' : '')}
                onClosed={this.onClosed}
                ref={instance => { this.popup = instance; }}
            >
                <div className='inner' ref={instance => { this.element = instance; }}>
                    {header}
                    <div className='popup-body'>
                        {this.props.children}
                    </div>
                    {bottom}
                </div>
            </PopupWrapper>
        )
    }
}
