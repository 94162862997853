import * as React from 'react';
import { Course } from '../../../models/course.model';
import { ThreeDotsLoader } from '../../global/loaders';
import PremiumCourseCard from './PremiumCourseCard';
import { isNullOrUndefined } from 'util';

interface IProps {
    premiumCourses: Array<Course>;
    myCourses: Set<string>;
}

export default class PremiumCoursesRow extends React.PureComponent<IProps> {
    render(): React.ReactNode {
        return (
            <div className='courses-row col'>
                <h3>Full Premium Courses</h3>
                <div style={{ marginBottom: '32px' }}>
                    Not sure about your level? <span style={{ fontWeight: 600 }}>TRY NOW</span> two lessons of each level for free!
                </div>
                <div className='row col-on-mobile flex-wrap'>
                    {isNullOrUndefined(this.props.premiumCourses) || isNullOrUndefined(this.props.myCourses) ? (
                        <ThreeDotsLoader show />
                    ) : (
                        <>
                            {this.props.premiumCourses.map((course) => (
                                <PremiumCourseCard
                                    course={course}
                                    active={!!this.props.myCourses.has(course.name)}
                                />
                            ))}
                            {/*<div className='extra-card'>
                                <p>More premium courses coming soon.</p>
                            </div> */}
                        </>
                    )}
                </div>
            </div>
        );
    }
}
