import * as React from "react";
import * as lity from "lity";

import { Context } from "../../../models/context.model";
import Popup from '../../global/popup/Popup';

const playPreviewYt = videoYoutubeId => {
    const videoPreview = lity(`https://www.youtube.com/watch?v=${videoYoutubeId}&rel=0`);
};

const openVideoEditor = videoYoutubeId => {
    window.open(`https://studio.youtube.com/video/${videoYoutubeId}/edit`, "_blank");
};

let vimeoPreviewPopup: Popup = null;

export const ChapterRowForCourseEditor = ({ context, functions }: { context: Context, functions: any }) => {
    
    return (
        <div className="context-row row">
            {context.videoSource !== "vimeo" ? (
                <img
                    className="context-row-img"
                    onClick={() => playPreviewYt(context.video)}
                    src={`https://img.youtube.com/vi/${context.video}/default.jpg`}
                />
            ) : (
                <img
                    className="context-row-img"
                    onClick={() => functions.playVimeoPreview(context)}
                    src={context.thumb}
                />
            )}

            <div className="context-row-content col">
                <span className="context-row-title">{context.name}</span>
                <a className="context-row-link" onClick={() => openVideoEditor(context.video)}>
                    {context.video}
                </a>
            </div>
            <div className="context-row-extra row">
                <div className="context-row-extra-questions">{context.intents.length}q</div>
            </div>
        </div>
    );
};
