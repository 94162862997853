import * as React from 'react';
import PopupWrapper from './popup/PopupWrapper';
import LoginForm from '../Login';
import RegForm from '../Registration';
import PassForm from '../ResetPasswordInit';
import {AuthService} from '../../services/auth.service';
import {TimelineMax} from 'gsap/TimelineMax';
import * as Promise from 'promise';

export default class AuthPopup extends React.PureComponent<any, any> {

    state = {
        isModal: false
    };

    element: HTMLDivElement;
    popup: PopupWrapper;

    componentDidMount(): void {
        const self = this;
        setTimeout(function() {
            $(self.element).find('.action-close').on('click', function() {
                self.close();
            });

            $(self.element).find('a[data-step]').on('click', function(e) {
                e.preventDefault();
                self.authStep($(this).data('step'));
            });
        }, 1000);
    }

    open = (isModal: boolean = false): Promise<void> => {
        this.setState({isModal});
        const step = this.props.step || 'login';
        $(this.element).find('.auth-step').css('display', 'none');
        $(this.element).find('.auth-' + step).css('display', 'block');
        return this.popup.open(isModal);
    };

    close = (): Promise<void> => {
        const self = this;
        return this.popup.close().then(() => {
            if (self.props.onClose) {
                self.props.onClose();
            }
        });
    };

    authStep = (step: string, noAnimaton: boolean = false): Promise<void> => {
        const authStep = $(this.element).find('.auth-' + step);
        const allAuthSteps = $(this.element).find('.auth-step');
        const duration = noAnimaton ? 0 : .4;
        return new Promise<void>((resolve, reject) => {
            const timeLine = new TimelineMax({
                onComplete: resolve
            });
            timeLine
                .to(allAuthSteps, duration, {autoAlpha: 0})
                .to(allAuthSteps, 0, {css: {display: 'none'}})
                .to(authStep, 0, {css: {display: 'block'}})
                .to(authStep, duration, {autoAlpha: 1});
        });
    };

    render(): React.ReactNode {
        const forms = (!AuthService.instance.isAuthenticated() || AuthService.instance.isAnonymous()) ?
            (
            <div ref={instance => { this.element = instance; }}>
                <LoginForm redirectUri={this.props.redirectUri} onSuccess={this.props.onSuccess}/>
                <RegForm redirectUri={this.props.redirectUri} onSuccess={this.props.onSuccess}/>
                <PassForm/>
            </div>
            ) :
            '';
        return (
            <PopupWrapper
                id='auth-popup'
                className={'centered' + (this.state.isModal ? ' modal' : '')}
                ref={instance => { this.popup = instance; }}
                onClosed={this.close}
            >
                <div className='gradient' />
                {forms}
            </PopupWrapper>
        )
    }
}
