export const syntax = {
    bold: {
        open: '[',
        close: ']'
    },
    faded: {
        open: '<',
        close: '>'
    },
    label: {
        text: {
            open: '{',
            close: '}'
        },
        label: {
            sign: '|'
        }
    },
    dialog: {
        sign: '- '
    },
    underline: {
        sign: '...'
    }
};
