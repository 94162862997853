interface AnswerFormat {
    transcript: string;
    confidence: number;
}

export class RecognitionResultsFormat {
    public answers: Array<AnswerFormat>;
    constructor(answers: Array<AnswerFormat>) {
        this.answers = answers;
    }
}
