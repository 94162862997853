import * as React from 'react';
import Popup from '../global/popup/Popup';
import * as Promise from 'promise';
import {Link} from 'react-router-dom';

export default class CaminoPremiumPopup extends React.Component<any, any> {

    state = {
    };
    premiumPopup: Popup;

    componentWillMount(): void {
    }

    open = (isModal: boolean = false): Promise<void> => {
        if (this.premiumPopup) {
            return this.premiumPopup.open(isModal);
        } else {
            return Promise.resolve();
        }
    };

    close = () => {
        if (this.premiumPopup) {
            return this.premiumPopup.close();
        } else {
            return Promise.resolve();
        }
    };

    getReferrer = () => {
        let referrer = localStorage.getItem('originalReferrer');
        if (!referrer && document.referrer && document.referrer.indexOf('camino') > -1) {
            referrer = document.referrer;
            localStorage.setItem('originalReferrer', referrer);
        }
        return referrer;
    };

    onSeePricingClick = () => {
        this.close();
        if (this.props.onSeePricingClick !== null) {
            this.props.onSeePricingClick();
        }
    }

    render(): React.ReactNode {
        const {} = this.state;
        const {start} = this.props;
        return (
            <Popup
                id='premium-popup'
                className='centered alc'
                hidefooter
                hideheader
                ref={instance => { this.premiumPopup = instance; }}
            >
                <div className='popup-hero'>
                    <div className='header-text'>Reserve today to get access to the full course</div>
                    <svg width='35' height='51' viewBox='0 0 35 51' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M5.96 21.63l-2.605-5.114C.51 10.934 2.737 4.08 8.317 1.236c5.58-2.843 12.433-.615 15.277 4.968l3.916 7.684-3.541 1.804-3.915-7.684c-1.85-3.63-6.305-5.078-9.932-3.23-3.628 1.848-5.075 6.305-3.226 9.934l3.524 6.917h19.557c2.442 0 4.423 1.98 4.423 4.424v20.523c0 2.443-1.98 4.424-4.423 4.424H4.423C1.98 51 0 49.02 0 46.576V26.053c0-2.444 1.98-4.424 4.423-4.424H5.96zm13.503 14.46c1.06-.724 1.755-1.94 1.755-3.32 0-2.22-1.8-4.02-4.018-4.02-2.219 0-4.017 1.8-4.017 4.02 0 1.38.695 2.597 1.755 3.32v5.524c0 1.25 1.012 2.264 2.262 2.264s2.263-1.013 2.263-2.264v-5.523z' fill='#FFF'/>
                    </svg>
                </div>
                <div className='popup-actions'>
                    <a className='button primary' onClick={this.onSeePricingClick}>
                        See pricing
                    </a>
                    <button className='ghost primary' onClick={() => {start()}}>Try free exercise</button>
                </div>
            </Popup>
        );
    }
}
