import { SlideGroup, Slide } from '../../../../models/slide.model';

export interface SlideEditorConflict {
    slideGroupIdx: number
    // If there is no slideIdx, it means that it is a conflict about "end". That is why this property is optional
    slideIdx?: number
}

/**
 * Checks if all the SlideGroups existing are valid or not.
 * Returns an array with conflicts inside.
 * 
 * The comments under the function tells which part of code tries to decide which slide or which property is problemmatic
 */
export const isValidCompletely = (slideGroups: SlideGroup[]): { isValid: boolean, conflicts: SlideEditorConflict[] } => {
    const conflicts: SlideEditorConflict[] = [];

    slideGroups.map((slideGroup, slideGroupIdx) => {
        slideGroup.slides.map((slide, slideIdx) => {
            // Check this slide's from value for every following slide (also slideGroup)
            for (let i: number = slideGroupIdx; i < slideGroups.length; i++) {
                for (let j: number = ((i === slideGroupIdx) ? (slideIdx + 1) : 0); j < slideGroups[i].slides.length; j++) {
                    // If there is a problem (from)
                    if (slide.from > slideGroups[i].slides[j].from) {
                        // If the conflicts of the problem do not exist in the conflicts array
                        // Root slide
                        if (conflicts.findIndex(c => (c.slideGroupIdx === slideGroupIdx && c.slideIdx === slideIdx)) === -1) {
                            conflicts.push({ slideGroupIdx, slideIdx });
                        }
                        // Target slide
                        if (conflicts.findIndex(c => (c.slideGroupIdx === i && c.slideIdx === j)) === -1) {
                            conflicts.push({ slideGroupIdx: i, slideIdx: j });
                        }
                    }
                }
                // If there is a problem (end)
                if (slide.from > slideGroups[i].end) {
                    // If the conflicts of the problem do not exist in the conflicts array
                    // Root slide
                    if (conflicts.findIndex(c => (c.slideGroupIdx === slideGroupIdx && c.slideIdx === slideIdx)) === -1) {
                        conflicts.push({ slideGroupIdx, slideIdx });
                    }
                    // Target slideGroup
                    if (conflicts.findIndex(c => (c.slideGroupIdx === i && c.slideIdx === undefined)) === -1) {
                        conflicts.push({ slideGroupIdx: i });
                    }
                }
            }
            // console.log('map slides', 'slideIdx', slideIdx, 'slideGroupIdx', slideGroupIdx, [...conflicts]);
        });
        // Check this slideGroup's end value for every following slide (also slideGroup)
        for (let i: number = slideGroupIdx + 1; i < slideGroups.length; i++) {
            for (let j: number = 0; j < slideGroups[i].slides.length; j++) {
                // If there is a problem (from)
                if (slideGroup.end > slideGroups[i].slides[j].from) {
                    // If the conflicts of the problem do not exist in the conflicts array
                    // Root slideGroup
                    if (conflicts.findIndex(c => (c.slideGroupIdx === slideGroupIdx && c.slideIdx === undefined)) === -1) {
                        conflicts.push({ slideGroupIdx });
                    }
                    // Target slide
                    if (conflicts.findIndex(c => (c.slideGroupIdx === i && c.slideIdx === j)) === -1) {
                        conflicts.push({ slideGroupIdx: i, slideIdx: j });
                    }
                }
            }
            // If there is a problem (end)
            if (slideGroup.end > slideGroups[i].end) {
                // If the conflicts of the problem do not exist in the conflicts array
                // Root slideGroup
                if (conflicts.findIndex(c => (c.slideGroupIdx === slideGroupIdx && c.slideIdx === undefined)) === -1) {
                    conflicts.push({ slideGroupIdx });
                }
                // Target slideGroup
                if (conflicts.findIndex(c => (c.slideGroupIdx === i && c.slideIdx === undefined)) === -1) {
                    conflicts.push({ slideGroupIdx: i });
                }
            }
        }
    });

    return { isValid: conflicts.length === 0, conflicts };
}
