import * as React from 'react';
import * as ProcessString from 'react-process-string';
import {Link, Redirect} from 'react-router-dom';
import * as Promise from 'promise';
import {Back, Expo, Linear, RoughEase, TweenMax} from 'gsap';
import {TimelineLite} from 'gsap/TimelineLite';
import {TimelineMax} from 'gsap/TimelineMax';
import ExerciseIntro from './learning/ExerciseIntro';
import ExrTutorial from './learning/ExrTutorial';
import ExrCompletion from './learning/ExrCompletion';
import TutorHelp from './learning/TutorHelp';
import {LanguageService} from '../services/language.service';
import {TextService} from '../services/text.service';
import {TrackingService} from '../services/tracking.service';
import {SpeechService} from '../services/speech.service';
import * as _ from 'underscore';
import * as qs from 'qs';
import WakeLock from './wakelock';
import ChapterNavi from './learning/ChapterNavi';
import Settings from './learning/Settings';
import Popup from './global/popup/Popup';
import Bubble from './global/Bubble';

import * as userImg from '../../../../assets/images/newmagic/user-avatar.svg';
import * as iconMic from '../../../../assets/images/newmagic/mic-active.png';
import * as waveImg from '../../../../assets/images/newmagic/wave.png';

import {AuthService} from '../services/auth.service';
import {RegistrationService} from '../services/registration.service';
import {ChannelType, StreamChannelFactory} from '../services/recognition/stream.channel.factory';
import {TranslateService} from '../services/translate.service';
import {DialogueService} from '../services/dialogue.service';
import {RecognitionStatus} from '../services/recognition/recognition.status';
import {SentenceService} from '../services/sentence.service';
import {UserAgentService} from '../services/user.agent.service';
import {CourseService} from '../services/course.service';
import {Course, CourseUnit, UnitExercise} from '../models/course.model';
import {LearnedDialogue} from '../models/learned.dialogue.model';
import {Intent} from '../models/intent.model';
import {Context} from '../models/context.model';
import AuthPopup from './global/AuthPopup';
import PremiumPopup from './PremiumPopup';
import {Brand, BrandingService} from '../services/branding.service';
import {LocalupdateService} from '../services/localupdate.service';
import {Dialogue} from '../models/dialogue.model';
import { ThreeDotsLoader } from './global/loaders';
import { ClickAndSpeakInstructions } from './learning/ClickAndSpeakInstructions';
import { LocalStorageService } from '../services/localstorage.service';

// This will be used to determine the afterwards action when the popup gets closed
enum AuthPopupType {
    PRE_VIDEO,
    DURING_INTENTS
}

/**
 * Learning algorithm:
 * 1. Get as params 1) seqAlpha of Dialogue, 2) learned language and 3) courseName
 *
 * 2. UI Component preparation
 *  2.1 load course
 *  2.2 load user_course
 *  2.3 save user_course with new current unit and exercise
 *  2.4 define current current exercise and next exercise
 *  2.5 load dialogue
 *  2.6 if exercise progress == 100
 *      2.6.1 reset exercise progress and save user_course
 *      2.6.2 reset progress of the corresponding LearnedDialogue
 *      2.6.3 init LearnedDialogue (set answers and wrongAnswers)
 *      2.6.4 start exercise by defining current Chapter/Context
 * 2.7 if exercise progress != 100
 *      2.7.1 load LearnedDialogue
 *      2.7.2 go to 2.6.3
 *
 * Intent started
 * 3. Prepare component
 *  3.1 load course
 *  3.2 load user_course
 *  3.3 translate question
 *  3.4 load TTS for question
 *  3.5 open websocket and start speech recognition via microphone
 *  3.6 if recognition was successful
 *      3.6.1 submit LearnedAttempt    // it's only for tracking purposes and not for business logic
 *      3.6.2 load success sound
 *      3.6.3 report STT (/stt/report/{channelId})
 *      3.6.4 load TTS for question
 *      3.6.5 submit LearnedEvent (/learned/event, updates LearnedDialogue progress)
 *      3.6.6 save user_course with new progress (i.e. currentChapter, progress, completed, duration)
 *      3.6.7 start next intent
 *  3.7 if recognition was NOT successful (now up to 2 attempts allowed)
 *      3.7.1 submit LearnedAttempt
 *      3.7.2 load failure sound
 *      3.7.3 report STT (/stt/report/{channelId})
 *      3.7.4 submit failed LearnedEvent
 *      3.7.5 save user_course with new progress
 *      3.7.6 start next intent
 *
 * 4. If all intents of context learned move to next context
 * 5. If all contexts of dialogue learned 1) set next dialogue and 2) show Lesson completion screen
 */
export default class Learning extends React.Component<any, any> {

    static FUZZYNESS = 1.0;
    static FAILED_ATTEMPTS_BEFORE_NEXT = 3;
    static EXPECTED_TEST_ANSWER = 'Hi, my name is Max and I am 26 years old.';
    static AUTH_REQUIRED = true;
    static LEAD_REQUIRED = true;

    instructors = LocalupdateService.instance.loadInstuctors();

    eqTimelines = [];
    cursortTimeline;
    languageIso = 'de';
    languageId = '56a14538395a12e86ee04e1f';
    checkTimeouts = [];
    submitInProgress = false;
    lastAnswerTime = null;
    successSound = 'https://res.cloudinary.com/magiclingua/video/upload/v1523376917/Success_2_othhx7.m4a';
    infoSound = 'https://res.cloudinary.com/magiclingua/video/upload/v1523469337/Alert_2_hqp75a.m4a';
    exrHowto = false;
    learnedDialogue: LearnedDialogue;
    inputHelpId = 'input-help';
    inputHowto = 'input-tutorial';
    tutorialStep = 0;
    streamChannel = null;
    streamChannelMonitor = null;
    testStreamChannel = null;
    testStreamChannelMonitor = null;
    testStreamVolume: number = 0;
    testStreamVolumeAvg = 0;
    testStreamVolumeAvgData = [];
    closingComponent = false;
    defaultPrompt = '';
    // This variable is to determine how we should react to the outcome of stopping the voice input,
    // because it will defer if voice input is stopped because of user submission or because of stopping UI
    isStoppingUI = false;
    currentProvidedAnswer = null;
    currentExpectedAnswer = null;
    courseParam = null;
    languageParam = null;
    dialogueParam = null;
    isIframe = false;
    interruptedMounting = false;
    paused = false;
    completionScreen;
    exerciseIntro: ExerciseIntro;
    debugComp;
    wsResponseTimeout = null;
    soundCheckPopup: Popup;
    authPopup: AuthPopup;
    premiumPopup;
    settingsScreen: Settings;
    inputAreaLoader: ThreeDotsLoader;
    clickAndSpeakInstructions: ClickAndSpeakInstructions;

    settingsButton: JQuery<HTMLElement>;
    actionsSet: JQuery<HTMLElement>;
    settingsTooltip: JQuery<HTMLElement>;

    emailPopup: Popup = null;
    leadSubmitedPopup: Popup = null;
    answerTimeout = null;

    readingDurration = 0;
    initMicDurration = 0;

    channelType = null;

    state = {
        currentIntentIdx: -1,
        currentContextIdx: -1,
        currentAnswer: null,
        currentExercise: null,
        nextExercise: null,
        intentStarted: null,
        video: '',
        videoSource: null,
        videoTitle: '',
        slideGroups: [],
        dialog: null,
        hintMode: 'open',
        points: 15,
        correctAnswer: false,
        answerAttempts: 0,
        channelId: '',
        progress: 0,
        questionTranslation: null,
        listening: false,
        pausedUI: false,
        currentTestAnswer: null,
        course: null,
        leadEmail: null,
        authPopupType: null as AuthPopupType,

        // For the styling of the input area
        isMouseOnInputArea: false,
    };

    componentWillMount(): void {
        this.dialogueParam = this.props.match.params.id;
        this.languageParam = this.props.match.params.lang;
        const searchObj = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        this.courseParam = searchObj.course;
        this.isIframe = searchObj.isIframe === 'true' ? true : false;

        this.channelType = SpeechService.instance.getSttMode();

        /**
         * Redirect to course page before mounting if:
         * - (mobile view or mobile device) and not iframe --> case is --> Opening learning screen in mobile normally
         * or
         * - iframe and mobile device --> case is -->  Trying to enter to the learning screen via an iframe on a mobile device
         * Please see that `isMobile()` `isMobileDevice()` and `isMobileUI()` methods of UserAgentService represents different things
         */
        if (
            (UserAgentService.instance.isMobile() && !this.isIframe)
            || (this.isIframe && UserAgentService.instance.isMobileDevice())
        ) {
            this.interruptedMounting = true;
            this.props.history.push(this.getCourseLink() || CourseService.instance.getCurrentCourseUrl(true));
        }
    }

    componentDidMount(): void {
        if (this.interruptedMounting) {
            return;
        }

        CourseService.instance.loadCourse(this.courseParam).then(course => {
            this.setState({course});
            this.markDialogueStarted(this.dialogueParam);
            this.setState({
                currentExercise: CourseService.instance.getCurrentExercise(true),
                nextExercise: CourseService.instance.getNextExercise(true)
            });
            TweenMax.set([/*$('#exercise-intro'),*/$('#task')], {autoAlpha: 0});
            this.closingComponent = false;
            this.languageIso = LanguageService.instance.isoByName(this.languageParam);
            const language = LanguageService.instance.getByISO(this.languageIso);
            this.languageId = language && language.id ? language.id : null;

            localStorage.setItem('latestCourseLang', this.languageIso);

            const proceed = () => {
                DialogueService.instance.getDialogue(this.dialogueParam)
                    .then((dialogue) => {
                        if (dialogue) {
                            const currExr = CourseService.instance.getCurrentExercise(true) as UnitExercise;
                            DialogueService.instance.getLearnedDialogue(dialogue.id)
                                .then((learnedDialogue: LearnedDialogue) => {
                                    this.initLearnedDialogue(dialogue, learnedDialogue);
                                })
                                .catch(() => {
                                    // TODO : proper error messaging/handling needed!
                                    console.error('Error when loading dialog');
                                });
                        } else {
                            // TODO : proper error messaging/handling needed!
                            console.error('No dialog loaded');
                        }
                    })
                    .catch((error) => {
                        // TODO : proper error messaging/handling needed!
                        console.error('Error when loading dialog');
                    });
            };
            if (AuthService.instance.isAuthenticated()) {
                proceed();
            } else {
                RegistrationService.instance.createTempAccountAndLogin().then(() => {
                    proceed();
                }, () => {
                    // TODO : proper error messaging/handling needed!
                    console.error('Error registering temp account');
                });
            }
        });
    }

    componentWillUnmount(): void {
        clearTimeout(this.answerTimeout);
        clearTimeout(this.wsResponseTimeout);
        this.closingComponent = true;
        this.closeChannel();
        // Hide the tooltip if exists
        if (this.settingsTooltip != null) {
            this.handleHidingSettingsTooltip();
        }
    }

    initLearnedDialogue(dialogue: Dialogue, learnedDialogue: LearnedDialogue): void {
        const dialog = dialogue;
        _.each(dialog.contexts, (context: Context) => {
            _.each(context.intents, (intent: Intent) => {
                const newAnswers = [];
                const newWrongAnswers = [];
                _.each(intent.answers, (answer: string) => {
                    const generatedAnswers = SentenceService.instance.generateAnswers(answer);
                    if (generatedAnswers) {
                        if (generatedAnswers.answers && generatedAnswers.answers.length > 0) {
                            _.each(generatedAnswers.answers, (a) => {
                                newAnswers.push({
                                    value: a
                                });
                            });
                        }
                        if (generatedAnswers.wrongAnswers && generatedAnswers.wrongAnswers.length > 0) {
                            _.each(generatedAnswers.wrongAnswers, (a) => {
                                newWrongAnswers.push(a);
                            });
                        }
                    }
                });
                intent.answers = newAnswers;
                intent['wrongAnswers'] = newWrongAnswers;
            });
        });
        this.processLearnedDialogue(learnedDialogue);
        this.setState({ dialog: dialog, currentContextIdx: 0});
        this.startExercise();

    }

    isCourseEnabled = (): boolean => {
        return this.getCourseLink() !== null;
    };

    getCourseLink = (): string => {
        return this.courseParam ? '/' + this.languageParam + '/course/' + this.courseParam : null;
    };

    markDialogueStarted = (seqAlpha: string): void => {
        CourseService.instance.setCurrentExercise(seqAlpha);
    };

    startExercise = (): void => {
        if (this.isAuthRequired()) {
            this.openAuthPopup(AuthPopupType.PRE_VIDEO, true);
            // this.emailPopup.open();
        } else {
            CourseService.instance.checkExerciseStartable(this.state.currentExercise, this.state.course, {
                'onStartable': () => this.startCurrentContext(),
                'onPremiumRequired': () => {
                    this.premiumPopup.open(true);
                }
            });
        }
    };

    finishExercise = (): void => {
        this.closeChannel();
        // update current and next exercises to have the latest progress
        this.setState({
            currentExercise: CourseService.instance.getCurrentExercise(true),
            nextExercise: CourseService.instance.getNextExercise(true)
        });

        CourseService.instance.setExerciseCompletedAndResetCurrentChapter(this.state.dialog.seqAlpha).then(course => {
            CourseService.instance.setNextExercise();
            this.completionScreen.startAnimation(course);

            this.dataLayerPush({
                'seqAlpha': this.state.dialog.seqAlpha,
                'event': 'exerciseComplete'
            });
        }, () => {
            console.log('error')
        });
    };

    dataLayerPush = (data?: any): void => {
        const w: any = window;
        data = data || {};
        if (AuthService.instance.isAuthenticated() && !AuthService.instance.isAnonymous()) {
            AuthService.instance.loadUser().then((user) => {
                if (user && user.email) {
                    data['email'] = user.email;
                }
                if (user && user.reference) {
                    data['userReference'] = user.reference;
                }
                w.dataLayer.push(data);
            });
        } else {
            w.dataLayer.push(data);
        }
    };

    startNextExercise = (): void => {
        // we get the current exercise because pointer was already set to it
        // right after the previous exercise was finished
        const nextExr: UnitExercise = CourseService.instance.getCurrentExercise(true) as UnitExercise;
        this.props.history.push(`/${this.languageParam}/learning/` + nextExr.seqAlpha + (this.courseParam ? '?course=' + this.courseParam : ''));
        location.reload();
    };

    startIntro = (): void => {
        this.exerciseIntro.start();
    };

    onVideoEnd = (): void => {
        const {dialog, currentContextIdx} = this.state;

        const proceed = () => this.nextExrStep();

        const currentContext = dialog.contexts[currentContextIdx];
        const resetLearnedProgress = {
            dialogueId: dialog.id,
            contextId: currentContext.id
        };
        DialogueService.instance.resetLearnedProgress(resetLearnedProgress).then((learnedDialogue) => {
            const progress = this.processLearnedDialogue(learnedDialogue);
            CourseService.instance.setExerciseProgress(dialog.seqAlpha, progress);

            proceed();
        }).catch((error) => {
            proceed();
        });
    };

    isAuthRequired = (): boolean => {
        return ( !AuthService.instance.isAuthenticated() || AuthService.instance.isAnonymous() )
            && Learning.AUTH_REQUIRED
            && CourseService.instance.hasCourseCompletedExercises(true) as boolean
            && !CourseService.instance.isCoursePublic(this.state.course.name);
    };

    isLeadSignupRequired = (): boolean => {

        const leadExists = localStorage.getItem('leadCollected');
        const askForLead: boolean = CourseService.instance.hasCourseCompletedExercises(true) as boolean;
        const currentUnit: CourseUnit = CourseService.instance.getCurrentUnit(true) as CourseUnit;
        let hasFreeDemo: boolean = false;
        if (currentUnit && currentUnit.attributes && currentUnit.attributes['freeDemo']) {
            hasFreeDemo = true;
        }
        const brand: Brand = BrandingService.instance.getBrand();

        return !leadExists
            && Learning.LEAD_REQUIRED
            && askForLead
            && (
                brand !== Brand.CAMINO
                || !hasFreeDemo
            );
    };

    startCurrentContext = () => {
        (CourseService.instance.getCurrentChapter(this.state.dialog.seqAlpha) as Promise<number>).then(currentChapter => {
            this.startContext(currentChapter || 0);
        });
    }

    startContext = (contextIdx: number): void => {
        const {dialog} = this.state;
        const self = this;

        const doStartContext = () => {
            CourseService.instance.setCurrentChapter(dialog.seqAlpha, contextIdx);

            this.setState({
                video:  dialog.contexts[contextIdx].video,
                videoSource: dialog.contexts[contextIdx].videoSource,
                videoTitle:  dialog.contexts[contextIdx].name,
                currentContextIdx: contextIdx,
                currentIntentIdx: -1,
                slideGroups: dialog.contexts[contextIdx].slideGroups
            });

            this.startIntro();
        };

        if (this.exerciseIntro.getIsPlayerDisplayed()) {
            this.exerciseIntro.minimizeVideo().then(_ => {
                this.closeChannel().then(doStartContext).catch(doStartContext);
            });
        } else {
            this.closeChannel().then(doStartContext);
        }
    };

    processLearnedDialogue = (learnedDialogue: LearnedDialogue): number => {
        this.learnedDialogue = learnedDialogue;
        const progress = learnedDialogue.progress * 100;
        this.setState({ progress });
        return progress;
    };

    shouldOpenAuthPopupDuringIntens = (): boolean => {
        return (
            (!AuthService.instance.isAuthenticated() || AuthService.instance.isAnonymous())
            && CourseService.instance.isCourseYoutube(this.state.course.name)
            && CourseService.instance.isYoutubeLimitOver(this.state.currentIntentIdx, this.state.currentContextIdx)
        );
    }

    /**
     * @param shouldCheckExtraConditions whether we should skip external conditions and continue with the normal learning flow or not
     */
    nextExrStep = (shouldCheckExtraConditions = true): void => {

        const proceed = () => {
            const {currentIntentIdx, currentContextIdx, dialog} = this.state;
            if (currentIntentIdx + 1 > dialog.contexts[currentContextIdx].intents.length - 1) {
                if (currentContextIdx + 1 > dialog.contexts.length - 1) {
                    this.finishExercise();
                } else {
                    this.startContext(currentContextIdx + 1);
                }
            } else {
                this.startIntent(currentIntentIdx + 1);
            }
        }

        if (shouldCheckExtraConditions) {
            if (this.shouldOpenAuthPopupDuringIntens()) {
                this.openAuthPopup(AuthPopupType.DURING_INTENTS);
            } else {
                proceed();
            }
        } else {
            proceed();
        }
    };

    startIntent = (intentIdx: number): void => {
        this.setState({
            currentIntentIdx: intentIdx,
            currentAnswer: null,
            correctAnswer: null,
            answerAttempts: 0,
            intentStarted: new Date(),
            questionTranslation: null
        });
        this.defaultPrompt = this.channelType !== ChannelType.WS
            ? 'Click and hold'
            : 'say your answer...';
        this.currentProvidedAnswer = null;
        this.currentExpectedAnswer = null;
        $('#input-text > span').html(this.defaultPrompt);
        const taskArea = $('#task-area');
        this.loadQuestionTranslation().then(_ => {
            // console.log('loadQuestionTranslation finished');
        }).catch(err => {
            // console.error(err);
        });
        TweenMax.set([taskArea], {autoAlpha: 0});
        TweenMax.to($('#task'), 1.2, {autoAlpha: 1, ease: Expo.easeOut});
        setTimeout(() => {
            if (!this.tutorialCompleted()) {
                TweenMax.set($('#hint-area .img-hint'), {autoAlpha: 0});
            }
            TweenMax.fromTo(taskArea, 1, {y: -10}, {y: 0, autoAlpha: 1, ease: Expo.easeOut}, 'start');
            this.readQuestion().then(() => {
                if (!this.tutorialCompleted()) {
                    setTimeout(this.showTutorial, 1000);
                } else {
                    if (this.channelType === ChannelType.WS) {
                        this.startVoiceInput();
                    } else {
                        this.prepareInputArea(this.channelType);
                    }
                }
                if (!LocalStorageService.instance.getIsSettingsTooltipShown()) {
                    this.handleSettingsTooltip();
                }
            }).catch(err => {
                // console.error(err);
            });
        }, 500);
    };

    loadQuestionTranslation = (): Promise<void> => {
        // console.log('loadQuestionTranslation');
        return new Promise<void>((resolve, reject) => {
            const { currentIntentIdx, currentContextIdx, dialog } = this.state;
            // TODO : there needs to be a mechanism to figure out SRC and TRG languages
            const srcIso = this.languageIso;
            const trgIso = this.languageIso === 'en' ? 'de' : 'en';
            const currentIntent = dialog.contexts[currentContextIdx].intents[currentIntentIdx];
            TranslateService.instance.translate(currentIntent.question, srcIso, trgIso).then((translation) => {
                translation = translation.replace(/&#39;/g, '\'');
                this.setState({questionTranslation: translation});
                resolve();
            }).catch(err => {
                this.setState({questionTranslation: null});
                reject(err);
            })
        });
    };

    readQuestion = (): Promise<void> => {
        // console.log('start reading');
        this.readingDurration = new Date().getTime();
        return new Promise<void>((resolve, reject) => {
            const actionSound = $('#task-area .action-sound');
            actionSound.addClass('playing');
            const { currentIntentIdx, currentContextIdx, dialog } = this.state;
            const currentIntent = dialog.contexts[currentContextIdx].intents[currentIntentIdx];
            SpeechService.instance.tts(currentIntent.question, this.languageIso).then(() => {
                actionSound.removeClass('playing');
                this.readingDurration = new Date().getTime() - this.readingDurration;
                // console.log('reading finished', this.readingDurration);
                resolve();
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    };

    finishIntent = (success: boolean): void => {
        const {answerAttempts, intentStarted, currentIntentIdx, currentContextIdx, dialog, course} = this.state;
        const currentContext = dialog.contexts[currentContextIdx];
        const currentIntent = currentContext.intents[currentIntentIdx];
        const question = currentIntent.question;
        const imageHint = currentIntent.imageHint;
        const intentStartedTime = intentStarted ? intentStarted.getTime() : new Date().getTime();
        const intentFinishedTime = new Date().getTime();
        const submitLearnedEvent = {
            platform: 'web',
            dialogueId: dialog.id,
            contextId: currentContext.id,
            intentId: currentIntent.id,
            course: course.name,
            expectedAnswer: this.currentExpectedAnswer,
            providedAnswer: this.currentProvidedAnswer,
            question,
            hint: imageHint,
            learnedEvent: {
                failedAttempts: answerAttempts || 0,
                success,
                started: intentStartedTime,
                ended: intentFinishedTime
            }
        };

        if (this.channelType !== ChannelType.WS) {
            this.stopListeningForSpaceBar();
        }

        DialogueService.instance.saveLearnedEvent(submitLearnedEvent)
            .then((learnedDialogue) => {
                const progress = this.processLearnedDialogue(learnedDialogue);
                CourseService.instance.setExerciseProgress(dialog.seqAlpha, progress);
                if (!this.tutorialCompleted()) {
                    this.showTutorial();
                } else {
                    this.intentTransition();
                }
            })
            .catch((err) => {
                // console.error('Failed to update progress');
                // console.error(err);
            });
    };

    intentTransition = (halt?: boolean): void => {
        let tl = new TimelineLite();
        tl = tl
            .to($('#task'), .6, {autoAlpha: 0, ease: Expo.easeOut}, 'start')
            .to($('#input-hightlighter'), .6, {y: 10, autoAlpha: 0, ease: Expo.easeOut}, 'start')
            .call(this.resetUI, []);
        if (!halt) {
            tl.call(this.nextExrStep, []);
        }
    };

    resetUI = (): void => {
        TweenMax.set([$('#input-area, #input-text > .line, .action-reveal, .input-instructions, #wrong-answer, .hint-module')], {clearProps: 'all'});
        $('#wrong-answer').html('');
        $('.input-wrapper').removeClass('active');
    };

    showHowto = (): void => {
        if (!this.exrHowto) {
            TweenMax.to($('.action-reveal'), .3, {autoAlpha: 0});
            this.showInstruction(this.inputHowto);
            this.exrHowto = true;
        }
    };

    tutorialCompleted = (flag: boolean = null): boolean => {
        if (UserAgentService.instance.isMobile()) {
            return true;
        } else {
            if (flag === null) {
                return localStorage.getItem('exr-tutorial') !== null;
            } else {
                localStorage.setItem('exr-tutorial', String(flag));
                return flag;
            }
        }
    };

    showTutorial = (): void => {
        if (this.tutorialCompleted()) {
            return;
        }
        const tutCont = $('#tutorial');
        const tl = new TimelineMax();
        this.tutorialStep++;
        const prevElem = this.tutorialStep > 1 ? tutCont.find('.tut-text:eq(' + (this.tutorialStep - 2) + ')') : null;
        const stepElem = tutCont.find('.tut-text:eq(' + (this.tutorialStep - 1) + ')');
        // step 1
        if (this.tutorialStep === 1) {
            tl
                .set(tutCont, {clearProps: 'all'})
                .set(tutCont, {css: {display: 'block', right: 0}})
                .set(tutCont.find('.tut-text, .tut-header'), {autoAlpha: 0})
                .set(stepElem, {y: 75})
                .set(tutCont, {css: {display: 'block', right: 0}})
                .fromTo(tutCont, .6, {autoAlpha: 0, x: 20}, {autoAlpha: 1, x: 0, ease: Expo.easeOut})
                .fromTo(stepElem, .6, {autoAlpha: 0}, {autoAlpha: 1, ease: Expo.easeOut})
            ;
        } else if (this.tutorialStep === 2) {
            const imgHint = $('#hint-area .img-hint');
            tl
                .to([prevElem, tutCont.find('button')], .6, {autoAlpha: 0, ease: Expo.easeOut})
                .set(tutCont.find('.tut-header'), {yPercent: -100, autoAlpha: 0}, '-=.6')
                .fromTo(imgHint, .6, {autoAlpha: 0,  y: -20}, {autoAlpha: 1, y: 0, ease: Expo.easeOut}, '+=.3')
                .to(tutCont, .3, {width: '+=50'})
                .set(stepElem, {y: imgHint.offset().top + (imgHint.height() - stepElem.height()) / 2 })
                .to([stepElem, tutCont.find('button')], .6, {autoAlpha: 1, ease: Expo.easeOut})
            ;
        } else if (this.tutorialStep === 3) {
            tl
                .set(stepElem, {y: 200})
                .to(tutCont, .3, {xPercent: 100}, 'start')
                .to([prevElem, tutCont.find('button')], .3, {autoAlpha: 0, ease: Expo.easeOut}, 'start')
                .set(tutCont, {css: {right: 'auto', left: 0}})
                .fromTo(tutCont, .6, {autoAlpha: 0, xPercent: -100}, {autoAlpha: 1, xPercent: 0, ease: Expo.easeOut})
                .set(tutCont, {css: { className: '+=left' }})
                .to(stepElem, .6, {autoAlpha: 1, ease: Expo.easeOut})
                .call(() => {

                    if (navigator.mediaDevices === undefined) {
                        // @ts-ignore
                        navigator.mediaDevices = {};
                    }

                    if (navigator.mediaDevices.getUserMedia === undefined) {
                        navigator.mediaDevices.getUserMedia = function(constraints) {

                            // First get ahold of the legacy getUserMedia, if present
                            // @ts-ignore
                            const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                            // Some browsers just don't implement it - return a rejected promise with an error
                            // to keep a consistent interface
                            if (!getUserMedia) {
                                return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                            }

                            // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                            return new Promise(function(resolve, reject) {
                                getUserMedia.call(navigator, constraints, resolve, reject);
                            });
                        }
                    }

                    navigator.mediaDevices.getUserMedia({audio: true})
                        .then((stream) => {
                            this.startVoiceInput();
                            this.showTutorial();
                        })
                        .catch((err) => {
                            // console.error('To continue you have to activate the microphone');
                            // console.error(err);
                        });
                }, []);
        } else if (this.tutorialStep === 4) {
            const input = $('.input-wrapper');
            tl
                .to(prevElem, .6, {autoAlpha: 0, ease: Expo.easeOut})
                .set(stepElem, {y: input.position().top + (input.height() - stepElem.height()) / 2 })
                .set(tutCont, {css: { className: '-=left', left: 'auto', right: 0 }})
                .set(tutCont, {top: '45vh'})
                .fromTo(tutCont, .6, {autoAlpha: 0, xPercent: 100}, {autoAlpha: 1, xPercent: 0, ease: Expo.easeOut})
                .to(stepElem, .6, {autoAlpha: 1, ease: Expo.easeOut})
                // .call(this.showHowto, [], this, "+=1.5")
            ;
        } else if (this.tutorialStep === 5) {
            tl
                .to(tutCont, .6, {autoAlpha: 0, x: -20, ease: Expo.easeOut})
            ;
        } else if (this.tutorialStep > 5) {
            this.tutorialCompleted(true);
            tl
                .set([tutCont, tutCont.find('.tut-header')], {clearProps: 'all'})
                .set(tutCont, {css: {display: 'block'}})
                .set(tutCont.find('.inner > header'), {autoAlpha: 0})
                .set(tutCont, {autoAlpha: 0})
                .set(tutCont, {css: { className: '-=left' }})
                .set(tutCont, {css: { className: '+=center' }})
                .to(tutCont, .6, {autoAlpha: 1, ease: Expo.easeOut})
                .to(tutCont, .6, {autoAlpha: 0, ease: Expo.easeOut}, '+=2')
                .call(this.finishIntent, [], this, '+=.6')
            ;
        }
    };

    startVoiceInput = (): void => {
        if (this.closingComponent) {
            this.closeChannel();
            return;
        }
        this.checkTimeouts = [];
        this.lastAnswerTime = null;
        this.submitInProgress = false;
        const sstLoading = $('#stt-loading');
        let sttLoaded = false;
        setTimeout(() => {
            if (!sttLoaded) {
                TweenMax.set(sstLoading, {css: {display: 'block'}});
                TweenMax.fromTo(sstLoading, .6, {autoAlpha: 0}, {autoAlpha: 1, ease: Expo.easeOut});
            }
        }, 1000);
        this.openChannel().then(() => {
            if (this.channelType === ChannelType.WS) {
                this.prepareInputArea(this.channelType).then(this.activateInputArea);
                if (!LocalStorageService.instance.getIsSettingsTooltipShown()) {
                    this.wsResponseTimeout = setTimeout(() => {
                        this.showSettingsTooltip('Having trouble with the speech recognition? Try a different dialogue mode.');
                    }, 8000);
                }
            } else {
                this.activateInputArea();
            }

            // hide stt loader
            TweenMax.to(sstLoading, .1, {
                autoAlpha: 0, ease: Expo.easeOut, onComplete: () => {
                    TweenMax.set(sstLoading, {clearProps: 'all'});
                }
            });
            sttLoaded = true;
        }).catch((error) => {
            this.forceUpdate();
        });
    };

    prepareInputArea = (channelType: ChannelType): Promise<void> => {
        const inputHighlighter = $('#input-hightlighter');
        const inputArea = $('#input-area');
        const inputAreaImitation = $('#input-area-imitation');
        // TODO: We will probably need different classes in each case later on but for now assigningg the same classes whether it's channel type is ws or http_manual solved the issue of #input-area having a 0 opacity starting from the 2nd intent
        const cls = channelType === ChannelType.WS ? 'active' : 'active';
        this.startListeningForSpaceBar();
        $('#reveal-answer-button, #reveal-answer-button-mobile').on('mousedown', (e: any) => {this.revealAnswer(e)});
        this.startListeningMouseDown();
        this.showClickAndSpeakInstructions();
        // If the mode is click and speak, then input area(s) should cause the cursor to be a pointer
        if (channelType !== ChannelType.WS) {
            inputArea.css('cursor', 'pointer');
            inputAreaImitation.css('cursor', 'pointer');
        }
        return new Promise<void>((resolve, reject) => {
            if (/*!$('.input-wrapper').hasClass('active') && */!$('.input-wrapper').hasClass('ready')) {
                TweenMax.fromTo([inputArea, inputHighlighter], .6, {y: 10}, {y: 0, autoAlpha: 1, ease: Expo.easeOut, onComplete: resolve});
            }
            $('.input-wrapper').removeClass('ready').addClass(cls);
        });
    }

    activateInputArea = (): void => {
        if (this.channelType === ChannelType.WS) {
            this.blinkCursor();
        }
        this.activateEqualizer();
    }

    /**
     * > > > START Input area listener related functions
     */

    onInputMouseDownListener = () => {
        if (this.channelType !== ChannelType.WS) {
            this.stopListeningMouseDown();
            this.startListeningMouseUp();
            this.startVoiceInputHttp();
            this.clickAndSpeakInstructions.onMouseClick();
        }
    };

    onInputMouseUpListener= () => {
        if (this.channelType !== ChannelType.WS) {
            this.stopListeningMouseUp();
            this.startListeningMouseDown();
            this.stopVoiceInputHttp();
            this.clickAndSpeakInstructions.onMouseNotClick();
        }
    };

    startListeningMouseUp = () => {
        $('#input-area, #input-area-imitation').on('mouseup', this.onInputMouseUpListener);
    }

    stopListeningMouseUp = () => {
        $('#input-area, #input-area-imitation').off('mouseup', this.onInputMouseUpListener);
    }

    startListeningMouseDown = () => {
        $('#input-area, #input-area-imitation').on('mousedown', this.onInputMouseDownListener);
    }

    stopListeningMouseDown = () => {
        $('#input-area, #input-area-imitation').off('mousedown', this.onInputMouseDownListener);
    }

    keyDownListener = (ev: KeyboardEvent): void => {
        // stopping listening for spacebar keydown because a long press is recognized as multiple keydowns and we don't want to execute this code over and over
        this.stopListeningSpaceBarKeyDown();

        if (ev.keyCode === 32 || ev.key === ' ') {
            this.startVoiceInputHttp();
            this.clickAndSpeakInstructions.onSpacebarClick();
            // TODO: This class also is handled by some state too, it will cause problems
            $('#input-hightlighter').removeClass('input-highlighter-light').addClass('input-highlighter-dark');
        }
    }

    keyUpListener = (ev: KeyboardEvent) => {
        // Get ready for another spacebar click
        this.startListeningSpaceBarKeyDown();

        if (ev.keyCode === 32 || ev.key === ' ') {
            this.stopVoiceInputHttp();
            this.clickAndSpeakInstructions.onSpacebarNotClick();
            // TODO: This class also is handled by some state too, it will cause problems
            $('#input-hightlighter').removeClass('input-highlighter-dark').addClass('input-highlighter-light');
        }
    }

    startListeningForSpaceBar = () => {
        if (this.channelType !== ChannelType.WS) {
            document.body.addEventListener('keydown', this.keyDownListener);
            document.body.addEventListener('keyup', this.keyUpListener);
        }
    }

    stopListeningForSpaceBar = () => {
        if (this.channelType !== ChannelType.WS) {
            document.body.removeEventListener('keydown', this.keyDownListener);
            document.body.removeEventListener('keyup', this.keyUpListener);
        }
    }

    startListeningSpaceBarKeyDown = () => {
        if (this.channelType !== ChannelType.WS) {
            document.body.addEventListener('keydown', this.keyDownListener);
        }
    }

    stopListeningSpaceBarKeyDown = () => {
        if (this.channelType !== ChannelType.WS) {
            document.body.removeEventListener('keydown', this.keyDownListener);
        }
    }

    /**
     * < < < END Input area listener related functions
     */

    startVoiceInputHttp = (): void => {
        this.startVoiceInput();
        this.inputAreaLoader.hide();
        this.defaultPrompt = 'Speak';
        $('#input-text > span').html(this.defaultPrompt);
    }

    stopVoiceInputHttp = (): void => {
        // TODO: Why an empty ThenPromise??
        this.stopVoiceInput().then(() => {
        });
        this.hideCursor();
        $('#input-text > span').html(null);
        this.inputAreaLoader.show();
    }

    hideClickAndSpeakInstructions = () => {
        if (this.channelType !== ChannelType.WS) {
            this.clickAndSpeakInstructions.hide();
        }
    }

    showClickAndSpeakInstructions = () => {
        if (this.channelType !== ChannelType.WS) {
            this.clickAndSpeakInstructions.show();
        }
    }

    onInputMouseEnter = () => {
        if (this.channelType !== ChannelType.WS) {
            this.setState(prevState => ({
                isMouseOnInputArea: true
            }), () => {
                this.clickAndSpeakInstructions.reduceSpacebarInstOpacity();
            });
        }
    }

    onInputMouseLeave = () => {
        if (this.channelType !== ChannelType.WS) {
            this.setState(prevState => ({
                isMouseOnInputArea: false
            }), () => {
                this.clickAndSpeakInstructions.incrementSpacebarInstOpacity();
            });
        }
    }

    fakeSubmitAnswer = (): void => {
        const { currentIntentIdx, currentContextIdx, dialog } = this.state;
        const currentIntent = dialog.contexts[currentContextIdx].intents[currentIntentIdx];
        this.setState({
                currentAnswer: currentIntent.answers[0].value
            },
            this.validateAnswer
        );
    };

    submitAnswer = (channelId?: string): void => {
        const self = this;
        channelId = !channelId && self.streamChannel ? self.streamChannel.getChannelId() : channelId;
        self.stopUI();
        self.showTutorial();
        setTimeout(() => {
            self.hideCursor();
            self.validateAnswer(channelId);
        }, 500);
    };

    stopUI = (): Promise<void> => {
        this.isStoppingUI = true;
        return new Promise<void>((resolve, reject) => {
            this.stopVoiceInput().then(() => {
            resolve
        }).catch(resolve);
            // hide helping UI elements
            TweenMax.to($('.action-reveal'), .3, {autoAlpha: 0});
            this.hideInstruction(this.inputHelpId);
            this.hideInstruction(this.inputHowto);
        });
    };

    markAnswerSubmitted = (): void => {
        const inputArea = $('#input-area');
        const inputHighlighter = $('#input-hightlighter');
        const input = inputArea.find('.input-wrapper');


        TweenMax.to($('#hint-area .img-hint'), 1, {scale: .8, yPercent: -40, autoAlpha: .3, transformOrigin: 'bottom left', ease: Expo.easeOut});
        TweenMax.to($('.input-instructions'), 1, {yPercent: 0, autoAlpha: 0, ease: Expo.easeOut});
        TweenMax.to(inputHighlighter, 1, {autoAlpha: 0, ease: Expo.easeOut});
        TweenMax.to(inputArea, 1, {minHeight: $(window).height() * 0.35, ease: Expo.easeOut});

        // reset wrong answer
        (new TimelineMax())
            .to($('#wrong-answer'), .3, {autoAlpha: 0})
            .set($('#wrong-answer'), {clearProps: 'all'})
            .call(() => { $('#wrong-answer').html(''); }, [])
        ;
        input.removeClass('active');
    };

    validateAnswer = (channelId?: string): void => {
        clearTimeout(this.answerTimeout);

        if (this.closingComponent) {
            this.closeChannel();
            return;
        }
        const { currentIntentIdx, currentContextIdx, dialog, currentAnswer, answerAttempts } = this.state;
        const currentIntent = dialog.contexts[currentContextIdx].intents[currentIntentIdx];
        let answer = currentAnswer || '';
        // trim spaces on start and end of the sentence
        answer = answer.replace(/ +(?= )/g, '').trim();
        // array of expected answers
        const expectedValues = _.map(currentIntent.answers, (a: any) => a.value);
        const result = TextService.instance.fuzzyCompareAnswers([answer], expectedValues, Learning.FUZZYNESS, this.languageIso);
        const correct = result.correct;

        let newAnswerAttempts = answerAttempts || 0;
        newAnswerAttempts = correct ? 0 : newAnswerAttempts + 1;

        this.setState({correctAnswer: correct, answerAttempts: newAnswerAttempts});
        if (correct) {
            const wordToHear = result.definedExpectedAnswer.replace(/[\[\]]/g, '');
            this.setState({currentAnswer: wordToHear});
            this.currentExpectedAnswer = result.definedExpectedAnswer;
            this.currentProvidedAnswer = result.userProvidedAnswer;
            const delay = 1000;
            $('#input-text > span').html(wordToHear);
            this.markAnswerSubmitted();
            // Hide the input area instructions for click and speak mode
            this.hideClickAndSpeakInstructions();
            setTimeout(this.markAnswerCorrect, delay);
            // track
            this.dataLayerPush({
                'task': '' + (currentContextIdx + 1) + '-' + (currentIntentIdx + 1),
                'question': currentIntent.question,
                'event': 'speakCorrect',
                'seqAlpha': dialog.seqAlpha
            });
        } else {
            // define expected and provided answers, define what "wrong" answer must be shown to user

            // value for BE statistics
            let userAnswerCorrected = answer;
            // value for UI with embedded HTML tags to mark some parts or the whole phrase as "wrong"(i.e. red)
            let userAnswerCorrectedWithTags = answer; // gets HTML tags with CSS styles

            if (!userAnswerCorrectedWithTags && expectedValues && expectedValues.length > 0) {
                // there was no answer provided by user take first expected answer and mark its whole text as "wrong"
                userAnswerCorrected = TextService.instance.cleanupText(expectedValues[0]);
                userAnswerCorrectedWithTags = '<span class="diff-delete">' + userAnswerCorrected + '</span> ';
            } else {
                // there was some answer provided by user
                // find closest diff between provided answer and all expected answers
                const chosenExpectedValueDiff = TextService.instance.closestDiff(answer, expectedValues);
                // console.log('chosenExpectedValueDiff: ' + JSON.stringify(chosenExpectedValueDiff));
                if (chosenExpectedValueDiff) {
                    userAnswerCorrected = TextService.instance.diffPrettyHtml(chosenExpectedValueDiff.diff, true, false);
                    userAnswerCorrectedWithTags = TextService.instance.diffPrettyHtml(chosenExpectedValueDiff.diff, true);
                    // console.log(`2 Corrected: "${userAnswerCorrected}"`);
                    // console.log(`2 CorrectedWithTags: "${userAnswerCorrectedWithTags}"`);
                }
            }

            this.currentExpectedAnswer = userAnswerCorrected;
            this.currentProvidedAnswer = answer;
            setTimeout(() => {
                this.markAnswerWrong(userAnswerCorrectedWithTags);
            }, 0);
            // track
            this.dataLayerPush({
                'task': (currentContextIdx + 1) + '-' + (currentIntentIdx + 1),
                'question': currentIntent.question,
                'event': 'speakWrong',
                'seqAlpha': dialog.seqAlpha
            });
        }
        this.reportAttempt(channelId, correct);
    };

    // Call if user answers and that answer is being validated (this.validateAnswer method)
    reportAttempt = (channelId: string, success: boolean): void => {
        const {currentIntentIdx, currentContextIdx, dialog, course} = this.state;
        const currentContext = dialog.contexts[currentContextIdx];
        const currentIntent = currentContext.intents[currentIntentIdx];
        const question = currentIntent.question;
        const imageHint = currentIntent.imageHint;
        const submitLearnedAttempt = {
            platform: 'web',
            dialogueId: dialog.id,
            contextId: currentContext.id,
            intentId: currentIntent.id,
            course: course.name,
            expectedAnswer: this.currentExpectedAnswer,
            providedAnswer: this.currentProvidedAnswer,
            question,
            hint: imageHint,
            success
        };
        DialogueService.instance.submitLearnedAttempt(submitLearnedAttempt)
            .then(() => {
                // do nothing
            })
            .catch(() => {
                // do nothing
            });
        if (channelId) {
            setTimeout(() => {
                SpeechService.instance.updateChannel(channelId, submitLearnedAttempt);
            }, 1000);
        }
    };

    markAnswerCorrect = (): void => {
        const {answerAttempts, currentAnswer} = this.state;
        // Hide because even after the recognition is completed user still can click on the input area which can bring a strange loading component to the screen
        this.inputAreaLoader.hide();
        // Listening will stop in finishIntent function too but it would be good if user couldn't register voice when correct answer is given too, because there are some time for animation in-between
        this.stopListeningForSpaceBar();
        clearTimeout(this.wsResponseTimeout);
        SpeechService.instance.play(this.successSound);
        TweenMax.to($('#input-text > .line'), 1, {width: '100%', ease: Expo.easeOut,
            onComplete: () => {
                Promise.all([
                    // don't show coins if there was a wrong attempt
                    answerAttempts > 0 ? Promise.resolve() : this.showPoints(),
                    SpeechService.instance.tts(currentAnswer, this.languageIso)
                ]).then(() => {
                    this.finishIntent(true);
                }).catch(() => {
                    this.finishIntent(true);
                });
            }
        });
    };

    markAnswerWrong = (userAnswerCorrected: string): void => {
        const {answerAttempts, currentAnswer} = this.state;
        clearTimeout(this.wsResponseTimeout);

        // Early step for showing the correct answer, do not proceed with this at the 1st wrong attempt
        if (answerAttempts !== 1) {
            setTimeout(() => {
                if (userAnswerCorrected) {
                    $('#input-text > #wrong-answer').html(userAnswerCorrected);
                    this.defaultPrompt = '&nbsp;';
                    $('#input-text > span').html(this.defaultPrompt);
                }
            }, 0);
        }

        // Play a simple info sound and shake the user avatar
        SpeechService.instance.play(this.infoSound);
        TweenMax.fromTo('.input-wrapper .user-avatar', 0.3, {x: -2}, {x: 2, ease: RoughEase.ease.config({strength: 8, points: 8, template: Linear.easeNone, randomize: false}) , clearProps: 'x'});

        // Check if user has spent all it's chances
        if (answerAttempts < Learning.FAILED_ATTEMPTS_BEFORE_NEXT) {

            // If it is the wrong answer in the intent, do not show or read out loud the correct answer, just start another attempt
            if (answerAttempts === 1) {
                this.startAnotherAnswerAttempt({showingCorrection: false});
            }

            // If it is the second wrong answer in the intent, then read out loud the correct answer
            else if (answerAttempts === 2) {
                SpeechService.instance.tts($(userAnswerCorrected).text(), this.languageIso).then(() => {
                    // Show correction
                    TweenMax.set($('#wrong-answer'), {clearProps: 'all'});
                    // Start another answer attempt and inform the method that answer is being shown in the past attempt
                    this.startAnotherAnswerAttempt({showingCorrection: true});
                });
            }

            // After 2nd, show correction and continue (This step will not be needed with the current FAILED_ATTEMPTS_BEFORE_NEXT setting tho)
            // this is dead code when FAILED_ATTEMPTS_BEFORE_NEXT == 3
            else {
                // Show correction
                TweenMax.set($('#wrong-answer'), {clearProps: 'all'});
                // Start another answer attempt and inform the method that answer is being shown in the past attempt
                this.startAnotherAnswerAttempt({showingCorrection: true});
            }

        } else {
            // read out loud the correct answer and go to next task

            // Show correction
            TweenMax.set($('#wrong-answer'), {clearProps: 'all'});

            // Read out loud and finalize the intent
            SpeechService.instance.tts($(userAnswerCorrected).text(), this.languageIso).then(() => {
                setTimeout(() => {
                    this.finishIntent(false);
                }, 500);
            });
        }
    };

    /**
     * Start new voice input, animate the #wrong-answer and change the default text in the answer area to 'try again'
     *
     * @param showingCorrection
     *  It is being used to determine whether to animate the #wrong-answer or not, if the wrong answer is not shown in the attempt,
     *  then we should not animate it because besides performance issues we also change it's opacity too, so it becomes visible which we don't want in some cases
     */
    startAnotherAnswerAttempt = ({ showingCorrection } : { showingCorrection: boolean }) => {
        if (showingCorrection) {
            setTimeout(() => {
                if (this.channelType === ChannelType.WS) {
                    this.startVoiceInput();
                }

                (new TimelineMax())
                    .to($('#wrong-answer'), .6, {
                        top: -45,
                        scale: .6,
                        transformOrigin: 'top left',
                        ease: Expo.easeInOut
                    })
                    .call(() => {
                        this.defaultPrompt = 'try again...';
                        $('#input-text > span').html(this.defaultPrompt);
                    }, [])
                ;
            }, 500);
        } else {
            // Start voice input without delay and show the 'try again' text, because we will not need to wait for some animation in this case
            if (this.channelType === ChannelType.WS) {
                this.startVoiceInput();
            }
            this.defaultPrompt = 'try again...';
            $('#input-text > span').html(this.defaultPrompt);
        }
    };

    // To be called ONLY in the phase the ThreeDotsLoader is shown.
    // We should delete this function if we remove the cancel logic on Click and Speak mode
    restartAnswerAttempt = () => {
        if (this.channelType === ChannelType.WS) {
            this.startVoiceInput();
        } else {
            this.inputAreaLoader.hide();
        }
        this.defaultPrompt = this.channelType === ChannelType.WS
            ? 'say your answer...'
            : 'Click and hold';
        $('#input-text > span').html(this.defaultPrompt);
    }

    revealAnswer = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        this.showInstruction(this.inputHelpId);
    };

    showTranslation = (): void => {
        TweenMax.fromTo($('#task-area .translation'), .3, {yPercent: -50, autoAlpha: 0}, {yPercent: 0, autoAlpha: 1, ease: Expo.easeOut});
    };

    hideTranslation = (): void => {
        TweenMax.to($('#task-area .translation'), .3, {yPercent: -50, autoAlpha: 0, ease: Expo.easeOut});
    };

    showPoints = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const el = $('#input-text > .points');
            const tl = new TimelineMax();
            tl
                .to(el, .8, {autoAlpha: 1, y: -60, ease: Expo.easeOut})
                .to(el, .2, {autoAlpha: 0, y: -75}, '+=.5')
                .to(el, 0, {y: 0})
                .call(resolve, [])
            ;
        });
    };

    stopVoiceInput = (): Promise<void> => {
        this.deactivateEqualizer();
        if (this.channelType === ChannelType.WS) {
            return this.closeChannel();
        } else {
            return this.flushChannel();
        }
    };

    flushChannel = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (this.streamChannel) {
                this.streamChannel.flush().then(() => {
                    resolve();
                }).catch((err) => {
                    reject(err);
                });
            } else {
                resolve();
            }
        });
    };

    closeChannel = (channel = null): Promise<void> => {
        //console.log('closeChannel');
        return new Promise<void>((resolve, reject) => {
            //console.log('this.streamChannel: ' + this.streamChannel);
            //console.log('channel: ' + channel);

            // function parameter 'channel' is needed to avoid threads' race conditions when opening and closing channel simultaneously
            let channelToClose = this.streamChannel ? this.streamChannel : channel;
            if (channelToClose) {
                //console.log('channel closing if');
                channelToClose.close().then(() => {
                    //console.log('closeChannel closed');
                    this.streamChannel = null;
                    channelToClose = null;
                    channel = null;

                    if (this.streamChannelMonitor) {
                        // console.log('streamChannelMonitor stopped');
                        clearTimeout(this.streamChannelMonitor);
                        this.streamChannelMonitor = null;
                    }
                    resolve();
                }).catch((err) => {
                    this.streamChannel = null;
                    channelToClose = null;
                    channel = null;

                    if (this.streamChannelMonitor) {
                        // console.log('streamChannelMonitor stopped');
                        clearTimeout(this.streamChannelMonitor);
                        this.streamChannelMonitor = null;
                    }
                    reject(err);
                });
            } else {
                resolve();
            }
        });
    };

    openChannel = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const proceed = () => {
                if (this.closingComponent || this.paused) {
                    // console.log('closingComponent');
                    this.closeChannel().then(resolve).catch(reject);
                } else {
                    this.streamChannel = StreamChannelFactory.instance.createChannel(
                        this.languageIso,
                        this.getCurrentIntentAnswers(true),
                        this.recognitionProcessing,
                        this.statusProcessing,
                        this.channelType
                    );
                    //console.log('openChannel opening');
                    let tempStreamChannel = this.streamChannel;

                    this.streamChannel.open().then(() => {
                        //console.log('openChannel opened');
                        if (this.closingComponent || this.paused) {
                            //console.log('openChannel opened closingComponent');
                            // it's possible that streamChannel became null before the channel was opened, preventing this case
                            this.closeChannel(!this.streamChannel ? tempStreamChannel : null).then(resolve).catch(reject);
                            tempStreamChannel = null;
                        } else {
                            // Do not set the timeout if channel type is not WS
                            if (this.channelType === ChannelType.WS) {
                                this.streamChannelMonitor = setTimeout(() => {
                                    this.submitInProgress = true;
                                    this.submitAnswer();
                                }, 13 * 1000);
                            }
                            // console.log('streamChannelMonitor started');
                            resolve();
                        }
                    }).catch((err) => {
                        console.error(err);
                        const rejectFnc = () => reject(err.message);
                        this.closeChannel().then(rejectFnc).catch(rejectFnc);
                    });
                }
            };
            this.closeChannel().then(proceed).catch(proceed);
        });
    };

    showInstruction = (instructionId: string): void => {
        const tl = new TimelineMax();
        const instructions = $('#' + instructionId);
        SpeechService.instance.play(this.infoSound);
        tl
            .set(instructions, {css: {display: 'block'}})
            .fromTo(instructions, .4, {scale: 0}, {scale: 1, transformOrigin: 'bottom left', ease: Back.easeOut})
            .to(instructions.nextAll(), .4, {x: '+=' + instructions.outerWidth(true), ease: Back.easeOut}, '-=.4')
        ;
        TweenMax.to($('.action-reveal'), .4, {autoAlpha: 0});
    };

    hideInstruction = (instructionId: string): void => {
        const tl = new TimelineMax();
        const instructions = $('#' + instructionId);
        const isVisible = instructions.is(':visible');
        tl
            .to(instructions, .3, {scale: 0, transformOrigin: 'bottom left', ease: Back.easeIn})
            .set(instructions, {clearProps: 'all'})
        ;
        if (isVisible) {
            tl
                .to(instructions.nextAll(), .3, {x: '-=' + instructions.outerWidth(true), ease: Back.easeIn}, '-=.3')
            ;
        }
    };

    activateEqualizer = (): void => {
        const equalizer = $('#input-hightlighter .equalizer');
        const timeline1 = new TimelineMax({yoyo: true, repeat: -1});
        this.eqTimelines.push(timeline1);
        timeline1
            .to(equalizer.find('.w1'), .2, {css: {height: 80}})
            .to(equalizer.find('.w1'), .2, {css: {height: 10}});
        const timeline2 = new TimelineMax({yoyo: true, repeat: -1, delay: .1});
        this.eqTimelines.push(timeline2);
        timeline2
            .to(equalizer.find('.w2'), .2, {css: {height: 60}})
            .to(equalizer.find('.w2'), .2, {css: {height: 10}});
        const timeline3 = new TimelineMax({yoyo: true, repeat: -1, delay: .2});
        this.eqTimelines.push(timeline3);
        timeline3
            .to(equalizer.find('.w3'), .2, {css: {height: 50}})
            .to(equalizer.find('.w3'), .2, {css: {height: 10}});
        const timeline4 = new TimelineMax({yoyo: true, repeat: -1, delay: .3});
        this.eqTimelines.push(timeline4);
        timeline4
            .to(equalizer.find('.w4'), .2, {css: {height: 30}})
            .to(equalizer.find('.w4'), .2, {css: {height: 10}});
        const timeline5 = new TimelineMax({yoyo: true, repeat: -1, delay: .05});
        this.eqTimelines.push(timeline5);
        timeline5
            .to(equalizer.find('.w5'), .2, {css: {height: 30}})
            .to(equalizer.find('.w5'), .2, {css: {height: 10}});
        const timeline6 = new TimelineMax({yoyo: true, repeat: -1, delay: 0});
        this.eqTimelines.push(timeline6);
        timeline6
            .to(equalizer.find('.w6'), .2, {css: {height: 60}})
            .to(equalizer.find('.w6'), .2, {css: {height: 10}});
    };

    deactivateEqualizer = (): void => {
        const equalizer = $('#input-hightlighter .equalizer');
        for (const i in this.eqTimelines) {
            this.eqTimelines[i].stop();
            TweenMax.to(equalizer.find('.wave'), .6, {css: {height: 0}});
        }
    };

    blinkCursor = (): void => {
        const cursor = $('#input-area .cursor');
        if (this.cursortTimeline) {
            this.cursortTimeline.stop();
        }
        this.cursortTimeline = new TimelineMax({yoyo: true, repeat: -1});
        this.cursortTimeline
            .fromTo(cursor, .2, {autoAlpha: 1}, {autoAlpha: 0}, '+=.2');
    };

    hideCursor = (): void => {
        const cursor = $('#input-area .cursor');
        if (this.cursortTimeline) {
            this.cursortTimeline.stop();
            this.cursortTimeline = null;
        }
        TweenMax.to(cursor, .1, {autoAlpha: 0});
    };

    getCurrentIntentAnswers = (includeWrong?: boolean): string[] => {
        const { currentIntentIdx, currentContextIdx, dialog } = this.state;
        const answers: string[] = [];
        if (dialog && currentContextIdx > -1 && currentIntentIdx > -1) {
            const currentIntent = dialog.contexts[currentContextIdx].intents[currentIntentIdx];
            if (currentIntent) {
                for (const answer of currentIntent.answers) {
                    if (answer && answer.value) {
                        answers.push(answer.value);
                    }
                }
                if (includeWrong && currentIntent.wrongAnswers) {
                    for (const answer of currentIntent.wrongAnswers) {
                        if (answer && answer.length > 0) {
                            answers.push(answer);
                        }
                    }
                }
            }
        }
        return answers;
    };

    adjustSpokenAnswers = (results: any, languageIso: string): string[] => {
        const self = this;
        const spokenAnswers: string[] = [];
        for (const answer of results.answers) {
            let adjusted: string = TextService.instance.adjustInput(answer.transcript.trim(), languageIso);
            adjusted = adjusted && adjusted.trim().length > 0 ? adjusted.trim() : null;
            if (adjusted) {
                spokenAnswers.push(adjusted);
            }
        }
        return spokenAnswers;
    };

    recognitionProcessing = (results: any): void => {

        const self = this;
        if (self.submitInProgress || !results || !results.answers) {
            return;
        }
        const result = self.preanalyzeRecognitionResults(results, self.getCurrentIntentAnswers(), self.languageIso);
        if (!result || !result.answer || result.answer.trim().length < 1) {
            return;
        }

        // Hide the loader when the text is recognized, recognitionProcessing function is called once for each recognition in case of this.channelType !== ChannelType.WS
        if (this.channelType !== ChannelType.WS) {
            this.inputAreaLoader.hide();
        }

        TweenMax.set($('#input-text > span'), {autoAlpha: 1});
        self.setState({ currentAnswer: result.answer ? result.answer.replace(/[\[\]]/g, '') : result.answer });
        self.scheduleRecognitionCheck(result, self.submitAnswer);
    };

    clearRecognitionCheck = (): void => {
        _.each(this.checkTimeouts, (t) => {
            if (t) {
                clearTimeout(t);
            }
        });
    };

    preanalyzeRecognitionResults = (results: any, expectedAnswers: string[], languageIso: string): any => {
        let result: any = null;
        const spokenAnswers: string[] = this.adjustSpokenAnswers(results, languageIso);
        if (spokenAnswers.length > 0) {
            result = TextService.instance.fuzzyCompareAnswers(
                spokenAnswers,
                expectedAnswers,
                Learning.FUZZYNESS,
                languageIso
            );
        }
        return result;
    };

    scheduleRecognitionCheck = (result: any, callbackSubmitFunction: () => void, autoSubmit?: boolean): void => {
        const submitFunction = () => {
            this.submitInProgress = true;
            if (callbackSubmitFunction) {
                callbackSubmitFunction();
            }
            this.clearRecognitionCheck();
        };

        const timeoutMillisec = this.channelType !== ChannelType.HTTP_MANUAL ? 1500 : 50;
        const timeDiff = this.channelType !== ChannelType.HTTP_MANUAL ? 1200 : 25;

        if (autoSubmit && result && result.correct && !this.state.listening) {
            submitFunction();
        } else {
            this.lastAnswerTime = new Date().getTime();
            const checkTimeout = setTimeout(() => {
                if (this.lastAnswerTime) {
                    if ((new Date().getTime() - this.lastAnswerTime) > timeDiff) {
                        if (this.state.listening) {
                            this.scheduleRecognitionCheck(null, callbackSubmitFunction, false);
                        } else {
                            submitFunction();
                        }
                    }
                }
            }, timeoutMillisec);
            this.checkTimeouts.push(checkTimeout);
        }
    };

    statusProcessing = (status: RecognitionStatus): void => {
        if (this.closingComponent) {
            return;
        }
        const {channelId} = this.state;
        // Refresh state in the UI
        if (channelId !== status.channelId) {
            // console.log({channelId});
            this.setState({channelId: status.channelId});
        }
        if (this.debugComp) {
            this.debugComp.handleStatus(status);
        }
        if (status.message === RecognitionStatus.MSG_START_VOICE) {
            // This is a dirty solution but it was starting to take so much time to come up with a cleaner one
            // This provides to set listening back to true when the channel type is changed to ChannelType.WS
            if (this.channelType !== ChannelType.WS) {
                this.setState({listening: true});
            }
        } else if (status.message === RecognitionStatus.MSG_END_VOICE) {
            this.setState({listening: false});
        } else if (status.message === RecognitionStatus.MSG_TRANSCRIBE_EMPTY) {
            if (this.isStoppingUI) {
                // Getting the result of stopping the voice inout is done here, so i should reset this variable here and act according to that value in here as well
                this.isStoppingUI = false;
            } else {
                // If entered here, it most likely means that the recognized voice file is just empty
                // And in this case user should not lose one of her/his chances and just try again like nothing has happened
                // P.S. This happens only at the Click-and-Speak mode as far as i have observed, let's see further
                console.log('transcribe empty event is detected! Are you sure that video file was not empty? (In the means of meaningful word(s))');
                // Just changing the input text and removing the input area loader is enough, voice input will start on spacebar or mouse click anyways.
                // TODO: Should we change some class instances too though?
                this.defaultPrompt = 'Click and hold';
                $('#input-text > span').html(this.defaultPrompt);
                this.inputAreaLoader.hide();
            }

        }
    };

    getHintUrl = (url: string): string => {
        if (!url) {
            return '';
        }
        if (url.indexOf('upload/') === -1) {
            return;
        }
        const urlParts: string[] = url.split('upload/');
        return urlParts[0] + 'upload/c_fill,h_240,w_270/' + urlParts[1];
    };

    pause = (showButton?: boolean): Promise<void> => {
        showButton = !!showButton;
        this.paused = true;
        this.setState({pausedUI: true});
        const overlay = $('#pause-overlay');
        return new Promise<void>((resolve, reject) => {
            this.stopUI().then(() => {
                this.intentTransition(true);
                resolve();
            }).catch(resolve);

            if (showButton) {
                TweenMax.set(overlay.find('> div'), {clearProps: 'all'});
            } else {
                TweenMax.set(overlay.find('> div'), {css: {display: 'none' }});
            }

            TweenMax.set(overlay, {css: {display: 'block'}});
            TweenMax.set(overlay, {autoAlpha: 0});
            TweenMax.to(overlay, .3, {autoAlpha: 1});
        });
    };

    resume = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            TweenMax.to($('#pause-overlay'), .3, {autoAlpha: 0, onComplete:
                () => {
                    TweenMax.set($('#pause-overlay'), {autoAlpha: 0});
                    TweenMax.set($('#pause-overlay'), {css: {display: 'none'}});

                    // Get the sttMode again because it mught be changed if the settings side-screen was opened
                    this.channelType = SpeechService.instance.getSttMode();

                    this.paused = false;
                    this.setState({pausedUI: false});
                    const {currentIntentIdx} = this.state;
                    this.startIntent(currentIntentIdx);
                    resolve();
                }
            });
        });
    };

    openSettings = (): void => {
        this.pause(false);
        this.settingsScreen.open();
    }

    afterSettingsClose = (): void => {
        this.resume().then(() => {
            // This makes sure that any time the settings are changed to ChannelType.WS, the state property `listening` will be false
            // Without `listening` being false, there occurs problems in WS speech recog, specifically in the `scheduleRecognitionCheck` method, for the time this comment is written
            if (this.channelType === ChannelType.WS) {
                this.setState({listening: false});
            }
        });
    }

    // Shows the settings tooltip if necessary. It won't be shown after 1 time
    // This method is where some logic exists about the tooltip, if the tooltip should be shown at the start of the intent for some reason, it will be shown
    // But it won't be shown again. And also, settings tooltip can be shown directly by some other 'instant' logic from some other methods as well, instead of waiting for the start of the next intent
    handleSettingsTooltip = (): void => {
        // ***Rule 1:*** If user is not at the first lesson anymore, show him/her the tooltip
        // Note: Using user progress to understand if he/she has completed the first lesson _after_ this stt mdoes feature is released is not optimal,
        // but otherwise we were gonna need to use some local storage values and update them in the end of each dialogue or intent which is not really performant,
        // even when we will not show the tooltip again, or we were gonna need to use some if clauses anyways even if we won't update the local storage
        if (CourseService.instance.getCurrentExerciseIdx() > 0) {
            this.showSettingsTooltip();
        }
        // There is no Rule 2 for now (At least there is not a rule which should be checked in the start of an intent)
    }

    /**
     * Show the settings tooltip without any condition and highlight the settings icon
     *
     * Hide it on:
     * - User clicks on the settings icon
     * - 7 secs pass (discarded for now)
     *
     * Adjust position with animation on:
     * - User hovers on the settings icon
     * - User stops hovering on the settings icon
     */
    showSettingsTooltip = (text?: string): void => {

        const tooltipText = text || 'Try a different dialogue mode. Simulate a life-like conversaion or decide yourself when to speak.';

        // Show tooltip
        this.settingsTooltip = this.showTooltip(
            $('#learning-settings-btn'),
            tooltipText,
            {isNew: true}
        );

        // Submit it to localStorage
        LocalStorageService.instance.setIsSettingsTooltipShown(true);

        this.settingsButton = $('#learning-settings-btn');
        this.actionsSet = $('#main-actions');

        // Highlight the settings icon
        this.settingsButton.addClass('settings-highlighted');

        // Move the tooltip when necessary
        this.actionsSet.on('mouseenter', () => {
            // Hardcoded number (75) and hardcoded duration (.2)
            const tl = new TimelineMax()
            tl.to(this.settingsTooltip, .2, {css: {left: this.settingsTooltip.offset().left - 75}, ease: Expo.easeInOut})
        });
        this.actionsSet.on('mouseleave', () => {
            // Hardcoded number (75) and hardcoded duration (.2)
            (new TimelineMax())
                .to(this.settingsTooltip, .2, {css: {left: this.settingsTooltip.offset().left + 75}, ease: Expo.easeInOut})
        });

        // Remove the tooltip when necessary
/*         setTimeout(() => {
            handleHidingSettingsTooltip();
        }, 7000); */
        this.settingsButton.on('click', () => {
            this.handleHidingSettingsTooltip();
        });
    }

    handleHidingSettingsTooltip = () => {
        this.hideTooltip(this.settingsTooltip);
        this.settingsButton.removeClass('settings-highlighted');
        this.actionsSet.off('mouseenter');
        this.actionsSet.off('mouseleave');
        this.settingsButton.off('click');
    }

    // Tooltip with an arrow on it's right
    showTooltip = (
        elem: JQuery<HTMLElement>, text: string,
        // Options
        {isNew = false}: {isNew?: boolean}
    ): JQuery<HTMLElement> => {
        const rect = elem.offset();

        const tooltipContent = isNew
            ? '<div class="label">new</div><br/>' + text
            : text

        const tooltip = $(`<div id="stt-modes-tooltip" class="tooltip arrow-right">${tooltipContent}</div>`).appendTo('#page-wrapper').css({opacity: 0});

        const tlOpen = new TimelineMax();
        tlOpen
            .set(tooltip, {autoAlpha: 0, xPercent: 10})
            .set(tooltip, {css:
                {top: rect.top
                    - (elem.outerHeight() / 2)
                    + ((tooltip.outerHeight() - tooltip.height()) / 2),
                left: rect.left - tooltip.outerWidth() - 18 }    // To find the top padding of the tooltip
            }, '+=.1')
            .to(tooltip, .4, {xPercent: 0, autoAlpha: 1, ease: Expo.easeOut})
        ;

        return tooltip
    };

    hideTooltip = (tooltip: JQuery<HTMLElement>) => {
        const tlClose = new TimelineMax({
            onComplete: () => {tooltip.remove()}
        });
        tlClose
            .to(tooltip, .2, {xPercent: 10, autoAlpha: 0, ease: Expo.easeIn})
        ;
    }

    openSoundCheck = (): void => {
        this.soundCheckPopup.open();
        this.pause().then(() => {
            this.openTestChannel()
                .then(() => {

                })
                .catch(() => {
                    // TODO : ?? anything to do on error
                });
        });
    };

    closeSoundCheck = (): void => {
        this.closeTestChannel().then(this.resume).catch(this.resume);
    };

    closeTestChannel = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (this.testStreamChannel) {
                this.testStreamChannel.close().then(() => {
                    this.testStreamVolume = 0;
                    this.testStreamVolumeAvg = 0;
                    this.testStreamVolumeAvgData = [];
                    this.testStreamChannel = null;
                    if (this.testStreamChannelMonitor) {
                        clearInterval(this.testStreamChannelMonitor);
                        this.testStreamChannelMonitor = null;
                    }
                    resolve();
                }).catch((err) => {
                    this.testStreamVolume = 0;
                    this.testStreamVolumeAvg = 0;
                    this.testStreamVolumeAvgData = [];
                    this.testStreamChannel = null;
                    if (this.testStreamChannelMonitor) {
                        clearInterval(this.testStreamChannelMonitor);
                        this.testStreamChannelMonitor = null;
                    }
                    reject(err);
                });
            } else {
                resolve();
            }
        });
    };

    openTestChannel = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            const proceed = () => {
                if (this.closingComponent) {
                    // console.log('closingComponent');
                    this.closeTestChannel().then(resolve).catch(reject);
                } else {
                    this.testStreamChannel = StreamChannelFactory.instance.createChannel(
                        'en',
                        [Learning.EXPECTED_TEST_ANSWER],
                        this.testRecognitionProcessing,
                        this.statusProcessing,
                        this.channelType
                    );
                    this.testStreamChannelMonitor = setInterval(() => {
                        const sound = this.testStreamChannel ? this.testStreamChannel.getAudioVolumeData() : {
                            clipped: false,
                            volume: 0
                        };
                        this.testStreamVolume = Number.parseFloat(Math.min(100, Number.parseFloat(sound.volume.toFixed(3)) * 100).toFixed(1));
                        if (!isNaN(this.testStreamVolume) && this.testStreamVolume > 0.0 && this.state.listening) {
                            this.testStreamVolumeAvgData.push(this.testStreamVolume);
                            this.testStreamVolumeAvg = _.reduce(this.testStreamVolumeAvgData, (memo, num) => memo + num, 0) / this.testStreamVolumeAvgData.length;
                        }
                    }, 200);
                    this.testStreamChannel.open().then(() => {
                        if (this.closingComponent) {
                            this.closeTestChannel().then(resolve).catch(reject);
                        } else {
                            resolve();
                        }
                    }).catch(() => {
                        this.closeTestChannel().then(resolve).catch(reject);
                    });
                }
            };
            this.closeTestChannel().then(proceed).catch(proceed);
        });
    };

    testRecognitionProcessing = (results: any): void => {
        if (!results || !results.answers) {
            return;
        }
        const result = this.preanalyzeRecognitionResults(results, [Learning.EXPECTED_TEST_ANSWER], 'en');
        if (!result || !result.answer || result.answer.trim().length < 1) {
            return;
        }
        this.setState({ currentTestAnswer: result.answer ? result.answer.replace(/[\[\]]/g, '') : result.answer });
        this.scheduleRecognitionCheck(result, () => {
            this.setState({ currentTestAnswer: null });
            this.testStreamVolumeAvg = 0;
            this.testStreamVolumeAvgData = [];
        });
    };

    // Always use this method to open the authPopup
    openAuthPopup = (authPopupType: AuthPopupType, isModal = false): void => {
        this.setState({authPopupType}, () => {
            this.authPopup.open(isModal);
        })
    }

    onAuthPopupSuccess = (): void => {
        if (this.state.authPopupType === AuthPopupType.PRE_VIDEO) {
            this.authPopup.close().then(() => this.startCurrentContext());
        } else if (this.state.authPopupType === AuthPopupType.DURING_INTENTS) {
            this.authPopup.close().then(() => this.nextExrStep(false));
        }
    };

    onAuthPopupCloseByItself = (): void => {
        if (this.state.authPopupType === AuthPopupType.PRE_VIDEO) {
            this.startIntro();
        } else if (this.state.authPopupType === AuthPopupType.DURING_INTENTS) {
            this.nextExrStep(false);
        }
    };

    changeLeadEmail = (e): void => {
        const email = e.target.value;
        this.setState({leadEmail: email});
    };

    isEmailValid = (email: string): boolean => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    submitEmail = (e, popup: Popup): void => {
        e.preventDefault();
        const email = this.state.leadEmail;
        if (this.isEmailValid(email)) {
            TrackingService.instance.sendFB('Lead', {content_name: 'Learning'});
            TrackingService.instance.sendGA('user', 'submitLead', 'Learning');
            localStorage.setItem('leadCollected', email);
            // Submit to server
            $.ajax({
                type: 'POST',
                url: '/api/email/add',
                data: JSON.stringify(
                    {
                        'email': email,
                        'attributes': {
                            target_lang: this.languageIso
                        }
                    }
                ),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: (data) => {
                    popup.close().then(() => {
                        this.leadSubmitedPopup.open().then(() => {
                            setTimeout(() => {
                                this.leadSubmitedPopup.close().then(() => {
                                    this.startIntro();
                                });
                            }, 3000);
                        });
                    });
                },
                failure: (errMsg) => {
                    alert('Something went wrong, please try again later');
                },
                complete: () => {
                    this.emailPopup.close();
                }
            } as JQueryAjaxSettings);
        } else {
            alert('Please, enter your email address!');
        }
    };

    render(): React.ReactNode {
        if (!this.state.course || !this.state.dialog) {
            return <div />
        } else {
            (CourseService.instance.getCurrentCourse() as Promise<Course>).then((course) => {
                const exr = CourseService.instance.getCurrentExercise(true) as UnitExercise;

                if (course && AuthService.instance.isUserPermittedForThisExercise(course.name, exr)) {
                    if (!CourseService.instance.isExerciseStartable(exr, course)) {
                        return <Redirect to={this.getCourseLink()} />;
                    }
                } else {
                    if (!CourseService.instance.isCourseBeta(course.name)) {
                        return <Redirect to={this.getCourseLink()} />;
                    }
                }
            });
        }

        // console.log('render Learning ' + (new Date).getTime());

        const { currentIntentIdx, currentContextIdx, dialog, hintMode, points, progress, channelId, questionTranslation, currentTestAnswer, listening } = this.state;
        const currentIntent = dialog && dialog.contexts && currentContextIdx > -1 && currentIntentIdx > -1 && currentIntentIdx < dialog.contexts[currentContextIdx].intents.length ? dialog.contexts[currentContextIdx].intents[currentIntentIdx] : null;
        const totalIntents = dialog && dialog.contexts[currentContextIdx] && dialog.contexts[currentContextIdx].intents ? dialog.contexts[currentContextIdx].intents.length : 0;
        const possibleAnswers = currentIntent && currentIntent.answers ? currentIntent.answers.slice(0, 2) : [];
        const instructorAvatar = this.instructors[this.languageIso].img;
        let hintImgSrc = (currentIntent !== null && currentIntent.image && currentIntent.image.reference) ? this.getHintUrl(currentIntent.image.reference) : '';
        hintImgSrc = hintImgSrc ? hintImgSrc.replace('http://', 'https://') : null;

        // Image hint and translaton
        let imageHint = currentIntent !== null ? currentIntent.imageHint : '';
        let imageHintText = '';
        let hintType = 'default';

        const regExp = /\([^)]+\)/;
        const matches = regExp.exec(imageHint);
        let hintTranslation = matches ? matches[0] : '';

        imageHint = imageHint.replace(hintTranslation, '').trim();
        imageHint = imageHint !== '' ? imageHint.split(':') : null;
        hintTranslation = (hintTranslation.replace('(', '')).replace(')', '');

        // image hint
        if (imageHint !== null) {
            if (imageHint.length === 1) {
                imageHintText = imageHint[0];
            } else {
                hintType = imageHint[0].toLowerCase();
                imageHintText = imageHint[1];
            }
        }

        const formattedImageHint = currentIntent !== null ?
            ProcessString([{
                regex: /\.\.\./g,
                fn: (key, result) => <span key={key} className='space' />
            }])(imageHintText)
            : '';

        const soundCheckHeader = <div>Read out loud: {Learning.EXPECTED_TEST_ANSWER}</div>;

        const {leadEmail} = this.state;

        const exrArea = (): JSX.Element => {
            return (
                <div id='exr-area'>
                    <ExerciseIntro
                        video={this.state.video}
                        videoTitle={this.state.videoTitle}
                        currentExr={this.state.currentExercise}
                        onVideoEnd={this.onVideoEnd}
                        ref={instance => { this.exerciseIntro = instance; }}
                        isCourseEnabled={this.isCourseEnabled}
                        getCourseLink={this.getCourseLink}
                        videoSource={this.state.videoSource}
                        slideGroups={this.state.slideGroups}
                        isIframe={this.isIframe}
                    />

                    <div className='chat-frame'>
                        <div id='task'>
                            <div id='task-area'>
                                <header>Task {currentIntentIdx + 1} of {totalIntents}</header>
                                <div className='message'>
                                    <figure>
                                        <div className='action-sound'>
                                            <button onClick={this.readQuestion}>
                                                <img src={instructorAvatar} className='logo' />
                                            </button>
                                            <i/>
                                            <i/>
                                            <i/>
                                        </div>
                                    </figure>
                                    <div className='text translatable' onMouseOver={this.showTranslation}  onMouseOut={this.hideTranslation}>{currentIntent !== null ? currentIntent.question : ''}</div>
                                </div>
                                <div className='translation'>{questionTranslation || ''}</div>

                                <div id='hint-area'>

                                    <TutorHelp
                                        helpId={this.inputHowto}
                                        header={'Possible answer:'}
                                        tutorSays={'Say this…'}
                                        intentIdx={currentIntentIdx}
                                        instructorAvatar={instructorAvatar}
                                        answers={possibleAnswers}
                                    />
                                    <TutorHelp
                                        helpId={this.inputHelpId}
                                        header={'Some possible answers:'}
                                        tutorSays={'You can say…'}
                                        instructorAvatar={instructorAvatar}
                                        intentIdx={currentIntentIdx}
                                        answers={possibleAnswers}
                                    />

                                    <Bubble
                                        className={['hint-module', 'img-hint', hintMode, 'type-' + hintType].join(' ') + ' hint-info-bubble'}
                                        updateTrigger={currentIntentIdx}
                                    >
                                        {/*<header>Hint:</header>*/}
                                        <div className='body'>
                                            <div className='command'>
                                                <div className='cmd-icon'/>
                                                <i>{hintType.replace(/answer/, 'reply')}</i>
                                            </div>
                                            <div>
                                                <div className='text'>
                                                    <span>
                                                        {formattedImageHint}
                                                        {(hintTranslation !== '' &&
                                                            <div className='text-translation'>"{hintTranslation}"</div>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            {hintImgSrc && <img src={hintImgSrc}/>}
                                        </div>
                                    </Bubble>

                                    <div id="input-area-imitation" onMouseEnter={this.onInputMouseEnter} onMouseLeave={this.onInputMouseLeave}/>
                                </div>
                            </div>

                            <div id='input-area' onMouseEnter={this.onInputMouseEnter} onMouseLeave={this.onInputMouseLeave}>
                                {UserAgentService.instance.isMobile() &&
                                    <button id='reveal-answer-button-mobile' className='button-minor action-reveal' onMouseUp={(e) => {e.stopPropagation();}}>I don't know</button>
                                }
                                <div className='input-wrapper message' style={this.state.isMouseOnInputArea ? {marginBottom: '10px'} : {}}>
                                    <figure className='user-avatar'>
                                        <img className='pic' src={userImg}/>
                                        <img className='mic' src={iconMic}/>
                                    </figure>
                                    <div className='text'>
                                        <span id='input-text'>
                                            <div id='wrong-answer' />
                                            {this.state.currentAnswer
                                                ?
                                                <span>{this.state.currentAnswer}</span>
                                                :
                                                <span>{this.defaultPrompt}</span>
                                            }
                                            <i className='line' />
                                            <div className='points'><svg width='23' height='21' xmlns='http://www.w3.org/2000/svg'><path d='M11.5 17.75l-5.554 2.92a1 1 0 0 1-1.45-1.054l1.06-6.185-4.493-4.38a1 1 0 0 1 .554-1.705l6.21-.902L10.602.817a1 1 0 0 1 1.794 0l2.777 5.627 6.209.902a1 1 0 0 1 .554 1.706l-4.493 4.38 1.06 6.184a1 1 0 0 1-1.45 1.054L11.5 17.75z' fill='#000' fillRule='evenodd' fillOpacity='.126'/></svg></div>
                                        </span>
                                        <i className='cursor' />
                                        <ThreeDotsLoader
                                            ref={instance => { this.inputAreaLoader = instance; }}
                                            size={ThreeDotsLoader.MEDIUM}
                                            // onCancel={this.restartAnswerAttempt}
                                        />
                                        {!UserAgentService.instance.isMobile() &&
                                            <button id='reveal-answer-button' className='button-minor action-reveal' onMouseUp={(e) => {e.stopPropagation();}}>I don't know</button>
                                        }
                                    </div>
                                </div>
                                {this.channelType !== ChannelType.WS
                                    ? <ClickAndSpeakInstructions
                                        ref={instance => { this.clickAndSpeakInstructions = instance; }}
                                    />
                                    : <div></div>}
                            </div>
                        </div>
                    </div>
                    <div id='input-hightlighter' className={this.channelType !== ChannelType.WS && !this.state.isMouseOnInputArea ? 'input-highlighter-light' : 'input-highlighter-dark'}>
                        <div id='skpaswr' onClick={this.fakeSubmitAnswer}/>

                        <div className='equalizer'>
                            <img className='wave w1' src={waveImg}/>
                            <img className='wave w2' src={waveImg}/>
                            <img className='wave w3' src={waveImg}/>
                            <img className='wave w4' src={waveImg}/>
                            <img className='wave w5' src={waveImg}/>
                            <img className='wave w6' src={waveImg}/>
                        </div>
                    </div>
                    <div id='stt-loading'>Activating your microphone<span>...</span> </div>
                </div>)
        }



        return this.interruptedMounting ? (<div/>) : (
            <div className='learning-view'>
                <ChapterNavi
                    chapters={dialog ? dialog.contexts : []}
                    learnedDialogue={this.learnedDialogue}
                    currentChapterIdx={currentContextIdx}
                    startChapter={this.startContext}
                    isIframe={this.isIframe}
                />

                <Settings
                    ref={instance => { this.settingsScreen = instance; }}
                    afterClose={this.afterSettingsClose}
                />

                {/*
                <Debug streamChannel = {this.streamChannel} channelId={channelId} ref={instance => { this.debugComp = instance; }}/>
                */}

                <AuthPopup
                    step='registration'
                    ref={instance => { this.authPopup = instance; }}
                    redirectUri={this.getCourseLink()}
                    onSuccess={this.onAuthPopupSuccess}
                    onClose={this.onAuthPopupCloseByItself}
                />

                {/*
                <Popup id="soundcheck-popup"
                       className="centered"
                       header={soundCheckHeader}
                       onClosed={this.closeSoundCheck}
                       hidefooter
                       ref={instance => { this.soundCheckPopup = instance; }}>
                    <div>
                        {this.testStreamChannel && this.testStreamChannel.getDeviceLabel()
                            ? <p>Denice ID: {this.testStreamChannel.getDeviceLabel()}</p>
                            : ''
                        }
                        <p>You said: {currentTestAnswer}</p>
                        <p>Volume: {this.testStreamVolume}</p>
                        <p>Volume AVG: {this.testStreamVolumeAvg}</p>
                    </div>
                </Popup>
                */}

                <Popup
                    id='email-popup'
                    className='centered'
                    hidefooter
                    hideheader
                    static={true}
                    ref={instance => { this.emailPopup = instance; }}
                >
                    <div className='alc'>
                        <img className='tutor-img' src={this.instructors[this.languageIso].img} />
                        <div className='header-text'>
                            Get more lessons!
                        </div>
                        <p>
                            Do you want to practice more? Leave your e-mail and we'll notify you
                            about the future updates and our special launch offer.
                            <br/>
                            {this.instructors[this.languageIso].signature}
                        </p>
                        <br/><br/>
                        <form onSubmit={(e) => {this.submitEmail(e, this.emailPopup)}}>
                            <div className='input-row'>
                                <input type='email' placeholder='Email address' value={leadEmail || ''} onChange={(e) => this.changeLeadEmail(e)} />
                            </div>
                            <div className='submit-row alc'>
                                <button className='button primary'>I want more lessons</button>
                            </div>
                        </form>
                    </div>
                </Popup>
                <Popup
                    id='lead-confirmation'
                    className='centered alc'
                    hidefooter
                    hideheader
                    static={true}
                    ref={instance => { this.leadSubmitedPopup = instance; }}
                >
                    <svg width='70' height='70' viewBox='0 0 70 70' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35zm0-3.182C17.427 66.818 3.182 52.573 3.182 35S17.427 3.182 35 3.182 66.818 17.427 66.818 35 52.573 66.818 35 66.818zM18.625 32.436c-.62-.62-1.63-.62-2.25 0-.62.622-.62 1.63 0 2.25L28.09 46.4c.62.622 1.628.622 2.25 0l22.425-22.424c.62-.62.62-1.63 0-2.25-.622-.62-1.63-.62-2.25 0l-21.3 21.3-10.59-10.59z' fill='#7ED323' fillRule='evenodd'/>
                    </svg>
                    <br/><br/>
                    <div className='header-text'>Thank you!</div>
                </Popup>
                <PremiumPopup
                    ref={instance => { this.premiumPopup = instance; }}
                    start={() => {}}
                    courseLink={this.getCourseLink()}
                    onSeePricingClick={() => this.props.history.push(this.getCourseLink() + '#pricing')}
                />

                <div id='main-actions'>
                    {this.isCourseEnabled() && !this.isIframe &&
                        <div>
                            <Link className='action-back ghost' to={this.getCourseLink()}>
                                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'><path d='M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z'/></svg>
                                <span>Back</span>
                            </Link>
                        </div>
                    }
                    <div>
                        <button className='ghost' onClick={this.paused ? () => {this.resume();} : () => {this.pause(true);}}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' className='btn-icon'><path d='M6 19h4V5H6v14zm8-14v14h4V5h-4z'/></svg>
                            <span>Pause</span>
                        </button>
                    </div>
                    <div>
                        <button id='learning-settings-btn' className='ghost' onClick={this.openSettings}>
                            <svg width='18' height='18' xmlns='http://www.w3.org/2000/svg'><g fill='none' fill-rule='evenodd'><path fillOpacity='0' fill='#FFF' d='M0 0h18v18H0z'/><path d='M15.37 10l-.749-.438a1.287 1.287 0 0 1 0-2.187l.75-.438c.624-.375.811-1.125.437-1.687l-.625-1.063c-.375-.625-1.124-.812-1.686-.437l-.75.438c-.812.5-1.873-.125-1.873-1.063V2.25c0-.688-.562-1.25-1.25-1.25H8.376c-.687 0-1.249.563-1.249 1.25v.813c0 .937-1.061 1.562-1.873 1.062l-.75-.375a1.21 1.21 0 0 0-1.686.438L2.192 5.25c-.312.625-.125 1.375.437 1.75l.75.438c.812.437.812 1.687 0 2.125l-.75.437c-.624.375-.811 1.125-.437 1.688l.625 1.062c.375.625 1.124.813 1.686.438l.75-.376c.812-.5 1.873.126 1.873 1.063v.875c0 .688.562 1.25 1.25 1.25h1.249c.687 0 1.249-.563 1.249-1.25v-.813c0-.937 1.061-1.562 1.873-1.062l.75.438a1.21 1.21 0 0 0 1.686-.438l.625-1.063c.312-.687.125-1.437-.437-1.812zM9 11a2.506 2.506 0 0 1-2.498-2.5C6.502 7.125 7.626 6 9 6a2.506 2.506 0 0 1 2.498 2.5c0 1.375-1.124 2.5-2.498 2.5z' fillOpacity='.8' fill='#10041E' fillRule='nonzero'/></g></svg>
                            <span>Settings</span>
                        </button>
                    </div>
                    {/*<div><button className="button" onClick={this.openSoundCheck}>M</button></div>*/}
                </div>

                <div id='pause-overlay'>
                    <div>
                        <header>Your lesson is paused</header>
                        <button className='ghost primary' onClick={this.resume}>▶ &nbsp;Resume</button>
                    </div>
                </div>

                {exrArea()}

                {(!this.tutorialCompleted() ?
                <ExrTutorial
                    showTutorial={this.showTutorial}
                    guideImg={instructorAvatar}
                    isIframe={this.isIframe}
                />
                : '')}

                <ExrCompletion
                    startNextExercise={this.startNextExercise}
                    ref={instance => { this.completionScreen = instance; }}
                    dataLayerPush={this.dataLayerPush}
                    finishedExr={this.state.currentExercise}
                    progress={progress}
                    nextExr={this.state.nextExercise}
                    isCourseEnabled={this.isCourseEnabled}
                    getCourseLink={this.getCourseLink}
                    courseImg={this.state.course ? this.instructors[this.languageIso].img : null}
                    isIframe={this.isIframe}
                />
                {UserAgentService.instance.isMobileDevice() ? (<WakeLock/>) : ''}
            </div>
        );
    }
}


