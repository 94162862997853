import * as React from 'react';
import {VoucherService} from '../services/voucher.service';
import {CSSProperties} from 'react';
import {Link} from 'react-router-dom';

const tableStyle = {
    borderCollapse: 'collapse',
    border: '1px solid black',
    padding: '5px'
} as CSSProperties;

export default class Vouchers extends React.Component<any, any> {

    state = {
        vouchers: []
    };

    componentDidMount(): void {
        $('#search-value').val('internal');
        this.findAllByCampaign();
    }

    findAllByCampaign = (): void => {
        this.setState({vouchers: []});
        const campaign: string = $('#search-value').val() as string;
        VoucherService.instance.getAllByCampaign(campaign)
            .then((vouchers) => {
                this.setState({vouchers: vouchers});
            })
            .catch((error) => {
                console.log('findAllByCampaign failed ' + error);
            });
    };

    render(): React.ReactNode {
        const {vouchers} = this.state;

        return (
            <div className='frame admin-page'>
                <div className='action-bar'>
                    <input type='text' id='search-value' name='search-value' placeholder='Search by (campaign)'/>
                    <button onClick={this.findAllByCampaign}>Search by campaign</button>

                    <Link className='action-right button' to='/admin/voucher-import'>+ Add vouchers</Link>
                </div>

                <br/>
                <div><span>Vouchers details (collection "voucher"): { vouchers.length }</span></div>
                <br/>
                <table style={tableStyle}>
                    <thead style={tableStyle}>
                    <tr style={tableStyle}>
                        <th style={tableStyle}>Voucher</th>
                        <th style={tableStyle}>VoucherType</th>
                        <th style={tableStyle}>Used</th>
                        <th style={tableStyle}>Campaign</th>
                        <th style={tableStyle}>Created</th>
                        <th style={tableStyle}>Updated</th>
                    </tr>
                    </thead>
                    <tbody style={tableStyle}>
                    {vouchers.map((voucher, i) => <tr key={i}>
                        <td style={tableStyle}>
                            <p>{voucher.voucher}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{voucher.voucherType}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{voucher.used === true ? 'true' : 'false'}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{voucher.campaign}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{voucher.created}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{voucher.updated}</p>
                        </td>
                    </tr>)
                    }
                    </tbody>
                </table>
                <br/>
            </div>
        );
    }
}
