import * as Inputmask from 'inputmask';
import { SlideGroup, SlideStyles } from '../../../../models/slide.model';

import { isValidCompletely, SlideEditorConflict } from './isValidCompletely';

export {
    isValidCompletely,
    SlideEditorConflict,
};

// Duration is smth lik 00:00.0 and seconds is just seconds as a number
export const durationToSeconds = (duration: string): number => {
    const mins = duration.split(':')[0];
    const secsAndMiliSecs = duration.split(':')[1];
    return parseFloat(mins) * 60.0 + parseFloat(secsAndMiliSecs);
}

// Duration is smth lik 00:00.0 and seconds is just seconds as a number
export const secondsToDuration = (seconds: number): string => {
    let minsString: string, secsAndMiliSecsString: string;
    let secsAndMiliSecs: number;

    // Create data in the type of number
    const mins = Math.floor(seconds / 60);
    secsAndMiliSecs = seconds % 60;

    // Turn the data to string format
    if (mins < 10) {
        minsString = '0' + mins.toString();
    } else {
        minsString = mins.toString();
    }

    if (secsAndMiliSecs < 10) {
        secsAndMiliSecsString = '0' + secsAndMiliSecs.toFixed(1);
    } else {
        secsAndMiliSecsString = secsAndMiliSecs.toFixed(1);
    }

    return minsString + ':' + secsAndMiliSecsString;
}

export const maskDurationInputs = (slideGroups: SlideGroup[]): void => {
    const durationMask = Inputmask({
        regex: '[0-9][0-9]:[0-5][0-9]\\.[0-9]'
    });
    slideGroups.forEach((slideGroup, slideGroupIdx) => {
        slideGroup.slides.forEach((slide, slideIdx) => {
            durationMask.mask($(`#duration-input-from-${slideGroupIdx}-${slideIdx}`));
            durationMask.mask($(`#duration-input-to-${slideGroupIdx}-${slideIdx}`));
        })
    });
}

export const calculateStylesForBack = (slideWidth: number): SlideStyles => {
    return new SlideStyles({ slideWidth });
}
