import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import {RegistrationService, RegistrationError} from '../services/registration.service';
import { TimelineMax, TweenMax, Back, Power0, Power1, Expo, Power4 } from 'gsap';

import * as iconClose from '../../../../assets/images/newmagic/icon-close.svg';
import * as qs from 'qs';
import {CourseService} from '../services/course.service';
import { runInThisContext } from 'vm';
import { AuthService } from '../services/auth.service';
import { ThreeDotsLoader } from './global/loaders/ThreeDotsLoader';

@withNamespaces('registration')
export default class Registration extends React.Component<any, any> {

    static USERNAME_VALIDATION = false;

    query: any = {};
    registrationChannel: any = {};
    referralCode: string;

    state = {
        redirectToReferrer: false,
        regFormStatus: null,
        error: null,
        errorUserExists: null,
        errorEmailExists: null,
        errorDoNotMatch: null,
        errorValidationUsername: null,
        errorValidationEmail: null,
        errorValidationPassword: null,
        registerAccount: {},
        referrer: null,
        isRegistrationInProgress: false,
    };

    componentWillMount(): void {
        this.query = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
        this.registrationChannel = RegistrationService.instance.retrieveRegistrationChannel() || {};
        this.referralCode = AuthService.instance.getReferralCode(this.query);

        if (this.query.freeCourse) {
            this.setState({referrer: {name: '', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1590789399/App/free-course-reg-avatar.jpg'}});
        } else if (this.referralCode) {
            AuthService.instance.loadUserByReferralCode(this.referralCode).then((user) => {
                const name = user && user.firstName ? user.firstName : 'Your friend';
                const img = user && user.profilePicture ? user.profilePicture : '';
                this.setState({referrer: {name, img}});
            });
        }
    }

    register = (e): void => {
        e.preventDefault();
        this.setState({
            redirectToReferrer: false,
            regFormStatus: null,
            error: null,
            errorUserExists: null,
            errorEmailExists: null,
            errorDoNotMatch: null,
            errorValidationUsername: null,
            errorValidationEmail: null,
            errorValidationPassword: null,
            isRegistrationInProgress: true,
        });
        const login: string = $('#reg-form-login').val() as string;
        let email: string = $('#reg-form-email').val() as string;
        email = email.trim();
        const password: string = $('#reg-form-password').val() as string;
        const crm: boolean = $('#reg-form-crm').is(':checked');
        let emailError = false;
        let usernameError = false;
        let passwordError = false;
        if (!this.isEmailValid(email)) {
            this.setState({
                errorValidationEmail: 'Please enter a valid email.',
                isRegistrationInProgress: false,
            });
            emailError = true;
        }
        if (Registration.USERNAME_VALIDATION && !this.isValidUsername(login)) {
            this.setState({
                errorValidationUsername: 'Please enter a valid username.',
                isRegistrationInProgress: false,
            });
            usernameError = true;
        }
        if (!this.isValidPassword(password)) {
            this.setState({
                errorValidationPassword: 'Enter at least 8 characters.',
                isRegistrationInProgress: false,
            });
            passwordError = true;
        }
        if (!emailError && !usernameError && !passwordError) {
            RegistrationService.instance.reg({
                login,
                password,
                email,
                userSettings: {receiveEmaisls: crm},
                langKey: 'en',
                defaultLearningLanguage: this.getTargetLang()
            }, password)
                .then(() => {
                    this.setState({ error: null, redirectToReferrer: true, isRegistrationInProgress: false, });
                    AuthService.instance.resetReferral();
                })
                .catch((result: {error: RegistrationError, errorDesc: string}) => {
                    if (result.error === RegistrationError.PASSWORDS_DO_NOT_MATCH) {
                        this.setState({
                            errorDoNotMatch: JSON.stringify(result.error),
                            redirectToReferrer: false,
                            isRegistrationInProgress: false,
                        });
                    } else if (result.error === RegistrationError.ERROR_LOGIN_EXISTS) {
                        this.setState({
                            errorUserExists: JSON.stringify(result.error),
                            redirectToReferrer: false,
                            isRegistrationInProgress: false,
                        });
                    } else if (result.error === RegistrationError.ERROR_EMAIL_EXISTS) {
                        this.setState({
                            errorEmailExists: JSON.stringify(result.error),
                            redirectToReferrer: false,
                            isRegistrationInProgress: false,
                        });
                    } else {
                        this.setState({
                            error: JSON.stringify(result.error),
                            redirectToReferrer: false,
                            isRegistrationInProgress: false,
                        });
                    }
                });
        }
    };

    getTargetLang = (): string => {
        return localStorage.getItem('latestCourseLang');
    };

    isEmailValid = (email: string): boolean => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    isValidUsername = (username?: string): boolean => {
        return username && username.trim().length > 2 && username.indexOf('@') < 0;
    };

    isValidPassword = (pass?: string): boolean => {
        return pass && pass.trim().length >= 8;
    };

    onEmailKeyDown = () => {
        this.setState({errorValidationEmail: null});
    };

    onPasswordKeyDown = () => {
        const password: string = $('#reg-form-password').val() as string;
        if (this.isValidPassword(password)) {
            this.setState({errorValidationPassword: null});
        }
    };

    // NB! Expects that component is wrapped with '<div id="auth-register"> ... </div>'
    onShowRegSocialClick = (): void => {
        const authStep = $('.auth-step.auth-registration');
        const showButton = authStep.find('.action-show-form');
        const form = authStep.find('#reg-form');
        const socLinks = authStep.find('.social-links');
        const socButtons = authStep.find('.social-buttons');
        const timeLine = new TimelineMax();
        timeLine
            .to(authStep.find('.inner'), .4, {autoAlpha: 0})
            .to([form, socLinks], 0, {css: {display: 'none'}})
            .to([showButton], 0, {css: {display: 'block'}, autoAlpha: 1})
            .to([socButtons], 0, {css: {display: 'flex'}, autoAlpha: 1})
            .to(authStep.find('.inner'), .4, {autoAlpha: 1});
    };

    // NB! Expects that component is wrapped with '<div id="auth-register"> ... </div>'
    onShowRegFormClick = (): void => {
        const authStep = $('.auth-step.auth-registration');
        const showButton = authStep.find('.action-show-form');
        const form = authStep.find('#reg-form');
        const socLinks = authStep.find('.social-links');
        const socButtons = authStep.find('.social-buttons');
        const timeLine = new TimelineMax();
        timeLine
            .to(authStep.find('.inner'), .4, {autoAlpha: 0})
            .to([showButton, socButtons], 0, {css: {display: 'none'}})
            .to([form, socLinks], 0, {css: {display: 'block'}})
            .to(authStep.find('.inner'), .4, {autoAlpha: 1});
    };

    render(): React.ReactNode {
        const from = this.props.redirectUri || CourseService.instance.getCurrentCourseUrl(true);
        const { error, errorUserExists, errorEmailExists, errorDoNotMatch, errorValidationUsername, errorValidationEmail, errorValidationPassword, regFormStatus, redirectToReferrer, registerAccount } = this.state;

        const registerAccountObj: any = registerAccount || {};
        registerAccountObj.userSettings = registerAccountObj.userSettings || {};

        let referrerName = '';
        let referrerImg = '';

        if (this.state.referrer) {
            referrerName = this.state.referrer.name;
            referrerImg = this.state.referrer.img;
        }

        if (redirectToReferrer) {
            if (this.props.onSuccess) {
                this.props.onSuccess();
            } else {
                return <Redirect to='/dashboard'/>;
            }
        }
        return (
            <div className='auth-registration auth-step'>

                {this.state.referrer ?
                    <>
                    <div className='auth-header popup-header referral-header'>
                        <div className='avatar-wrapper'>
                            <figure className='avatar'>
                                <i>{referrerName[0]}</i>
                                {referrerImg &&
                                <img src={referrerImg}/>
                                }
                            </figure>
                            <svg width='36' height='36' xmlns='http://www.w3.org/2000/svg'><g fill-rule='nonzero' fill='none'><path d='M32 36H4c-1.1 0-2-.94-2-2.09V13h32v20.91c0 1.15-.9 2.09-2 2.09z' fill='#FF1E75'/><path d='M36 14H0V8c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2v6z' fill='#FF6AA4'/><path d='M16 14h4v22h-4V14zM26 0h-4l-6 6h4l6-6z' fill='#E3C300'/><path fill='#FFDB00' d='M14 0h-4l6 6v8h4V6z'/></g></svg>
                        </div>
                        <header>Sign up and learn how to<br/>get your free course</header>
                        <img className='action-close' src={iconClose} />
                    </div>
                    </>
                    :
                    <>
                    <div className='auth-header popup-header'>
                        <header>Create new account</header>
                        <img className='action-close' src={iconClose} />
                    </div>
                    </>
                }

                <div className='inner'>
                    <section className='social-buttons'>
                        <form id='signup-facebook' action='/connect/facebook' method='POST'>
                            <input type='hidden' name='scope' value='email,public_profile' />
                            {(this.props.utm_source || this.query.utm_source || this.registrationChannel.source) &&
                            <input type='hidden' name='utm_source' value={this.props.utm_source || this.query.utm_source || this.registrationChannel.source} />
                            }
                            {(this.props.utm_medium || this.query.utm_medium) &&
                            <input type='hidden' name='utm_medium' value={this.props.utm_medium || this.query.utm_medium || this.registrationChannel.medium} />
                            }
                            {(this.props.utm_campaign || this.query.utm_campaign) &&
                            <input type='hidden' name='utm_campaign' value={this.props.utm_campaign || this.query.utm_campaign || this.registrationChannel.campaign} />
                            }
                            {(this.props.utm_content || this.query.utm_content) &&
                            <input type='hidden' name='utm_content' value={this.props.utm_content || this.query.utm_content || this.registrationChannel.content} />
                            }
                            {(this.props.utm_term || this.query.utm_term) &&
                            <input type='hidden' name='utm_term' value={this.props.utm_term || this.query.utm_term || this.registrationChannel.term} />
                            }
                            {this.query.referral &&
                            <input type='hidden' name='referral' value={this.query.referral} />
                            }
                            {this.getTargetLang() &&
                            <input type='hidden' name='target_lang' value={this.getTargetLang()} />
                            }
                            {from &&
                            <input type='hidden' name='redirect' value={from} />
                            }
                            {registerAccountObj && registerAccountObj.userSettings && registerAccountObj.userSettings.receiveEmails &&
                            <input type='hidden' name='receive_emails' value={registerAccountObj.userSettings.receiveEmails} />
                            }
                        </form>
                        <form id='signup-google' action='/connect/google' method='POST'>
                            <input type='hidden' name='scope' value='profile email' />
                            {(this.props.utm_source || this.query.utm_source) &&
                            <input type='hidden' name='utm_source' value={this.props.utm_source || this.query.utm_source || this.registrationChannel.source} />
                            }
                            {(this.props.utm_medium || this.query.utm_medium) &&
                            <input type='hidden' name='utm_medium' value={this.props.utm_medium || this.query.utm_medium || this.registrationChannel.medium} />
                            }
                            {(this.props.utm_campaign || this.query.utm_campaign) &&
                            <input type='hidden' name='utm_campaign' value={this.props.utm_campaign || this.query.utm_campaign || this.registrationChannel.campaign} />
                            }
                            {(this.props.utm_content || this.query.utm_content) &&
                            <input type='hidden' name='utm_content' value={this.props.utm_content || this.query.utm_content || this.registrationChannel.content} />
                            }
                            {(this.props.utm_term || this.query.utm_term) &&
                            <input type='hidden' name='utm_term' value={this.props.utm_term || this.query.utm_term || this.registrationChannel.term} />
                            }
                            {this.query.referral &&
                            <input type='hidden' name='referral' value={this.referralCode} />
                            }
                            {this.getTargetLang() &&
                            <input type='hidden' name='target_lang' value={this.getTargetLang()} />
                            }
                            {from &&
                            <input type='hidden' name='redirect' value={from} />
                            }
                            {registerAccountObj && registerAccountObj.userSettings && registerAccountObj.userSettings.receiveEmails &&
                            <input type='hidden' name='receive_emails' value={registerAccountObj.userSettings.receiveEmails} />
                            }
                        </form>
                        {/*<button className='facebook' type='submit' form='signup-facebook'>*/}
                        {/*    <span><i>Sign up with</i> Facebook</span>*/}
                        {/*</button>*/}
                        <button className='google' type='submit' form='signup-google'>
                            <span><i>Sign up with</i> Google</span>
                        </button>
                    </section>

                    <div className='social-links' onMouseDown={this.onShowRegSocialClick}>
                        Sign up with <span className='action-facebook'>Facebook</span> or <span className='action-google'>Google</span>
                    </div>
                    <div className='separator'>
                        <i/><span>or sign up with email</span><i/>
                    </div>

                    <button className='action-show-form' onClick={this.onShowRegFormClick}>Sign up with email</button>

                    <form id='reg-form' name='regForm' role='form' onSubmit={this.register} className={regFormStatus}>
                        <div className={'input-row ' + (errorValidationEmail ? 'error' : '')}>
                            <input
                                type='email'
                                id='reg-form-email'
                                name='email'
                                placeholder='Email'
                                value={registerAccountObj.email}
                                autoComplete='email'
                                onKeyDown={this.onEmailKeyDown}
                            />
                            <div className='input-info'>
                                {errorValidationEmail &&
                                <div>{errorValidationEmail}</div>
                                }
                            </div>
                        </div>
                        <div className={'input-row ' + (errorValidationPassword ? 'error' : '')}>
                            <input
                                type='password'
                                id='reg-form-password'
                                name='password'
                                placeholder='Password'
                                value={registerAccountObj.password}
                                autoComplete='new-password'
                                onKeyDown={this.onPasswordKeyDown}
                            />
                            <div className='input-info'>
                                {errorValidationPassword &&
                                <div>{errorValidationPassword}</div>
                                }
                            </div>
                        </div>
                        <div className='input-row legal'>
                            <label>
                                <input id='reg-form-crm' type='checkbox' value={registerAccountObj.userSettings.receiveEmails} />
                                <span>I want to receive news about new features and offers from Magiclingua</span>
                            </label>
                        </div>
                        <br/><br/>
                        <div className='info-row legal'>
                            By signing up I agree to the <a href='/legal/magiclingua/terms.html' target='_blank'>Terms of Service</a> of Magiclingua.
                        </div>

                        <div className='info-row reg-errors'>
                            {error &&
                            <div>
                                <strong>Something went wrong!</strong> Please try again later.
                            </div>
                            }
                            {errorUserExists &&
                            <div>
                                <strong>Login name already registered!</strong> Please choose another one.
                            </div>
                            }
                            {errorEmailExists &&
                            <div>
                                <strong>E-mail is already in use!</strong> Please choose another one.
                            </div>
                            }
                            {errorDoNotMatch &&
                            <div>
                                Your passwords don't match
                            </div>
                            }
                        </div>

                        {this.state.isRegistrationInProgress ? (
                            <ThreeDotsLoader show margin='20px auto 10px auto' />
                        ) : (
                            <div className='input-row submit-row'>
                                <button className='button' type='submit'>
                                    Sign up
                                </button>
                            </div>
                        )}
                    </form>
                    <div className='url-row'>
                        <Link data-step='login' to='/signin'>I have an account</Link>
                    </div>
                </div>
            </div>
        )
    }
}
