import * as React from 'react';
import {UserService} from '../services/user.service';
import {CSSProperties} from 'react';
import {AuthService} from '../services/auth.service';
import {Link} from 'react-router-dom';

const tableStyle = {
    borderCollapse: 'collapse',
    border: '1px solid black',
    padding: '5px'
} as CSSProperties;

export default class Users extends React.Component<any, any> {

    state = {
        users: []
    };

    findByLogin = (): void => {
        this.setState({users: []});
        const login: string = $('#search-value').val() as string;
        UserService.instance.getByLogin(login)
            .then((user) => {
                this.setState({users: [user]});
            })
            .catch((error) => {
                console.log('findByLogin failed ' + error);
            });
    };

    findByEmail = (): void => {
        this.setState({users: []});
        const email: string = $('#search-value').val() as string;
        UserService.instance.getByEmail(email)
            .then((user) => {
                this.setState({users: [user]});
            })
            .catch((error) => {
                console.log('findByEmail failed ' + error);
            });
    };

    findByReference = (): void => {
        this.setState({users: []});
        const reference: string = $('#search-value').val() as string;
        UserService.instance.getByReference(reference)
            .then((user) => {
                this.setState({users: [user]});
            })
            .catch((error) => {
                console.log('findByReference failed ' + error);
            });
    };

    findById = (): void => {
        this.setState({users: []});
        const id: string = $('#search-value').val() as string;
        UserService.instance.getById(id)
            .then((user) => {
                this.setState({users: [user]});
            })
            .catch((error) => {
                console.log('findById failed ' + error);
            });
    };

    findByReferralCode = (): void => {
        this.setState({users: []});
        const referralCode: string = $('#search-value').val() as string;
        UserService.instance.getByReferralCode(referralCode)
            .then((user) => {
                this.setState({users: [user]});
            })
            .catch((error) => {
                console.log('findByReferralCode failed ' + error);
            });
    };

    loginUnderUser = (): void => {
        if (!this.state.users[0]) {
            // no user, no login
            console.log('no loaded user means no logged in user')
            return;
        }

        const reference: string = this.state.users[0].reference;
        AuthService.instance.loginByReference(reference)
            .then(() => {
                this.setState({users: []});

                // redirect to Home
                this.props.history.push('/');
            })
            .catch((error) => {
                console.log('loginUnderUser failed ' + error);
            });
    };

    render(): React.ReactNode {
        const {users} = this.state;
        return (
            <div className='frame admin-page'>
                <div className='action-bar'>
                    <input type='text' id='search-value' name='search-value' placeholder='Search by (email, login, reference)'/>
                    <button onClick={this.findByEmail}>Search by email</button>
                    <button onClick={this.findByLogin}>Search by login</button>
                    <button onClick={this.findByReference}>Search by reference</button>
                    <button onClick={this.findById}>Search by ID</button>
                    <button onClick={this.findByReferralCode}>Search by Referral Code</button>
                    <button onClick={this.loginUnderUser}>Login under user</button>

                    <Link className='action-right button' to='/admin/user-import'>+ Add Users</Link>
                </div>
                <br/>
                <div><span>User details (collection "user")</span></div>
                <br/>
                <table style={tableStyle}>
                    <thead style={tableStyle}>
                    <tr style={tableStyle}>
                        <th style={tableStyle}>ID</th>
                        <th style={tableStyle}>Reference</th>
                        <th style={tableStyle}>Login</th>
                        <th style={tableStyle}>Email</th>
                        <th style={tableStyle}>First Name</th>
                        <th style={tableStyle}>Last Name</th>
                        <th style={tableStyle}>Activated</th>
                        <th style={tableStyle}>Roles</th>
                        <th style={tableStyle}>Created</th>
                        <th style={tableStyle}>Updated</th>
                        <th style={tableStyle}>Product Access</th>
                        <th style={tableStyle}>Tutor Sessions</th>
                        <th style={tableStyle}>Referral Code</th>
                        <th style={tableStyle}>Referred By</th>
                        <th style={tableStyle}>Credit</th>
                        <th style={tableStyle}>Brand</th>
                        <th style={tableStyle}>Default Learning Lang</th>
                        <th style={tableStyle}>LangKey</th>
                        <th style={tableStyle}>Allowed for support courses</th>
                        <th style={tableStyle}>Campaign</th>
                        <th style={tableStyle}>Source</th>
                        <th style={tableStyle}>Medium</th>
                        <th style={tableStyle}>Content</th>
                        <th style={tableStyle}>Term</th>
                        <th style={tableStyle}>Country</th>
                        <th style={tableStyle}>ZipCode</th>
                        <th style={tableStyle}>City</th>
                        <th style={tableStyle}>State</th>
                        <th style={tableStyle}>OpenAddress</th>
                    </tr>
                    </thead>
                    <tbody style={tableStyle}>
                    {users.map((user, i) => <tr key={i}>
                        <td style={tableStyle}>
                            <p>{user.id}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.reference}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.login}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.email}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.firstName}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.lastName}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.activated === true ? 'true' : 'false'}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.authorities.join(',')}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.createdDate}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.lastModifiedDate}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.productAccess}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.tutorSessionsCount}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.referralCode}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.referredBy}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.credit}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.brand}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.defaultLearningLanguage}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.langKey}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.userSettings &&
                            Object.keys(user.userSettings.allowedForSupportCourses).map(key => `${user.userSettings.allowedForSupportCourses[key]}`).join(' & ')}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.registrationChannel && user.registrationChannel.campaign}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.registrationChannel && user.registrationChannel.source}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.registrationChannel && user.registrationChannel.medium}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.registrationChannel && user.registrationChannel.content}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.registrationChannel && user.registrationChannel.term}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.address && user.address.country}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.address && user.address.zipCode}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.address && user.address.city}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.address && user.address.state}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{user.address && user.address.openAddress}</p>
                        </td>
                    </tr>)}
                    </tbody>
                </table>
                <br/>
                <div><span>All product subscriptions of the user (collection "product_subscription")</span></div>
                <br/>
                {users[0] &&
                    <table style={tableStyle}>
                        <thead style={tableStyle}>
                        <tr style={tableStyle}>
                            <th style={tableStyle}>ID</th>
                            <th style={tableStyle}>User ID</th>
                            <th style={tableStyle}>Gateway ID</th>
                            <th style={tableStyle}>Plan ID</th>
                            <th style={tableStyle}>Sync Required</th>
                            <th style={tableStyle}>Expired</th>
                            <th style={tableStyle}>Emulated</th>
                            <th style={tableStyle}>Price</th>
                            <th style={tableStyle}>Currency</th>
                            <th style={tableStyle}>Payment Method</th>
                            <th style={tableStyle}>Created</th>
                            <th style={tableStyle}>Updated</th>
                            <th style={tableStyle}>Start</th>
                            <th style={tableStyle}>End</th>
                            <th style={tableStyle}>Attributes</th>
                            <th style={tableStyle}>Plan Name</th>
                            <th style={tableStyle}>Plan Description</th>
                            <th style={tableStyle}>Plan Price</th>
                            <th style={tableStyle}>Plan Discount Price</th>
                            <th style={tableStyle}>Plan Currency</th>
                            <th style={tableStyle}>Plan Tutor Sessions</th>
                            <th style={tableStyle}>Plan Access Restrictions</th>
                            <th style={tableStyle}>Plan Duration</th>
                            <th style={tableStyle}>Plan Brand</th>
                            <th style={tableStyle}>Plan Sale</th>
                        </tr>
                        </thead>
                        <tbody style={tableStyle}>
                        {users[0].productSubscriptions.map((subscription, i) => <tr key={i}>
                            <td style={tableStyle}>
                                <p>{subscription.id}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.user}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.gatewayId}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.planId}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.syncRequired === true ? 'true' : 'false'}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.endProcessed === true ? 'true' : 'false'}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.emulated === true ? 'true' : 'false'}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.price}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.currencyIsoCode}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.paymentMethodType}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.created}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.updated}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.start}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.end}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{ subscription.attributes && Object.keys(subscription.attributes).map(key => `${key}=${subscription.attributes[key]}`).join(' & ') }</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.name}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.description}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.price}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.discountPrice}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.currencyIsoCode}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.tutorSessionsCount}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>
                                    {subscription.plan.accessRestrictions && 'course count = ' + subscription.plan.accessRestrictions.courseCount +
                                        '; language count = ' + subscription.plan.accessRestrictions.languageCount}
                                </p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.duration && subscription.plan.duration.count + ' ' + subscription.plan.duration.unit}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.brand}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{subscription.plan.sale}</p>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                }
                <br/>
                <div><span>Referral details (collection "referral_event")</span></div>
                <br/>
                {users[0] &&
                    <table style={tableStyle}>
                        <thead style={tableStyle}>
                        <tr style={tableStyle}>
                            <th style={tableStyle}>Referee ID</th>
                            <th style={tableStyle}>Event</th>
                            <th style={tableStyle}>Perk</th>
                            <th style={tableStyle}>Created</th>
                        </tr>
                        </thead>
                        <tbody style={tableStyle}>
                        {users[0].referralEvents.map((event, i) => <tr key={i}>
                            <td style={tableStyle}>
                                <p>{event.refereeId}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.eventType}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.perk}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.createdDate}</p>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                }
                <br/>
                <div><span>All courses of the user (collection "user_course")</span></div>
                <br/>
                {users[0] &&
                    <table style={tableStyle}>
                        <thead style={tableStyle}>
                        <tr style={tableStyle}>
                            <th style={tableStyle}>UserCourse ID</th>
                            <th style={tableStyle}>User ID</th>
                            <th style={tableStyle}>Course ID</th>
                            <th style={tableStyle}>Current Unit</th>
                            <th style={tableStyle}>Version</th>
                            <th style={tableStyle}>UserCourse Archived</th>
                            <th style={tableStyle}>UserCourse Published</th>
                            <th style={tableStyle}>Activation Start</th>
                            <th style={tableStyle}>Activation End</th>
                            <th style={tableStyle}>UserCourse Created</th>
                            <th style={tableStyle}>UserCourse Updated</th>
                            <th style={tableStyle}>Course SeqNumeric</th>
                            <th style={tableStyle}>Course SeqAlpha</th>
                            <th style={tableStyle}>Course Version</th>
                            <th style={tableStyle}>Course Name</th>
                            <th style={tableStyle}>Course Title</th>
                            <th style={tableStyle}>Course Subtitle</th>
                            <th style={tableStyle}>Course Language</th>
                            <th style={tableStyle}>Course Language ISO</th>
                            <th style={tableStyle}>Course Archived</th>
                            <th style={tableStyle}>Course Created</th>
                            <th style={tableStyle}>Course Updated</th>
                        </tr>
                        </thead>
                        <tbody style={tableStyle}>
                        {users[0].courses.map((course, i) => <tr key={i}>
                            <td style={tableStyle}>
                                <p>{course.id}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.user}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.course}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.currentUnit}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.version}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.archived === true ? 'true' : 'false'}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.published === true ? 'true' : 'false'}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.activationStartDate}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.activationEndDate}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.created}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.updated}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.seqNumeric}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.seqAlpha}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.version}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.name}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.title}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.subtitle}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.courseLang}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.courseLangISO}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.archived === true ? 'true' : 'false'}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.created}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{course.fullCourse.updated}</p>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                }
                <br/>
                <div><span>All tutor session events of the user (collection "tutor_session_event")</span></div>
                <br/>
                {users[0] &&
                    <table style={tableStyle}>
                        <thead style={tableStyle}>
                        <tr style={tableStyle}>
                            <th style={tableStyle}>Course</th>
                            <th style={tableStyle}>Course Unit</th>
                            <th style={tableStyle}>Event</th>
                            <th style={tableStyle}>Time</th>
                            <th style={tableStyle}>Type Kind</th>
                            <th style={tableStyle}>Type Name</th>
                            <th style={tableStyle}>Duration</th>
                            <th style={tableStyle}>External Uuid</th>
                            <th style={tableStyle}>StartTime</th>
                            <th style={tableStyle}>End Time</th>
                            <th style={tableStyle}>Invitee Start Time</th>
                            <th style={tableStyle}>Invitee End Time</th>
                            <th style={tableStyle}>Created At</th>
                            <th style={tableStyle}>Location</th>
                            <th style={tableStyle}>Cancelled</th>
                            <th style={tableStyle}>Canceler Name</th>
                            <th style={tableStyle}>Cancel Reason</th>
                            <th style={tableStyle}>Canceled At</th>
                        </tr>
                        </thead>
                        <tbody style={tableStyle}>
                        {users[0].tutorSessionEvents.map((event, i) => <tr key={i}>
                            <td style={tableStyle}>
                                <p>{event.course}</p>
                            </td>
                            <td style={tableStyle}>
                                 <p>{event.courseUnit}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.event}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.time}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.typeKind}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.typeName}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.duration}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.externalUuid}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.startTime}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.endTime}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.inviteeStartTime}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.inviteeEndTime}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.createdAt}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.location}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.cancelled === true ? 'true' : 'false'}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.cancelerName}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.cancelReason}</p>
                            </td>
                            <td style={tableStyle}>
                                <p>{event.canceledAt}</p>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                }
            </div>
        );
    }
}
