import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from './global/Loader';
import * as braintree from 'braintree-web-drop-in';
import { TimelineMax, TimelineLite, Back, Power0, Power1, Expo, Power4 } from 'gsap';
import {PaymentService, GENERAL_PAYMENT_FAILURE, EMAIL_ALREADY_IN_USE, PAYMENT_NOT_INITIALIZED} from '../services/payment.service';
import {TrackingService} from '../services/tracking.service';
import {RegistrationError} from '../services/registration.service';
import {PurchaseDetails} from '../models/purchase.details.model';
import {PurchaseRequest} from '../models/purchase.request.model';
import ReCAPTCHA from 'react-google-recaptcha';

export default class Checkout extends React.Component<any, any> {

    state = {
        btInstance: null,
        paymentInProgress: false,
        paymentRequestable: false,
        error: null,
        errorDetails: null,
        recaptchaValue: null
    };

    tlLoader = null;
    loader: Loader;
    recaptchaRef;

    componentDidMount(): void {
        if (this.props.price > 0) {
            PaymentService.instance.clientToken().then((clientToken) => {

                // initialize payment method on Braintree side for this particular customer and purchase
                braintree.create({
                    authorization: clientToken,
                    container: '#bt-dropin',
                    locale: this.adjustedLocale(),
                    paypal: {
                        flow: 'vault',
                        buttonStyle: {
                            color: 'silver',
                            shape: 'rect',
                            size: 'large'
                        }
                    }
                }, (createErr, instance) => {
                    this.setState({btInstance: instance});

                    instance.on('paymentMethodRequestable', (event) => {
                        this.setState({paymentRequestable: true}, this.props.handleIsFormValid);
                    });

                    instance.on('noPaymentMethodRequestable', (event) => {
                        this.setState({paymentRequestable: false}, this.props.handleIsFormValid);
                    });
                });
            });
        } else {
            this.setState({paymentRequestable: true}, this.props.handleIsFormValid);
        }
    }

    adjustedLocale = (): string => {
        const iso: string = 'en'; // TODO : change to dynamically recognize language
        if (iso === 'de') {
            return 'de_DE';
        } else if (iso === 'en') {
            return 'en_US';
        } else if (iso === 'it') {
            return 'it_IT';
        }  else {
            return iso;
        }
    };

    setPaymentInProgress = (isInProgress: boolean): void => {
        this.setState({paymentInProgress: isInProgress});
        if (this.props.setPaymentInProgress) {
            this.props.setPaymentInProgress(isInProgress);
        }
        //  if (isInProgress) {
        //    this.loader.start();
        //  } else {
        //    this.loader.stop();
        //  }
    };

    onRecaptchaChange = (value): void => {
        this.setState({recaptchaValue: value}, () => {
            this.props.handleIsFormValid();
        });
    };

    getRecaptchaValue = (): any => {
        return this.state.recaptchaValue;
    }

    isValid = (): boolean => {
        return this.state.recaptchaValue !== null && this.state.paymentRequestable;
    }

    processPaymentSuccess = (transactionId: any, paymentMethod: string): void => {
        this.props.onSuccess(transactionId, paymentMethod);
    };

    processPaymentError = (error: any, processorResponseText?: string): void => {
        if (processorResponseText && processorResponseText.length > 0) {
        }
        this.setState({
            error: error || GENERAL_PAYMENT_FAILURE,
            errorDetails: processorResponseText && processorResponseText.length > 0
                ? ' Bank transaction details: ' + processorResponseText
                : null
        });
    };

    pay = (e): void => {
        // In case this component is in a form field
        e.preventDefault();

        const { paymentInProgress } = this.state;
        const { plan, price, disablePayment } = this.props;

        if (paymentInProgress || disablePayment) {
            return;
        }
        this.setPaymentInProgress(true);
        this.setState({error: null});

        const proceed = () => {
            const planId = plan ? plan.id : null;

            if (price > 0) {
                this.payForPositiveAmount(planId);
            } else {
                this.payForZeroAmount(planId);
            }
        };

        // 1. authenticate user by either loading authenticated one or registering a new one
        // 2. execute actual purchase
        if (this.props.preAuth) {
            this.props.preAuth()
                .then(proceed)
                .catch((result: {error: RegistrationError, errorDesc: string}) => {
                    if (result.error === RegistrationError.ERROR_EMAIL_EXISTS) {
                        this.setPaymentInProgress(false);
                        this.processPaymentError(EMAIL_ALREADY_IN_USE);
                    } else {
                        this.setPaymentInProgress(false);
                        this.processPaymentError(GENERAL_PAYMENT_FAILURE);
                    }
                });
        } else {
            proceed();
        }
    };

    // send a request to our API to pay via Braintree because there is some money to pay
    payForPositiveAmount = (planId: string) => {
        const { btInstance } = this.state;
        const { price, course } = this.props;

        // send payment request to API
        if (planId && btInstance) {
            btInstance.requestPaymentMethod((err, payload) => {
                if (err) {
                    this.processPaymentError(PAYMENT_NOT_INITIALIZED);
                    this.setPaymentInProgress(false);
                } else {
                    // nonce identifies this purchase on Braintree side when our API sends a request to their API
                    const request: PurchaseRequest = {
                        planId,
                        price,
                        paymentMethodNonce: payload.nonce,
                        courses: [course],
                        captcha: this.getRecaptchaValue()
                    };

                    PaymentService.instance.pay(request)
                        .then((result: PurchaseDetails) => {
                            if (result && result.success === true) {
                                TrackingService.instance.sendGA('user', 'purchase', '' + planId, price);
                                this.setPaymentInProgress(false);
                                this.processPaymentSuccess(result.transactionId, result.paymentMethod);
                            } else {
                                this.setPaymentInProgress(false);
                                this.processPaymentError(result.failureReason, result.processorResponseText);
                            }
                        })
                        .catch((error) => {
                            this.setPaymentInProgress(false);
                            this.processPaymentError(GENERAL_PAYMENT_FAILURE);
                        })
                }
            });
        } else {
            this.processPaymentError(PAYMENT_NOT_INITIALIZED);
            this.setPaymentInProgress(false);
        }
    }

    // send a request to our API to simulate a payment because there is no purchase amount
    payForZeroAmount = (planId: string) => {
        const { price, course } = this.props;

        // send payment request to API
        if (planId) {
            const request: PurchaseRequest = { planId, price, skipPayment: true, courses: [course] };
            PaymentService.instance.pay(request)
                .then((result: PurchaseDetails) => {
                    if (result && result.success === true) {
                        TrackingService.instance.sendGA('user', 'purchase', '' + planId, price);
                        this.setPaymentInProgress(false);
                        this.processPaymentSuccess(result.transactionId, result.paymentMethod);
                    } else {
                        this.setPaymentInProgress(false);
                        this.processPaymentError(result.failureReason, result.processorResponseText);
                    }
                })
                .catch((error) => {
                    this.setPaymentInProgress(false);
                    this.processPaymentError(GENERAL_PAYMENT_FAILURE);
                })
        } else {
            this.processPaymentError(PAYMENT_NOT_INITIALIZED);
            this.setPaymentInProgress(false);
        }
    }

    render(): React.ReactNode {
        const { error, errorDetails, paymentInProgress, btInstance } = this.state;
        const { price, plan, disablePayment } = this.props;

        const errorRequiresLoginMessage = error && error === EMAIL_ALREADY_IN_USE;

        return (
            <div id='checkout-form'>
                <div className='bt-drop-in-wrapper'>
                    <div id='bt-dropin'/>
                </div>
                <div className={(paymentInProgress ? 'hidden' : '')} >
                    {error &&
                        <p className={'error-text ' + (error ? '' : 'hidden')}>{error}</p>
                    }
                    {errorRequiresLoginMessage &&
                        <p className={'error-text ' + (error ? '' : 'hidden')}>
                            <span>Please </span>
                            <Link to='/signin'>log in</Link>
                            <span> or use another e-mail.</span>
                        </p>
                    }
                    <br/>
                    {errorDetails &&
                        <p className={'error-text ' + (errorDetails ? '' : 'hidden')}>{errorDetails}</p>
                    }
                    {errorDetails && <br/>}
                    {error &&
                        <p className={'error-text ' + (error ? '' : 'hidden')}>Use "CHOOSE ANOTHER WAY TO PAY" link above to provide payment details.</p>
                    }
                    <br/>
                    {error &&
                        <p className={'error-text ' + (error ? '' : 'hidden')}>
                            <span>For support please </span>
                            <a href="mailto:hello@magiclingua.com">contact us</a>
                            <span>.</span>
                        </p>
                    }
                    <br/>

                    {plan &&
                    <div className='order-desc'>
                        <span>You are purchasing "{plan.description}" for </span>
                        <span style={{ fontWeight: 700 }}>{price} {plan.currencyIsoCode}</span>
                    </div>
                    }

                    <div>
                        <br/>
                        <ReCAPTCHA
                            ref={instance => this.recaptchaRef = instance}
                            sitekey='6Lda19MUAAAAAOfExigy3NB-hzjfIhVyH8WldTqx'
                            onChange={this.onRecaptchaChange}
                        />
                    </div>

                    <button
                        className={'button primary ' + (plan && (btInstance || price == 0) ? '' : 'hidden ') + (paymentInProgress ? 'loading' : '')}
                        disabled={disablePayment || paymentInProgress}
                        onClick={this.pay}
                    >
                        <span>Buy your course</span>
                        <div className='loader'/>
                    </button>

                    <div className='small-hint'/>
                </div>
            </div>
        );
    }
}
