import * as React from "react";

import * as moreIcon from "../../../../../../assets/images/newmagic/more-horizontal.svg";
import * as deleteIcon from "../../../../../../assets/images/newmagic/delete.svg";
import * as duplicateIcon from "../../../../../../assets/images/newmagic/duplicate.svg";
import * as arrowLeftIcon from "../../../../../../assets/images/newmagic/arrow-left.svg";
import * as arrowRightIcon from "../../../../../../assets/images/newmagic/arrow-right.svg";
import * as arrowUpIcon from "../../../../../../assets/images/newmagic/arrow-up.svg";
import * as arrowDownIcon from "../../../../../../assets/images/newmagic/arrow-down.svg";

export class EditorDropdown extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            displayMenu: false as Boolean
        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    }

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener("click", this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener("click", this.hideDropdownMenu);
        });
    }

    render() {
        return (
            <div className="editor-dropdown">
                <div onClick={this.showDropdownMenu}>
                    {this.props.title == "more-icon" ? <img src={moreIcon} className="editor-icon" /> : this.props.title}
                </div>

                {this.state.displayMenu ? (
                    <ul>
                        {this.props.duplicate ? (
                            <li onClick={this.props.duplicate}>
                                Duplicate <img src={duplicateIcon} />
                            </li>
                        ) : null}

                        {this.props.delete ? (
                            <li onClick={this.props.delete}>
                                Delete <img src={deleteIcon} />
                            </li>
                        ) : null}

                        {this.props.availability ? (
                            <li className="nested-dd-li">
                                <div className="nested-dd-item-wrapper">
                                    <div onClick={() => this.props.availability.change(true)} className={'nested-dd-item editor-icon sharp' + (this.props.availability.status === true ? ' nested-dd-item-selected' : '')}>
                                        <svg width="24" height="24" stroke="green" fill="none" stroke-width="2">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                            <circle cx="12" cy="12" r="3" />
                                        </svg>
                                    </div>
                                    <div onClick={() => this.props.availability.change(false)} className={'nested-dd-item editor-icon sharp' + (this.props.availability.status === false ? ' nested-dd-item-selected' : '')}>
                                        <svg width="24" height="24" stroke="black" fill="none" stroke-width="2">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                            <circle cx="12" cy="12" r="3" />
                                        </svg>
                                    </div>
                                    <div onClick={() => this.props.availability.change(null)} className={'nested-dd-item editor-icon sharp' + (this.props.availability.status === null ? ' nested-dd-item-selected' : '')}>
                                        <svg width="24" height="24" stroke="red" fill="none" stroke-width="2">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                            <circle cx="12" cy="12" r="3" />
                                        </svg>
                                    </div>
                                </div>
                            </li>
                        ) : null}

                        {this.props.move ? (
                            <li className="nested-dd-li">
                                <div className="nested-dd-item-wrapper">
                                    {this.props.move.up ? (
                                        <div className="nested-dd-item editor-icon" onClick={this.props.move.up}>
                                            <img src={arrowUpIcon} />
                                        </div>
                                    ) : null}
                                    {this.props.move.down ? (
                                        <div className="nested-dd-item editor-icon" onClick={this.props.move.down}>
                                            <img src={arrowDownIcon} />
                                        </div>
                                    ) : null}
                                    {this.props.move.left ? (
                                        <div className="nested-dd-item editor-icon" onClick={this.props.move.left}>
                                            <img src={arrowLeftIcon} />
                                        </div>
                                    ) : null}

                                    {this.props.move.right ? (
                                        <div className="nested-dd-item editor-icon" onClick={this.props.move.right}>
                                            <img src={arrowRightIcon} />
                                        </div>
                                    ) : null}
                                </div>
                            </li>
                        ) : null}
                    </ul>
                ) : null}
            </div>
        );
    }
}
