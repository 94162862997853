/* tslint:disable */
'use strict';

import {AbstractService} from './abstract.service';

export class Base64Service extends AbstractService {

    private static _instance: Base64Service;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    private static KEY_STR = 'ABCDEFGHIJKLMNOP' +
        'QRSTUVWXYZabcdef' +
        'ghijklmnopqrstuv' +
        'wxyz0123456789+/' +
        '=';

    encode(input) {
        let output = '';
        let chr1;
        let chr2;
        let chr3: any = '';
        let enc1;
        let enc2;
        let enc3;
        let enc4: any = '';
        let i = 0;

        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                Base64Service.KEY_STR.charAt(enc1) +
                Base64Service.KEY_STR.charAt(enc2) +
                Base64Service.KEY_STR.charAt(enc3) +
                Base64Service.KEY_STR.charAt(enc4);
            chr1 = chr2 = chr3 = '';
            enc1 = enc2 = enc3 = enc4 = '';
        }

        return output;
    }

    decode(input) {
        let output = '';
        let chr1;
        let chr2;
        let chr3: any = '';
        let enc1;
        let enc2;
        let enc3;
        let enc4: any = '';
        let i = 0;

        // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

        while (i < input.length) {
            enc1 = Base64Service.KEY_STR.indexOf(input.charAt(i++));
            enc2 = Base64Service.KEY_STR.indexOf(input.charAt(i++));
            enc3 = Base64Service.KEY_STR.indexOf(input.charAt(i++));
            enc4 = Base64Service.KEY_STR.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 !== 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 !== 64) {
                output = output + String.fromCharCode(chr3);
            }

            chr1 = chr2 = chr3 = '';
            enc1 = enc2 = enc3 = enc4 = '';
        }
        return output;
    };
}
