import * as React from 'react';
import axios from 'axios/index';
import {Link} from 'react-router-dom';
import * as _ from 'underscore';
import * as Promise from 'promise';
import Popup from './global/popup/Popup';
import {VoucherService} from '../services/voucher.service';
import {CSSProperties} from "react";

const tableStyle = {
    borderCollapse: 'collapse',
    border: '1px solid black',
    padding: '5px'
} as CSSProperties;

export default class AdminVoucherImport extends React.Component<any, any> {

    state = {
        textInput: '',
        campaign: null,
        processing: false,
        createdVouchers: []
    };

    packageMapping = {
        // TODO
    }

    componentDidMount(): void {

    }

    onTextInputChange = (event) => {
        this.setState({
            textInput: event.target.value
        });
    };

    onSubmitImport = (event) => {
        event.preventDefault();

        this.setState({processing: true});

        this.importVouchers(this.state.textInput).then((response) => {
            this.setState({
                createdVouchers: response.createdVouchers,
                processing: false
            });
        }, (response) => {
            this.setState({processing: false});
        });
    };

    parseData = (vouchers) => {
        return vouchers = vouchers.split(/\r\n|\r|\n/);
    };

    importVouchers = (vouchers): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            if (vouchers.length === 0) {
                console.log('no data to import provided');
                return;
            }
            if (this.state.campaign === null || this.state.campaign.length === 0) {
                console.log('no campaign provided');
                return;
            }

            const data = this.parseData(vouchers);
            console.log(data);

            if (data !== null) {
                VoucherService.instance.addVouchersForCampaign(data, this.state.campaign)
                    .then((result) => {
                        this.setState({createdVouchers: result});
                    })
                    .catch((error) => {
                        console.log('error during vouchers import: ' + error)
                    })
            } else {
                reject({'errorMsg': 'Data invalid'});
            }

        });
    };

    changeCampaign = (e): void => {
        const campaign = e.target.value;
        this.setState({campaign});
    };

    render(): React.ReactNode {
        const {createdVouchers} = this.state;

        return (
            <div className='frame admin-page'>
                <header className='page-header'>
                    <h1>Import vouchers</h1>
                </header>
                <div className='input-row'>
                    <div>Campaign</div>
                    <label>
                        <input
                            type='text'
                            placeholder='enter campaign'
                            required={true}
                            onChange={(e) => this.changeCampaign(e)}
                        />
                    </label>
                </div>
                <br/>
                <div id='voucher-import-container'>
                    <div id='import-input'>
                        <form onSubmit={this.onSubmitImport}>
                            <header>Paste your json</header>
                            <div className='input-row'>
                                <textarea
                                    value={this.state.textInput || ''}
                                    onChange={(e) => this.onTextInputChange(e)}
                                    disabled={this.state.processing}
                                />
                            </div>
                            <div className='submit-row alr'>
                                <button className='button primary' disabled={this.state.processing}>Start import</button>
                            </div>
                        </form>
                    </div>
                    <div id='import-output'>
                        <header>Output</header>

                        <table style={tableStyle}>
                            <thead style={tableStyle}>
                            <tr style={tableStyle}>
                                <th style={tableStyle}>ID</th>
                                <th style={tableStyle}>Voucher</th>
                                <th style={tableStyle}>VoucherType</th>
                                <th style={tableStyle}>Used</th>
                                <th style={tableStyle}>Campaign</th>
                                <th style={tableStyle}>Created</th>
                                <th style={tableStyle}>Updated</th>
                            </tr>
                            </thead>
                            <tbody style={tableStyle}>
                            {createdVouchers.map((voucher, i) => <tr key={i}>
                                <td style={tableStyle}>
                                    <p>{voucher.id}</p>
                                </td>
                                <td style={tableStyle}>
                                    <p>{voucher.voucher}</p>
                                </td>
                                <td style={tableStyle}>
                                    <p>{voucher.voucherType}</p>
                                </td>
                                <td style={tableStyle}>
                                    <p>{voucher.used === true ? 'true' : 'false'}</p>
                                </td>
                                <td style={tableStyle}>
                                    <p>{voucher.campaign}</p>
                                </td>
                                <td style={tableStyle}>
                                    <p>{voucher.created}</p>
                                </td>
                                <td style={tableStyle}>
                                    <p>{voucher.updated}</p>
                                </td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
             </div>
        );
    }
}
