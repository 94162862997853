import * as React from 'react';
import { throws } from 'assert';

export default class Bubble extends React.Component<any, any> {

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.updateTrigger !== undefined ? this.props.updateTrigger !== nextProps.updateTrigger : true;
    }

    render(): React.ReactNode {
        return (
            <div className={'info-bubble ' + this.props.className}>
                <div className='inner'>
                    {this.props.children}
                </div>
                <svg className='tail' width='64' height='67' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'><defs><path d='M21.201 43.916c1.404-.569 2.35-.981 2.837-1.238C33.45 37.71 41.024 28.818 46.759 16H20.938c2.268 8.498 1.962 16.31-.918 23.035-.199.464-.57 1.29-1.115 2.478a1.788 1.788 0 0 0 2.296 2.403z' id='b1'/><filter x='-114.2%' y='-99.5%' width='328.4%' height='327.5%' filterUnits='objectBoundingBox' id='a1'><feOffset dy='4' in='SourceAlpha' result='shadowOffsetOuter1'/><feGaussianBlur stdDeviation='10' in='shadowOffsetOuter1' result='shadowBlurOuter1'/><feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' in='shadowBlurOuter1'/></filter></defs><g fill='none' fillRule='evenodd'><use fill='#000' filter='url(#a1)' xlinkHref='#b1'/><use fill='#FFF' xlinkHref='#b1'/></g></svg>
            </div>
        );
    }
}
