import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { LanguageService } from '../../services/language.service';

interface IProps {
    langISO: string;
    changeLanguage: (langISO: string) => void;
}

class CoursesHeader extends React.PureComponent<IProps> {
    render(): React.ReactNode {
        const lang = LanguageService.instance.getByISO(this.props.langISO).name;
        return (
            <div id='courses-header'>
                <div className='col'>
                    <h2>{lang} courses</h2>

                    <p className='courses-header-text'>
                        All our {lang} courses are 100% focused on grammar and speaking skills. Choose your level and start speaking {lang}{' '}
                        today.
                    </p>
                </div>
            </div>
        );
    }
}

export default withRouter(CoursesHeader);
