import {Image} from './image.model';

export class Intent {
    constructor(
        public id?: string,
        public question?: string,
        public image?: Image,
        public imageHint?: string,
        public answers?: string[]
    ) {
        this.answers = [];
    }
}
