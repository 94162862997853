// JavaScript's [Date] class has the months starting from 0
const MONTHS: Map<number, string> = new Map([
    [0, 'January'],
    [1, 'February'],
    [2, 'March'],
    [3, 'April'],
    [4, 'May'],
    [5, 'June'],
    [6, 'July'],
    [7, 'August'],
    [8, 'September'],
    [9, 'October'],
    [10, 'November'],
    [11, 'December'],
]);

export class DateService {

    private static _instance: DateService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    getMonthName(num: number): string {
        return MONTHS.get(num);
    }

    getMonthNameShort(num: number): string {
        return this.getMonthName(num).slice(0, 3);
    }
}
