import * as Promise from 'promise';
import * as _ from 'underscore';
import * as LRUCache from 'lru-cache';
import { Howl } from 'howler';

import axios from 'axios/index';
import {AbstractService} from './abstract.service';
import {UserAgentService} from './user.agent.service';
import {SubmitLearnedAttempt} from '../models/submit.learned.attempt.model';
import {SearchType} from '../models/search.type.enum';
import { ChannelType } from './recognition/stream.channel.factory';

const STT_MODE_KEY = 'STT_MODE_KEY';

export class SpeechService extends AbstractService {

    // Remove these to out of class
    public static readonly STT_MODE_HTTP = 'STT_MODE_HTTP';
    public static readonly STT_MODE_WS = 'STT_MODE_WS';

    private static _instance: SpeechService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    private howls = [];
    private backgrounds = [];
    private cacheHolder: LRUCache = new LRUCache({
        max: 1000
    });

    updateChannel(channelId, submitLearnedAttempt: SubmitLearnedAttempt): Promise<Map<string, any>> {
        const self = this;
        return new Promise<Map<string, any>>((resolve, reject) => {
            axios.post(
                '/api/speech/stt/report/' + channelId,
                submitLearnedAttempt
            )
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    sttSearch(searchType?: SearchType, searchValue?: string): Promise<Map<string, any>[]> {
        const self = this;
        return new Promise<Map<string, any>[]>((resolve, reject) => {
            axios.get(
                '/api/speech/stt/search',
                {
                    params: {
                        size: 200,
                        search: SearchType[searchType],
                        value: searchValue
                    }
                }
            )
            .then((response) => {
                const stt: Map<string, any>[] = response.data as Map<string, any>[];
                if (stt) {
                    resolve(stt);
                } else {
                    reject('No STT received');
                }
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    prepareStringForTTS(str: string) {
        return !str ? null : str.replace(/\[.*\]/g, '').trim();
    }

    requestTTS(request): Promise<Map<string, Map<string, any>>> {
        const self = this;
        return new Promise<Map<string, Map<string, any>>>((resolve, reject) => {
            axios.post(
                '/api/speech/tts',
                request
            )
            .then((response) => {
                const tts: Map<string, Map<string, any>> = response.data as Map<string, Map<string, any>>;
                if (tts) {
                    resolve(tts);
                } else {
                    reject('No TTS received');
                }
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }

    cacheAudio(text: string, lang: string, data) {
        this.cacheHolder.set('' + lang + '_' + text, data);
    }

    cacheTTS(request): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            // we do not need to retrieve sounds, which are already cached.
            const filtered: any = {};
            _.each(_.keys(request), (lang) => {
                const texts = request[lang];
                _.each(texts, (text) => {
                    const cachedData = self.cacheHolder.get('' + lang + '_' + text);
                    if (!cachedData) {
                        filtered[lang] = filtered[lang] || [];
                        filtered[lang].push(text);
                    }
                });
            });
            request = filtered;
            if (_.keys(request).length > 0) {
                self.requestTTS(request).then((response) => {
                    if (response) {
                        _.each(_.keys(request), (lang: string) => {
                            const texts: string[] = request[lang];
                            _.each(texts, (text: string) => {
                                if (response[lang]) {
                                    const data = response[lang][text];
                                    if (data) {
                                        self.cacheAudio(text, lang, data);
                                    }
                                }
                            });
                        });
                        resolve();
                    } else {
                        reject();
                    }
                }, () => {
                    reject();
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
            } else {
                resolve();
            }
        });
    }

    stop() {
        _.each(this.howls, (h) => {
            h.unload();
        });
        _.each(this.backgrounds, (h) => {
            h.unload();
        });
    }

    play(data?, volume?: number, loop?: boolean, background?: boolean): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            if (data && data.trim().length > 0) {
                data = data.trim();
                _.each(this.howls, (h) => {
                    h.unload();
                });
                this.howls = [];
                const howl = new Howl({
                    src: [data],
                    // html5: UserAgentService.instance.isSafari(),
                    // Fix ios doesn't play: ios doesn't support playback sprite anymore
                    // https://github.com/goldfire/howler.js/issues/822
                    // sprite: {
                    //     '__default': [0, (duration-0.0)*1000]
                    // },
                    volume: volume || 1,
                    loop: loop || false,
                    onend: resolve
                });
                if (!background) {
                    this.howls.push(howl);
                } else {
                    this.backgrounds.push(howl);
                }
                howl.play();
            } else {
                // if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
                /* tslint:disable */
                new Howl({src: [0]});
                const unlockIOSAudioPlayback = () => {
                    const context = (<any>window).Howler.ctx;
                    const oscillator = context.createOscillator();
                    oscillator.frequency.value = 200;
                    oscillator.connect(context.destination);
                    oscillator.start(0);
                    oscillator.stop(0);
                    resolve();
                };
                unlockIOSAudioPlayback();
                // }
            }
        });
    }

    getTts(text: string, lang: string): Promise<string | null> {
        const self = this;
        return new Promise<string | null>((resolve, reject) => {
            if (!text || text.trim().length < 1) {
                resolve(null);
            } else {
                text = text.trim();
                lang = lang || 'en-US';
                const cachedData = self.cacheHolder.get('' + lang + '_' + text);
                if (cachedData) {
                    resolve(cachedData);
                } else {
                    const request: any = {};
                    request[lang] = [text];
                    self.requestTTS(request).then((response) => {
                        if (response) {
                            const data = response[lang][text];
                            console.log({ data });
                            if (data) {
                                self.cacheAudio(text, lang, data);
                                resolve(data);
                            } else {
                                resolve(null);
                            }
                        } else {
                            resolve(null);
                        }
                    }, err => {
                        console.error(err);
                        resolve(null);
                    })
                    .catch(() => {
                        resolve(null);
                    });
                }
            }
        });
    }

    tts(text: string, lang: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            if (!text || text.trim().length < 1) {
                resolve();
            } else {
                text = text.trim();
                lang = lang || 'en-US';
                const cachedData = self.cacheHolder.get('' + lang + '_' + text);
                if (cachedData) {
                    self.play(cachedData).then(() => resolve(), () => resolve).catch(() => resolve());
                } else {
                    const request: any = {};
                    request[lang] = [text];
                    self.requestTTS(request).then((response) => {
                        if (response) {
                            const data = response[lang][text];
                            if (data) {
                                self.cacheAudio(text, lang, data);
                                self.play(data).then(() => resolve()).catch(err => {
                                    console.error(err);
                                    resolve()
                                });
                            } else {
                                resolve();
                            }
                        } else {
                            resolve();
                        }
                    }, err => {
                        console.error(err);
                        resolve();
                    })
                    .catch(() => {
                        resolve();
                    });
                }
            }
        });
    }

    /**
     * Sets the stt-mode in ***localStorage***, not in db
     */
    setSttMode(ct: ChannelType) {
        const sttMode: string = sttModeChannelTypeToString(ct);
        localStorage.setItem(STT_MODE_KEY, sttMode);
    }

    /**
     * Returns the stt-mode from ***localStorage***, not from db
     */
    getSttMode(): ChannelType {
        const sttMode: string = localStorage.getItem(STT_MODE_KEY) || SpeechService.STT_MODE_WS;
        const ct: ChannelType = sttModeStringToChannelType(sttMode);
        return ct;
    }

}

// This method exists because localStorage only works with strings
const sttModeChannelTypeToString = (ct: ChannelType) => {
    if (ct === ChannelType.WS) {
        return SpeechService.STT_MODE_WS;
    } else {
        return SpeechService.STT_MODE_HTTP;
    }
}

// This method exists because localStorage only works with strings
const sttModeStringToChannelType = (sttMode: string) => {
    if (sttMode === SpeechService.STT_MODE_WS) {
        return ChannelType.WS;
    } else {
        return ChannelType.HTTP_MANUAL;
    }
}
