'use strict';

import * as JsDiff from 'diff';
import * as _ from 'underscore';
import {AbstractService} from './abstract.service';

export class JsDiffService extends AbstractService {

    private static PATTERN_AMP = /&/g;
    private static PATTERN_LT = /</g;
    private static PATTERN_GT = />/g;
    private static PATTERN_PARA = /\n/g;

    private static _instance: JsDiffService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    diff(text1, text2) {
        return JsDiff.diffWords(text1, text2, {ignoreCase: true});
    }

    // marks both "added" and "removed" parts as "delete" ones, i.e. these parts are wrong
    diffPrettyHtml(diffs: any[], markAdded?: boolean, withTags = true) {
        const html = [];
        _.each(diffs, (diff) => {
            if (diff.value) {
                const text = diff.value
                    .replace(JsDiffService.PATTERN_AMP, '&amp;')
                    .replace(JsDiffService.PATTERN_LT, '&lt;')
                    .replace(JsDiffService.PATTERN_GT, '&gt;')
                    .replace(JsDiffService.PATTERN_PARA, '&para;<br>');
                if (diff.added !== true && diff.removed !== true) {
                    if (withTags) {
                        html.push('<span class="diff-equal">' + text + '</span>');
                    } else {
                        html.push(text);
                    }
                } else if (diff.added === true) {
                    if (markAdded) {
                        if (withTags) {
                            html.push('<span class="diff-delete">' + text + '</span>');
                        } else {
                            html.push(text);
                        }
                    }
                } else if (diff.removed === true) {
                    if (!markAdded) {
                        if (withTags) {
                            html.push('<span class="diff-delete">' + text + '</span>');
                        } else {
                            html.push(text);
                        }
                    }
                }
            }
        });
        return html.join('');
    }
}
