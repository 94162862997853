import * as React from 'react';
import * as uuid from 'uuid';

import * as crossCircleIcon from '../../../../../../assets/images/newmagic/cross-circle.svg';

import { secondsToDuration, durationToSeconds, maskDurationInputs, calculateStylesForBack, isValidCompletely } from './functions';

import { karaokeMacro, dialogMacro } from './functions/macros';

import { Slide, SlideGroup } from '../../../models/slide.model';

interface SlideGroupDuration {
    end: string;
    slideDurations: {
        from: string;
    }[];
}

interface SlideEditorControlsProps {
    slideGroups: SlideGroup[];
    onChange: (slideGroups: SlideGroup[]) => void;
    save: () => void;
    videoPlaybackData: {
        seconds: number;
    };
    onSlideFocus?: any;
}

interface SlideEditorControlsState {
    currFromFirstOld: number;
    slideGroupDurations: SlideGroupDuration[];
    conflicts: {
        slideGroupIdx: number;
        slideIdx?: number;
    }[];
}

export default class SlideEditorControls extends React.Component<SlideEditorControlsProps, SlideEditorControlsState> {
    constructor(props) {
        super(props);

        this.state = {
            currFromFirstOld: null,
            slideGroupDurations: null,
            conflicts: []
        };
    }

    componentWillMount() {
        this.setSlideGroupDurationsBySlideGroups(this.props.slideGroups);
    }

    componentDidMount() {
        maskDurationInputs(this.props.slideGroups);
    }

    componentWillReceiveProps(nextProps: SlideEditorControlsProps) {
        let isNewSlideGroup: boolean = false;
        if (nextProps.slideGroups.length === this.props.slideGroups.length + 1) {
            isNewSlideGroup = true;
        }
        this.setSlideGroupDurationsBySlideGroups(nextProps.slideGroups).then(() => {
            if (isNewSlideGroup) {
                this.scrollToSlideGroup(this.state.slideGroupDurations.length - 1);
            }
        });
    }

    componentDidUpdate() {
        maskDurationInputs([...this.props.slideGroups]);
    }

    scrollToSlideGroup = (slideGroupIdx: number): void => {
        const top = document.getElementById(`slide-group-${slideGroupIdx}`).offsetTop;
        document.getElementById('editor-slide-group-wrapper').scrollTop = top - 10;
    };

    setSlideGroupDurationsBySlideGroups(slideGroups: SlideGroup[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(
                {
                    slideGroupDurations: slideGroups.map(slideGroup => {
                        return {
                            end: secondsToDuration(slideGroup.end),
                            slideDurations: slideGroup.slides.map(slide => {
                                return { from: secondsToDuration(slide.from) };
                            })
                        };
                    })
                },
                () => resolve()
            );
        });
    }

    /**
     * To do change operations easier/cleaner.
     * Returns the idxs of the requested slideGroup and slide
     */
    findIndexes(
        slideGroupId: string,
        slideId?: string
    ): {
        slideGroupIdx: number;
        slideIdx: number;
    } {
        const slideGroupIdx = this.props.slideGroups.findIndex((slideGroup: SlideGroup) => slideGroup.id === slideGroupId);

        let slideIdx: number;
        if (slideId) {
            slideIdx = this.props.slideGroups[slideGroupIdx].slides.findIndex((slide: Slide) => slide.id === slideId);
        } else {
            slideIdx = null;
        }

        return {
            slideGroupIdx,
            slideIdx
        };
    }

    // Checks if there is an incompetence about "start" and "end" values, and records it in the conflicts var to be shown to the user later
    isConflict = (slideGroupIdx: number, slideIdx?: number): boolean => {
        if (slideIdx !== undefined) {
            // If it is a "from" value
            const res: number = this.state.conflicts.findIndex(c => c.slideGroupIdx === slideGroupIdx && c.slideIdx === slideIdx);
            if (res === -1) {
                return false;
            } else {
                return true;
            }
        } else {
            // If it is an "end" value
            const res: number = this.state.conflicts.findIndex(c => c.slideGroupIdx === slideGroupIdx && c.slideIdx === undefined);
            if (res === -1) {
                return false;
            } else {
                return true;
            }
        }
    };

    change = {
        slide: {
            // To allow the user to make changes on the fields, no error check, no effect on parent component
            local: {
                from: (slideGroupIdx: number, slideIdx: number, target: any): void => {
                    const { slideGroupDurations } = this.state;
                    slideGroupDurations[slideGroupIdx].slideDurations[slideIdx].from = target.value;
                    this.setState({ slideGroupDurations });
                }
            },
            // Checks for errors and causes the parent component's data to get updated
            global: {
                from: (slideGroupId: string, slideId: string, target: any): void => {
                    const value: number = durationToSeconds(target.value);
                    const { slideGroupIdx, slideIdx } = this.findIndexes(slideGroupId, slideId);

                    const newSlideGroups: SlideGroup[] = [...this.props.slideGroups];
                    newSlideGroups[slideGroupIdx].slides[slideIdx].from = value;

                    const { isValid, conflicts } = isValidCompletely(newSlideGroups);

                    this.setState({ conflicts });

                    if (isValid) {
                        this.props.onChange(newSlideGroups);
                    } else {
                        this.setSlideGroupDurationsBySlideGroups(this.props.slideGroups);
                    }
                },
                fromFirst: (slideGroupId: string, target: any): void => {
                    const { slideGroupIdx } = this.findIndexes(slideGroupId);

                    const oldValue: number = this.state.currFromFirstOld;
                    const newValue: number = durationToSeconds(target.value);
                    const change: number = newValue - oldValue;

                    const newSlideGroups: SlideGroup[] = [...this.props.slideGroups];
                    const slideGroup = newSlideGroups[slideGroupIdx];

                    slideGroup.slides = slideGroup.slides.map(slide => ({ ...slide, from: slide.from + change }));

                    slideGroup.end += change;

                    const { isValid, conflicts } = isValidCompletely(newSlideGroups);

                    this.setState({ conflicts });

                    if (isValid) {
                        this.props.onChange(newSlideGroups);
                    } else {
                        this.setSlideGroupDurationsBySlideGroups(this.props.slideGroups);
                    }
                },
                // For all slides in a SlideGroup though, but as the width property is still in the Slide class, i didn't name this function as changeSlideGroupWidth
                width: (slideGroupId: string, target: any): void => {
                    const value = calculateStylesForBack(target.value);

                    const { slideGroupIdx } = this.findIndexes(slideGroupId);
                    const newSlideGroups: SlideGroup[] = [...this.props.slideGroups];
                    newSlideGroups[slideGroupIdx].slides = newSlideGroups[slideGroupIdx].slides.map(slide => ({
                        ...slide,
                        styles: value
                    }));

                    this.props.onChange(newSlideGroups);
                },
                content: (slideGroupId: string, slideId: string, target: any): void => {
                    const value = target.value;
                    const { slideGroupIdx, slideIdx } = this.findIndexes(slideGroupId, slideId);

                    const newSlideGroups: SlideGroup[] = [...this.props.slideGroups];
                    newSlideGroups[slideGroupIdx].slides[slideIdx].text = value;

                    this.props.onChange(newSlideGroups);
                }
            }
        },
        slideGroup: {
            // To allow the user to make changes on the fields, no error check, no effect on parent component
            local: {
                end: (slideGroupIdx: number, target: any): void => {
                    const { slideGroupDurations } = this.state;
                    slideGroupDurations[slideGroupIdx].end = target.value;
                    this.setState({ slideGroupDurations });
                }
            },
            // Checks for errors and causes the parent component's data to get updated
            global: {
                end: (slideGroupId: string, target: any): void => {
                    const value: number = durationToSeconds(target.value);

                    const { slideGroupIdx } = this.findIndexes(slideGroupId);

                    const newSlideGroups: SlideGroup[] = [...this.props.slideGroups];
                    newSlideGroups[slideGroupIdx].end = value;

                    const { isValid, conflicts } = isValidCompletely(newSlideGroups);

                    this.setState({ conflicts });

                    if (isValid) {
                        this.props.onChange(newSlideGroups);
                    } else {
                        this.setSlideGroupDurationsBySlideGroups(this.props.slideGroups);
                        // alert('Your input is not valid');
                    }
                }
            }
        }
    };

    deleteSlide(slideGroupId: string, slideId: string): void {
        const indexSlideGroup = this.props.slideGroups.findIndex((slideGroup: SlideGroup) => slideGroup.id === slideGroupId);
        const slideGroup: SlideGroup = this.props.slideGroups[indexSlideGroup];

        slideGroup.slides = slideGroup.slides.filter(slide => slide.id !== slideId);

        // Collect the SlideGroups to a editable object
        let slideGroups: SlideGroup[] = this.props.slideGroups;

        // Delete the SlideGroup if there is o slides inside
        if (slideGroup.slides.length === 0) {
            slideGroups = this.props.slideGroups.filter((slideGroup: SlideGroup) => slideGroup.id !== slideGroupId);
        }

        this.props.onChange(slideGroups);
    }

    macros = {
        karaokeMacro: (slideGroup: SlideGroup, slideGroupIdx: number): void => {
            this.props.slideGroups[slideGroupIdx] = karaokeMacro(slideGroup);
            this.props.onChange(this.props.slideGroups);
        },
        dialogMacro: (slideGroup: SlideGroup, slideGroupIdx: number): void => {
            this.props.slideGroups[slideGroupIdx] = dialogMacro(slideGroup);
            this.props.onChange(this.props.slideGroups);
        }
    };

    // If slideGroupIdx is -1 it means there is no slideGroups yet
    addSlideGroup = (slideGroupIdx: number): void => {
        const secs = Number(this.props.videoPlaybackData.seconds.toFixed(1));      // To make all the sec data with 1 decimal (default for this case is 3 i guess)
        const end = this.props.slideGroups[slideGroupIdx] ? this.props.slideGroups[slideGroupIdx].end : null;
        const nextSlideGroup = this.props.slideGroups[slideGroupIdx + 1];
        const nextFrom: number = nextSlideGroup ? nextSlideGroup.slides[0].from : null;
        const newNextFrom: number = nextFrom;
        const newSlideGroup: SlideGroup = new SlideGroup({
            id: uuid.v4(),
            end:
                slideGroupIdx === -1
                    ? secs + 5
                    : // is last slideGroup?
                    !nextSlideGroup
                    ? // is current?
                      secs >= end
                        ? secs + 5
                        : end + 5
                    : // is current?
                    secs >= end && secs <= nextFrom
                    ? secs + 5 > nextFrom
                        ? nextFrom
                        : secs + 5
                    : end + 5 > nextFrom
                    ? nextFrom
                    : end + 5,
            slides: [
                new Slide({
                    id: uuid.v4(),
                    from:
                        slideGroupIdx === -1
                            ? secs
                            : // is last slideGroup?
                            !nextSlideGroup
                            ? // is current?
                              secs >= end
                                ? secs
                                : end
                            : // is current?
                            secs >= end && secs <= nextFrom
                            ? secs
                            : end,
                    text: '',
                    styles: calculateStylesForBack(50)
                })
            ]
        });
        const newSlideGroups: SlideGroup[] = [...this.props.slideGroups];
        newSlideGroups.splice(slideGroupIdx + 1, 0, newSlideGroup);
        this.props.onChange(newSlideGroups);
    };

    addSlide(slideGroupIdx: number, slideIdx: number): void {
        const slideGroup: SlideGroup = this.props.slideGroups[slideGroupIdx];
        const prevSlide: Slide = slideGroup.slides[slideIdx];

        const newSlide: Slide = new Slide({
            id: uuid.v4(),
            from: prevSlide.from,
            text: '',
            styles: calculateStylesForBack(50)
        });

        this.props.slideGroups[slideGroupIdx].slides.splice(slideIdx + 1, 0, newSlide);
        this.props.onChange(this.props.slideGroups);
    }

    render() {
        return (
            <div className='editor-container col'>
                <div className='editor-slide-group-wrapper' id='editor-slide-group-wrapper'>
                    {this.props.slideGroups.map((slideGroup: SlideGroup, slideGroupIdx: number) => (
                        <div className='editor-slide-group-wrapper-2'>
                            <div className='editor-slide-group-wrapper-3'>
                                <div key={slideGroup.id} className='editor-slide-group' id={`slide-group-${slideGroupIdx}`}>
                                    <div className='editor-slide-wrapper'>
                                        {slideGroup.slides.map((slide: Slide, slideIdx: number) => (
                                            <div key={slide.id} className='editor-slide row'>
                                                <div className='slide-duration col'>
                                                    <input
                                                        id={`duration-input-from-${slideGroupIdx}-${slideIdx}`}
                                                        type='text'
                                                        className='duration'
                                                        style={this.isConflict(slideGroupIdx, slideIdx) ? { borderColor: 'red' } : null}
                                                        name='from'
                                                        value={this.state.slideGroupDurations[slideGroupIdx].slideDurations[slideIdx].from}
                                                        onFocus={
                                                            slideIdx === 0
                                                                ? e =>
                                                                      this.setState({
                                                                          currFromFirstOld: durationToSeconds(e.target.value)
                                                                      })
                                                                : null
                                                        }
                                                        onChange={e => this.change.slide.local.from(slideGroupIdx, slideIdx, e.target)}
                                                        onBlur={e =>
                                                            slideIdx === 0
                                                                ? this.change.slide.global.fromFirst(slideGroup.id, e.target)
                                                                : this.change.slide.global.from(slideGroup.id, slide.id, e.target)
                                                        }
                                                    />
                                                    {!(slideIdx === slideGroup.slides.length - 1) ? null : (
                                                        <input
                                                            id={`duration-input-to-${slideGroupIdx}-${slideIdx}`}
                                                            type='text'
                                                            className='duration'
                                                            style={this.isConflict(slideGroupIdx) ? { borderColor: 'red' } : null}
                                                            name='end'
                                                            value={this.state.slideGroupDurations[slideGroupIdx].end}
                                                            onChange={e => this.change.slideGroup.local.end(slideGroupIdx, e.target)}
                                                            onBlur={e => this.change.slideGroup.global.end(slideGroup.id, e.target)}
                                                        />
                                                    )}
                                                </div>
                                                <div className='slide-text'>
                                                    <textarea
                                                        className='slide-edit-text'
                                                        name='text'
                                                        defaultValue={slide.text}
                                                        onFocus={() => {
                                                            this.props.onSlideFocus(slide.from);
                                                        }}
                                                        onChange={e => {
                                                            this.change.slide.global.content(slideGroup.id, slide.id, e.target);
                                                        }}
                                                    />
                                                </div>
                                                <div className='slide-extra col'>
                                                    <div className='row'>
                                                        <div className='slide-width'>
                                                            <select
                                                                name='styles'
                                                                onChange={e => this.change.slide.global.width(slideGroup.id, e.target)}
                                                            >
                                                                <option value='50' selected={slide.styles.slideWidth == 50 ? true : false}>
                                                                    50%
                                                                </option>
                                                                <option
                                                                    value='100'
                                                                    selected={slide.styles.slideWidth == 100 ? true : false}
                                                                >
                                                                    100%
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div
                                                            className='slide-delete'
                                                            onClick={() => this.deleteSlide(slideGroup.id, slide.id)}
                                                        >
                                                            <img src={crossCircleIcon} />
                                                        </div>
                                                    </div>
                                                    {slideGroup.slides.length !== 1 ? null : (
                                                        <div className='row button-group'>
                                                            <button
                                                                onClick={() => this.macros.karaokeMacro(slideGroup, slideGroupIdx)}
                                                                className='button secondary'
                                                            >
                                                                Kar.
                                                            </button>
                                                            <button
                                                                onClick={() => this.macros.dialogMacro(slideGroup, slideGroupIdx)}
                                                                className='button secondary'
                                                            >
                                                                Dialog
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                <button className='add-slide' onClick={() => this.addSlide(slideGroupIdx, slideIdx)}>
                                                    +
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='add-slide-group-wrapper'>
                                <button className='add-slide-group' onClick={() => this.addSlideGroup(slideGroupIdx)}>
                                    +
                                </button>
                                <div className='add-slide-group-line' />
                            </div>
                        </div>
                    ))}
                </div>
                {this.props.slideGroups.length === 0 ? (
                    <button className='add-slide-group-first-time' onClick={() => this.addSlideGroup(-1)}>
                        Click to add your first Slide Group!
                    </button>
                ) : null}
            </div>
        );
    }
}
