import {AbstractService} from './abstract.service';
import * as _ from 'underscore';
import {MD5} from 'crypto-js';

import * as guideES from '../../../../assets/images/newmagic/course/guide-es.png';
import * as guideFR from '../../../../assets/images/newmagic/course/guide-fr.png';
import * as guideIT from '../../../../assets/images/newmagic/course/guide-it.png';
import * as guideDE from '../../../../assets/images/newmagic/course/guide-de.png';

export class LocalupdateService extends AbstractService {

    private static _instance: LocalupdateService;

    private static testimonials = [
        {
            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538565862/christi_w5abgx.png',
            name: 'Christi Gasperi',
            text: 'I loved it!! The voice recognition worked very well...almost too well as I would correct ' +
            'myself and the voice recognition would pick that up too!! The skype chat was AMAZING!! I don\'t have anyone ' +
            'here to talk to... so it was wonderful to be able to speak to someone who knew what I was saying!!'
        },
        {
            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538565862/gina_i6ykno.png', name: 'Gina Squier',
            text: 'I really like the idea of the follow up session with a tutor. The tutor can check that material has ' +
            'been understood and pronounciation is correct and the learner can practice with a real person. ' +
            'I think this is what\'s missing from a lot of online platforms/mobile apps and sets your course apart from the others.'
        },
        {
            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538565862/default-avatar_sbz7e4.svg',
            name: 'Steve',
            text: 'I liked it a lot!<br/> The voice recognition worked very well (even with my Scottish accent), and the videos explained things clearly.'
        },
        {
            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538565862/default-avatar_sbz7e4.svg',
            name: 'Fernando Troche',
            text: 'I\'ve done tapes, various things like pimsleur, learn as you drive, mango, duolingo.' +
            ' I feel they can be fine for some, they try to be interactive, but there is that crucial actual ' +
            'interaction piece I feel they miss. Like someone to talk to.'
        },
        {
            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538565862/default-avatar_sbz7e4.svg',
            name: 'Paulina Díaz',
            text: 'The whole concept is pretty awesome!<br/> The site is easy to use, and the fact I would be able to speak ' +
            'german without actually embarass myself is incredible'
        },
        {
            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538565862/default-avatar_sbz7e4.svg',
            name: 'Simon',
            text: 'I was really impressed with the speech recognition tool. It picked out the phrases as I said them, ' +
            'even if I started incorrectly or ‘ummed’ and ‘erred’ before giving the correct answer. ' +
            'The whole experience was pretty seamless and I found the exercises challenged me just enough to keep me interested and motivated.'
        },
        {
            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538565862/default-avatar_sbz7e4.svg',
            name: 'Adiever Bagatsolon',
            text: 'I really like the help you guys are offering. You guys are the best!!!!!'
        },
        {
            img: 'https://res.cloudinary.com/magiclingua/image/upload/v1538565862/default-avatar_sbz7e4.svg',
            name: 'Rose Kay',
            text: 'The course was focused in speaking practical German short sentences. Very few online courses offer ' +
            'speaking lessons - so this is unique and very useful.'
        }
    ];

    private static instuctors = {
        'es': {img: guideES, name: 'Laura', signature: 'Yours, Laura'},
        'fr': {img: guideFR, name: 'Mylaina', signature: 'Yours, Mylaina'},
        'it': {img: guideIT, name: 'Emanuela', signature: 'Yours, Emanuela'},
        'de': {img: guideDE, name: 'Anja', signature: 'Deine Anja'},
    };

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public constructor() {
        super();
    }

    authorizeBetaUser() {
        return LocalupdateService.testimonials;
    }

    loadTestimonials() {
        return LocalupdateService.testimonials;
    }

    loadInstuctors() {
        return LocalupdateService.instuctors;
    }
}
