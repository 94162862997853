import * as React from 'react';
import {WebhookService} from '../services/webhook.service';
import {CSSProperties} from "react";

const tableStyle = {
    borderCollapse: 'collapse',
    border: '1px solid black',
    padding: '5px'
} as CSSProperties;

export default class Webhooks extends React.Component<any, any> {

    state = {
        webhooks: []
    };

    componentWillMount(): void {
        WebhookService.instance.getAll()
            .then((webhooks) => {
                this.setState({webhooks: webhooks});
            })
            .catch((error) => {
                console.log('getAll failed ' + error);
            });
    }

    render(): React.ReactNode {
        const {webhooks} = this.state;

        return (
            <div style={{marginTop: '100px'}}>
                <div><span>Webhooks details (collection "webhook"): { webhooks.length }</span></div>
                <br/>
                <table style={tableStyle}>
                    <thead style={tableStyle}>
                    <tr style={tableStyle}>
                        <th style={tableStyle}>ID</th>
                        <th style={tableStyle}>WebhookType</th>
                        <th style={tableStyle}>ExternalId</th>
                        <th style={tableStyle}>Url</th>
                        <th style={tableStyle}>ExternalCreatedAt</th>
                        <th style={tableStyle}>Active</th>
                        <th style={tableStyle}>Events</th>
                    </tr>
                    </thead>
                    <tbody style={tableStyle}>
                    {webhooks.map((webhook, i) => <tr key={i}>
                        <td style={tableStyle}>
                            <p>{webhook.id}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{webhook.type}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{webhook.externalId}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{webhook.url}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{webhook.externalCreatedAt}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{webhook.active === true ? 'true' : 'false'}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{Array.from(webhook.events).join(' ')}</p>
                        </td>
                    </tr>)
                    }
                    </tbody>
                </table>
                <br/>
            </div>
        );
    }
}
