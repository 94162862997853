'use strict';

import {AbstractService} from './abstract.service';
import {User} from '../models/user.model';

export enum Brand {
    CAMINO = 'camino',
    MAGICLINGUA = 'magiclingua',
}

export class BrandingService extends AbstractService {

    private static _instance: BrandingService;

    private brand: Brand;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public getBrand(): Brand {
        this.brand = Brand.CAMINO;
        return this.brand;
    }

    public chooseComponent<T>(components: Map<Brand, T>, defaultComponent?: T): T {
        let result: T = defaultComponent;
        if (components && components.has(this.getBrand())) {
            result = components.get(this.getBrand());
        }
        return result;
    }

    public isCaminoBrand() {
        return this.getBrand() === Brand.CAMINO;
    }

    public isCaminoBrandByUser(user: User) {
        return user.brand.toLowerCase() === Brand.CAMINO;
    }
}
