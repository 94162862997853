import * as React from "react";
import { slideContentParser } from "./functions";
import { SlideStylesSlide } from "./config/slideStyles.model";

export const SlideContainer = ({
    slideVisible,
    text,
    style,
    editorMode
}: {
    slideVisible: boolean,
    text: any,
    style: SlideStylesSlide,
    editorMode: boolean
}) => {
    return (
        <div className={'slide-container' + (slideVisible ? ' slide' : '')} style={style}>
            <div className={'slide-contents' + (style.width === '100%' ? ' is-slide-full-width' : '')}>
                {editorMode ? slideContentParser(text, style) : text}
            </div>
        </div>
    );
};
