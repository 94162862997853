import {StreamChannel} from './stream.channel';
import {RecognitionStatus} from './recognition.status';
import {RecognitionResultsFormat} from './recognition.results.format';
import {WsStreamChannel} from './ws.stream.channel';
import {HttpStreamChannel} from './http.stream.channel';

export enum ChannelType {
    WS = 'ws',
    HTTP_AUTO = 'http_auto',
    HTTP_MANUAL = 'http_manual'
}

export class StreamChannelFactory {

    private static _instance: StreamChannelFactory;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public createChannel(
        langISO: string,
        speechContext: Array<string>,
        recognitionProcessor: (results: RecognitionResultsFormat) => void,
        statusProcessor: (status: RecognitionStatus) => void,
        channelType?: ChannelType
    ): StreamChannel {
        if (!channelType) {
            channelType = ChannelType.WS;
        }
        if (channelType === ChannelType.WS) {
            return new WsStreamChannel(
                langISO,
                speechContext,
                recognitionProcessor,
                statusProcessor
            );
        } else {
            return new HttpStreamChannel(
                langISO,
                speechContext,
                recognitionProcessor,
                statusProcessor,
                channelType === ChannelType.HTTP_AUTO
            );
        }
    }
}
