import * as Promise from 'promise';
import axios from 'axios/index';
import {AbstractService} from './abstract.service';

export class WebhookService extends AbstractService {

    private static _instance: WebhookService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    getAll(): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/webhooks/'
            )
                .then((response) => {
                    const webhooks = response.data;
                    if (webhooks) {
                        resolve(webhooks);
                    } else {
                        reject('No webhooks found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

}
