import * as React from 'react';
import { numberToPixel } from '../UtilityFunctions/UtilityFunctions';

const SIZE_SMALL = 4;
const SIZE_MEDIUM = 8;
const SIZE_LARGE = 16;
const SIZE_VERY_LARGE = 32;

interface IProps {
    // Default is LARGE -> 16px currently
    size?: string;
    // Whether the element should be displayed when initted or not
    show?: boolean;
    margin?: string;
}


/**
 * A loader with 3 horizontal dots which are going up and down
 */
export class ThreeDotsLoader extends React.PureComponent<IProps, any> {
    public static readonly SMALL = 'SMALL';
    public static readonly MEDIUM = 'MEDIUM';
    public static readonly LARGE = 'LARGE';
    public static readonly VERY_LARGE = 'VERY_LARGE';


    el: HTMLDivElement;

    // this property will be used to calculate some width and height properties of the loader's elements
    atomicSize: number;

    constructor(props: IProps) {
        super(props);
        this.decideAtomicSize(this.props.size);
    }

    // Decide the atomicSize using the sizeType given when the component is called
    decideAtomicSize = (sizeType: string) => {
        switch (sizeType) {
            case ThreeDotsLoader.SMALL:
                this.atomicSize = SIZE_SMALL;
                break;
            case ThreeDotsLoader.MEDIUM:
                this.atomicSize = SIZE_MEDIUM;
                break;
            case ThreeDotsLoader.LARGE:
                this.atomicSize = SIZE_LARGE;
                break;
            case ThreeDotsLoader.VERY_LARGE:
                this.atomicSize = SIZE_VERY_LARGE;
                break;
            default:
                this.atomicSize = SIZE_LARGE;
                break;
        }
    };

    // Calculates a px value in strings based on the atomicSize property
    // This method makes it easier to read the html code and see the size factors
    calcSize = (factor: number) => {
        return numberToPixel(this.atomicSize * factor);
    };

    show = () => {
        this.el.style.setProperty('display', 'flex');
        // this.startCancelButtonTimer();
        // $('#cancel-button').on('mousedown', (e) => {e.stopPropagation()});
    };

    hide = () => {
        this.el.style.setProperty('display', 'none');
        // clearTimeout(this.timer);
    };

    // timer;

    /*startCancelButtonTimer = () => {
        this.timer = setTimeout(() => {
            this.showCancelButton();
        }, 7000);
    }

    showCancelButton = () => {
        document.getElementById('cancel-button').style.display = 'block'
    }

    hideCancelButton = () => {
        document.getElementById('cancel-button').style.display = 'none'
    }

    onCancel = (e) => {
        e.stopPropagation();
        this.hideCancelButton();
        this.props.onCancel();
    }*/

    render(): React.ReactNode {
        return (
                <div
                    className='loader-three-dots-up-down'
                    ref={c => (this.el = c)}
                    style={{ 
                        width: this.calcSize(5),
                        height: this.calcSize(2),
                        display: this.props.show ? 'flex' : 'none',
                        margin: this.props.margin ? this.props.margin : 'default'
                    }}
                >
                    <div className='loader-three-dots-part-1' style={{ width: this.calcSize(1), height: this.calcSize(1) }}></div>
                    <div className='loader-three-dots-part-2' style={{ width: this.calcSize(1), height: this.calcSize(1) }}></div>
                    <div className='loader-three-dots-part-3' style={{ width: this.calcSize(1), height: this.calcSize(1) }}></div>
                </div>
        );
    }
}
