import * as React from 'react';
import {PaymentService} from '../services/payment.service';
import {CSSProperties} from 'react';

const tableStyle = {
    borderCollapse: 'collapse',
    border: '1px solid black',
    padding: '5px'
} as CSSProperties;

export default class LearningPlans extends React.Component<any, any> {

    state = {
        learningPlans: []
    };

    componentWillMount(): void {
        PaymentService.instance.plans()
            .then((learningPlans) => {
                this.setState({learningPlans});
            })
            .catch((error) => {
                console.log('error while loading plans: ' + error)
            });
    }

    render(): React.ReactNode {
        const {learningPlans} = this.state;

        return (
            <div className='frame admin-page'>
                <div><span>Learning plans details (collection "learning_plans"): { learningPlans.length }</span></div>
                <br/>
                <table style={tableStyle}>
                    <thead style={tableStyle}>
                    <tr style={tableStyle}>
                        <th style={tableStyle}>ID</th>
                        <th style={tableStyle}>Name</th>
                        <th style={tableStyle}>Description</th>
                        <th style={tableStyle}>Price</th>
                        <th style={tableStyle}>Discount Price</th>
                        <th style={tableStyle}>Currency</th>
                        <th style={tableStyle}>Tutor Sessions</th>
                        <th style={tableStyle}>Access Restrictions</th>
                        <th style={tableStyle}>Duration</th>
                        <th style={tableStyle}>Brand</th>
                        <th style={tableStyle}>Sale</th>
                    </tr>
                    </thead>
                    <tbody style={tableStyle}>
                    {learningPlans.map((learningPlan, i) => <tr key={i}>
                        <td style={tableStyle}>
                            <p>{learningPlan.id}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.name}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.description}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.price}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.discountPrice}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.currencyIsoCode}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.tutorSessionsCount}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>
                                {learningPlan.accessRestrictions && 'course count = ' + learningPlan.accessRestrictions.courseCount +
                                    '; language count = ' + learningPlan.accessRestrictions.languageCount}
                            </p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.duration && learningPlan.duration.count + ' ' + learningPlan.duration.unit}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.brand}</p>
                        </td>
                        <td style={tableStyle}>
                            <p>{learningPlan.sale}</p>
                        </td>
                    </tr>)
                    }
                    </tbody>
                </table>
                <br/>
            </div>
        );
    }
}
