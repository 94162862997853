import * as React from 'react';
import * as qs from 'qs';
import { withNamespaces } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import {AuthService} from "../services/auth.service";

import * as iconClose from '../../../../assets/images/newmagic/icon-close.svg';

@withNamespaces('reset')
export default class ResetPasswordFinish extends React.Component<any, any> {

    state = {
        redirectToReferrer: false,
        key: null,
        keyMissing: null,
        doNotMatch: null,
        error: null,
        success: null,
        errorValidationNewPassword: null,
        errorValidationConfirmPassword: null
    };

    componentWillMount(): void {
        this.setState({key: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).key});
    }

    finishReset = (e): void => {
        e.preventDefault();
        const pass: string = $('#reset-form-password').val() as string;
        const passConfirmed: string = $('#reset-form-confirmpassword').val() as string;
        const key = this.state.key;
        this.setState(
            {
                redirectToReferrer: false,
                keyMissing: null,
                doNotMatch: null,
                error: null,
                success: null,
                errorValidationNewPassword: null,
                errorValidationConfirmPassword: null
            }
        );
        if (key) {
            if (pass && passConfirmed && pass === passConfirmed) {
                AuthService.instance.resetPasswordFinish({
                    key: key,
                    newPassword: pass
                })
                    .then(() => {
                        this.setState({ success: 'OK' });
                    })
                    .catch((error) => {
                        this.setState({ error: JSON.stringify(error) })
                    });
            } else {
                this.setState({doNotMatch: true});
            }
        } else {
            this.setState({keyMissing: true});
        }
    };

    render(): React.ReactNode {
        const from = '/';
        const { keyMissing, doNotMatch, error, success, errorValidationNewPassword, errorValidationConfirmPassword, redirectToReferrer } = this.state;
        const { t, i18n } = this.props;
        if (redirectToReferrer) {
            return (
                <Redirect to={from}/>
            )
        }
        return (
            <div className="auth-password auth-step">
                <div className="auth-header popup-header">
                    <header>Set new password</header>
                    <img className="action-close" src={iconClose} />
                </div>
                <div className="inner">
                    <form name="resertForm" role="form" onSubmit={this.finishReset} className={(success === 'OK' ? 'success' : '') + ' ' + (!!keyMissing ? 'error' : '')} autoComplete="off" noValidate>
                        <div className={'input-row ' + (!!errorValidationNewPassword ? 'error' : '')}>
                            <input type="password"
                                   id="reset-form-password"
                                   name="resetPassword"
                                   placeholder="New password"
                                   required />
                            <div className="input-info">
                                {errorValidationNewPassword &&
                                <div>{errorValidationNewPassword}</div>
                                }
                            </div>
                        </div>

                        <div className={'input-row ' + (!!errorValidationConfirmPassword ? 'error' : '')}>
                            <input type="password"
                                   id="reset-form-confirmpassword"
                                   name="resetConfirmPassword"
                                   placeholder="Confirm password"
                                   required />
                            <div className="input-info">
                                {errorValidationConfirmPassword &&
                                <div>{errorValidationConfirmPassword}</div>
                                }
                            </div>
                        </div>

                        <div className="input-row">
                            <div className="info-row">
                                {error &&
                                <div>
                                    <span>
                                        Your password couldn't be reset since the reset link is too old. <Link className="alert-link" to="/reset/password/init">Request</Link> a new one.
                                    </span>
                                </div>
                                }
                                {keyMissing &&
                                <div>
                                    <span>The reset key is missing.</span>
                                </div>
                                }
                                {doNotMatch &&
                                <div>
                                    <span>Passwords do not match.</span>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="input-row submit-row">
                            <button className="button" type="submit">Save new password</button>
                        </div>
                        <div id="success">
                            <div>New password saved!</div>
                            <div>
                                <p>Now you can <Link to="/signin">Sign in</Link>.</p>
                            </div>
                            <figure>
                                <svg viewBox="0 0 19 13">
                                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
                                       strokeLinecap="round" strokeLinejoin="round">
                                        <g className="check" transform="translate(-536.000000, -259.000000)" stroke="#fff"
                                           strokeWidth="3">
                                            <g id="Welcome" transform="translate(0.000000, 58.000000)">
                                                <path
                                                    d="M549,198 C549,198 548.086348,205.264289 549,212 C545.5,211.313296 542,212 542,212"
                                                    id="Rectangle-299"
                                                    transform="translate(545.500000, 205.000000) rotate(-315.000000) translate(-545.500000, -205.000000) "/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </figure>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
