import * as React from 'react';
import CourseUnitLayout from './course/CourseUnitLayout';
import {Redirect} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import {Expo} from 'gsap';
import {TimelineMax} from 'gsap/TimelineMax';
import Popup from './global/popup/Popup';
import {UserAgentService} from '../services/user.agent.service';
import {BrandingService, Brand} from '../services/branding.service';

import {AuthService} from '../services/auth.service';
import AuthPopup from './global/AuthPopup';
import PremiumPopup from './PremiumPopup';
import CountDown from './global/CountDown';
import MainFooter from './global/MainFooter';
import * as lity from 'lity';
import * as _ from 'underscore';
import * as qs from 'qs';

import * as iconPlay from '../../../../assets/images/newmagic/icon-play.svg';

import * as planImg2 from '../../../../assets/images/newmagic/payment/plan-advanced.png';
import * as planImg3 from '../../../../assets/images/newmagic/payment/plan-pro.png';

import * as appBannerImg from '../../../../assets/images/newmagic/app-banner-popup.png';

import * as appstoreBadge from '../../../../assets/images/newmagic/appstores/appstore.png';
import * as playstoreBadge from '../../../../assets/images/newmagic/appstores/playstore.png';
import * as appRating from '../../../../assets/images/newmagic/appstores/rating.png';

import {LanguageService} from '../services/language.service';
import {TrackingService} from '../services/tracking.service';
import {SpeechService} from '../services/speech.service';
import {CourseService} from '../services/course.service';
import {CourseUnit, UnitExercise} from '../models/course.model';
import {LocalupdateService} from '../services/localupdate.service';
import { LINK_GOOGLE_PLAY_STORE, LINK_APPLE_APP_STORE } from '../constants/MobileAppLinks';
import { CoursePlanCard } from './course/CoursePlanCard';

export default class Course extends React.Component<any, any> {

    static AUTH_REQUIRED = false;

    state = {
        courseDescClass: 'closed',
        leadEmail: null,
        course: null,
        offerActive: null,
        firstStartableExrIndex: 0,
        userIsPermittedToCourse: false,
        calendlyScheduleIframe: null,
        schedulingInfo: {
            unitId: null,
            seqAlpha: null,
            fetchingUserCourse: false
        } as {
            unitId: string
            seqAlpha: string,
            fetchingUserCourse: boolean
        }
    };

    instructors = LocalupdateService.instance.loadInstuctors();
    testimonials = LocalupdateService.instance.loadTestimonials()

    langMapping = {
        'en': {
            'de': 'German',
            'es': 'Spanish',
            'fr': 'French',
            'it': 'Italian'
        }
    };
    enabledLanguages = ['de', 'es'];
    startExrAfterAuth = null;
    deadline: Date;
    courseParam = null;
    languageParam = null;
    demoParam = null;
    languageIso = null;
    authPopup: AuthPopup = null;
    deviceIncompatiblePopup: Popup = null;
    sessionPopup: Popup = null;
    emailPopup: Popup = null;
    leadSubmitedPopup: Popup = null;
    premiumPopup = null;
    tutorPackagePopup = null;
    isMenuSticky;
    highlightTrial = true;

    componentWillMount(): void {
        this.languageParam = this.props.match.params.lang;
        this.languageIso = LanguageService.instance.isoByName(this.languageParam);
        this.courseParam = this.props.match.params.id;
        const searchObj = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        this.demoParam = searchObj.demo;

        this.highlightTrial = !AuthService.instance.isAuthenticated() || CourseService.instance.isCourseBeta(this.courseParam);
    }

    componentDidMount(): void {
        this.updateCourseData().then(() => {
            this.activateStickyHeader();

            const loc = window.location.href.split('#');
            if (loc[1] && loc[1] === 'pricing') {
                $('#pricing')[0].scrollIntoView();
            }
        }, null);
        setTimeout(() => {
            this.initPricingSection();
        }, 1000);

        window.addEventListener('focus', this.windowOnFocusListener);
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.windowOnFocusListener);
    }

    windowOnFocusListener = (): void => {
        this.updateCourseData();
    }

    initPricingSection = () => {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.2.0/js/swiper.min.js';
        script.async = true;
        document.body.appendChild(script);

        const link = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.2.0/css/swiper.min.css';
        link.media = 'all';
        document.body.appendChild(link);

        const w: any = window;
        script.onload = () => {
            new w.Swiper ('.testemonial-swiper', {
                pagination: {
                    el: '.swiper-pagination'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            });
        };
    }

    activateStickyHeader = () => {
        this.isMenuSticky = false;
        const windowHeight = $(window).height() - 100;
        const stickyHeader = $('#sticky-header');
        const pricingTop = $('#pricing') != null
            ? $('#pricing').offset() != null
                ? $('#pricing').offset().top
                : null
            : null;
        window.scrollTo(0, 0);

        $(window).scroll(() => {
            const scrollTop = $(window).scrollTop();

            if (!this.isMenuSticky && scrollTop >= windowHeight) {
                stickyHeader.addClass('show');
                this.isMenuSticky = true;
            }
            if (this.isMenuSticky && (
                scrollTop < windowHeight
                || (pricingTop == null
                    ? false
                    : scrollTop >= (pricingTop - 100))
                )) {
                stickyHeader.removeClass('show');
                this.isMenuSticky = false;
            }
        });
    }

    updateCourseData = () => {
        return new Promise<Course>((resolve, reject) => {
            CourseService.instance.loadCourse(this.courseParam).then(course => {
                this.setState({
                    course,
                    firstStartableExrIndex: CourseService.instance.getFirstStartableExercise(course),
                    userIsPermittedToCourse: AuthService.instance.isUserPermittedForThisCourse(course.name),
                });

                resolve();
            });
        });
    }

    calendlyScheduleIframeBuilder = (courseUnit: CourseUnit): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const userData: {firstName: string, lastName: string, email: string} = AuthService.instance.getDataRequiredByCalendlyIframe();
            userData === undefined ? reject() : null;

            const firstName = userData.firstName ? userData.firstName : '';
            const lastName = userData.lastName ? userData.lastName : '';

            // there are three Calendly accounts:
            // 1. Magiclingua: calendly.com/magiclingua-tutoring  - for all DE sessions
            // 2. Spanish Tutoring: calendly.com/spanish-tutoring - for all camino and our own ES sessions
            // 3. Inhispania: https://calendly.com/inhispania/60min - for Inhispania brand

            const eventAccountLink = 'https://calendly.com/spanish-tutoring/30min';

            const course = `${this.state.course.title} | ${this.state.course.subtitle}`;
            const unit = courseUnit.title;
            const unitId = courseUnit.id;

            // Even if user opens the same tutor session scheduling, re-create the iframe (see popup's code too)
            this.setState({calendlyScheduleIframe: null}, () => {
                this.setState({
                    calendlyScheduleIframe: `${eventAccountLink}?email=${userData.email}&name=${firstName}%20${lastName}&a1=${course}&a2=${unit}&utm_campaign=${userData.email}&utm_source=${this.courseParam}&utm_medium=${unitId}`
                }, () => {
                    resolve();
                });
            });
        });
    }

    schedulingPopupClosed = (): void => {
        const onFetchComplete = (): void => {
            this.setState(prevState => (
                {
                    schedulingInfo: {
                        ...prevState.schedulingInfo,
                        fetchingUserCourse: false
                    }
            }));
        }
        this.setState(prevState => ({schedulingInfo: {...prevState.schedulingInfo, fetchingUserCourse: true}}), () => {
            setTimeout(() => {
                this.updateCourseData().then(() => {onFetchComplete()}, null);
            }, 6000);
        });
    }

    scheduleSession = (courseUnit: CourseUnit, event?: any): void => {
        if (AuthService.instance.doesUserHaveTutorSessions()) {
            // Set the active scheduling seqAlpha and unitId to show a loading state on that
            const activeSchedulingSeqAlpha: string = (this.state.course.units as CourseUnit[]).find(unit => unit.id === courseUnit.id).exercises.find(lesson => lesson.type === 'tutor').seqAlpha
            this.setState(prevState => ({schedulingInfo: {...prevState.schedulingInfo, seqAlpha: activeSchedulingSeqAlpha, unitId: courseUnit.id}}), () => {
                // Build the iframe link and open the popup
                this.calendlyScheduleIframeBuilder(courseUnit).then(() => {
                    this.sessionPopup.open();
                }, null);
            })
        } else {
            this.tutorPackagePopup.open();
            // this.showTooltip(event, 'You don\'t have any available tutor sessions');
        }
    };

    getCourseLink = (path?: string): string => {
        return this.courseParam ? '/' + this.languageParam + '/course/' + this.courseParam + (path ? path : '') : null ;
    };

    onOfferEnd = (): void => {
        $('#offer-countdown').remove();
    };

    startCountdown = (): void => {
        if (localStorage.getItem('reserveDeadline')) {
            this.deadline = new Date(Date.parse(localStorage.getItem('reserveDeadline')));
        } else {
            this.deadline = new Date(new Date().getTime() + (24 * 60 * 60 * 1000));
            localStorage.setItem('reserveDeadline', this.deadline.toString());
        }
        if (this.deadline.getTime() > new Date().getTime()) {
            this.setState({offerActive: true});
        } else {
            this.deadline = new Date();
        }
    };

    playCourseIntro = (): void => {
        SpeechService.instance.play();
        if (this.state.course.courseIntro) {
            const lightbox = lity(this.state.course.courseIntro);
        }
    };

    startExercise = (exr: UnitExercise, event?: any): void => {
        const self = this;

        SpeechService.instance.play();

        if (UserAgentService.instance.isMobile()) {
            // if it is mobile, show popup suggesting going to the store
            this.deviceIncompatiblePopup.open();
            return;
        } else {
            // if ((!AuthService.instance.isAuthenticated() || AuthService.instance.isAnonymous()) && Course.AUTH_REQUIRED) {
            //    this.startExrAfterAuth = exr;
            //    this.authPopup.open();
            //    return;
            // }

            const _executeStartExercise = () => {
                const screenBlender = $('.screen-blender');
                const body = $('body');
                const tl = new TimelineMax();
                tl
                    .set(screenBlender, {css: {display: 'block', opacity: 0}})
                    .set(body, {css: {overflow: 'hidden'}})
                    .to(body, .6, {scale: 1.02, ease: Expo.easeOut}, 'hide')
                    .to(screenBlender, .6, {opacity: 1, ease: Expo.easeOut}, 'hide')
                    .set(body, {clearProps: 'all'})
                    .call(() => {
                        this.props.history.push(`/${this.state.course.courseLang}/learning/` + exr.seqAlpha + '?course=' + this.courseParam);
                    }, [])
                ;
            }

            const _onPermissionRequired = () => {
                if (_.contains(this.enabledLanguages, this.languageIso)) {
                    this.premiumPopup.open();
                } else {
                    // this.emailPopup.open();
                }
            }

            const _onNotAvailable = (event: any) => {
                this.showTooltip(event, 'This exercise is not available yet.<br/> We\'ll activate it soon. Stay tuned!');
            }

            CourseService.instance.checkExerciseStartable(exr, this.state.course, {
                'onStartable': () => {_executeStartExercise()},
                'onNotAvailable': () => {_onNotAvailable(event)},
                'onPremiumRequired': () => {_onPermissionRequired()}
            });
        }
    };

    showTooltip = (event, text) => {
        if (event.currentTarget) {
            const elem = $(event.currentTarget);
            const rect = elem.offset();

            $('#page-wrapper .tooltip').remove();

            const tooltip = $(`<div class="tooltip alc arrow-bottom">${text}</div>`).appendTo('#page-wrapper').css({opacity: 0});

            const tlOpen = new TimelineMax();
            tlOpen
                .set(tooltip, {autoAlpha: 0, yPercent: -80})
                .set(tooltip, {css: {top: rect.top, left: rect.left - (tooltip.outerWidth() - elem.outerWidth()) / 2 }}, '+=.1')
                .to(tooltip, .4, {yPercent: -105, autoAlpha: 1, ease: Expo.easeOut})
            ;

            const _hideTooltip = (tooltip) => {
                const tlClose = new TimelineMax({
                    delay: .4,
                    onComplete: () => {tooltip.remove()}
                });
                tlClose
                    .to(tooltip, .2, {yPercent: -90, autoAlpha: 0, ease: Expo.easeIn})
                ;
            }

            $(event.target).mouseleave(() => {
                _hideTooltip(tooltip);
            });
        }
    };

    switchDescription = (): void => {
        const {courseDescClass} = this.state;
        this.setState({
            courseDescClass: (courseDescClass === 'closed' ? 'open' : 'closed')
        });
    };

    onAuthSuccess = (): void => {
        this.authPopup.close().then(() => {
            if (this.startExrAfterAuth !== null) {
                this.startExercise(this.startExrAfterAuth);
            }
        });
    };

    isEmailValid = (email: string): boolean => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    changeLeadEmail = (e): void => {
        const email = e.target.value;
        this.setState({leadEmail: email});
    };

    submitEmail = (e, popup: Popup): void => {
        e.preventDefault();
        const email = this.state.leadEmail;
        if (this.isEmailValid(email)) {
            TrackingService.instance.sendFB('Lead', {content_name: 'Course'});
            TrackingService.instance.sendGA('user', 'submitLead', 'Course');
            // Submit to server
            $.ajax({
                type: 'POST',
                url: '/api/email/add',
                data: JSON.stringify(
                    {
                        'email': email,
                        'attributes': {
                            target_lang: this.languageIso
                        }
                    }
                ),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                success: (data) => {
                    popup.close().then(() => {
                        this.leadSubmitedPopup.open().then(() => {
                            setTimeout(() => {
                                this.leadSubmitedPopup.close();
                            }, 3000);
                        });
                    });
                },
                failure: (errMsg) => {
                    alert('Something went wrong, please try again later');
                },
                complete: () => {
                    this.emailPopup.close();
                }
            } as JQueryAjaxSettings);
        } else {
            alert('Please, enter your email address!');
        }
    };

    isCaminoBrand = (): boolean => {
        return BrandingService.instance.isCaminoBrand();
    }

    isCourseCrash = (): boolean => {
        return CourseService.instance.isCourseCrash(this.state.course.name);
    }

    render(): React.ReactNode {
        const course = this.state.course;

        if (!course) {
            return <div />
        }

        if (CourseService.instance.isCourseBeta(course.name) && !(AuthService.instance.isBetaUser() || AuthService.instance.isAdmin())) {
            return <Redirect to={AuthService.instance.getDirectoryByUserPermissions()} />
        }

        const units: CourseUnit[] = course.units;
        const currentExr: UnitExercise = CourseService.instance.getCurrentExercise(true) as UnitExercise;
        const courseName = course.title + ' - ' + course.subtitle;

        let ctaLabel;

        if (UserAgentService.instance.isMobile()) {
            ctaLabel = (<div>START LEARNING</div>);
        } else if (currentExr) {
            ctaLabel =  (<div>{(currentExr['exrNr'] === 1 ? 'START' : 'CONTINUE') + ': LESSON ' + currentExr['exrNr']}<span className='subline'>{currentExr.titleTr}</span></div>);
        }

        const videoPoster = this.instructors[this.state.course.courseLangISO].img;

        const {leadEmail} = this.state;
        return (
            <div className='course-view'>
                <AuthPopup
                    step='registration'
                    ref={instance => { this.authPopup = instance; }}
                    redirectUri={this.getCourseLink()} onSuccess={this.onAuthSuccess}/>

                <Popup
                       id='device-incompatible-popup'
                       className='centered'
                       hidefooter
                       hideheader
                       ref={instance => { this.deviceIncompatiblePopup = instance; }}
                >
                    <div className='popup-hero'>
                        <img src={appBannerImg} />
                    </div>

                    <br/><br/>
                    <div className='header-text'>Install the app and start<br/> “{course.title}” today</div>
                    <div className='app-rating'>
                        <img src={appRating}/>
                        <div>Average rating from Google Play Store and the App Store: 4.5</div>
                    </div>

                    <div className='playstore-buttons'>
                        {(!UserAgentService.instance.isAndroid() || !UserAgentService.instance.isMobile()) &&
                        <a target='_blank' href={LINK_APPLE_APP_STORE}>
                            <img src={appstoreBadge} alt='Get it on the Appstore' />
                        </a>
                        }
                        {(!UserAgentService.instance.isIOS() || !UserAgentService.instance.isMobile()) &&
                        <a target='_blank' href={LINK_GOOGLE_PLAY_STORE}>
                            <img src={playstoreBadge} alt='Get it on the Appstore' />
                        </a>
                        }
                    </div>

                    <div className='hint'>Compatible with your smartphone or tablet</div>
                </Popup>

                <Popup
                    id='session-info'
                    className='centered'
                    closeCaption='close'
                    hideheader
                    hidefooter
                    ref={instance => { this.sessionPopup = instance; }}
                    onClosed={this.schedulingPopupClosed}
                >
                    <div className='alc'>
                        {this.state.calendlyScheduleIframe === null
                            ? null
                            : <iframe src={this.state.calendlyScheduleIframe} width='320px' height='630px' frameBorder='0'/>
                        // This is to be able to reload the iframe even if user tries to schedule for the same unit one time after another (see scheduleSession method too)
                        }
                    </div>
                </Popup>

                <Popup
                    id='email-popup'
                    className='centered'
                    hidefooter
                    hideheader
                    ref={instance => { this.emailPopup = instance; }}
                >
                    <div className='alc'>
                        <div className='header-text'>
                            We are preparing the {this.langMapping['en'][this.languageIso]} course right now.
                        </div>
                        <p>
                            Leave your e-mail to get notified
                        </p>
                        <br/>
                        <form onSubmit={(e) => {this.submitEmail(e, this.emailPopup)}}>
                            <div className='input-row'>
                                <input type='email' placeholder='Email address' value={leadEmail || ''} onChange={(e) => this.changeLeadEmail(e)} />
                            </div>
                            <div className='submit-row alc'>
                                <button className='button primary'>Get free lesson & discount</button>
                            </div>
                        </form>
                        <div id='email-benefits'>
                            <ul>
                                <li>Receive 33% discount</li>
                                <li>Get a FREE tutor session</li>
                            </ul>
                        </div>
                    </div>
                </Popup>
                <Popup
                    id='email-popup'
                    className='centered alc'
                    hidefooter
                    hideheader
                    ref={instance => { this.leadSubmitedPopup = instance; }}
                >
                        <svg width='70' height='70' viewBox='0 0 70 70' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35zm0-3.182C17.427 66.818 3.182 52.573 3.182 35S17.427 3.182 35 3.182 66.818 17.427 66.818 35 52.573 66.818 35 66.818zM18.625 32.436c-.62-.62-1.63-.62-2.25 0-.62.622-.62 1.63 0 2.25L28.09 46.4c.62.622 1.628.622 2.25 0l22.425-22.424c.62-.62.62-1.63 0-2.25-.622-.62-1.63-.62-2.25 0l-21.3 21.3-10.59-10.59z' fill='#7ED323' fillRule='evenodd'/>
                        </svg>
                        <br/>
                        <br/>
                        <div className='header-text'>Thank you!</div>
                        <p>We will inform you as soon as we have news for you.</p>
                </Popup>
                <PremiumPopup
                    ref={instance => { this.premiumPopup = instance; }}
                    start={() => {this.startExercise(units[0].exercises[0])}}
                    onSeePricingClick={document.getElementById('pricing') !== undefined
                        ? () => document.getElementById('pricing').scrollIntoView()
                        : () => {}
                    }
                />
                <Popup
                    id='tutor-package-popup'
                    className='centered alc'
                    hidefooter
                    hideheader
                    ref={instance => { this.tutorPackagePopup = instance; }}
                >
                    <div className='popup-hero'/>
                    <div className='plan-list'>
                        <div className='plan'>
                            <div className='text'>
                                <header>
                                    <span>Boost your progress</span>
                                    Get the 10 Tutoring Session Bundle
                                </header>
                                <ul>
                                    <li>1-on-1 video chats with native tutors (30 min. each)</li>
                                    <li>Ask questions and increase your knowledge</li>
                                    <li>Get tons of additional speaking practice</li>
                                    <li>Learn to understand native speakers</li>
                                    <li>Choose your own schedule</li>
                                </ul>
                            </div>
                            <div className='prices'>
                                <div className='price-striked'>€ 199</div>
                                <div className='price'><span className='price-unit'>€</span> 149</div>
                            </div>
                            <div className='hint yearly-hint'>No subscription; A one-time payment</div>
                            <Link className='button primary' to='/enroll?plan=5dfc1a4b282df992ce23fd94'>Purchase upgrade</Link>
                        </div>
                    </div>
                </Popup>

                <div className='screen-blender'/>
                <div className='course-header'>
                    {
                        (this.state.offerActive ?
                            <div className='offer-countdown hide-mobile' onClick={() => { this.props.history.push('/enroll') }}>
                                <header>Reserve the course now and get <strong>40% off</strong></header>
                                <CountDown deadline={this.deadline} onend={this.onOfferEnd} hideDays/>
                                <br/><Link to='/enroll'>See offer →</Link>
                            </div> :
                            ''
                        )
                    }
                    <div className='frame'>
                        <header>
                            <h1><div className={'flag-item ' + this.state.course.courseLangISO}/> {this.state.course.title}</h1>
                        </header>
                        <div className='subheader'>{this.state.course.subtitle}</div>
                        <figure id='course-intro' onClick={() => {this.startExercise(currentExr)}}>
                            <img className='action-play' src={iconPlay} alt='Play' />
                            <img className='video-poster' src={videoPoster} alt='Video poster'/>
                        </figure>
                        <div id='course-actions'>
                            {(
                                currentExr.type && currentExr.type === 'tutor' ?
                                    <button className='button primary' onClick={(event) => this.scheduleSession(course.units[course[CourseService.TRANSIENT_CURRENT_UNIT]], event)}>Next: Schedule tutor session</button> :
                                    <button className='button primary lesson' onClick={(event) => {this.startExercise(currentExr, event)}} style={{textTransform: 'capitalize'}}>{ctaLabel}</button>

                            )}
                        </div>
                    </div>
                    <div className={'header-bg ' +
                        (this.state.course.courseLangISO + (this.isCourseCrash() ? '-crash' : ''))
                    }/>
                </div>
                <div id='sticky-header'>
                    <div className='frame'>
                        <div className='inner'>
                            {(
                                currentExr.type && currentExr.type === 'tutor' ?
                                    <button className='button primary' onClick={(event) => this.scheduleSession(course.units[course[CourseService.TRANSIENT_CURRENT_UNIT]], event)}>Next: Schedule tutor session</button> :
                                    <button className='button primary' onClick={(event) => {this.startExercise(currentExr, event)}} style={{textTransform: 'capitalize'}}>{ctaLabel}</button>

                            )}
                        </div>
                    </div>
                </div>

                <section id='course-info'>
                    <div className='frame'>
                        {
                            (this.state.offerActive ?
                                    <div className='offer-countdown hide-desktop' onClick={() => { this.props.history.push('/enroll') }}>
                                        <header>Reserve the course now and get <strong>40% off</strong></header>
                                        <CountDown deadline={this.deadline} onend={this.onOfferEnd} hideDays/>
                                        <br/><Link to='/enroll'>See offer →</Link>
                                    </div> :
                                    ''
                            )
                        }
                        <div className={this.state.courseDescClass + ' inner'}>
                            <section className='course-desc'>
                                <div>
                                    <header>About this course</header>
                                    <p>{this.state.course.desc}</p>
                                </div>
                                <div>
                                    <header>Is this course for me?</header>
                                    <p>{this.state.course.forwhom}</p>
                                </div>
                            </section>
                            <section className='course-stats'>
                                <header>What you get</header>
                                <div className='inner'>
                                    <div className='stat-row'><strong>{this.isCourseCrash() ? '10' : '80'}+</strong> <span>Lessons for different everyday situations</span></div>
                                    <div className='stat-row'><strong>{course.videoLessonsCount}+</strong> <span>Video tutorials on grammar</span></div>
                                    <div className='stat-row'><strong>{course.speakingExercisesCount}+</strong> <span>Sentences you will speak yourself</span></div>
                                </div>
                            </section>
                            <button onClick={this.switchDescription} className='action-more hide-desktop'>{this.state.courseDescClass === 'open' ? 'Less' : 'More'}</button>
                        </div>
                    </div>
                </section>
                <div className='course-plan'>
                    <div className='frame'>
                        <div className='course-wrapper'>
                            {units.map((unit: CourseUnit, i: number) =>
                                <CourseUnitLayout
                                    startExercise={this.startExercise}
                                    scheduleSession={this.scheduleSession}
                                    firstStartableExrIndex={this.state.firstStartableExrIndex}
                                    userIsPermittedToCourse={this.state.userIsPermittedToCourse}
                                    unit={unit}
                                    isCourseBeta={CourseService.instance.isCourseBeta(course.name)}
                                    currentExrAlpha={currentExr ? currentExr.seqAlpha : null}
                                    index={i}
                                    key={i}
                                    isSchedulingIfThisUnit={unit.id === this.state.schedulingInfo.unitId ? this.state.schedulingInfo.fetchingUserCourse : false}
                                    highlightTrial={this.highlightTrial}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className='pricing'>
                    {!this.state.userIsPermittedToCourse &&
                    <>
                        <a id='pricing' />
                        <section id='reserve-content' className='boxes'>
                            <div className='inner'>
                                <header className='section-header alc'>
                                    <h2>Enroll in this course</h2>
                                </header>

                                <p className='alc reserve-desc'>
                                    Pay and keep this awesome course for three months!
                                </p>

                                <h2 className='alc'><div>Options to enroll</div></h2>

                                <div id='plan-wrapper'>
                                    <div className='plan-list'>
                                        <CoursePlanCard
                                            highlightedTitle='Unbeatable value'
                                            title='Full course'
                                            subtitle={courseName}
                                            image={planImg2}
                                            features={[
                                                'All speaking exercises',
                                                'All video lessons',
                                            ]}
                                            price={this.isCourseCrash() ? 19 : 198}
                                            enrollPath={
                                                `/enroll?plan=` +
                                                (this.isCourseCrash() ? `5eca4d98145e8bd3a2d992b5` : `5e25ed85282df992ce2d10a6`) +
                                                `&course=${course.name}&courseName=${courseName}`
                                            }
                                        />

                                        {/*<CoursePlanCard*/}
                                        {/*    highlightedTitle='Get the full experience'*/}
                                        {/*    title='Full course with tutors'*/}
                                        {/*    subtitle={courseName}*/}
                                        {/*    image={planImg3}*/}
                                        {/*    features={[*/}
                                        {/*        'All speaking exercises',*/}
                                        {/*        'All video lessons',*/}
                                        {/*        (this.isCourseCrash() ? '2' : '10') + ' tutoring sessions with native speakers',*/}
                                        {/*    ]}*/}
                                        {/*    price={this.isCourseCrash() ? 49 : 498}*/}
                                        {/*    enrollPath={*/}
                                        {/*        `/enroll?plan=` +*/}
                                        {/*        (this.isCourseCrash() ? `5ecac4b6145e8bd3a2e00654` : `5e25ed85282df992ce2d10a8`) +*/}
                                        {/*        `&course=${course.name}&courseName=${courseName}`*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                                {false &&
                                <div id='referral-acknowledgement' className='alc'>
                                    {this.isCourseCrash() ? (
                                        <Link to={AuthService.instance.isAuthenticated() ? '/dashboard' : '/registration?freeCourse=1'} className='ghost primary'>
                                            <svg width='36' height='36' xmlns='http://www.w3.org/2000/svg'><g fill-rule='nonzero' fill='none'><path d='M32 36H4c-1.1 0-2-.94-2-2.09V13h32v20.91c0 1.15-.9 2.09-2 2.09z' fill='#FF1E75'/><path d='M36 14H0V8c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2v6z' fill='#FF6AA4'/><path d='M16 14h4v22h-4V14zM26 0h-4l-6 6h4l6-6z' fill='#E3C300'/><path fill='#FFDB00' d='M14 0h-4l6 6v8h4V6z'/></g></svg>
                                            Get this course for free
                                        </Link>
                                    ) : (
                                        <Link to={AuthService.instance.isAuthenticated() ? '/dashboard' : '/registration?freeCourse=1'} className='ghost primary'>
                                            <svg width='36' height='36' xmlns='http://www.w3.org/2000/svg'><g fill-rule='nonzero' fill='none'><path d='M32 36H4c-1.1 0-2-.94-2-2.09V13h32v20.91c0 1.15-.9 2.09-2 2.09z' fill='#FF1E75'/><path d='M36 14H0V8c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2v6z' fill='#FF6AA4'/><path d='M16 14h4v22h-4V14zM26 0h-4l-6 6h4l6-6z' fill='#E3C300'/><path fill='#FFDB00' d='M14 0h-4l6 6v8h4V6z'/></g></svg>
                                            Get 40€ off
                                        </Link>
                                    )}
                                </div>
                                }
                            </div>
                        </section>
                        <section id='course-stats'>
                            <header className='frame section-header alc'>
                                <h2>Get a life-time access to</h2>
                            </header>
                            <div className='frame'>
                                <div id='stats-cont'>
                                    <div className='stat-row'><strong>{this.isCourseCrash() ? '10' : '80'}+</strong> <span>Lessons for different everyday situations</span></div>
                                    <div className='stat-row'><strong>{this.isCourseCrash() ? '3' : '12'}+</strong> <span>Hours of explainer-videos with grammar and tips</span></div>
                                    <div className='stat-row'><strong>{course.speakingExercisesCount}+</strong> <span>Sentences you will speak yourself</span></div>
                                </div>
                            </div>
                        </section>
                    </>
                    }

                    {!this.state.userIsPermittedToCourse &&
                    <>
                    <section id='faq'>
                        <div className='frame'>
                            <header className='section-header alc'>
                                <h2>FAQ</h2>
                            </header>

                            <div id='faq-cont'>
                                <article>
                                    <header>Will it improve my pronunciation?</header>
                                    <p>
                                        Yes. You will hear your language instructor pronounce every word you need multiple times in the videos. In the dialogues with AI you will also hear the correct pronunciation repeatedly.
                                        <br/><br/>
                                        Our AI conversation partner is based on the latest voice synthesis technology that is constantly developing and being refined to sound even more human-like.
                                    </p>
                                </article>

                                <article>
                                    <header>Can the AI conversation partner understand me?</header>
                                    <p>
                                        Yes, our speech recognition models are trained to recognize even strong accents. Just as when you speak to real people in real life, the AI will understand you better if you try to pronounce all words correctly. If your AI partner understands you, then real humans will, too.
                                        <br/><br/>
                                        Your AI conversation partner will accept almost any answer that makes sense given the asked question. You can even use slang. However, it's very polite and doesn't like bad words :)
                                    </p>
                                </article>


                                {/*<article>*/}
                                {/*    <header>Who are the tutors?</header>*/}
                                {/*    <p>Our tutors are native speakers. We only accept tutors after meeting them in person to make sure they are open people and have good pronunciation and a neutral accent.</p>*/}
                                {/*</article>*/}

                                <article>
                                    <header>Are mobile apps supported?</header>
                                    <p>We have an Android as well as an iOS apps that you can use on your smartphone or tablet. You can download the Android app by clicking <a href={LINK_GOOGLE_PLAY_STORE} target='_blank'>here</a> or the iOS app by clicking <a href={LINK_APPLE_APP_STORE} target='_blank'>here</a>.</p>
                                </article>
                            </div>
                        </div>
                    </section>

                    <section id='reviews'>
                        <div className='frame'>
                            <header className='section-header alc'>
                                <h2>What our users say</h2>
                            </header>

                            <div className='swiper-container testemonial-swiper'>
                                <div className='swiper-wrapper'>
                                    {this.testimonials.map((testimonial, i) =>
                                    <div className='review swiper-slide' key={i}>
                                        <svg className='quote-icon' width='31' height='22' xmlns='http://www.w3.org/2000/svg'><defs><linearGradient x1='21.113%' y1='0%' x2='65.666%' y2='100%' id='a'><stop stopColor='#BE92FF' offset='0%'/><stop stopColor='#4DDCB9' offset='100%'/></linearGradient></defs><path d='M707.4 1970c-1.6 0-2.4-.783-2.4-2.348v-8.767c0-5.714 2.56-10.254 8.717-10.88 1.04-.079 1.44.86.72 1.722-2.16 2.504-2.479 4.461-2.559 6.027 0 .47.32.783.8.783h3.679c1.52 0 2.399.86 2.399 2.348v8.767c0 1.565-.88 2.348-2.4 2.348H707.4zm17.243 0c-1.6 0-2.399-.783-2.399-2.348v-8.767c0-5.714 2.56-10.254 8.717-10.88 1.04-.079 1.44.86.72 1.722-2.16 2.504-2.479 4.461-2.559 6.027 0 .47.32.783.8.783h3.679c1.52 0 2.399.86 2.399 2.348v8.767c0 1.565-.88 2.348-2.4 2.348h-8.957z' transform='translate(-705 -1948)' fill='url(#a)' fillRule='evenodd'/></svg>
                                        <p dangerouslySetInnerHTML={{__html: testimonial.text}} />
                                        <figure>
                                            <img src={testimonial.img} />
                                            <figcaption dangerouslySetInnerHTML={{__html: testimonial.name}} />
                                        </figure>
                                    </div>
                                    )}
                                </div>
                                <div className='swiper-pagination' />
                                <div className='swiper-button-prev' />
                                <div className='swiper-button-next' />
                            </div>
                        </div>
                    </section>

                    <section className='alc closing-cta'>
                        <Link className='button primary' to='#pricing'>Enroll in the course</Link>
                    </section>
                    </>
                    }
                </div>

                <MainFooter />
            </div>
        );

    }
}
