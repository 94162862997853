import * as React from 'react';
import { Link } from 'react-router-dom';
import {withNamespaces} from 'react-i18next';
import MainFooter from './global/MainFooter';
import {TweenMax, Expo} from 'gsap';

@withNamespaces('languages')
export default class Languages extends React.Component<any, any> {

    state = {
        allSupportedLanguages: ['en', 'es', 'de', 'fr', 'it']
    };

    courses = [
        {lang: 'es', label: 'Spanish', url: '/spanish/courses'},
        {lang: 'de', label: 'German', url: '/german/courses'},
        {lang: 'fr', label: 'French', url: '/french/course/fr-a1?demo=1', locked: true},
        {lang: 'it', label: 'Italian', url: '/italian/course/it-a1?demo=1', locked: true},
        {lang: 'en', label: 'English', url: '/english/course/en-a1', locked: true},
        {lang: 'ru', label: 'Russian', url: '/russian/course/ru-a1', locked: true},
        {lang: 'po', label: 'Portuguese', url: '/portuguese/course/po-a1', locked: true}
    ];

    componentDidMount(): void {
        setTimeout(() => {
            TweenMax.fromTo('#language-list', .6, {autoAlpha: 0}, {autoAlpha: 1, ease: Expo.easeOut});
        }, 250);
    }

    render(): React.ReactNode {
        return (
            <div>
                <header className='page-header alc'>
                    <h1>I want to speak...</h1>
                </header>
                <div id='language-list'>
                    <div className='frame'>
                    {this.courses.map((course, i) =>
                        !course.locked ?
                        <Link to={course.url} className={'language ' + course.lang} key={i}>
                            <div className={'flag-item ' + course.lang}/>
                            <header>{course.label}</header>
                            <div className='bg'/>
                        </Link>
                            :
                        <span className={'language locked ' + course.lang} key={i}>
                            <div className={'flag-item ' + course.lang}/>
                            <header>{course.label} <i>coming soon</i></header>
                            <div className='bg'/>
                        </span>
                    )}
                    </div>
                </div>
                <MainFooter />
            </div>
        );
    }
}
