import {Intent} from './intent.model';
import {SlideGroup} from './slide.model';

export class Context {
    constructor(
        public id?: string,
        public name?: string,
        public video?: string,
        public intents?: Intent[],
        public slideGroups?: SlideGroup[],
        public videoSource?: string,
        public thumb?: string
    ) {
        this.intents = [];
        this.slideGroups = [];
    }
}
