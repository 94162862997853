import * as React from 'react';
import { withNamespaces } from 'react-i18next';

@withNamespaces('jobs')
export default class Jobs extends React.Component<any, any> {

    render(): React.ReactNode {
        const { t } = this.props;
        return (
            <div style={{height: '100vh', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'visible!important', position: 'relative', zIndex: 2}}>
                <p>{t('project')}</p>
                <a href={'https://s3-eu-west-1.amazonaws.com/magic-static/Magiclingua_projectstudy.pdf'}>Details</a>
            </div>
        );
    }
}
