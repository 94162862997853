import * as Promise from 'promise';
import axios from 'axios/index';
import {AbstractService} from './abstract.service';
import { UserTrackingEvent } from '../models/user.tracking.event.model';

export class TrackingService extends AbstractService {

    private static readonly USER_EVENT_API_ROOT = '/api/tracking/userevent/';

    private static _instance: TrackingService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    sendFB(eventName: string, params?: any): void {
        if ((<any>window).fbq) {
            (<any>window).fbq('track', eventName, params || {});
        }
    }

    sendGA(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number) {
        if ((<any>window).ga) {
            (<any>window).ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue);
        }
    }

    /**
     * To send BigQuery user events (To trigger BE to send BigQuery events actually)
     *
     * Doesn't wait for BE's answer as it should not affect the ui flow
     */
    sendUserEvent(userTrackingEvent: UserTrackingEvent): void {
        axios.post(
            TrackingService.USER_EVENT_API_ROOT,
            userTrackingEvent
        );
    }
}
