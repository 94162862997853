import {RecognitionStatus} from './recognition.status';
import {RecognitionResultsFormat} from './recognition.results.format';

export abstract class StreamChannel {

    protected constructor(
        langISO: string,
        speechContext: Array<string>,
        recognitionProcessor: (results: RecognitionResultsFormat) => void,
        statusProcessor: (status: RecognitionStatus) => void
    ) {
    }

    public abstract getChannelId(): string;

    public abstract getDeviceLabel(): string;

    public abstract open(): Promise<void>;

    public abstract close(): Promise<void>;

    /**
     * To be used for implementations which buffer audio before sending it to transcription provider.
     * Calling this method must force socket to send the buffered audio for transcription.
     * Some implementations, which do realtime streaming would ignore this call, since they do not buffer the audio chunks.
     */
    public abstract flush(): Promise<void>;

    public abstract getAudioFrequencyData(): Uint8Array;

    public abstract getAudioVolumeData(): any;
}
