import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import MainFooter from './global/MainFooter';

@withNamespaces('team')
export default class Team extends React.Component<any, any> {

    team = [
        {name: 'Silver', position: 'Co-Founder, CEO', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1543787579/SIlver_cri2wf.jpg', langs: ['ee', 'de', 'en', 'es'], desc: 'Silver has worked as a Product Manager and Business Developer in the tech sector for the past ten years, among others for a well known language learning company. Driven by numbers he believes: what gets measured gets done!'},
        {name: 'Gleb', position: 'Co-Founder, Product & UX', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1543952745/Gleb_uksojy.jpg', langs: ['de', 'ru', 'en', 'es'], desc: 'During the last ten years, Gleb’s career brought him from backend developer to frontend developer to UX and product design. Gleb is the swiss knife who could build any product entirely by himself. In his 2,5 years at a major language learning company, he built a deep understanding of the language learning industry.'},
        {name: 'Oleksandr', position: 'Co-Founder, CTO', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1579602535/Oleks_oe4lvv.jpg', langs: ['uk', 'ru', 'en', 'de', 'fr'], desc: 'Olek is passionate about software design and machine learning. In his long career, he has worked for various companies as a full-stack engineer and software architect. Olek doesn\'t see the difference between human and programming languages. He believes all of them have one purpose that is communication!'},
        {name: 'Monika', position: 'Marketing', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1543788182/Moni_p4mktg.jpg', langs: ['de', 'ee', 'en', 'es', 'tu'], desc: 'With a degree in online communication, Monika knows how to spread the word and build meaningful connections. With all the energy, we also suspect she has an extra battery somewhere.'},
        {name: 'Barbara', position: 'Didactics', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1543787579/Barbara_eonynr.jpg', langs: ['de', 'en', 'es'], desc: 'Barbara is currently a spanish teacher at a school in Cologne/Germany. She can’t wait for the bots to take over boring, repetitive task to free more time to be a teacher. She has a masters degree in education.'},
        {name: 'Jakob', position: 'PR', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1543787579/Jakob_xzyoir.jpg', langs: ['de', 'en'], desc: 'Jakob is a student at the Technical University of Munich. He is passionate about different cultures, traveling and tech, as this is a part of his study. He is our brain when it comes to creative writing and kicks some ass at numbers.'},
        {name: 'Mohamad', position: 'PR', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1543787579/Mohamad_iocwks.jpg', langs: ['ar', 'de', 'en'], desc: 'Mohamad’s fascination is traveling. He decided to pursue his degree in Business administration at the Technical University Munich. Being an Arabic native speaker he found English and German very different. His next goal is learning French and Turkish. '},
        {name: 'Janis', position: 'Marketing', img: 'https://res.cloudinary.com/magiclingua/image/upload/v1543952675/Janis_r0ywts.jpg', langs: ['de', 'en', 'es'], desc: 'Janis is studying Management and Technology with focus on Informatics at the TUM. He‘s highly interested in everything new, especially from the tech world. He is the master of ads and lead generation.'}
    ];

    render(): React.ReactNode {
        return (
            <div>
                <section id='top'>
                    <div className='frame'>
                        <header className='page-header'>
                            <h1>Meet the team</h1>
                        </header>
                    </div>
                </section>
                <div className='frame'>
                    <div id='mission' className='box'>
                        <header>Our mission</header>
                        <p>
                            Our mission is to provide everyone with a personalized, digital language learning experience that is more affordable, flexible and fun than a human teacher.
                            <br/><br/>
                            Through rapidly evolving technologies such as Machine Learning, we believe that in the long-term, digital teachers will overtake their human counterparts in many areas. They will become more efficient at understanding individuals’ needs and providing them with the right
                            exercises. Human teachers will move toward the roles of language coaches and motivators.
                        </p>
                    </div>
                </div>
                <section id='team'>
                    <div className='frame'>
                        <div id='team-wall'>
                            {this.team.map((p, i) =>
                            <article key={i} className={p.langs.join(' ')}>
                                <figure>
                                    <img src={p.img}/>
                                </figure>
                                <header>
                                    <strong>{p.name}</strong>
                                    <div>{p.position}</div>
                                </header>
                                <div className='languages-team-member'>
                                {p.langs.map((lang, j) =>
                                    <div className={'flag-item ' + lang} key={j}/>
                                )}
                                </div>
                                <p>{p.desc}</p>
                            </article>
                            )}
                        </div>
                    </div>
                </section>
                <MainFooter />
            </div>
        );
    }
}
