import * as Promise from 'promise';
import axios from 'axios/index';
import {AbstractService} from './abstract.service';

export class ProductSubscriptionService extends AbstractService {

    private static _instance: ProductSubscriptionService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    getAllByUser(): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get('/api/productsubscriptions/')
                .then((response) => {
                    const subscriptions = response.data;
                    if (subscriptions) {
                        resolve(subscriptions);
                    } else {
                        reject('No product subscriptions found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }
}
