export class SlideStyles {
    public video: SlideStylesVideo;
    public slide: SlideStylesSlide;
    constructor(
        {video, slide}: {video: SlideStylesVideo, slide: SlideStylesSlide}
    ) {
        this.video = video;
        this.slide = slide;
    }
}

export class SlideStylesVideo {
    public left: string;
    constructor(
        {left}: {left: string}
    ) {
        this.left = left;
    }
}

export class SlideStylesSlide {
    public left: string;
    public width: string;
    constructor(
        {left, width}: {left: string, width: string}
    ) {
        this.left = left;
        this.width = width;
    }
}
