import { AbstractService } from './abstract.service';

// Just gets and sets stuff, no business logic whatsoever
export class LocalStorageService extends AbstractService {

    private static _instance: LocalStorageService;

    private static readonly FIRST_ENTERED_PATH = 'FIRST_ENTERED_PATH';
    private static readonly IS_SETTINGS_TOOLTIP_SHOWN = 'IS_SETTINGS_TOOLTIP_SHOWN';

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public setFirstEnteredPath(pathname: string): void {
        localStorage.setItem(LocalStorageService.FIRST_ENTERED_PATH, pathname);
    }

    public getFirstEnteredPath(): string {
        return localStorage.getItem(LocalStorageService.FIRST_ENTERED_PATH);
    }

    public removeFirstEnteredPath(): void {
        localStorage.removeItem(LocalStorageService.FIRST_ENTERED_PATH);
    }

    public getIsSettingsTooltipShown(): boolean {
        return this.stringToBoolean(
            localStorage.getItem(LocalStorageService.IS_SETTINGS_TOOLTIP_SHOWN)
        );
    }

    public setIsSettingsTooltipShown(isShown: boolean): void {
        localStorage.setItem(
            LocalStorageService.IS_SETTINGS_TOOLTIP_SHOWN,
            this.booleanToString(isShown)
        );
    }

    public handleSessionEnd(): void {
        this.removeFirstEnteredPath();
    }

    private booleanToString(boolean: boolean): string {
        if (boolean === true) {
            return 'true';
        } else {
            return 'false'
        }
    }

    private stringToBoolean(string: string): boolean {
        if (string === 'true') {
            return true;
        } else {
            // null and undefined will be false too
            return false;
        }
    }
}
