import * as React from 'react';
import {Link} from 'react-router-dom';

import { Course } from '../../../models/course.model';

import * as eyeIcon from '../../../../../../assets/images/newmagic/eye.svg';
import * as moreIcon from '../../../../../../assets/images/newmagic/more-horizontal.svg';

interface CourseCardForCoursesEditorProps {
    course: Course;
};

const countLessons = (course: Course): Number => {
    return course.units
        .map(unit => unit.exercises)
        .map(lessons => lessons.length)
        .reduce((acc, length) => {
            return acc + length;
        });
};

export const CourseCardForCoursesEditor = (props: CourseCardForCoursesEditorProps) => {
    const { course } = props;
    return (
        <div className='editor-course-card row'>
            <Link to={`/editor/overview/course/${course.name}`}>
                <div className='editor-course-card-title'>
                    {course.title}
                </div>
                <div>{course.subtitle}</div>
                <div>{course.name}</div>
            </Link>
            <div className='editor-course-card-extra row'>
                <div>{course.units.length} units</div>
                <div>{countLessons(course)} lessons</div>
                <div className='editor-icon'>
                    <img src={eyeIcon} />
                </div>
                <div className='editor-icon'>
                    <img src={moreIcon} />
                </div>
            </div>
        </div>
    );
};
