import * as Promise from 'promise';
import axios from 'axios/index';
import {AbstractService} from './abstract.service';

export class SystemService extends AbstractService {

    private static _instance: SystemService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    getAllLeaders(): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/system/leaders'
            )
                .then((response) => {
                    const leaders = response.data;
                    if (leaders) {
                        resolve(leaders);
                    } else {
                        reject('No leaders found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getAllNodes(): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/system/nodes'
            )
                .then((response) => {
                    const nodes = response.data;
                    if (nodes) {
                        resolve(nodes);
                    } else {
                        reject('No nodes found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

}
