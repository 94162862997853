import * as React from 'react';
import {Link} from 'react-router-dom';
import * as PubSub from 'pubsub-js';
import {withNamespaces} from 'react-i18next';
import {AuthService} from '../../services/auth.service';
import * as logo from '../../../../../assets/images/newmagic/logo.svg';
import * as logoPartner from '../../../../../assets/images/newmagic/logo_caminobarcelona.png';
import * as defaultAvatar from '../../../../../assets/images/newmagic/default-avatar.svg';
import {TimelineMax, TweenMax, Expo} from 'gsap';
import * as Promise from 'promise';
import {CourseService} from '../../services/course.service';
import './camino-nav.sass';

@withNamespaces('navigation')
export default class CaminoNavigation extends React.Component<any, any> {

    state = {
        signedUser: null,
        courseUrl: null
    };

    userLoadedTopic = null;
    signinTopic = null;
    signoutTopic = null;
    element: HTMLDivElement;

    menuOpen = false;

    componentWillMount(): void {
        if (!this.state.signedUser && AuthService.instance.isAuthenticated()) {
            AuthService.instance.loadUser().then((user) => {
                this.setState({ signedUser: user });
                (CourseService.instance.getCurrentCourseUrl() as Promise<string>).then(url => {
                    this.setState({ courseUrl: url });
                });
            });
        }
        this.userLoadedTopic = PubSub.subscribe('USER_LOADED', (msg, data) => {
            this.setState({ signedUser: data ? data.user : null });
        });
        this.signinTopic = PubSub.subscribe('SIGNIN', (msg, data) => {
            this.setState({ signedUser: data ? data.user : null });
        });
        this.signoutTopic = PubSub.subscribe('SIGNOUT', (msg, data) => {
            this.setState({ signedUser: null });
        });
    }

    componentWillUnmount(): void {
        PubSub.unsubscribe(this.userLoadedTopic);
        PubSub.unsubscribe(this.signinTopic);
        PubSub.unsubscribe(this.signoutTopic);
    }

    toggleMenu = (e): void => {
        const self = this;
        if (e) {
            e.stopPropagation();
        }
        const menu = $(this.element).find('aside');
        const tl = new TimelineMax();
        if (self.menuOpen === false) {
            tl
                .to(menu, 0, {css: {visibility: 'visible', opacity: 1}})
                .to(menu, 0, {autoAlpha: 0, y: -20})
                .to(menu, 0, {css: {display: 'block'}})
                .to(menu, .2, {autoAlpha: 1, y: 0, ease: Expo.easeOut});
            self.menuOpen = true;
        } else {
            tl
                .to(menu, .2, {autoAlpha: 0, y: -20, ease: Expo.easeInOut})
                .set(menu, {clearProps: 'all'});
            self.menuOpen = false;
        }
    };

    gotoArea = (areaId: string): void => {
        TweenMax.to(window, .6, {scrollTo: {y: areaId, offsetY:25}});
    };

    getReferrer = () => {
        let referrer = localStorage.getItem('originalReferrer');
        if (!referrer && document.referrer && document.referrer.indexOf('camino') > -1) {
            referrer = document.referrer;
            localStorage.setItem('originalReferrer', referrer);
        }
        return referrer;
    };

    render(): React.ReactNode {
        const { signedUser } = this.state;
        const { t, i18n } = this.props;
        // let signingLink = null;
        // let menuOpener = null;
        const stateSignedIn = signedUser && !AuthService.instance.isAnonymous();
        let userSalutation = '';
        let accountAction = null;
        let avatar = defaultAvatar;

        if (stateSignedIn) {
            userSalutation = signedUser.email;
            avatar = signedUser.profilePicture ? signedUser.profilePicture : defaultAvatar;
            accountAction =
                (
                <div id='menu-opener' onClick={this.toggleMenu}>
                    <svg className='menu-icon' width='18' height='12' viewBox='335 19 18 12' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M335 20c0-.552.445-1 .993-1h16.014c.548 0 .993.444.993 1 0 .552-.445 1-.993 1h-16.014c-.548 0-.993-.444-.993-1zm0 5c0-.552.445-1 .993-1h16.014c.548 0 .993.444.993 1 0 .552-.445 1-.993 1h-16.014c-.548 0-.993-.444-.993-1zm0 5c0-.552.445-1 .993-1h16.014c.548 0 .993.444.993 1 0 .552-.445 1-.993 1h-16.014c-.548 0-.993-.444-.993-1z' fill='#606060' fillRule='evenodd'/>
                    </svg>
                    <div className='profile'>
                        <img src={avatar}/>
                        <span>{userSalutation}</span>
                    </div>
                    <aside>
                        <ul>
                            <li className='show-mobile'>
                                <div className='profile-m'>
                                    <img src={avatar}/>
                                    <span>{userSalutation}</span>
                                </div>
                            </li>

                            <li>
                                <Link to='/dashboard'>
                                <svg className='icon' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><path d='M13.282 12.98c2.326.302 3.883 1.615 4.188 2.091.577.88.529 2.929.529 2.929H2.002s-.08-2.179.69-3.059c.434-.375 1.797-1.659 4.124-1.976 2.31-.303 1.604-2.612 1.685-2.583.08.044-.017-.49-.017-.49S6.8 8.435 6.816 7.122c.032-1.328 0-2.006 0-2.006S7.089 2 9.463 2l1.332.577c2.134 0 2.487 2.54 2.487 2.54v2.192s-.273 1.861-1.605 2.54c0 0-.096.533-.016.49.08-.029-.69 2.323 1.62 2.64z' fill='#4D3766' fillRule='evenodd'/></svg>
                                    <span>{t('dashboard')}</span>
                                </Link>
                            </li>

                            <li className='nav-logout'>
                                <Link to='/signout'>
                                    <svg className='icon' width='20' height='20' viewBox='19 109 20 20' xmlns='http://www.w3.org/2000/svg'>
                                        <g fill='none' fillRule='evenodd'>
                                            <path fill='none' d='M19 109h20v20H19z'/>
                                            <path className='main' d='M27.078 118l.018-1 2.938 2.052L27.026 121l.017-1h-5.547c-.55 0-.996-.448-.996-1 0-.556.446-1 .996-1h5.582zM25 116.004c0 .552-.448 1-1 1s-1-.448-1-1V114c0-1.102.9-2 1.995-2h10.01c1.102 0 1.995.904 1.995 2.002v9.996c0 1.102-.9 2.002-1.995 2.002h-10.01c-1.106 0-1.995-.896-1.995-2.01v-1.875c0-.552.448-1 1-1s1 .448 1 1c0 0 0 1.884-.002 1.885 0 0 10.002-.005 10.002-.002 0 0-.003-9.998.005-9.998H25v2.004z' fill='#4D3766' fillRule='nonzero'/>
                                        </g>
                                    </svg>
                                    <span>{t('logout')}</span>
                                </Link>
                            </li>
                        </ul>
                    </aside>
                </div>
                );
        } else {
            accountAction =
                (
                <div className='auth-actions'>
                    <Link className='ghost' to='/signin'>{t('login')}</Link>
                </div>
                );
        }

        const homeUrl = AuthService.instance.getDirectoryByUserPermissions();

        return (
            <div id='navbar' className='camino-nav' ref={instance => { this.element = instance; }}>
                <div className='frame'>
                    <Link id='logo' to={homeUrl}><img src={logoPartner} /></Link>
                    {/*<a id='logo' href='https://caminobarcelona.com/courses/online-spanish-course.php'><img src={logoPartner} /></a>*/}
                    <nav>
                        {stateSignedIn &&
                        <ul id='admin-links'>
                            {AuthService.instance.isSupport() && <li className='link-courses'><Link to='/editor/overview/courses'>Courses</Link></li> }
                            {AuthService.instance.isAdmin() && <li className='link-courses'><Link to='/admin/users'>Users</Link></li> }
                            {AuthService.instance.isAdmin() && <li className='link-courses'><Link to='/admin/vouchers'>Vouchers</Link></li> }
                            {AuthService.instance.isAdmin() && <li className='link-courses'><Link to='/admin/learningplans'>Plans</Link></li> }
                            {AuthService.instance.isAdmin() && <li className='link-courses'><Link to='/admin/webhooks'>Webhooks</Link></li> }
                            {AuthService.instance.isAdmin() && <li className='link-courses'><Link to='/admin/spell-check'>Spell Check</Link></li> }
                        </ul>
                        }
                    </nav>
                    <img id='accr' src='https://www.caminobarcelona.com/img/camino/accreditation.jpg'/>
                    {/*<a href='https://caminobarcelona.com/courses/online-spanish-course.php' className='button' id='partner-sale-link'><span className='hide-mobile'>Enroll today at 15% discount</span><span className='hide-desktop'>Enroll</span></a>*/}
                    {accountAction}
                </div>
            </div>
        );
    }
}
