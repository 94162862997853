import * as Promise from 'promise';
import * as _ from 'underscore';

import {AbstractService} from './abstract.service';
import {LanguageService} from './language.service';
import {JsDiffService} from './js.diff.service';

export class TextService extends AbstractService {

    private static _instance: TextService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public prepareForComparison(str: string, language?: string, pos?: string) {
        const self = this;
        let result = str.toLowerCase().trim().replace(/[.,\/\?¿#!¡$%\^&\*;:{}=\-_`~]/g, ' ').replace(/\s/g, ' ').replace(/\s{2,}/g, ' ').trim();
        result = result.replace(/\(.*\)/g, '')/*.replace(/\[.*\]/g, '')*/;
        result = self.cleanupText(result);
        // accept all kind of apostrophes
        result = result.replace(/(‘|’)/g, '\'');
        result = LanguageService.instance.removeDiacritics(result);
        if (language && language === 'en') {
            let modified = false;
            if (pos === 'NOUN' || pos === 'PHRASE') {
                if (result.startsWith('the ') && result.length > 4) {
                    result = result.substring(4);
                    modified = true;
                }
            }
            if (!modified && (pos === 'VERB' || pos === 'PHRASE')) {
                if (result.startsWith('to ') && result.length > 3) {
                    result = result.substring(3);
                    modified = true;
                }
            }
        }
        return result;
    }

    public diffPrettyHtml(diff, markAdded?: boolean, withTags = true) {
        return JsDiffService.instance.diffPrettyHtml(diff, markAdded, withTags);
    }

    public cleanupText(text: string): string {
        return text ? text.replace(/[\(\)\[\]]/g, ' ').replace(/\s/g, ' ').replace(/\s{2,}/g, ' ').trim() : text;
    }

    // 1. computes diffs between answer and every expectedValue
    // 2. defines out of expected ones the closest diff to the answer
    public closestDiff(answer, expectedValues) {
        const self = this;
        let chosenExpectedValueDiff = null;
        if (answer) {
            answer = self.cleanupText(answer);
            if (expectedValues && expectedValues.length > 0) {
                const allDiffs = [];

                // compute a diff for every expectedValue
                _.each(expectedValues, (originalExpectedValue: string) => {
                    const expectedValue = self.cleanupText(originalExpectedValue);
                    const currentDiff = JsDiffService.instance.diff(answer, expectedValue);
                    allDiffs.push({
                        diff: currentDiff,
                        value: expectedValue,
                        originalValue: originalExpectedValue
                    });
                });

                // let's define the "best" diff
                let firstDifferences = 0;
                let firstSimilarities = 0;
                _.each(allDiffs[0].diff, (d: any) => {
                    if (d.added === true || d.removed === true) {
                        firstDifferences += d.count;
                    } else {
                        firstSimilarities += d.count;
                    }
                });

                // if there are multiple possible answers find the best matching one other take that only one
                if (allDiffs.length > 1) {
                    let nextBestDiff = null;
                    let nextBestDifferences = 0;
                    let nextBestSimilarities = 0;

                    // define the best match looking at all the defined above diffs
                    for (let i = 1; i < allDiffs.length; i++) {
                        const nextDiff = allDiffs[i];
                        let nextDifferences = 0;
                        let nextSimilarities = 0;
                        _.each(nextDiff.diff, (d: any) => {
                            if (d.added === true || d.removed === true) {
                                nextDifferences += d.count;
                            } else {
                                nextSimilarities += d.count;
                            }
                        });
                        if (!nextBestDiff || (nextSimilarities > nextBestSimilarities && nextDifferences <= nextBestDifferences)) {
                            nextBestDiff = nextDiff;
                            nextBestDifferences = nextDifferences;
                            nextBestSimilarities = nextSimilarities;
                        }
                    }

                    if (nextBestDiff && nextBestSimilarities > firstSimilarities + 1 && nextBestDifferences <= firstDifferences) {
                        chosenExpectedValueDiff = nextBestDiff;
                    } else {
                        chosenExpectedValueDiff = allDiffs[0];
                    }
                } else {
                    chosenExpectedValueDiff = allDiffs[0];
                }
            }
        }
        return chosenExpectedValueDiff;
    }

    public fuzzyCompareAnswers(providedAnswers: string[], expectedAnswers: string[], fuzzyRatio = 0.8, language?: string, pos?: string): any {
        let correct = false;
        let answer = null;
        let definedExpectedAnswer = null;
        let userProvidedAnswer = providedAnswers && providedAnswers.length > 0 ? providedAnswers[0] : null;
        let score = 0;
        let maxScore = 0;
        const preparedProvidedAnswers = providedAnswers && providedAnswers.length > 0 ? _.map(providedAnswers, (pa) => this.prepareForComparison(pa, language, pos)) : [];
        const preparedExpectedAnswers = expectedAnswers && expectedAnswers.length > 0 ? _.map(expectedAnswers, (ea) => this.prepareForComparison(ea, language, pos)) : [];
        for (let i = 0; i < providedAnswers.length; i++) {
            const providedAnswer = providedAnswers[i];
            const provided = preparedProvidedAnswers[i];
            if (provided.length === 0) {
                continue;
            }
            const containingIdx = preparedExpectedAnswers.indexOf(provided);
            if (containingIdx >= 0) {
                correct = true;
                score = 1.0;
                answer = expectedAnswers[containingIdx];
                definedExpectedAnswer = expectedAnswers[containingIdx];
                userProvidedAnswer = providedAnswer;
                break;
            }
            for (let j = 0; j < expectedAnswers.length; j++) {
                const expectedAnswer = expectedAnswers[j];
                const expected = preparedExpectedAnswers[j];
                if (expected.length === 0) {
                    continue;
                }
                if (provided.includes(expected)) {
                    correct = true;
                    score = 1.0;
                    answer = expectedAnswer;
                    definedExpectedAnswer = expectedAnswer;
                    userProvidedAnswer = providedAnswer;
                    break;
                }

                // use levenshtein distance for fuzzy match
                // https://en.wikipedia.org/wiki/Levenshtein_distance
                const matrix = [];
                // 1. increment along the first column of each row
                for (let i = 0; i <= expected.length; i++) {
                    matrix[i] = [i];
                }
                // 2. increment each column in the first row
                for (let j = 0; j <= provided.length; j++) {
                    matrix[0][j] = j;
                }
                // 3. fill in the rest of the matrix
                for (let i = 1; i <= expected.length; i++) {
                    for (let j = 1; j <= provided.length; j++) {
                        if (expected.charAt(i - 1) === provided.charAt(j - 1)) {
                            matrix[i][j] = matrix[i - 1][j - 1];
                        } else {
                            matrix[i][j] = Math.min(
                                matrix[i - 1][j - 1] + 1,       // substitution
                                Math.min(
                                    matrix[i][j - 1] + 1,       // insertion
                                    matrix[i - 1][j] + 1        // deletion
                                )
                            );
                        }
                    }
                }
                const fuzzyDistance = matrix[expected.length][provided.length];
                const cLength = Math.max(provided.length, expected.length);
                score = 1.0 - (fuzzyDistance / cLength);
                if (score >= maxScore) {
                    maxScore = score;
                    answer = providedAnswer;
                    definedExpectedAnswer = expectedAnswer;
                    userProvidedAnswer = providedAnswer;
                }
                if (score > fuzzyRatio) {
                    correct = true;
                    answer = expectedAnswer;
                    definedExpectedAnswer = expectedAnswer;
                    userProvidedAnswer = providedAnswer;
                }
            }
            if (correct) {
                break;
            }
        }
        return {
            score,
            correct,
            answer,
            definedExpectedAnswer,
            userProvidedAnswer
        };
    }

    public adjustInput(input: string, iso: string): string {
        if (input && input.length > 0) {
            input = input.replace(/[.,?!;:]/g, ' ').replace(/\s/g, ' ').replace(/\s{2,}/g, ' ');
            const words: string[] = input.split(' ');
            input = ' ';
            let indexToSkip = -1;
            _.each(words, (word, idx) => {
                let skip = false;
                if (indexToSkip > -1) {
                    if (idx > indexToSkip) {
                        indexToSkip = -1;
                    } else {
                        skip = true;
                    }
                }
                if (!skip && iso === 'en') {
                    if (word === 'ergh' || word === 'erm' || word === 'uhm' || word === 'er' || word === 'um') {
                        skip = true;
                    }
                }
                if (!skip && input.endsWith(' ' + word + ' ')) {
                    skip = true;
                }
                if (!skip && idx < words.length - 1) {
                    let temp = '';
                    for (let i = idx; i < words.length; i++) {
                        temp += words[i] + ' ';
                        if (input.endsWith(' ' + temp)) {
                            skip = true;
                            indexToSkip = i;
                            break;
                        }
                    }
                }
                if (!skip) {
                    input += word + ' ';
                }
            });
        }
        if (input && input.startsWith(' ') && input.length > 1) {
            input = input.substr(1);
        }
        return input;
    }
}
