import * as React from 'react';

// Describes the slides syntax to the content editors
export const syntaxPopupContent = (): JSX.Element => {
    return (
        <>
            <p className='bold'> Content Syntax </p> <br />
            <p>
                <span className='highlight'>[</span>abc<span className='highlight'>]</span> <br /> -> <br /> abc will be{' '}
                <span className='highlight'>highlighted</span>
            </p>
            <br />
            <p>
                <span className='highlight'>{'<'}</span>abc<span className='highlight'>{'>'}</span> <br /> -> <br /> abc will be{' '}
                <span className='highlight'>faded</span>
            </p>
            <br />
            <p>
                <span className='highlight'>{'{'}</span>def<span className='highlight'>|</span>abc
                <span className='highlight'>{'}'}</span> <br /> -> <br /> def will be at the top of abc in a
                <span className='highlight'> circle </span> as an explanation
            </p>
            <br />
            <p>
                <span className='highlight'>-</span> abc <br /> <span className='highlight'>-</span> def <br />
                <span className='highlight'>-</span> ghi <br /> -> <br /> abc, def and ghi will be in
                <span className='highlight'> dialog </span> format and they will be displayed vertically
                <span style={{ fontStyle: 'italic', opacity: 0.75 }}>
                    {' '}
                    (consider using the dialog macro to create dialogs instead of writing them yourself)
                </span>
            </p>
            <br />
            <p>
                <span className='highlight'>...</span> <br /> -> <br />
                <span className='highlight'> Long underline </span>
            </p>
            <br />
            <br />
            <br />
            <p className='bold'>Macros Syntax</p> <br />
            <br />
            <p className='highlight'>Karaoke</p> <br />
            <p>abc def ghi</p>
            <br />
            <p>
                ----->{'  '}turns to{'  '}----->
            </p>
            <br />
            <div className='row'>
                <div className='col is-eq'>
                    <p>[abc] def ghi</p>
                </div>
                <div className='col is-eq'>
                    <p>abc [def] ghi</p>
                </div>
                <div className='col is-eq'>
                    <p>abc def [ghi]</p>
                </div>
            </div>
            <br />
            <br />
            <p className='highlight'>Dialog</p> <br />
            <p>
                abc
                <br />
                def
                <br />
                ghi
            </p>
            <br />
            <p>
                ----->{'  '}turns to{'  '}----->
            </p>
            <br />
            <div className='row'>
                <div className='col is-eq'>
                    <p>
                        - abc
                        <br />-<br />-
                    </p>
                </div>
                <div className='col is-eq'>
                    <p>
                        - abc
                        <br />- def
                        <br />-
                    </p>
                </div>
                <div className='col is-eq'>
                    <p>
                        - abc
                        <br />- def
                        <br />- ghi
                    </p>
                </div>
            </div>
        </>
    );
};
