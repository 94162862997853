import * as Promise from 'promise';
import axios from 'axios/index';
import {AbstractService} from './abstract.service';

export class VoucherService extends AbstractService {

    private static _instance: VoucherService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    getAllByCampaign(campaign: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.get(
                '/api/vouchers/campaign/' + campaign
            )
                .then((response) => {
                    const vouchers = response.data;
                    if (vouchers) {
                        resolve(vouchers);
                    } else {
                        reject('No vouchers found!');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    addVouchersForCampaign(data: any, campaign: string): Promise<any> {
        const self = this;
        return new Promise<any>((resolve, reject) => {
            axios.post(
                '/api/vouchers/campaign/' + campaign,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject('No data received');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }
}
