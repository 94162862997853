import * as React from 'react';
import MainFooter from '../global/MainFooter';
import { Course, UnitExercise } from '../../models/course.model';
import { CourseService } from '../../services/course.service';
import PremiumCoursesRow from './PremiumCourses/PremiumCoursesRow';
import CoursesHeader from './CoursesHeader';
import { CoursesState } from '../../models/courses.state.model';
import { LanguageService } from '../../services/language.service';
import { isNullOrUndefined } from 'util';
import { AuthService } from '../../services/auth.service';

import { TimelineMax } from 'gsap/TimelineMax';
import CrashCoursesRow from './CrashCourses/CrashCoursesRow';

interface IProps {
    langISO?: string;
}

interface IState {
    freeLessons: Array<UnitExercise>;
    crashCourses: Array<Course>;
    premiumCourses: Array<Course>;
    user: any;
    myCourses: Set<string> | null;
}

export default class Courses extends React.Component<IProps, IState> {
    currentSupportedLanguages = ['es'];
    langISO: string;
    coursesState: CoursesState;

    state = {
        // hidden in ui
        freeLessons: null,
        // hidden in ui
        crashCourses: null,
        // Show all according to the lang
        premiumCourses: null,
        user: null,
        myCourses: null,
    };

    componentWillMount() {
        AuthService.instance.loadUser().then((user) => {
            this.setState({user});
        });

        if (isNullOrUndefined(this.props.langISO)) {
            const lang: string = (this.props as any).match.params.lang;
            this.langISO = LanguageService.instance.isoByName(lang);
        } else {
            this.langISO = this.props.langISO;
        }
    }

    componentDidMount(): void {
        this.init();
    }

    init = async () => {
        await this.fetchAllRelevantCoursesAndLessons();
        await this.loadCourses();
        this.filterAndSetRelevantCoursesAndLessons();
    };

    /**
     * Get ->
     * - free lessons (not possible now)
     * - crash courses (not possible now)
     * - premium courses
     */
    fetchAllRelevantCoursesAndLessons = async() => {
        this.coursesState = await CourseService.instance.getCoursesStateForUserV2();
    };

    /**
     * Use this.langISO to filter and set the courses in the previously fetched coursesState
     */
    filterAndSetRelevantCoursesAndLessons = (): void => {
        const premiumCourses = this.coursesState.openedCourses.filter((course) => course.courseLangISO === this.langISO);
        const crashCourses = this.coursesState.crashCourses.filter((course) => course.courseLangISO === this.langISO);
        this.setState({ premiumCourses, crashCourses });
    };

    loadCourses = (): void => {
        CourseService.instance.getCoursesStateForUserV2().then((coursesState: CoursesState) => {
            this.addCourses(coursesState);
        });
    }

    // loading the state with all courses present in DB
    addCourses = (coursesState: CoursesState): void => {
        const { openedCourses } = coursesState;
        const myCourses = new Set<string>();

        if (openedCourses.length !== 0) {
            // for every course check access for the user, BE doesn't do it because on mobile we show all courses, i.e. with and without access
            for (const i in openedCourses) {
                const userCourse = openedCourses[i].userCourse;
                if (userCourse && userCourse.activationStartDate && userCourse.activationEndDate) {
                    const startDateMs: number = (new Date(userCourse.activationStartDate)).getTime();    // ISO time to milliseconds
                    const endDateMs: number = (new Date(userCourse.activationEndDate)).getTime();    // ISO time to milliseconds

                    if (startDateMs <= Date.now() && Date.now() <= endDateMs) {
                        myCourses.add(openedCourses[i].name);
                    }
                }
            }
        }

        this.setState({myCourses});
    }

    changeLanguage = (langISO: string) => {
        this.langISO = langISO;

        const tl = new TimelineMax();
        tl
            .to('#courses', .6, {autoAlpha: 0})
            .call(this.filterAndSetRelevantCoursesAndLessons, null)
            .to('#courses', .6, {autoAlpha: 1})
        ;
        // this.filterAndSetRelevantCoursesAndLessons();
    };

    render(): React.ReactNode {
        if (this.currentSupportedLanguages.indexOf(this.langISO) < 0) {
            return (
                <div>Sorry, we couldn't find the language you are searching for, are you sure you have selected an existing language?</div>
            );
        }
        return (
            <>
                <div id='courses' className='frame'>
                    <CoursesHeader langISO={this.langISO} changeLanguage={this.changeLanguage} />
                    {/*<CrashCoursesRow crashCourses={this.state.crashCourses} user={this.state.user} />*/}
                    <PremiumCoursesRow
                        premiumCourses={this.state.premiumCourses}
                        myCourses={this.state.myCourses}
                    />
                </div>
                <MainFooter />
            </>
        );
    }
}
