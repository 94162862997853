'use strict';

import * as Promise from 'promise';
import {AbstractService} from './abstract.service';
import {LanguageService} from './language.service';
import {AuthService} from './auth.service';
import {GoogleOptimiseService} from './google.optimise.service';
import {RegistrationService} from './registration.service';

export class InitService extends AbstractService {

    private static _instance: InitService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    load(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            LanguageService.instance.getAll().then(() => {
                RegistrationService.instance.retrieveRegistrationChannel();
                GoogleOptimiseService.instance.initialize().then(() => {
                    AuthService.instance.loadUser().then(resolve).catch(resolve);
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }
}
