import { AbstractService } from './abstract.service';
import axios from 'axios'

export class MagicPlayerService extends AbstractService {

    private static _instance: MagicPlayerService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    // Returns the thumbnail url
    public getVimeoThumb = (videoId: string): Promise<string> => {
        const self = this;
        return new Promise<string>((resolve, reject) => {
            axios.get(`api/vimeo/video/${videoId}/thumb`)
            .then((response) => {
                const thumb: string = response.data;
                if (thumb !== null) {
                    resolve(thumb);
                } else {
                    reject('Cannot find the thumb');
                }
            })
            .catch((error) => {
                reject(this.errorToMessage(error));
            });
        });
    }
}
