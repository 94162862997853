import ReactHtmlParser from 'react-html-parser';

import { syntax } from '../config/syntax';
import { SlideStylesSlide } from '../config/slideStyles.model';

export const slideContentParser = (content: string, style?: SlideStylesSlide): any => {
    if (content === '') {
        return '';
    } else {

        if (content[0] === '-' && content[1] === ' ') {
            content = dialogFunction(content);
        } else {
            content = baseFunction(content);
        }

        return ReactHtmlParser('<span class="slide-content-wrapper"' + (style ? (style.width === '100%' ? ' style="text-align: center"' : '') : '') + '>' + content + '</span>');
    }
};

// BOLD, FADED, LABEL, UNDERLINE
const baseFunction = (content: string): string => {
    const { bold, faded, underline } = syntax;

    // Special treatment for FADED
    content = content
        .replace(new RegExp(faded.open, 'g'), '<span class="slide-faded"')
        .replace(new RegExp(faded.close, 'g'), '</span>')
        .replace(new RegExp('<span class="slide-faded"', 'g'), '<span class="slide-faded">')

    // Special treatment for LABEL
    content = wordlabelFunction(content);

    // Replace the open signs according to the related stylings and classes
    content = content.replace(new RegExp('\\' + bold.open, 'g'), '<span class="slide-bold">');

    // Replace all close signs with </span>
    content = content
        .replace(new RegExp(bold.close, 'g'), '</span>');

    // Conculde everything with the special case syntax
    content = content
        .replace(new RegExp('\\.\\.\\.', 'g'), '<span class="slide-underline-wrapper"><span class="slide-underline-inner"></span></span>');

    return content;
};

// DIALOG
const dialogFunction = (content: string): string => {
    const { dialog } = syntax;

    content = content.replace(new RegExp(dialog.sign, 'g'), '');
    const subContents: string[] = content.split('\n');

    subContents.forEach((subContent, idx) => {
        if (subContent === '') {
            subContents[idx] = '<span class="slide-dialog">&nbsp;</span>';
        } else {
            subContents[idx] = '<span class="slide-dialog">' + baseFunction(subContent) + '</span>';
        }
    })

    return '<span class="slide-dialog-wrapper">' + subContents.join('') + '</span>';
};

// CIRCLE - private
const wordlabelFunction = (content: string): string => {
    const { label } = syntax;
    content = content
        .replace(new RegExp(label.text.open, 'g'), '<span class="slide-word-wrapper"><span class="slide-wordlabel">')
        .replace(new RegExp('\\' + label.label.sign, 'g'), '</span>')
        .replace(new RegExp(label.text.close, 'g'), '</span>')
    return content;
};
