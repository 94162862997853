import * as React from 'react';
import {Link} from 'react-router-dom';
import * as logo from '../../../../../../assets/images/newmagic/logo_caminobarcelona.png';
import { withNamespaces } from 'react-i18next';
import './camino-footer.sass';

@withNamespaces('footer')
export default class CaminoMainFooter extends React.Component<any, any> {

    componentDidMount() {
        let link: any = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = 'https://www.caminobarcelona.de/favicon.ico';
        document.getElementsByTagName('head')[0].appendChild(link);

        document.title = 'Free trial - Premium Online Course by Camino Barcelona';
    }

    render(): React.ReactNode {
        const {t} = this.props;
        return (
            <footer id='main-footer'>
                <div className='frame'>
                    <img id='school-seals' src='https://www.caminobarcelona.com/img/camino/footer-logos.jpg' />

                    <div id='footer-top'>
                        <img id='footer-logo' src={logo} alt='Logo' />
                    </div>
                    <br/><br/>
                    <div className='copyright'>© 2023 Camino Barcelona, Calle Diputación 119, 08015 Barcelona, Spain</div>
                </div>
            </footer>
        );
    }
}
