import * as React from 'react';
import { withNamespaces } from 'react-i18next';
import { AuthService } from '../services/auth.service';
import { Link, Redirect } from 'react-router-dom';

import * as iconClose from '../../../../assets/images/newmagic/icon-close.svg';
import { CourseService } from '../services/course.service';

const USER_DEFAULT: string = 'DEFAULT';
const USER_PREMIUM = 'PREMIUM';
const USER_BETA = 'BETA';

@withNamespaces('login')
export default class Login extends React.Component<any, any> {
    state = {
        redirectToReferrer: false,
        authenticationError: null,
        registrationError: null
    };

    login = (e): void => {
        e.preventDefault();
        const email: string = $('#login-form-username').val() as string;
        const password: string = $('#login-form-password').val() as string;
        this.setState({
            redirectToReferrer: false,
            authenticationError: null,
            registrationError: null
        });
        AuthService.instance
            .login(email, password)
            .then(user => {
                this.setState({ error: null, redirectToReferrer: true });
            })
            .catch(error => {
                this.setState({ authenticationError: JSON.stringify(error), redirectToReferrer: false });
            });
    };

    render(): React.ReactNode {
        const { authenticationError, registrationError, redirectToReferrer } = this.state;
        const from = this.props.redirectUri || (redirectToReferrer ? AuthService.instance.getDirectoryByUserPermissions() : '/');
        if (redirectToReferrer) {
            if (this.props.onSuccess) {
                this.props.onSuccess();
            } else {
                return <Redirect to={from} />;
            }
        }
        return (
            <div className='auth-login auth-step'>
                <div className='auth-header popup-header'>
                    <header>Login</header>
                    <img className='action-close' src={iconClose} />
                </div>
                <div className='inner'>
                    <section className='social-buttons'>
                        <form id='signin-facebook' action='/connect/facebook' method='POST'>
                            <input type='hidden' name='scope' value='email,public_profile' />
                            {from && <input type='hidden' name='redirect' value={from} />}
                        </form>
                        <form id='signin-google' action='/connect/google' method='POST'>
                            <input type='hidden' name='scope' value='profile email' />
                            {from && <input type='hidden' name='redirect' value={from} />}
                        </form>
                        {/*<button className='facebook' type='submit' form='signin-facebook'>*/}
                        {/*    <span>*/}
                        {/*        <i>Log in with</i> Facebook*/}
                        {/*    </span>*/}
                        {/*</button>*/}
                        <button className='google' type='submit' form='signin-google'>
                            <span>
                                <i>Log in with</i> Google
                            </span>
                        </button>
                    </section>

                    <div className='separator'>
                        <i />
                        <span>or</span>
                        <i />
                    </div>

                    <form id='login-form' name='loginForm' role='form' onSubmit={this.login} autoComplete='on' noValidate>
                        <div className='input-row'>
                            <input
                                id='login-form-username'
                                type='text'
                                name='username'
                                placeholder='Username or email'
                                autoComplete='username'
                            />
                        </div>
                        <div className='input-row'>
                            <input
                                id='login-form-password'
                                type='password'
                                name='password'
                                placeholder='Password'
                                autoComplete='current-password'
                            />
                        </div>
                        <div className='info-row'>
                            {authenticationError && (
                                <div>
                                    <div>Sorry, we couldn't log you in!</div> Please check your credentials and try again.
                                </div>
                            )}
                            {registrationError && (
                                <div>
                                    <div>You already have an account. Please login with</div> your email/username and password.
                                </div>
                            )}
                        </div>
                        <div className='input-row submit-row'>
                            <button className='button' type='submit'>
                                Sign in
                            </button>
                        </div>
                        <div className='url-row'>
                            <Link data-step='password' to='/recover-password'>Forgot password?</Link>
                            <Link data-step='registration' to='/registration'>I don't have an account</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
