import * as React from 'react';
import {UserAgentService} from '../../services/user.agent.service';

export default class TutorHelp extends React.Component<any, any> {

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.intentIdx !== nextProps.intentIdx;
    };

    render(): React.ReactNode {

        if (!this.props.answers || this.props.answers.length < 1) {
            return (<div/>);
        }

        return (
            <div>
                {UserAgentService.instance.isMobile()
                    ?
                    <div id={this.props.helpId} className='info-box hint-module'>
                        <header>{this.props.header}</header>
                        <div className='inner'>
                            <div className='body'>
                                {this.props.answers.map((possibleAnswer, i) =>
                                    <div className='instruction' key={i}>“{possibleAnswer.value.replace(/[\[\]]/g, '')}”</div>)
                                }
                            </div>
                            <div>
                                <figure className='tutor-avatar'><img src={this.props.instructorAvatar} alt='Tutor' /></figure>
                                <header className='tutor-msg top'>
                                    <div>{this.props.tutorSays}</div>
                                </header>
                            </div>
                        </div>
                    </div>
                    :
                    <div id={this.props.helpId} className='info-box hint-module'>
                        <header>{this.props.header}</header>
                        <div className='inner'>
                            <div className='body'>
                                <header className='tutor-msg'>
                                    <div>{this.props.tutorSays}</div>
                                </header>
                                {this.props.answers.map((possibleAnswer, i) =>
                                    <div className='instruction' key={i}>“{possibleAnswer.value.replace(/[\[\]]/g, '')}”</div>)
                                }
                            </div>
                            <figure className='tutor-avatar'><img src={this.props.instructorAvatar} alt='Tutor' /></figure>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
