import * as React from 'react';
import axios from 'axios/index';
import {Link} from 'react-router-dom';
import * as _ from 'underscore';
import * as Promise from 'promise';
import Popup from './global/popup/Popup';
import {UserService} from '../services/user.service';

export default class AdminUserImport extends React.Component<any, any> {

    state = {
        textInput: '',
        processing: false,
        updatedDueToExistingLogin: [],
        updatedDueToExistingEmail: [],
        createdUsers: [],
        importError: '',
        importMsg: '',
        updatedUsers: [],
        failedDueToMissingUser: [],
        failedDueToMissingOrNotActiveSubscription: [],
        updateError: '',
        updateMsg: ''
    };

    packageMapping = {
        // TODO
    }

    componentDidMount(): void {

    }

    onTextInputChange = (event) => {
        this.setState({
            textInput: event.target.value
        });
    };

    onSubmitImport = (event) => {
        event.preventDefault();

        this.setState({processing: true});

        this.importUsers(this.state.textInput).then((response) => {
            this.setState({
                createdUsers: response.createdUsers,
                updatedDueToExistingLogin: response.updatedDueToExistingLogin,
                updatedDueToExistingEmail: response.updatedDueToExistingEmail,
                importMsg: response.updatedDueToExistingLogin.length === 0 && response.updatedDueToExistingLogin.length === 0
                    ? 'All users successfully imported'
                    : `${response.createdUsers.length} users created,
                    ${response.updatedDueToExistingLogin.length} users updated due to existing login,
                    ${response.updatedDueToExistingEmail.length} users updated due to existing email `,
                processing: false
            });
        }, (response) => {
            this.setState({processing: false});
        });
    };

    onSubmitUpdate = (event) => {
        event.preventDefault();

        this.setState({processing: true});

        this.updateImportedUsers(this.state.textInput).then((response) => {
            this.setState({
                updatedUsers: response.updatedUsers,
                failedDueToMissingUser: response.failedDueToMissingUser,
                failedDueToMissingOrNotActiveSubscription: response.failedDueToMissingOrNotActiveSubscription,
                importMsg: response.failedDueToMissingUser.length === 0 && response.failedDueToMissingOrNotActiveSubscription.length === 0
                    ? 'All users successfully update'
                    : `${response.updatedUsers.length} users updated,
                    ${response.failedDueToMissingUser.length} users failed due to missing user,
                    ${response.failedDueToMissingOrNotActiveSubscription.length} users failed due to missing active subscription `,
                processing: false
            });
        }, (response) => {
            this.setState({processing: false});
        });
    };

    parseData = (jsonStr) => {
        jsonStr = jsonStr[jsonStr.length - 1] === ',' ? jsonStr.substring(0, jsonStr.length - 1) : jsonStr;
        jsonStr = jsonStr[0] === '[' ? jsonStr : '[' + jsonStr + ']';
        jsonStr = jsonStr.replace(/'/g, '"');

        try {
            return JSON.parse(jsonStr);
        } catch (e) {
            return null;
        }
    };

    importUsers = (jsonStr): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            const data = this.parseData(jsonStr);

            if (data !== null) {
                UserService.instance.addUsers(data)
                    .then((importResult) => {
                        resolve({
                            createdUsers: importResult.createdUsers,
                            updatedDueToExistingLogin: importResult.updatedDueToExistingLogin,
                            updatedDueToExistingEmail: importResult.updatedDueToExistingEmail
                        });
                    })
                    .catch((error) => {
                        console.log('error during user import: ' + error)
                    })
            } else {
                reject({'errorMsg': 'Data invalid'});
            }

        });
    };

    updateImportedUsers = (jsonStr): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            const data = this.parseData(jsonStr);

            if (data !== null) {
                UserService.instance.updateUsersDueToSelectedCourse(data)
                    .then((updateResult) => {
                        resolve({
                            updatedUsers: updateResult.updatedUsers,
                            failedDueToMissingUser: updateResult.failedDueToMissingUser,
                            failedDueToMissingOrNotActiveSubscription: updateResult.failedDueToMissingOrNotActiveSubscription
                        });
                    })
                    .catch((error) => {
                        console.log('error during user update: ' + error)
                    })
            } else {
                reject({'errorMsg': 'Data invalid'});
            }

        });
    };

    render(): React.ReactNode {
        return (
            <div className='frame admin-page'>
                <header className='page-header'>
                    <h1>Import users</h1>
                </header>
                <div id='user-import-container'>
                    <div id='import-input'>
                        <form onSubmit={this.onSubmitImport}>
                            <header>Paste your json</header>
                            <div className='input-row'>
                                <textarea
                                    value={this.state.textInput || ''}
                                    onChange={(e) => this.onTextInputChange(e)}
                                    disabled={this.state.processing}
                                />
                            </div>
                            <div className='submit-row alr'>
                                <button className='button primary' disabled={this.state.processing}>Start import</button>
                            </div>
                        </form>
                    </div>
                    <div id='import-output'>
                        <header>Output</header>

                        <div className='msg success'>{this.state.importMsg}</div>
                        <div className='msg error'>{this.state.importError}</div>

                        {this.state.updatedDueToExistingLogin && this.state.updatedDueToExistingLogin.length > 0 &&
                        <div id='updated-due-to-login-users' className='user-list'>
                            <header>Updated due to existing login</header>
                            {this.state.updatedDueToExistingLogin.map((user, i) =>
                                <div key={i}>{user.login} - {user.email}</div>
                            )}
                        </div>
                        }

                        {this.state.updatedDueToExistingEmail && this.state.updatedDueToExistingEmail.length > 0 &&
                        <div id='updated-due-to-email-users' className='user-list'>
                            <header>Updated due to existing email</header>
                            {this.state.updatedDueToExistingEmail.map((user, i) =>
                                <div key={i}>{user.login} - {user.email}</div>
                            )}
                        </div>
                        }

                        {this.state.createdUsers && this.state.createdUsers.length > 0 &&
                            <div id='created-users' className='user-list'>
                                <header>Created</header>
                                {this.state.createdUsers.map((user, i) =>
                                    <div key={i}>{user.login} - {user.email}</div>
                                )}
                            </div>
                        }
                    </div>
                </div>
                <br/>
                <br/>
                <div id='user-update-container'>
                    <div id='update-input'>
                        <form onSubmit={this.onSubmitUpdate}>
                            <header>Paste your json</header>
                            <div className='input-row'>
                                <textarea
                                    value={this.state.textInput || ''}
                                    onChange={(e) => this.onTextInputChange(e)}
                                    disabled={this.state.processing}
                                />
                            </div>
                            <div className='submit-row alr'>
                                <button className='button primary' disabled={this.state.processing}>Start update</button>
                            </div>
                        </form>
                    </div>
                    <div id='update-output'>
                        <header>Output</header>

                        <div className='msg success'>{this.state.updateMsg}</div>
                        <div className='msg error'>{this.state.updateError}</div>

                        {this.state.updatedUsers && this.state.updatedUsers.length > 0 &&
                        <div id='updated-with-course' className='user-list'>
                            <header>Updated with selected course</header>
                            {this.state.updatedUsers.map((user, i) =>
                                <div key={i}>{user.email} - {user.selectedCourse}</div>
                            )}
                        </div>
                        }

                        {this.state.failedDueToMissingUser && this.state.failedDueToMissingUser.length > 0 &&
                        <div id='failed-due-to-missing-user' className='user-list'>
                            <header>Failed update due to missing user</header>
                            {this.state.failedDueToMissingUser.map((user, i) =>
                                <div key={i}>{user.email} - {user.selectedCourse}</div>
                            )}
                        </div>
                        }

                        {this.state.failedDueToMissingOrNotActiveSubscription && this.state.failedDueToMissingOrNotActiveSubscription.length > 0 &&
                            <div id='failed-due-to-missing-subscription' className='user-list'>
                                <header>Failed update due to missing active subscription</header>
                                {this.state.failedDueToMissingOrNotActiveSubscription.map((user, i) =>
                                    <div key={i}>{user.email} - {user.selectedCourse}</div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
