import * as React from 'react';
import { Link } from 'react-router-dom';

import { ChapterRowForCourseEditor } from './ChapterRow';
import { EditorDropdown } from '../Utility/EditorDropdown';

import { DialogueService } from '../../../services/dialogue.service';

import { UnitExercise } from '../../../models/course.model';
import { Dialogue } from '../../../models/dialogue.model';
import { Context } from '../../../models/context.model';

import * as editIcon from '../../../../../../assets/images/newmagic/edit.svg';
import * as editWhiteIcon from '../../../../../../assets/images/newmagic/edit-white.svg';
import * as checkIcon from '../../../../../../assets/images/newmagic/check.svg';

interface LessonCardForCourseEditorProps {
    exercise: UnitExercise;
    functions: any;
    unitId: string;
    unitIdx: number;
    idx: number;
    courseName: string;
    language: string;
}

interface LessonCardForCourseEditorState {
    exercise: UnitExercise;
    dialogue: Dialogue;
    titleEditable: boolean;
    titleTrEditable: boolean;
}

export class LessonCardForCourseEditor extends React.Component<LessonCardForCourseEditorProps, LessonCardForCourseEditorState> {
    constructor(props) {
        super(props);

        this.state = {
            exercise: null as UnitExercise,
            dialogue: null as Dialogue,
            titleEditable: false as boolean,
            titleTrEditable: false as boolean
        };

        this.toggleTitleEditable = this.toggleTitleEditable.bind(this);
        this.toggleTitleTrEditable = this.toggleTitleTrEditable.bind(this);
        this.editTitle = this.editTitle.bind(this);
        this.editTitleTr = this.editTitleTr.bind(this);
    }

    componentDidMount() {
        this.setState({ exercise: this.props.exercise }, () => {
            if (this.props.exercise.type != 'tutor') {
                this.state.exercise.seqAlpha
                    ? DialogueService.instance.getDialogue(this.state.exercise.seqAlpha).then(dialogue => {
                            this.setState({ dialogue: dialogue }, () => {
                                // Send the number of questions to the main course overview component to show in ui
                                this.props.functions.recordNumberOfQuestions(
                                    this.findNumberOfQuestions()
                                );
                            });
                      })
                    : console.log('seqAlpha null or not found');
            } else {
                this.setState({ dialogue: null });
            }
        });
    }

    componentWillReceiveProps(nextProps: LessonCardForCourseEditorProps) {
        this.setState({ exercise: nextProps.exercise }, () => {
            if (nextProps.exercise.type != 'tutor') {
                this.state.exercise.seqAlpha
                    ? DialogueService.instance.getDialogue(this.state.exercise.seqAlpha).then(dialogue => {
                          this.setState({ dialogue: dialogue });
                      })
                    : console.log('seqAlpha null or not found');
            } else {
                this.setState({ dialogue: null });
            }
        });
    }

    findNumberOfQuestions = (): number => {
        let numberOfQuestions: number = 0;
        this.state.dialogue.contexts.forEach(context => {
            numberOfQuestions += context.intents.length
        });
        return numberOfQuestions;
    }

    toggleTitleEditable = (): void => {
        this.setState(prevState => {
            return { titleEditable: !prevState.titleEditable };
        });
    };

    toggleTitleTrEditable = (): void => {
        this.setState(prevState => {
            return { titleTrEditable: !prevState.titleTrEditable };
        });
    };

    editTitle = (e: any): void => {
        this.props.functions.editTitle(
            this.state.dialogue,
            e.target.textContent,
            this.props.unitIdx,
            this.props.idx,
            this.props.exercise.type
        );
        this.setState({ titleEditable: false });
    };

    editTitleTr = (e: any): void => {
        this.setState({ titleTrEditable: false });
        this.props.functions.editTitleTr(
            this.state.dialogue,
            e.target.textContent,
            this.props.unitIdx,
            this.props.idx,
            this.props.exercise.type
        );
    };

    render() {
        const { exercise, dialogue } = this.state;
        const { functions, language } = this.props;
        const courseName = this.props.courseName;
        return (
            <div className='exercise-card col'>
                <div className='exercise-card-header col'>
                    <div className='exercise-card-header-topline row'>
                        <Link
                            className='editor-icon sharp'
                            target='_blank'
                            to={`/${language ? language : `noLanguage`}/learning/${
                                exercise ? (exercise.seqAlpha ? exercise.seqAlpha : `noSeqAlpha`) : `noExercise`
                            }?course=${courseName ? courseName : `noCourseName`}`}
                        >
                            {exercise ? (
                                exercise.available ? (
                                    <svg width='24' height='24' stroke='green' fill='none' stroke-width='2'>
                                        <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' />
                                        <circle cx='12' cy='12' r='3' />
                                    </svg>
                                ) : exercise.available === false ? (
                                    <svg width='24' height='24' stroke='black' fill='none' stroke-width='2'>
                                        <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' />
                                        <circle cx='12' cy='12' r='3' />
                                    </svg>
                                ) : (
                                    <svg width='24' height='24' stroke='red' fill='none' stroke-width='2'>
                                        <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' />
                                        <circle cx='12' cy='12' r='3' />
                                    </svg>
                                )
                            ) : (
                                `no exercise`
                            )}
                        </Link>

                        <div
                            className='exercise-card-image'
                            style={
                                exercise
                                    ? {
                                          backgroundImage: `url(${exercise.img})`
                                      }
                                    : null
                            }
                            onClick={() => functions.editImageOpenPopup(this.props.unitId, this.props.exercise.seqAlpha, exercise.img)}
                        >
                            <img src={editWhiteIcon} className='image-edit-icon' />
                            <div className='image-edit-bg' />
                        </div>

                        <EditorDropdown
                            title='more-icon'
                            delete={() => functions.deleteDialogue(dialogue, exercise, this.props.unitId, exercise.type)}
                            availability={{
                                status: exercise ? exercise.available : null,
                                change: (value: boolean) => functions.changeAvailability(this.props.unitIdx, this.props.idx, value)
                            }}
                            move={{
                                right: () => functions.moveRight(this.props.unitIdx, this.props.idx),
                                left: () => functions.moveLeft(this.props.unitIdx, this.props.idx),
                                up: () => functions.moveUp(this.props.unitIdx, this.props.idx),
                                down: () => functions.moveDown(this.props.unitIdx, this.props.idx)
                            }}
                        />
                    </div>

                    <div className='exercise-card-seqalpha alc'>{exercise ? exercise.seqAlpha : ''}</div>

                    <div className='exercise-card-title row alc'>
                        {!this.state.titleEditable ? (
                            <span>
                                <Link to={exercise ? `/editor?seqAlpha=${exercise.seqAlpha}&course=${courseName ? courseName : `noCourseName`}` : `/editor/overview/courses`} target='_blank'>
                                    <span>{exercise ? exercise.title : 'loading..'}</span>
                                </Link>
                                <img className='title-edit-icon' src={editIcon} onClick={this.toggleTitleEditable} />
                            </span>
                        ) : (
                            <span>
                                <span contentEditable={true} className='editable-title' onBlur={this.editTitle}>
                                    {exercise ? exercise.title : 'loading..'}
                                </span>
                                <img className='title-edit-icon' src={checkIcon} onClick={this.toggleTitleEditable} />
                            </span>
                        )}
                    </div>

                    <div className='exercise-card-title-tr row alc'>
                        <span
                            contentEditable={this.state.titleTrEditable}
                            className={this.state.titleTrEditable ? `editable-title` : ``}
                            onBlur={this.editTitleTr}
                        >
                            {exercise ? exercise.titleTr : 'loading..'}
                            <img
                                className='title-tr-edit-icon'
                                src={!this.state.titleTrEditable ? editIcon : checkIcon}
                                onClick={this.toggleTitleTrEditable}
                            />
                        </span>
                    </div>
                </div>

                <div className='exercise-card-content-wrapper col'>
                    <div className='exercise-card-content col'>
                        {dialogue
                            ? dialogue.contexts.map((context: Context) => {
                                  return <ChapterRowForCourseEditor context={context} functions={this.props.functions.contextFunctions} />;
                              })
                            : exercise
                            ? exercise.seqAlpha
                                ? ''
                                : 'seqAlpha null or not found'
                            : ''}
                    </div>
                </div>
            </div>
        );
    }
}
