import * as React from "react";
import { Link } from "react-router-dom";

import { LessonCardForCourseEditor } from "./LessonCard";
import { EditorDropdown } from "../Utility/EditorDropdown";

import { CourseUnit, UnitExercise } from "../../../models/course.model";

type Props = {
    unit: CourseUnit;
    idx: number;
    courseName: string;
    functions: any;
    language: string;
};

export const UnitRowForCourseEditor = ({ unit, idx, courseName, functions, language }: Props) => {
    return (
        <div className="unit-row col">
            <div className="row">
                <span className="unit-idx">Unit {idx + 1}</span>
            </div>
            <div className="unit-title-row row">
                <span
                    className="unit-title editable-title"
                    contentEditable={true}
                    onBlur={e => functions.editTitle(e.target.textContent, idx)}
                >
                    {unit.title}
                </span>
                <EditorDropdown
                    title="more-icon"
                    delete={() => functions.deleteUnit(unit)}
                />
            </div>
            <div className="row unit-exercises">
                {unit.exercises.map(
                    (exercise: UnitExercise, exerciseIdx: number) => 
                        <LessonCardForCourseEditor
                            unitId={unit.id}
                            unitIdx={idx}
                            idx={exerciseIdx}
                            exercise={exercise}
                            courseName={courseName}
                            functions={functions.exerciseFunctions}
                            language={language}
                        />
                )}
                <div className="exercise-card add-unit-exercise col">
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            functions.addNewDialogue(unit);
                        }}
                    >
                        <input
                            type="text"
                            id={`new-dialogue-name-${unit.id}`}
                            name="new-dialogue-name"
                            placeholder="Dialogue name"
                        />
                        <br />
                        <select name='dialogue-types' id={`new-dialogue-type-${unit.id}`} style={{marginTop: '10px'}}>
                            <option value='Lesson'>Lesson</option>
                            <option value='Tutor'>Tutor</option>
                        </select>
                        <br />
                        <div style={{marginTop: '10px'}}>
                            <button className="button primary">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};