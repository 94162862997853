export const base64Mp3Prefix = `data:audio/mp3;base64,`;

export const normalizeBlobContent = (content: string, prefix = base64Mp3Prefix): string => {
    return content.startsWith(prefix) ? content : prefix + content;
};

export const denormalizeBlobContent = (content: string, prefix = base64Mp3Prefix): string => {
    return content.startsWith(prefix) ? content.substr(prefix.length) : content;
};

/**
 * @param checkBodyForBase64Prefix true if body must be checked for the bas 64 prefix, false otherwise
 * @param body can be encoded body or a link to the body stored in the browser
 * @param filename name of the file
 * @param prefix base64 prefix
 */
export const createAndDownloadBlobFile = (
    checkBodyForBase64Prefix: boolean,
    body: string,
    filename: string,
    prefix = base64Mp3Prefix,
): void => {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
        link.setAttribute('href', checkBodyForBase64Prefix ? normalizeBlobContent(body, prefix) : body);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
