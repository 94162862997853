import * as React from 'react';
import * as Promise from 'promise';
import {PaymentService, GENERAL_PAYMENT_FAILURE, EMAIL_ALREADY_IN_USE} from '../../services/payment.service';
import {Redirect} from 'react-router-dom';
import MainFooter from '../global/MainFooter';
import {AuthService} from '../../services/auth.service';

import * as qs from 'qs';

import {RegistrationService, RegistrationError} from '../../services/registration.service';
import {BrandingService} from '../../services/branding.service';
import {UserService} from '../../services/user.service';
import { TrackingService } from '../../services/tracking.service';
import { PaymentSuccessful } from './PaymentSuccessful';
import { FieldEnterPersonalData } from './FieldEnterPersonalData';
import { FieldSelectPaymentMethod } from './FieldSelectPaymentMethod';
import FieldEnterBillingInformation from './FieldEnterBillingInformation';
import { UserAddress } from '../../models/user.model';
import { FieldSelectLevel } from './FieldSelectLevel';
import {PurchaseDetails} from '../../models/purchase.details.model';
import {PurchaseRequest} from '../../models/purchase.request.model';

export default class Reserve extends React.Component<any, any> {

    planId: string;

    isChallenge: boolean;
    hasLevelSelection: boolean;

    hasCourse: boolean;
    courseName: string;
    hasCourseName: boolean;

    fieldEnterPersonalData: FieldEnterPersonalData;
    fieldEnterBillingAddress: FieldEnterBillingInformation;
    fieldSelectPaymentMethod: FieldSelectPaymentMethod;
    FieldSelectLevel: FieldSelectLevel;

    utm_campaign: string;

    isUserSignedin: boolean;
    userHasCredit: boolean;
    userCredit: number;

    referralCode: string;

    state = {
        paymentSuccessful: false,
        redirectTo: null,
        paymentInProgress: false,
        plan: null,
        course: null,
        isInputValid: false,
        learningLanguageCTA: 'de',
        voucherPaymentError: null as string,
        voucherCode: null,
        hasVoucher: false,
        partialVoucherCode: null,
    };

    componentWillMount(): void {
        const searchObj = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        this.planId = searchObj.plan;

        const voucherCode = searchObj.voucher;
        const course = searchObj.course;

        this.setState({
            course,
            voucherCode,
            hasVoucher: voucherCode != null,
        });

        this.hasCourse = course != null;
        this.courseName = searchObj.courseName;
        this.hasCourseName = this.courseName != null;

        this.referralCode = AuthService.instance.getReferralCode(searchObj);

        if (this.hasCourse) {
            this.setState({learningLanguageCTA: course.substr(0, course.indexOf('-'))});
        }

        // camino hotfix, define defaultLearningLanguage from the learning plan
        if (this.planId === '5e163d42282df992ce65fcf1' || this.planId === '5e163d42282df992ce65fcf3') {
            this.setState({learningLanguageCTA: 'es'});
        }

        // Mashable Hotfix
        this.isChallenge = this.hasCourse && searchObj.courseName.toLowerCase().indexOf('challenge') !== -1;
        this.hasLevelSelection = searchObj.level_selection && searchObj.level_selection === '1' || this.isChallenge;

        if (this.isChallenge) {
            // Set campaign. Default is 'mashable'. To pass a different campaign, we
            this.utm_campaign = 'mashable';
        }
        if (this.hasLevelSelection) {
            // Remove the level from the heading
            this.courseName = this.courseName.replace(' - Beginner level', '');
        }
        // end of mashable hotfix

        // Set campaign. If a different campaign is stated in the url we overwrite it.
        if (searchObj.utm_campaign) {
            this.utm_campaign = searchObj.utm_campaign;
        }

        this.isUserSignedin = AuthService.instance.isAuthenticated() && !AuthService.instance.isAnonymous();
        if (this.isUserSignedin) {
            this.userCredit = AuthService.instance.getUser().credit;
            this.userHasCredit = this.userCredit && this.userCredit > 0;
        }
    }

    componentDidMount(): void {
        PaymentService.instance.plan(this.planId)
            .then((plan) => {
                this.setState({plan})
            })
            .catch((error) => {
                // TODO : Do we want to handle it ???
            });
    }

    // For keeping track of some statistics
    dataLayerPush = (data: any): void => {
        data = data || {};
        const w: any = window;
        if (AuthService.instance.isAuthenticated() && !AuthService.instance.isAnonymous()) {
            AuthService.instance.loadUser().then((user) => {
                if (user && user.email) {
                    data['email'] = user.email;
                }
                if (user && user.reference) {
                    data['userReference'] = user.reference;
                }
                w.dataLayer.push(data);
            });
        } else {
            w.dataLayer.push(data);
        }
    };

    handleIsFormValid = (): void => {
        this.setIsInputValid(this.isValid(false));
    }

    // Checks all sub-fields if they are valid or not
    isValid = (highlightFields?: boolean): boolean => {
        return this.fieldEnterPersonalData.isValid(highlightFields) && (this.state.hasVoucher ? true : this.fieldEnterBillingAddress.isValid(highlightFields) && this.fieldSelectPaymentMethod.isValid());
    }

    // Will be specially used by the children components
    setIsInputValid = (isInputValid: boolean): void => {
        // Always keep isInputValid in either `true` or `false`
        if (isInputValid === null || isInputValid === undefined) {
            isInputValid = false;
        }
        this.setState({isInputValid});
    }

    /**
     * Call this if the payment is successful.
     * Calls preAuth, then loads the user. Then logs the user out if the plan is 10 weeks challenge
     */
    onCheckoutSuccess = (transactionId: any, paymentMethod: string): boolean => {
        const price = this.getFinalPrice();

        const is10WeeksChallenge = this.isPlan10WeeksChallenge();

        this.dataLayerPush({
            'transactionId': transactionId,  // payment gateway transaction id like braintree-id or internal voucher
            'transactionTotal': price,       // if voucher => 0, else if plan.discountPrice => plan.discountPrice else plan.price
            'event': 'buyPackage',
            'brand': BrandingService.instance.getBrand().toString(),    // magiclingua or camino or anja
            'language': this.state.learningLanguageCTA, // de, es, etc
            'course': this.state.course,                      // course name like de-beginner
            'transactionProducts': [{
                   'sku': this.state.plan.id,           // package id, learningPlan id
                   'name': this.state.plan.description, // plan's human readable name
                   'price': price,                      // again price as described above
                   'quantity': 1                        // required parameter, hardcode to 1 for all packages
            }]
        });

        const finishSuccess = () => {
            // MAG-1473: for 10 weeks challenge user must end up in logged out state
            if (is10WeeksChallenge) {
                AuthService.instance.logout();
            }
            this.setState({paymentSuccessful: true}, () => {
                window.scrollTo(0, 0);
            });
        };

        const finishError = () => {
            alert('Internal error happened! Please contact our customer support.');
        };

        const proceed = () => {
            AuthService.instance.loadUser().then(account => {
                PaymentService.instance.payTest({
                    'platform': 'web',
                    'planId': this.state.plan.id,
                    'planName': this.state.plan.name,
                    'course': this.state.course,
                    'value': price,
                    'currency': 'EUR',
                    'transactionId': transactionId,
                    'paymentMethod': paymentMethod,
                    'language': this.state.learningLanguageCTA,
                    'period': 'onetime',
                    'firstName': this.fieldEnterPersonalData.getUserFirstName() || account.firstName,
                    'lastName': this.fieldEnterPersonalData.getUserLastName() || account.lastName,
                    'email': this.fieldEnterPersonalData.getUserEmail() || account.email,
                    'brand': BrandingService.instance.getBrand().toString()
                }).then(() => {
                    finishSuccess();
                }).catch(() => {
                    finishError();
                });
            }, error => {
                finishError();
            });
        };

        this.preAuth().then(() => {
            proceed();
        }, () => {
            finishError();
        });
        return false;
    };

    setCourse = (course: string): void => {
        this.setState({course});
    };

    setVoucherCode = (code: string | null): void => {
        if (code === '100PERCENTOFF') {
            this.setState({
                voucherCode: code,
                hasVoucher: true,
                partialVoucherCode: null,
            }, this.handleIsFormValid);
        } else {
            this.setState({
                voucherCode: null,
                hasVoucher: false,
                partialVoucherCode: code,
            }, this.handleIsFormValid);
        }
    };

    /**
     * @if user is authenticated
     *  load user and update user
     * @if user is **not** authenticated
     *  fastReg user
     */
    preAuth = (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            if (AuthService.instance.isAuthenticated() && !AuthService.instance.isAnonymous()) {

                // check if firstName or lastName must be updated
                AuthService.instance.loadUser().then((user) => {
                    if (user) {
                        // existing loaded user doesn't have firstName or lastName or the existing do not match to new entered values then update user in DB
                        if ( !user.firstName || !user.lastName ||
                            user.firstName !== this.fieldEnterPersonalData.getUserFirstName() ||
                            user.lastName !== this.fieldEnterPersonalData.getUserLastName() ||
                            (!this.state.hasVoucher && !user.address) ||
                            (!this.state.hasVoucher && user.address.country !== this.fieldEnterBillingAddress.getUserCountry()) ||
                            (!this.state.hasVoucher && user.address.zipCode !== this.fieldEnterBillingAddress.getUserZipCode()) ||
                            (!this.state.hasVoucher && user.address.city !== this.fieldEnterBillingAddress.getUserCity()) ||
                            (!this.state.hasVoucher && user.address.state !== this.fieldEnterBillingAddress.getUserState()) ||
                            (!this.state.hasVoucher && user.address.openAddress !== this.fieldEnterBillingAddress.getUserOpenAddress())) {

                            user.firstName = this.fieldEnterPersonalData.getUserFirstName();
                            user.lastName = this.fieldEnterPersonalData.getUserLastName();
                            user.referral = this.referralCode;

                            if (!this.state.hasVoucher) {
                                if (user.address === null) {
                                    user.address = {};
                                }
                                user.address.country = this.fieldEnterBillingAddress.getUserCountry();
                                user.address.zipCode = this.fieldEnterBillingAddress.getUserZipCode();
                                user.address.city = this.fieldEnterBillingAddress.getUserCity();
                                user.address.state = this.fieldEnterBillingAddress.getUserState();
                                user.address.openAddress = this.fieldEnterBillingAddress.getUserOpenAddress();
                            }

                            UserService.instance.updateUser(user)
                                .then((importResult) => {
                                    resolve();
                                })
                                .catch((error) => {
                                    console.log('error during user update: ' + error)
                                })
                        }
                    }
                    resolve();
                });
            } else {
                const userParams = {
                    firstName: this.fieldEnterPersonalData.getUserFirstName(),
                    lastName: this.fieldEnterPersonalData.getUserLastName(),
                    brand: BrandingService.instance.getBrand().toString().toUpperCase(),
                    defaultLearningLanguage: this.state.learningLanguageCTA,
                    referral: this.referralCode
                } as any;

                const utmParams = {} as any;
                utmParams.utm_campaign = this.utm_campaign;

                if (!this.state.hasVoucher) {
                    userParams.address = new UserAddress({
                        country: this.fieldEnterBillingAddress.getUserCountry(),
                        zipCode: this.fieldEnterBillingAddress.getUserZipCode(),
                        city: this.fieldEnterBillingAddress.getUserCity(),
                        state: this.fieldEnterBillingAddress.getUserState(),
                        openAddress: this.fieldEnterBillingAddress.getUserOpenAddress()
                    });
                }

                if (!this.isUserSignedin) {
                    userParams.password = this.fieldEnterPersonalData.getUserPassword();
                    userParams.email = this.fieldEnterPersonalData.getUserEmail();
                }

                RegistrationService.instance.fastreg(userParams, utmParams).then(() => {
                    resolve();
                }).catch((result: {error: RegistrationError, errorDesc: string}) => {
                    if (result.error === RegistrationError.ERROR_EMAIL_EXISTS) {
                        this.setPaymentInProgress(false);
                        this.setState({voucherPaymentError: EMAIL_ALREADY_IN_USE});
                    } else {
                        this.setPaymentInProgress(false);
                        this.setState({voucherPaymentError: GENERAL_PAYMENT_FAILURE});
                        AuthService.instance.resetReferral();
                    }
                    reject(result);
                });
            }
        });
    };

    setPaymentInProgress = (isInProgress: boolean): void => {
        this.setState({paymentInProgress: isInProgress});
    };

    // No "actual" payment is done, currently there is only "full voucher"
    reserveWithVoucherCode = (e): void => {
        e.preventDefault();

        if (this.state.paymentInProgress || !this.state.isInputValid) {
            return;
        }

        this.setPaymentInProgress(true);
        this.setState({voucherPaymentError: null});

        const proceed = () => {
            const request: PurchaseRequest = {
                planId: this.planId,
                price: 0,
                fullVoucher: this.state.voucherCode,
                courses: [this.state.course],
            };
            PaymentService.instance.pay(request)
                .then((result: PurchaseDetails) => {
                    if (result && result.success === true) {
                        TrackingService.instance.sendGA('user', 'purchase', '' + this.planId, 0);
                        this.setPaymentInProgress(false);
                        this.onCheckoutSuccess(result.transactionId, result.paymentMethod);
                    } else {
                        this.setPaymentInProgress(false);
                        this.setState({voucherPaymentError: result.failureReason || GENERAL_PAYMENT_FAILURE});
                    }
                }).catch((error) => {
                    this.setPaymentInProgress(false);
                    this.setState({voucherPaymentError: GENERAL_PAYMENT_FAILURE});
                });
        }

        // 1. verify voucher
        // 2. authenticate user by either loading authenticated one or registering a new one
        // 3. execute actual purchase

        PaymentService.instance.verifyVoucher(this.state.voucherCode)
            .then((result: {success: boolean, failureReason: string}) => {
               if (result && result.success === true) {
                    this.preAuth().then(proceed).catch();
                } else {
                    this.setPaymentInProgress(false);
                    this.setState({voucherPaymentError: result.failureReason || GENERAL_PAYMENT_FAILURE});
                }
            })
            .catch((error) => {
                this.setPaymentInProgress(false);
                this.setState({voucherPaymentError: GENERAL_PAYMENT_FAILURE});
            });
    }

    secureLimitedOfferSubtitle = (price: number, originalPrice: number): React.ReactNode => {
        const {plan} = this.state;
        const userCreditCanBeApplied = this.userCreditCanBeApplied();
        const originalPriceShown = userCreditCanBeApplied || plan.discountPrice || this.state.hasVoucher;

        return (
            <p>
                You are purchasing the plan “{plan.description}” for
                <span>
                    {' '}
                    <span className={this.state.hasVoucher ? 'text-success underlined' : ''} style={{ fontWeight: 700 }}>
                        {' '}{price} {plan.currencyIsoCode}{' '}
                    </span>
                    { originalPriceShown ? ' instead of' : ''} {' '}
                </span>
                { originalPriceShown &&
                    <span className='strike-through'>
                        {originalPrice} {plan.currencyIsoCode}
                    </span>
                }
                { userCreditCanBeApplied === true && <br/> }
                { userCreditCanBeApplied === true &&
                    <span>
                        {`Your existing credit is ${this.userCredit} ${plan.currencyIsoCode} and it is applied automatically`}
                    </span>
                }
            </p>
        );
    }

    isPaymentTrulySuccessful = () => {
        return this.state.paymentSuccessful && this.planExists();
    }

    planExists = () => {
        return this.state.plan != null;
    }

    // return true if the current purchase is neither for a discounted plan nor with voucher and user has a positive credit
    userCreditCanBeApplied = (): boolean => {
        return this.planExists() && this.state.plan.discountPrice == null && !this.state.hasVoucher && this.userHasCredit;
    }

    getOriginalPrice = (): number => {
        return this.planExists() ? parseFloat(this.state.plan.price) : 0;
    }

    // return price paid by the user
    // - if (full) voucher is present price is 0
    // - else if the plan is discounted price is plan's discount price
    // - else if credit > 0 price is plan's price - credit (+ handling the case when credit > plan's price)
    // - else price is plan's price
    getFinalPrice = (): number => {
        const originalPrice = this.getOriginalPrice();

        if (!this.planExists()) {
            return 0;
        }

        if (this.userCreditCanBeApplied()) {
            return originalPrice - this.userCredit < 0 ? 0 : originalPrice - this.userCredit;
        } else {
            // return this.state.hasVoucher
            //     ? 0
            //     : this.state.partialVoucherCode === '75PERCENTOFF'
            //         ? 49
            //         : (this.state.plan.discountPrice ? this.state.plan.discountPrice : originalPrice);
            return this.state.hasVoucher
                ? 0
                : (this.state.plan.discountPrice ? this.state.plan.discountPrice : originalPrice);
        }
    }

    isPlan10WeeksChallenge = (): boolean => {
        const plan = this.state.plan;
        return plan && (
            plan.name === '10WeeksGermanChallenge' || plan.name === '10WeeksGermanChallengePlus10TutorSessions' ||
            plan.name === '10WeeksSpanishChallenge' || plan.name === '10WeeksSpanishChallengePlus10TutorSessions'
            );
    }

    // TODO For testing purposes
    /*consoleLogAllFormData = (e): void => {
        e.preventDefault();
        console.log(
            'First Name:' + this.fieldEnterPersonalData.getUserFirstName() + '\n' +
            'Last Name:' + this.fieldEnterPersonalData.getUserLastName() + '\n' +
            'Email:' + this.fieldEnterPersonalData.getUserEmail() + '\n' +
            'Password:' + this.fieldEnterPersonalData.getUserPassword() + '\n' +
            'City:' + this.fieldEnterBillingAddress.getUserCity() + '\n' +
            'Country:' + this.fieldEnterBillingAddress.getUserCountry() + '\n' +
            'Open Address:' + this.fieldEnterBillingAddress.getUserOpenAddress() + '\n' +
            'State:' + this.fieldEnterBillingAddress.getUserState() + '\n' +
            'ZIP:' + this.fieldEnterBillingAddress.getUserZipCode()
        )
    }*/

    render(): React.ReactNode {
        const { plan, paymentInProgress, paymentSuccessful } = this.state;
        const originalPrice = this.getOriginalPrice();
        const price = this.getFinalPrice();

        if (!this.planId) {
            return <Redirect to='/'/>;
        }

        const is10WeeksChallenge = this.isPlan10WeeksChallenge();

        return (
            <div>
                {!paymentSuccessful &&
                <div id='step-payment'>
                    {this.planExists() &&
                    <header className='page-header'>
                        <div className='frame'>
                            <h1>Secure your limited offer now{this.hasCourseName ? ` for "${this.courseName}"` : ''}</h1>
                            {this.secureLimitedOfferSubtitle(price, originalPrice)}
                        </div>
                    </header>
                    }

                    <div className='frame'>
                        {plan &&
                            <div id='checkout-steps' className={paymentInProgress ? 'in-progress' : ''}>
                                {/* <div className='loader-blocker'/> */}
                                <form id='payment-form' autoComplete='off'>
                                    {this.hasLevelSelection &&
                                        <FieldSelectLevel
                                            handleIsFormValid={this.handleIsFormValid}
                                            lang={this.state.learningLanguageCTA}
                                            course={this.state.course}
                                            setCourse={this.setCourse}
                                        />
                                    }
                                    <FieldEnterPersonalData
                                        ref={instance => this.fieldEnterPersonalData = instance}
                                        stepNumber={this.hasLevelSelection ? 2 : 1}
                                        isUserSignedin={this.isUserSignedin}
                                        handleIsFormValid={this.handleIsFormValid}
                                        onPartialVoucher={this.setVoucherCode}
                                        hasVoucher={this.state.hasVoucher}
                                        voucherCode={this.state.voucherCode}
                                    />
                                    <FieldEnterBillingInformation
                                        ref={instance => this.fieldEnterBillingAddress = instance}
                                        isUserSignedin={this.isUserSignedin}
                                        handleIsFormValid={this.handleIsFormValid}
                                    />
                                    <FieldSelectPaymentMethod
                                        ref={instance => this.fieldSelectPaymentMethod = instance}
                                        plan={this.state.plan}
                                        price={price}
                                        course={this.state.course}
                                        campaign={this.utm_campaign}
                                        disablePayment={!this.state.isInputValid}
                                        voucherCode={this.state.voucherCode}
                                        hasVoucher={this.state.hasVoucher}
                                        voucherPaymentError={this.state.voucherPaymentError}
                                        paymentInProgress={this.state.paymentInProgress}
                                        reserveWithVoucherCode={this.reserveWithVoucherCode}
                                        setPaymentInProgress={this.setPaymentInProgress}
                                        preAuth={this.preAuth}
                                        onSuccess={this.onCheckoutSuccess}
                                        handleIsFormValid={this.handleIsFormValid}
                                    />
                                    {/*<button onClick={this.consoleLogAllFormData}>console log</button>*/}
                                </form>
                            </div>
                        }
                    </div>
                </div>
                }
                {this.isPaymentTrulySuccessful() &&
                    <PaymentSuccessful plan={plan} userFirstName={this.fieldEnterPersonalData.getUserFirstName()} is10WeeksChallenge={is10WeeksChallenge} />
                }
                <MainFooter />
            </div>
        );
    }
}
