import * as React from 'react';
import {Link} from 'react-router-dom';
import {Expo, Bounce} from 'gsap';
import ExrStarRank from '../global/ExrStarRank';
import {SpeechService} from '../../services/speech.service';
import {TimelineMax} from 'gsap/TimelineMax';
import {UnitExercise} from '../../models/course.model';
import {CourseService} from '../../services/course.service';

declare global {
    interface Window { stopNetflixTimer: any; }
}

export default class ExrCompletion extends React.PureComponent<any, any> {

    state = {
        timer: 9,
        canStartNext: false
    };
    timerInterval = null;
    starSound: string = 'https://res.cloudinary.com/magiclingua/video/upload/v1526223201/earned-star_lkvn83.mp3';
    unmounted: boolean = false;
    element: HTMLDivElement;
    timerStartable: boolean = true;
    courseTrack: HTMLAudioElement;

    componentWillMount() {
        this.courseTrack = this.generateCourseTrack();
    }
    
    componentDidMount(): void {
        this.unmounted = false;
        window.stopNetflixTimer = this.stopTimer;
    }

    componentWillUnmount(): void {
        SpeechService.instance.stop();
        this.courseTrack.pause();
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
        this.unmounted = true;
    }

    generateCourseTrack = (): HTMLAudioElement => {
        const audio = new Audio('https://res.cloudinary.com/magiclingua/video/upload/v1526212302/course-track_huvl45.mp3');
        audio.volume = .2;
        audio.loop = true;
        return audio;
    }

    fadeoutCourseTrack = (milliseconds: number): void => {
        const TOTAL_INTERVALS = 50;
        setInterval(() => {
            if (this.courseTrack.volume - (this.courseTrack.volume / TOTAL_INTERVALS) > 0.03) {
                this.courseTrack.volume -= this.courseTrack.volume / TOTAL_INTERVALS;
            } else {
                this.courseTrack.pause();
                return;
            }
        }, milliseconds / TOTAL_INTERVALS);
    }

    startTimer = (): void => {
        if (this.unmounted || !this.timerStartable) {
            return;
        }
        let {timer} = this.state;
        this.timerInterval = setInterval(() => {
            this.setState({
                timer: timer--
            });
            if (this.unmounted) {
                clearInterval(this.timerInterval);
            } else if (timer < 0) {
                clearInterval(this.timerInterval);
                this.startNextExercise();
            }
        }, 1000);
    };

    stopTimer = (): void => {
        this.timerStartable = false;
        clearInterval(this.timerInterval);
    }

    startNextExercise = (): void => {
        if (this.unmounted) {
            return;
        }
        const tl = new TimelineMax();
        const screen = $(this.element);
        tl
            .to(screen, .6, {autoAlpha: 0, scale: 1.2, ease: Expo.easeOut})
            .set(screen, {clearProps: 'all'})
            .call(this.props.startNextExercise, [], this, '+=.2')
        ;
    };

    repeat = (): void => {
        location.reload();
    };

    startAnimation = (course): void => {

        this.setState({
            canStartNext: this.props.nextExr /* && CourseService.instance.checkExerciseStartable(this.props.nextExr, course) */ && this.props.nextExr['type'] !== 'tutor'
        }, () => {

            const self = this;
            const tl = new TimelineMax({delay: .2});
            const screen = $(this.element);
            const exrList = screen.find('.exr-list');
            const finishedExr = screen.find('.exr-item.finished');
            const nextExr = screen.find('.exr-item.next');
            const buttons = screen.find('.action-start');
            const starRank = finishedExr.find('.star-rank');
            const timer = nextExr.find('.timer');
            this.courseTrack.play();
    
            // Show the completed lesson
            tl
                .set([nextExr, buttons, starRank.find('.star.active'), screen, timer], {clearProps: 'all'})
                .set(screen, {css: {display: 'block'}})
                .set(screen, {autoAlpha: 0})
                .set(exrList, {xPercent: 25})
                .set([nextExr, buttons, starRank.find('.star.active'), timer], {autoAlpha: 0})
                .to(screen, 1, {autoAlpha: 1, ease: Expo.easeOut})
                .to($('#ani-pause'), 1, {x: 0})
            ;

            // Stars animation
            starRank.find('.star.active').each(function() {
                tl.fromTo($(this), .5, {scale: 2, autoAlpha: 0}, {scale: 1, autoAlpha: 1, ease: Bounce.easeOut, onStart: () => {
                    SpeechService.instance.play(self.starSound, 0.2);
                }});
                tl.to($('#ani-pause'), .8, {x: 0})
            });

            if (this.props.isIframe) {
                this.fadeoutCourseTrack(3000);
            } else {
                if (this.props.finishedExr.seqAlpha === '5x4') {
                    tl.call(() => {
                        location.href = 'https://www.kickstarter.com/projects/1427862973/start-speaking-your-new-language-from-day-1-magicl?ref=a3gmqb';
                    }, []);
                }
        
                tl.to($('#ani-pause'), 1, {x: 0});
                tl
                    .to(exrList, 1, {xPercent: -25, ease: Expo.easeInOut}, 'move')
                    .to(nextExr, 1, {autoAlpha: 1, ease: Expo.easeInOut}, 'move')
                    .to(finishedExr, 1, {autoAlpha: .3, ease: Expo.easeInOut}, 'move')
                    .to(starRank, 1, {yPercent: -90, scale: .7, ease: Expo.easeInOut}, 'move')
                    .to(buttons, .5, {autoAlpha: 1, ease: Expo.easeOut}, '+=1')
                    .to(timer, .5, {autoAlpha: 1, ease: Expo.easeOut}, '+=1')
                ;
                if (this.state.canStartNext) {
                    tl.call(this.startTimer, []);
                } else {
                    // TODO: this event isn't correct anymore
                    this.props.dataLayerPush({
                        'event': 'exerciseCompleteLast'
                    });
                }
            }
        });
    };

    render() {
        const rank = Math.round(this.props.progress * 3 / 100);
        const nextExr: UnitExercise = this.props.nextExr as UnitExercise;

        return (
            <div id='exr-completion' ref={c => this.element = c}>
                {this.props.isCourseEnabled() && !this.props.isIframe &&
                    <Link className='action-back ghost' to={this.props.getCourseLink()}>‹ Back to course overview</Link>
                }
                <div className='inner'>
                    <header>Great job!</header>
                    <div className='exr-list'>
                        <div className='exr-item finished'>
                            <figure style={{backgroundImage: 'url(' + this.props.finishedExr.img + ')'}}>
                                <ExrStarRank rank={rank} />
                                <span className='line'/>
                            </figure>
                            <header>
                                <span>{'Lesson ' +  this.props.finishedExr.exrNr}</span>
                                <div>{this.props.finishedExr.title}</div>
                            </header>
                        </div>
                        {(this.state.canStartNext ?
                            <div className='exr-item next'>
                                <figure style={{backgroundImage: 'url(' + nextExr.img + ')'}}>
                                    <span className='timer'>00:0{this.state.timer}</span>
                                </figure>
                                <header>
                                    <span>{'Lesson ' +  nextExr[CourseService.TRANSIENT_EXR_NR]}</span>
                                    <div>{nextExr.title}</div>
                                </header>
                            </div>
                        :
                            <div className='exr-item next'>
                                <figure style={{backgroundImage: 'url(' + this.props.courseImg + ')'}}/>
                            </div>
                        )}
                        <div className='exr-actions'>
                            <div><button className='ghost action-start' onClick={this.repeat}>Repeat <svg width='17' height='17' xmlns='http://www.w3.org/2000/svg'><path d='M3.58 13.545A7.035 7.035 0 0 0 8.46 15.5c3.889 0 7.04-3.135 7.04-7s-3.151-7-7.04-7a7.036 7.036 0 0 0-5.099 2.173l-.722-.691A8.036 8.036 0 0 1 8.46.5c4.44 0 8.04 3.581 8.04 8 0 4.419-3.6 8-8.04 8a8.035 8.035 0 0 1-5.65-2.308L.987 15.72l-.273-4.993 4.87 1.136-2.004 1.681z' fill='#FFF' fillRule='nonzero'/></svg></button></div>
                            {this.state.canStartNext ? (
                                <div><button className='ghost action-start start' onClick={this.startNextExercise}>Start next →</button></div>
                            ) : !this.props.isIframe ? (
                                <div className='next'>
                                    <div><Link className='ghost action-start start' to={this.props.getCourseLink()}>Back to course</Link></div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
