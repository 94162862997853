import * as React from 'react';
import * as prgStar from '../../../../../assets/images/newmagic/progress-star.svg';
import * as prgStarPlaceholder from '../../../../../assets/images/newmagic/progress-star-ph.svg';

export default class ExrStarRank extends React.Component<any, any> {

    render(): React.ReactNode {
        const rank = this.props.rank? this.props.rank : 0;
        return (
            <div className="star-rank">
                <div>
                    <img className="star-placeholder" src={prgStarPlaceholder} alt="Star" />
                    <img src={prgStar} className={'star ' + (rank > 0? 'active' : '')} alt="Star" />
                </div>
                <div>
                    <img className="star-placeholder" src={prgStarPlaceholder} alt="Star" />
                    <img src={prgStar} className={'star ' + (rank > 1? 'active' : '')} alt="Star" />
                </div>
                <div>
                    <img className="star-placeholder" src={prgStarPlaceholder} alt="Star" />
                    <img src={prgStar} className={'star ' + (rank > 2? 'active' : '')} alt="Star" />
                </div>
            </div>
        );
    }
}
