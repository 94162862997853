export class UserTrackingEvent {
    constructor(
        public category: string,
        public action: string,
        public platform?: string,
        // It will be a Map, but will be initialized as an object literal, and not a JavaScript Map
        // Idk why, otherwise axios does not send the Map's content to the server for some reason
        public additionalData?: any
    ) {
    }
}
