import {AbstractService} from './abstract.service';

export class UserAgentService extends AbstractService {

    private static _instance: UserAgentService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    isMobileDevice() {
        return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i.test((<any>navigator).userAgent);
    }

    isMobileUI() {
        return (<any>window).matchMedia
            && (<any>window).matchMedia('(min-width: 320px)').matches
            && (<any>window).matchMedia('(max-width: 767px)').matches;
    }

    isMobile() {
        return this.isMobileDevice() || this.isMobileUI();
    }

    isIOS(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    isAndroid(): boolean {
        return /Android/.test(navigator.userAgent);
    }

    isChromeiOS() {
        return this.isMobileDevice() && (<any>navigator).userAgent.indexOf('CriOS') !== -1;
    }

    isSafari() {
        return (<any>navigator).userAgent.indexOf('Safari') !== -1 && (<any>navigator).userAgent.indexOf('Chrome') === -1;
    }

    isChrome() {
        return (<any>navigator).userAgent.indexOf('Chrome') !== -1;
    }

    isFirefox() {
        return (<any>navigator).userAgent.indexOf('Firefox') !== -1;
    }
}
