export abstract class AbstractService {

    protected errorToMessage(error: any): string {
        let message = 'Error';
        if (typeof error === 'string' || error instanceof String) {
            message = error as string;
        } else {
            if (error && error.response) {
                message = 'Error ' + error.response.status + ': ' + JSON.stringify(error.response.data);
            }
        }
        return message;
    }

    protected paramsSerializer(params: any) {
        let str = '';
        if (params) {
            let first = true;
            for (const k in params) {
                if (params[k] instanceof Array) {
                    for (const v of params[k]) {
                        str += (first ? '' : '&') + k + '=' + v;
                        first = false;
                    }
                } else if (params[k] || params[k] === 0) {
                    str += (first ? '' : '&') + k + '=' + params[k];
                    first = false;
                }
            }
        }
        return str;
    }
}
