import * as Promise from 'promise';
import axios from 'axios/index';

import {AbstractService} from './abstract.service';

export class TranslateService extends AbstractService {

    private static _instance: TranslateService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    translate(text: string, srcIso: string, trgIso: string): Promise<string> {
        const self = this;
        return new Promise<string>((resolve, reject) => {
            axios.get(
                '/api/translate',
                {
                    params: {
                        text,
                        srcIso,
                        trgIso
                    }
                }
            )
            .then((response) => {
                const data = response.data;
                if (data && data.result) {
                    resolve(data.result);
                } else {
                    reject('No translation received');
                }
            })
            .catch((error) => {
                reject(self.errorToMessage(error));
            });
        });
    }
}
