import * as React from 'react';
import * as Promise from 'promise';
import { TimelineMax, TweenMax, Back, Power0, Power1, Expo, Power4 } from 'gsap';

export default class PopupWrapper extends React.Component<any, any> {

    options;
    element;

    componentDidMount(): void {
        this.options = this.props.options || {};
        this.options.popupDisplay = this.options.popupDisplay || 'block';
        this.options.animation = this.options.animation || 'fade';
        TweenMax.set($(this.element),{css: {display: 'none'}});
    }

    open = (isModal: boolean = false): Promise<void> => {
        const self = this;
        const popup = $(this.element);
        const popupBG = $('<div class="popup-bg"></div>');
        return new Promise<void>((resolve, reject) => {
            const timeLine = new TimelineMax({
                onComplete() {
                    if (self.options.trackPageView) {
                        // For GA we want to know which popup was opened
                        // self.$analytics.pageTrack(options.trackPageView);
                    }
                    resolve();
                }
            });

            if ($('.popup-bg').length === 0) {
                popup.after(popupBG);

                if (!isModal) {
                    popupBG.on('click', () => {
                        self.close().then(() => {
                            if (self.props.onClosed) {
                                self.props.onClosed();
                            }
                        });
                    });
                }

                $('body,html').addClass('mode-modal');
            }
            // animation
            if (self.options.animation === 'fade') {
                timeLine
                    .set(popup, {clearProps: 'all'})
                    .set(popup, {autoAlpha: 0})
                    .fromTo(popupBG, .2, {css: {opacity: 0}}, {css: {opacity:.5}})
                    .to(popup, .2, {autoAlpha: 1}, '-=.2');
            } else if (self.options.animation === 'slideUp') {
                timeLine
                    .to(popup, 0, {autoAlpha: 1})
                    .fromTo(popup, .4, {y: '100%'}, {y: '0%'});
            }
        });
    };

    close = (): Promise<void> => {
        const self = this;
        const popup = $(this.element);
        const popupBG = popup.next('.popup-bg');
        return new Promise<void>((resolve, reject) => {
            const timeLine = new TimelineMax({
                onComplete() {
                    TweenMax.set({clearProps: 'all'});
                    $('.popup-bg').remove();
                    $('body,html').removeClass('mode-modal');
                    resolve();
                }
            });
            if (self.options.animation === 'fade') {
                timeLine.to(popup, .4, {autoAlpha: 0, scale: .5, ease: Power4.easeOut});
                timeLine.to(popupBG, .2, {css: {opacity: 0}}, '-=.4');
            } else if (self.options.animation === 'slideUp') {
                timeLine.to(popup, .4, {y: '100%'});
            }
        });
    };

    render(): React.ReactNode {
        return (
            <div id={this.props.id}
                 className={'popup ' + (this.props.className? this.props.className : '') }
                 ref = {c => this.element = c}>
                {this.props.children}
            </div>
        )
    }
}
