import * as langThemeDe from '../../../../../assets/images/newmagic/language-themes/de.png';
import * as langThemeEs from '../../../../../assets/images/newmagic/language-themes/es.png';

export interface ICourseInfo {
    level: number;
    levelText: string;
    languageThemeImg: any;
}

const courses = {
    // Our crash courses
    'de-beginner-crash': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'de-crash-present-and-past-tenses': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'de-crash-sentence-structure': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'es-beginner-crash': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,
    'es-crash-past-tenses': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,
    'es-crash-dilemmas': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,

    // Our (not challenge)
    'de-beginner': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'de-intermediate': {
        level: 2,
        levelText: 'Intermediate',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'es-beginner': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,
    'es-intermediate': {
        level: 2,
        levelText: 'Intermediate',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,
    'es-advanced': {
        level: 3,
        levelText: 'Advanced',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,

    // our challenges
    'de-beginner-10weekschallenge': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'de-intermediate-10weekschallenge': {
        level: 2,
        levelText: 'Intermediate',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'de-beginner-10weekschallenge-40120': {
        level: 1,
        levelText: 'Beginner',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'de-intermediate-10weekschallenge-40120': {
        level: 2,
        levelText: 'Intermediate',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,

    // Camino
    'es-beginner-camino-challenge': {
        level: 1,
        levelText: 'Beginner - A1',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,
    'es-intermediate-camino-challenge': {
        level: 2,
        levelText: 'Elementary - A2',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,
    'es-advanced-camino-challenge': {
        level: 3,
        levelText: 'Intermediate - B1',
        languageThemeImg: langThemeEs,
    } as ICourseInfo,

    // Anja
    'de-a11': {
        level: 1,
        levelText: 'Beginner (A1.1)',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
    'de-a12': {
        level: 2,
        levelText: 'Beginner (A1.2)',
        languageThemeImg: langThemeDe,
    } as ICourseInfo,
}

/**
 * This provider will be used as a last resort "hard-coded" db in case we don't want to fetch
 * the needed data from BE for some reason (implementation, performance..)
 */
export default class CourseInfoProvider {
    private static _instance: CourseInfoProvider;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    public getCourseInfo(courseName: string): ICourseInfo {
        return courses[courseName];
    }
}
