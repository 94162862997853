export const secondsToDuration = (seconds: number): string => {
    let minsString: string, secsAndMiliSecsString: string;
    let secsAndMiliSecs: number;

    // Create data in the type of number
    const mins = Math.floor(seconds / 60);
    secsAndMiliSecs = seconds % 60;

    // Turn the data to string format
    if (mins < 10) {
        minsString = '0' + mins.toString();
    } else {
        minsString = mins.toString();
    }

    if (secsAndMiliSecs < 10) {
        secsAndMiliSecsString = '0' + secsAndMiliSecs.toFixed(1);
    } else {
        secsAndMiliSecsString = secsAndMiliSecs.toFixed(1);
    }

    return minsString + ':' + secsAndMiliSecsString;
}
