import * as React from 'react';
import { AuthService } from '../../services/auth.service';

interface IProps {
    handleIsFormValid: VoidFunction;
    setCourse: Function;
    lang: string;
    course: string;
}

interface IState {
    level: string;
}

export class FieldSelectLevel extends React.Component<IProps, IState> {
    private el: HTMLDivElement;
    private testUrls: object = {
        'de': 'https://forms.gle/QvBGqeJVocLPxoyc8',
        'es': 'https://forms.gle/ZTxPSkBv2rdks6EA7'
    };

    constructor(props) {
        super(props);
    }

    private setCourse = (e): void => {
        this.props.setCourse(e.target.value);
    };

    getLevel = (): string => {
        return this.state.level;
    };

    isValid = (): boolean => {
        return !!this.state.level;
    };

    render() {
        return (
            <div ref={c => (this.el = c)} id='level-choice'>
                <h2>
                    <i>1</i>
                    <span>Choose your level</span>
                </h2>
                <div>
                    <label>
                        <header>
                            <input
                                type='radio'
                                name='level-choice'
                                value={this.props.course.replace(/-beginner|-intermediate/, '-beginner')}
                                onClick={this.setCourse}
                                checked={this.props.course.indexOf('beginner') !== -1}
                            />
                            <span>Beginner</span>
                        </header>
                        <p>If you are a total beginner, or have even tried some apps or other language learning methods and felt, like many others, you still could only do the basics at best, then the Beginner level is for you. You will build a solid foundation for confident and fluent speech.</p>

                        <div className='hint'>For placement score under 11 strongly recommended</div>
                    </label>
                    <label>
                        <header>
                            <input
                                type='radio'
                                name='level-choice'
                                value={this.props.course.replace(/-beginner|-intermediate/, '-intermediate')}
                                onClick={this.setCourse}
                                checked={this.props.course.indexOf('intermediate') !== -1}
                            />
                            <span>Intermediate</span>
                        </header>
                        <p>If you already feel confident to start a conversation with a native speaker, but feel as if you are quite limited in your expressions and run out of topics after some time, then be sure to go with the Intermediate level. This challenge will bring you to the whole new level.</p>
                        <div className='hint'>For placement score of 11 or higher</div>
                    </label>
                </div>
                <div id='placement-test'>
                    <div>Not sure which level to choose?</div>
                    <a href={this.testUrls[this.props.lang]} target='_blank' className='ghost'>Take the placement test</a>
                </div>
            </div>
        );
    }
}
