import * as React from 'react';

export const warningsPopupContent = (): JSX.Element => {
    return (
        <div style={{ lineHeight: 1.5 }}>
            <p>
                <span style={{ fontWeight: 'bold' }}> 1. </span>
                <span style={{ fontWeight: 500 }}> In dialog mode, all lines should begin with "- " </span>
                <br />
                <span style={{ opacity: 0.75 }}>
                    Do not create a new line in dialog mode without putting "- " (dash and a space) to the beginning of the line, otherwise
                    problems can come up.
                </span>
            </p>
            <br />
            <p>
                <span style={{ fontWeight: 'bold' }}> 2. </span>
                <span style={{ fontWeight: 500 }}>
                    Put spaces near all emojis, unless it is the label part of the label syntax, {'{'} | part of {'{'} | }
                </span>
                <br />
                <span style={{ opacity: 0.75 }}>
                    Emoji support on our mobile app is weak right now (21.03.2020), and ALL characters that is directly adjacent to the
                    emoji will disappear on mobile. And if this character is a syntax character ('[', '>', etc.), then this issue might
                    break the slides logic on mobile, thus causing strange issues. So, please avoid putting things adjacent to emojis.
                    Unless it is the label part of the label syntax, if it is that part, then you should not put spaces around the emoji,
                    otherwise it will be displayed as a strange character.
                </span>
            </p>
            <br />
            <p>
                <span style={{ fontWeight: 'bold' }}> 3. </span>
                <span style={{ fontWeight: 500 }}>Be sure you close the label syntax</span>
                <br />
                <span style={{ opacity: 0.75 }}>
                    When using the label syntax, {'{'}label|base}, please be sure that you close the label syntax usage via "}"
                </span>
            </p>
        </div>
    );
};
