import * as Promise from 'promise';
import { AbstractService } from './abstract.service';
import * as _ from 'underscore';
import { LanguageCourse, Course, CourseUnit, UnitExercise } from '../models/course.model';
import { UserCourse, UserCourseUnit, UserUnitExercise } from '../models/user.course.model';
import { CoursesState } from '../models/courses.state.model';
import { AuthService } from './auth.service';
import axios from 'axios';
import { resolve } from 'dns';
import { User } from '../models/user.model';
import { Image } from '../models/image.model';
import { TrackingService } from './tracking.service';
import { UserTrackingEvent } from '../models/user.tracking.event.model';

class ExerciseIndex {
    constructor(
        public unit: CourseUnit,
        public exercise: UnitExercise,
        public unitIndex: number,
        public exerciseIndex: number
    ) { }
}

export class CourseService extends AbstractService {

    private static readonly PARAM_COURSE_ID = 'courseID';
    private static readonly COURSE_BETA_INDICATOR = 'beta';
    private static readonly COURSE_CRASH_INDICATOR = 'crash';
    private static readonly COURSE_PUBLIC_INDICATOR = 'public';
    private static readonly COURSE_YOUTUBE_INDICATOR = 'youtube';

    public static readonly TRANSIENT_EXR_NR = 'exrNr';

    public static readonly TRANSIENT_COMPLETED = 'completed';
    public static readonly TRANSIENT_PROGRESS = 'progress';
    public static readonly TRANSIENT_DURATION = 'duration';
    public static readonly TRANSIENT_SCHEDULED = 'scheduled';

    public static readonly TRANSIENT_CURRENT_UNIT = 'currentUnit';
    public static readonly TRANSIENT_CURRENT_EXERCISE = 'currentExercise';
    public static readonly TRANSIENT_CURRENT_CHAPTER = 'currentChapter';

    private static _instance: CourseService;

    public static get instance() {
        return this._instance || (this._instance = new this());
    }

    private lastLoadedCourse: Course;
    private lastLoadedUserCourse: UserCourse;

    setCurrentChapter(seqAlpha: string, chapterIdx: number, save: boolean = true) {
        this.setCurrentExercise(seqAlpha, false);
        const course: Course = this.getCurrentCourse(true) as Course;
        if (course) {
            const exerciseIndex: ExerciseIndex = this.findExercise(course, {'seqAlpha': seqAlpha});
            if (exerciseIndex) {
                exerciseIndex.exercise[CourseService.TRANSIENT_CURRENT_CHAPTER] = chapterIdx;
                if (save) {
                    this.saveCurrentCourse();
                }
            }
        }
    }

    setNextExercise() {
        const nextExercise: UnitExercise = this.getNextExercise(true) as UnitExercise;
        if (nextExercise && nextExercise.available && nextExercise.seqAlpha && !nextExercise.seqAlpha.startsWith('null_')) {
            this.setCurrentExercise(nextExercise.seqAlpha);
        }
    }

    setCurrentExercise(seqAlpha: string, save: boolean = true) {
        const course: Course = this.getCurrentCourse(true) as Course;
        if (course) {
            const exerciseIndex: ExerciseIndex = this.findExercise(course, {'seqAlpha': seqAlpha});
            if (exerciseIndex) {
                course[CourseService.TRANSIENT_CURRENT_UNIT] = exerciseIndex.unitIndex;
                exerciseIndex.unit[CourseService.TRANSIENT_CURRENT_EXERCISE] = exerciseIndex.exerciseIndex;
                if (save) {
                    this.saveCurrentCourse();
                }
            }
        }
    }

    setExerciseProgress(seqAlpha: string, progress: number, save: boolean = true): void {
        const course: Course = this.getCurrentCourse(true) as Course;
        if (course) {
            const foundExercise: ExerciseIndex = this.findExercise(course, {'seqAlpha': seqAlpha});
            if (foundExercise && foundExercise.exercise) {
                foundExercise.exercise[CourseService.TRANSIENT_PROGRESS] = progress || 0;
                if (save) {
                    this.saveCurrentCourse();
                }
            }
        }
    }

    setExerciseCompletedAndResetCurrentChapter(seqAlpha: string, completed: number = 1, save: boolean = true): Promise<Course> {
        return new Promise<Course>((resolve, reject) => {
            const course: Course = this.getCurrentCourse(true) as Course;
            if (course) {
                const foundExercise: ExerciseIndex = this.findExercise(course, {'seqAlpha': seqAlpha});
                if (foundExercise && foundExercise.exercise) {
                    foundExercise.exercise[CourseService.TRANSIENT_COMPLETED] = completed || 0;
                    foundExercise.exercise[CourseService.TRANSIENT_CURRENT_CHAPTER] = 0;

                    // notify BE with an event
                    const additionalData = {
                        dialogueSeqAlpha: seqAlpha,
                        course: course.name,
                        language: course.courseLangISO
                    };
                    const event = new UserTrackingEvent('LEARNING', 'LESSON_COMPLETE', 'web', additionalData);
                    TrackingService.instance.sendUserEvent(event);

                    if (save) {
                        (this.saveCurrentCourse(true) as Promise<void>).then(() => {resolve(course); });
                    } else {
                        reject();
                    }
                } else {
                    reject();
                }
            } else {
                reject();
            }
        });
    }

    // This will reset completed and progress props to 0
    resetExerciseProgress(seqAlpha: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const course: Course = this.getCurrentCourse(true) as Course;
            if (course) {
                const foundExercise: ExerciseIndex = this.findExercise(course, {'seqAlpha': seqAlpha});
                if (foundExercise && foundExercise.exercise) {
                    foundExercise.exercise[CourseService.TRANSIENT_COMPLETED] = 0;
                    foundExercise.exercise[CourseService.TRANSIENT_PROGRESS] = 0;
                    foundExercise.exercise[CourseService.TRANSIENT_CURRENT_CHAPTER] = 0;
                    foundExercise.exercise[CourseService.TRANSIENT_DURATION] = 0;

                    (this.saveCurrentCourse(true) as Promise<void>).then(() => {
                        resolve();
                    }).catch((err) => {
                        console.log(err);
                        reject();
                    });
                } else {
                    reject();
                }
            } else {
                reject();
            }
        });
    }

    loadCourse(courseId: string, user?: User): Promise<Course> {
        const self = this;
        return new Promise<Course>((resolve, reject) => {
            this.getCourse(courseId).then(
                course => {

                    function _doLoadCourse(user?: User) {
                        self.getUserCourse(courseId).then(
                            userCourse => {
                                // init UI state with existing stored progress
                                // self.removeTutorSessionsIfNotInPackage(course, user);
                                self.syncUserToGeneral(userCourse, course);
                                localStorage.setItem(CourseService.PARAM_COURSE_ID, courseId);
                                self.lastLoadedCourse = course;
                                self.lastLoadedUserCourse = userCourse;
                                resolve(course);
                            }, reject
                        )
                    }

                    if (AuthService.instance.isAuthenticated()) {
                        AuthService.instance.loadUser().then(user => {
                            _doLoadCourse(user);
                        });
                    } else {
                        _doLoadCourse(null);
                    }
                }, reject
            )
        });
    }

    // TODO this function iterates unnecessary as the same iteration is done in syncUserToGeneral and 'tutor' exercises can be removed there
    removeTutorSessionsIfNotInPackage(course: Course, user?): Course {
        // if user doesn't have tutors in the package
        if (user && user.tutorSessionsCount && user.tutorSessionsCount === -1) {
            for (let i = 0; i < course.units.length; i++) {
                const unit = course.units[i];
                if (unit) {
                    for (let j = 0; j < unit.exercises.length; j++) {
                        if (unit.exercises[j]['type'] === 'tutor') {
                            course.units[i].exercises.splice(j, 1);
                        }
                    }
                }
            }
        }
        return course;
    }

    hasCourseCompletedExercises(immediately: boolean = false): Promise<boolean> | boolean {
        const has = (course: Course): boolean => {
            let hasCompleted: boolean = false;
            if (course) {
                const found = _.find(course.units, unit => {
                    return !!_.find(unit.exercises, exercise => {
                        return !!exercise[CourseService.TRANSIENT_COMPLETED];
                    });
                });
                hasCompleted = !!found;
            }
            return hasCompleted;
        };
        if (immediately) {
            return has(this.getCurrentCourse(true) as Course);
        } else {
            return new Promise<boolean>((resolve, reject) => {
                (this.getCurrentCourse() as Promise<Course>).then(
                    course => resolve(has(course)), reject
                );
            });
        }
    }

    /**
     * Only call this if the user is in a "youtube" course.
     * Return true if:
     * - 3rd intent completed
     * - not in 1st chapter
     */
    isYoutubeLimitOver = (intentIdx: number, contextIdx: number) => {
        // intentIdx > 1 because intentIdx starts at -1 in learning component,
        // and it is hard to put the funciton that calls this function to somewhere more convenient
        return intentIdx > 1 || contextIdx > 0
    }

    getCurrentCourseUrl(immediately: boolean = false): Promise<string> | string {
        if (immediately) {
            let url = null;
            const course = this.getCurrentCourse(true) as Course;
            if (course) {
                url = '/' + course.courseLang + '/course/' + course.seqAlpha;
            } else {
                // redirecting to Dashboard because it's the only common page for our sites and co-branded ones
                url = '/dashboard';
            }
            return url;
        } else {
            return new Promise<string>((resolve, reject) => {
                (this.getCurrentCourse() as Promise<Course>).then(
                    course => {
                        if (course) {
                            resolve('/' + course.courseLang + '/course/' + course.seqAlpha);
                        } else {
                            // redirecting to Dashboard because it's the only common page for our sites and co-branded ones
                            resolve('/dashboard');
                        }
                    }, reject
                );
            });
        }
    };

    getCurrentChapter(seqAlpha: string, immediately: boolean = false): Promise<number> | number {
        const getChapter = (course: Course) => {
            let chapter = null;
            if (course) {
                const exerciseIndex: ExerciseIndex = this.findExercise(course, {'seqAlpha': seqAlpha});
                if (exerciseIndex && course[CourseService.TRANSIENT_CURRENT_UNIT] === exerciseIndex.unitIndex) {
                    chapter = exerciseIndex.exercise[CourseService.TRANSIENT_CURRENT_CHAPTER];
                }
            }
            return chapter || 0;
        };
        if (immediately) {
            return getChapter(this.getCurrentCourse(true) as Course);
        } else {
            return new Promise<number>((resolve, reject) => {
                (this.getCurrentCourse() as Promise<Course>).then(
                    course => resolve(getChapter(course)), reject
                );
            });
        }
    }

    getCurrentExercise(immediately: boolean = false): Promise<UnitExercise> | UnitExercise {
        const getExercise = (course: Course) => {
            let exercise = null;
            if (course) {
                const unitIdx = course[CourseService.TRANSIENT_CURRENT_UNIT] || 0;
                const unit = unitIdx < course.units.length ? course.units[unitIdx] : null;
                if (unit) {
                    const exerciseIdx = unit[CourseService.TRANSIENT_CURRENT_EXERCISE] || 0;
                    if (exerciseIdx < unit.exercises.length && unit.exercises[exerciseIdx]) {
                        exercise = unit.exercises[exerciseIdx];
                    }
                }
            }
            return exercise;
        };
        if (immediately) {
            return getExercise(this.getCurrentCourse(true) as Course);
        } else {
            return new Promise<UnitExercise>((resolve, reject) => {
                (this.getCurrentCourse() as Promise<Course>).then(
                    course => resolve(getExercise(course)), reject
                );
            });
        }
    }

    getNextExercise(immediately: boolean = false): Promise<UnitExercise> | UnitExercise {
        const getExercise = (course: Course) => {
            let exercise = null;
            if (course) {
                const unitIdx = course[CourseService.TRANSIENT_CURRENT_UNIT] || 0;
                const unit = unitIdx < course.units.length ? course.units[unitIdx] : null;
                if (unit) {
                    const exerciseIdx = unit[CourseService.TRANSIENT_CURRENT_EXERCISE] || 0;
                    const nextExerciseIdx = exerciseIdx + 1;
                    if (nextExerciseIdx < unit.exercises.length && unit.exercises[nextExerciseIdx]) {
                        exercise = unit.exercises[nextExerciseIdx];
                    }
                    // "tutor session" lessons must not become "current" lesson
                    if (exercise && exercise.type === 'tutor') {
                        return null;
                    }
                    if (!exercise) {
                        const nextUnitIdx = unitIdx + 1;
                        const nextUnit = nextUnitIdx < course.units.length ? course.units[nextUnitIdx] : null;
                        if (nextUnit && nextUnit.exercises[0]) {
                            exercise = nextUnit.exercises[0];
                        }
                    }
                }
            }
            return exercise;
        };
        if (immediately) {
            return getExercise(this.getCurrentCourse(true) as Course);
        } else {
            return new Promise<UnitExercise>((resolve, reject) => {
                (this.getCurrentCourse() as Promise<Course>).then(
                    course => resolve(getExercise(course)), reject
                );
            });
        }

    }

    getCurrentUnit(immediately: boolean = false): Promise<CourseUnit> | CourseUnit {
        const getUnit = (course: Course): CourseUnit => {
            let unit: CourseUnit = null;
            if (course) {
                const unitIdx = course[CourseService.TRANSIENT_CURRENT_UNIT] || 0;
                if (course.units[unitIdx]) {
                    unit = course.units[unitIdx];
                }
            }
            return unit;
        };
        if (immediately) {
            return getUnit(this.getCurrentCourse(true) as Course);
        } else {
            return new Promise<CourseUnit>((resolve, reject) => {
                (this.getCurrentCourse() as Promise<Course>).then(
                    course => resolve(getUnit(course)), reject
                );
            });
        }
    }

    getAllCourses(): Promise<Array<Course>> {
        const self = this;
        return new Promise<Array<Course>>((resolve, reject) => {
            axios
                .get(`/api/courses`)
                .then((response) => {
                    resolve(response.data as Array<Course>);
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                })
        });
    }

    getOneCourse(courseId: string): Promise<Course> {
        return new Promise<Course>((resolve, reject) => {
            this.getCourse(courseId)
                .then(course => {
                    if (course == null) {
                        reject('course is null?');
                    }
                    resolve(course);
                });
        });
    }

    updateCourse(course: Course): Promise<Course> {
        const self = this;
        return new Promise<Course>((resolve, reject) => {
            axios.put(
                `/api/courses/${course.id}`,
                course
            )
                .then((response) => {
                    resolve(response.data as Course);
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    updateExerciseImage(courseId: string, unitId: string, exerciseId: string, imgUrl: string): Promise<Course> {
        const self = this;

        const image: Image = new Image();
        image.originalReference = imgUrl;

        return new Promise<Course>((resolve, reject) => {
            axios.post(
                `/api/courses/${courseId}/${unitId}/${exerciseId}/update-exercise-image`,
                image
            )
                .then((response) => {
                    resolve(response.data as Course);
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    addCourseUnit(courseId: string, unit: CourseUnit): Promise<Course> {
        const self = this;
        return new Promise<Course>((resolve, reject) => {
            axios.put(
                `/api/courses/${courseId}/add-unit`,
                unit
            )
                .then((response) => {
                    resolve(response.data as Course);
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    deleteCourseUnit(courseId: string, unit: CourseUnit): Promise<Course> {
        const self = this;
        return new Promise<Course>((resolve, reject) => {
            axios.put(
                `/api/courses/${courseId}/delete-unit`,
                unit
            )
                .then((response) => {
                    resolve(response.data as Course);
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    addUnitExercise(courseId: string, unitId: string, exercise: UnitExercise): Promise<Course> {
        const self = this;
        return new Promise<Course>((resolve, reject) => {
            axios.put(
                `/api/courses/${courseId}/${unitId}/add-exercise`,
                exercise
            )
                .then((response) => {
                    resolve(response.data as Course);
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    deleteUnitExercise(courseId: string, unitId: string, exercise: UnitExercise): Promise<Course> {
        const self = this;
        return new Promise<Course>((resolve, reject) => {
            axios
                .put(
                    `api/courses/${courseId}/${unitId}/delete-exercise`,
                    exercise
                )
                .then(response => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject('Failed to delete the UnitExercise');
                    }
                })
                .catch(error => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    getLastLoadedUserCourseStart(): any {
        return this.lastLoadedUserCourse.activationStartDate;
    }

    getLastLoadedUserCourseEnd(): any {
        return this.lastLoadedUserCourse.activationEndDate;
    }

    isCourseBeta(courseName: string): boolean {
        return courseName.includes(CourseService.COURSE_BETA_INDICATOR);
    }

    isCourseCrash(courseName: string): boolean {
        return courseName.includes(CourseService.COURSE_CRASH_INDICATOR);
    }

    isCoursePublic(courseName: string): boolean {
        return courseName.includes(CourseService.COURSE_PUBLIC_INDICATOR);
    }

    isCourseYoutube(courseName: string): boolean {
        return courseName.includes(CourseService.COURSE_YOUTUBE_INDICATOR);
    }

    isExerciseFreeDemo(exr: UnitExercise): boolean {
        return exr.attributes && exr.attributes['freeDemo'] && exr.attributes['freeDemo'] === true;
    }

    hasUnitFreeDemo(unit: CourseUnit): boolean {
        let freeDemo: boolean = false;

        if (unit.exercises) {
            for (const i in unit.exercises) {
                if (this.isExerciseFreeDemo(unit.exercises[i])) {
                    freeDemo = true;
                    break;
                }
            }
        }

        return freeDemo;
    }

    getLastCompletedExercise(course: Course): ExerciseIndex {
        let exerciseIndex: ExerciseIndex = null;

        for (let i = course.units.length - 1; i >= 0; i--) {
            const unit = course.units[i];
            if (unit) {
                for (let j = unit.exercises.length - 1; j >= 0; j--) {
                    if (unit.exercises[j]['completed']) {
                        exerciseIndex = new ExerciseIndex(unit, unit.exercises[j], i, j);
                        break;
                    }
                    if (exerciseIndex) {
                        break;
                    }
                }
            }
            if (exerciseIndex) {
                break;
            }
        }
        return exerciseIndex;
    }

    getFirstStartableExercise(course: Course): ExerciseIndex {
        const lastCompletedExrIndex = this.getLastCompletedExercise(course);
        let nextExerciseIndex = this.findExercise(course, {
            exrNr: lastCompletedExrIndex !== null ? lastCompletedExrIndex.exercise['exrNr'] + 1 : 1
        });

        if (!(nextExerciseIndex && AuthService.instance.isUserPermittedForThisExercise(course.name, nextExerciseIndex.exercise) && nextExerciseIndex.exercise.available)) {
            return lastCompletedExrIndex;
        } else {
            // The offset defines the unit number from which we start to apply the sequencial logic. The reason is that many backers already solved the first units and we don't want to force them redoing them
            const unitOffset = {
                121: 2
            };

            if (unitOffset[course.seqNumeric] && AuthService.instance.isBetaUser()) {
                if (!lastCompletedExrIndex || lastCompletedExrIndex.exercise['exrNr'] < course.units[unitOffset[course.seqNumeric]].exercises[0]['exrNr']) {
                    nextExerciseIndex = this.findExercise(course, {
                        exrNr: course.units[unitOffset[course.seqNumeric]].exercises[0]['exrNr']
                    });
                }
            }

            return nextExerciseIndex;
        }
    }

    isExerciseStartable(exr: UnitExercise, course: Course): boolean {
        if (AuthService.instance.isSuperUser()) {
            return true;
        }
        return exr.available;
    }

    checkExerciseStartable(exr: UnitExercise, course: Course, params?: any): boolean {
        params = params || {};
        // TBD: the first if is a quick fix, integrate it into the isUserPermittedForThisExercise function
        if (exr.attributes['freeDemo']) {
            if (params.onStartable) {
                params.onStartable();
            }
            return true;
        // If permission's there
        } else if (course && AuthService.instance.isUserPermittedForThisExercise(course.name, exr)) {
            // console.log('Permitted');
            if (CourseService.instance.isCoursePublic(course.name)) {
                if (params.onStartable) {
                    params.onStartable();
                }
                // console.log('onStartable');
                return true;
            } else if (CourseService.instance.isExerciseStartable(exr, course)) {
                if (params.onStartable) {
                    params.onStartable();
                }
                // console.log('onStartable');
                return true;
            } else {
                if (!CourseService.instance.isCourseBeta(course.name)) {
                    if (params.onNotAvailable) {
                        params.onNotAvailable();
                    }
                    // console.log('unstartable');
                    return false;
                }
            }
        // If no permission
        } else {
            // console.log('Not permitted');
            // If it's a sellable course => premium popup
            if (!CourseService.instance.isCourseBeta(course.name)) {
                if (params.onPremiumRequired) {
                    params.onPremiumRequired();
                }
                return false;
            }
        }
    }

    getCurrentCourse(immediately: boolean = false): Promise<Course> | Course {
        if (immediately) {
            return this.lastLoadedCourse;
        } else {
            return new Promise<Course>((resolve, reject) => {
                const currentCourseId = (this.lastLoadedCourse ? this.lastLoadedCourse.seqAlpha : null) || localStorage.getItem(CourseService.PARAM_COURSE_ID);
                if (currentCourseId) {
                    if (this.lastLoadedCourse && (this.lastLoadedCourse.id === currentCourseId
                        || this.lastLoadedCourse.seqAlpha === currentCourseId
                        || this.lastLoadedCourse.name === currentCourseId)) {
                        resolve(this.lastLoadedCourse);
                    } else {
                        this.lastLoadedCourse = null;
                        this.lastLoadedUserCourse = null;
                        this.loadCourse(currentCourseId).then(resolve, reject);
                    }
                } else {
                    resolve(null);
                }
            });
        }
    }

    // It uses getCurrentCourse(immeadiately = true)
    // So should keep it in mind when using this method
    getCurrentExerciseIdx() {
        const course = this.getCurrentCourse(true) as Course;
            let exerciseIdx: number = 0;
            if (course) {
                const unitIdx: number = course[CourseService.TRANSIENT_CURRENT_UNIT] || 0;
                const unit: CourseUnit = unitIdx < course.units.length ? course.units[unitIdx] : null;
                if (unit) {
                    exerciseIdx = unit[CourseService.TRANSIENT_CURRENT_EXERCISE] || 0;
                }
            }
            return exerciseIdx;
    }

    private findExercise(course: Course, query: object): ExerciseIndex {
        let exerciseIndex: ExerciseIndex = null;
        const queryKey = Object.keys(query)[0];
        const queryValue = query[queryKey];

        // console.log(queryKey, queryValue);

        for (let i = 0; i < course.units.length; i++) {
            const unit = course.units[i];
            if (unit) {
                for (let j = 0; j < unit.exercises.length; j++) {
                    if (unit.exercises[j][queryKey] === queryValue) {
                        exerciseIndex = new ExerciseIndex(unit, unit.exercises[j], i, j);
                        break;
                    }
                    if (exerciseIndex) {
                        break;
                    }
                }
            }
            if (exerciseIndex) {
                break;
            }
        }
        return exerciseIndex;
    }

    private saveCurrentCourse(withPromise?: boolean): Promise<void> | void {
        if (withPromise) {
            this.syncGeneralToUser(this.lastLoadedCourse, this.lastLoadedUserCourse);
            return new Promise<void>((resolve, reject) => {
                this.saveUserCourse(this.lastLoadedUserCourse)
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            });
        } else {
            this.syncGeneralToUser(this.lastLoadedCourse, this.lastLoadedUserCourse);
            this.saveUserCourse(this.lastLoadedUserCourse);
        }

    }

    /**
     * Copies current UI state exercise's progress to userCourse object for every unit, UI -> BE
     */
    private syncGeneralToUser(course: Course, userCourse: UserCourse) {
        if (course && userCourse) {
            _.each(course.units, unit => {
                const userCourseUnit: UserCourseUnit = userCourse.units[unit.id];
                userCourseUnit.currentExercise = unit[CourseService.TRANSIENT_CURRENT_EXERCISE] || 0;
                _.each(unit.exercises, exercise => {
                    if (userCourseUnit && userCourseUnit.exercises[exercise.seqAlpha]) {
                        const userUnitExercise: UserUnitExercise = userCourseUnit.exercises[exercise.seqAlpha];
                        userUnitExercise.currentChapter = exercise[CourseService.TRANSIENT_CURRENT_CHAPTER] || 0;
                        userUnitExercise.completed = exercise[CourseService.TRANSIENT_COMPLETED] || 0;
                        userUnitExercise.progress = exercise[CourseService.TRANSIENT_PROGRESS] || 0;
                        userUnitExercise.scheduled = exercise[CourseService.TRANSIENT_SCHEDULED] !== undefined ? exercise[CourseService.TRANSIENT_SCHEDULED] : false;
                    }
                });
            });
            userCourse.currentUnit = course[CourseService.TRANSIENT_CURRENT_UNIT] || 0;
        }
    }

    /**
     * Initializes UI state of current unit, exercise and exercises progress, BE -> UI
     */
    private syncUserToGeneral(userCourse: UserCourse, course: Course) {
        if (userCourse && course) {
            let exrNr = 0;
            _.each(course.units, unit => {
                const userCourseUnit: UserCourseUnit = userCourse.units[unit.id];
                unit[CourseService.TRANSIENT_CURRENT_EXERCISE] = userCourseUnit.currentExercise;
                _.each(unit.exercises, exercise => {
                    if (exercise.available !== null) {
                        exercise[CourseService.TRANSIENT_EXR_NR] = ++exrNr;
                    }

                    if (userCourseUnit && userCourseUnit.exercises[exercise.seqAlpha]) {
                        const userUnitExercise: UserUnitExercise = userCourseUnit.exercises[exercise.seqAlpha];
                        exercise[CourseService.TRANSIENT_CURRENT_CHAPTER] = userUnitExercise.currentChapter;
                        exercise[CourseService.TRANSIENT_COMPLETED] = userUnitExercise.completed;
                        exercise[CourseService.TRANSIENT_PROGRESS] = userUnitExercise.progress;
                        exercise[CourseService.TRANSIENT_SCHEDULED] = userUnitExercise.scheduled;
                    }
                });
            });
            course[CourseService.TRANSIENT_CURRENT_UNIT] = userCourse.currentUnit;
        }
    }

    private getCourse(courseId: string): Promise<Course> {
        const self = this;
        return new Promise<Course>((resolve, reject) => {
            axios.get(
                '/api/courses/' + courseId
            )
                .then((response) => {
                    const course: Course = response.data as Course;
                    if (course) {
                        resolve(course);
                    } else {
                        reject('Failed to retrieve Course');
                    }
                })
                .catch((error) => {
                    console.error('getCourse failed', error);
                    reject(self.errorToMessage(error));
                });
        });
    }

    public getUserCourse(courseId: string): Promise<UserCourse> {
        const self = this;
        return new Promise<UserCourse>((resolve, reject) => {
            axios.get(
                '/api/courses/user/' + courseId
            )
                .then((response) => {
                    const course: UserCourse = response.data as UserCourse;
                    if (course) {
                        resolve(course);
                    } else {
                        reject('Failed to retrieve UserCourse');
                    }
                })
                .catch((error) => {
                    // console.error('getUserCourse failed', error);
                    reject(self.errorToMessage(error));
                });
        });
    }

    private saveUserCourse(userCourse: UserCourse): Promise<UserCourse> {
        const self = this;
        return new Promise<UserCourse>((resolve, reject) => {
            axios.put(
                '/api/courses/user',
                userCourse
            )
                .then((response) => {
                    const course: UserCourse = response.data as UserCourse;
                    if (course) {
                        if (course.id && !userCourse.id) {
                            userCourse.id = course.id;
                        }
                        resolve(course);
                    } else {
                        reject('Failed to save UserCourse');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }

    public getCoursesStateForUserV2(): Promise<CoursesState> {
        const self = this;
        return new Promise<CoursesState>((resolve, reject) => {
            axios.get(
                '/api/courses/state/v2'
            )
                .then((response) => {
                    const coursesState: CoursesState = response.data as CoursesState;
                    if (coursesState) {
                        resolve(coursesState);
                    } else {
                        reject('Failed to retrieve courses state');
                    }
                })
                .catch((error) => {
                    reject(self.errorToMessage(error));
                });
        });
    }
}
