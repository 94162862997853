import {ImageProvider} from './image.provider.enum';
import {ImageStorage} from './image.storage.enum';

export class Image {
    constructor(
        public provider?: ImageProvider,
        public imageStorage?: ImageStorage,
        public reference?: string,
        public originalReference?: string,
        public created?: any
    ) {
    }
}
