import * as uuid from 'uuid';

import { syntax } from '../../../../MagicPlayer/config/syntax';

import { SlideGroup, Slide } from '../../../../../models/slide.model';
import { roundToPrecision } from './functions';

/**
 * Clears the "- " expressions at the beginnings of the lines.
 * Because sometimes content editors might copy-paste the previous dialogs which are created by the dialog macro,
 * and naturally "- " expressions come with them
 */
const trimSlide = (text: string): string => {
    return text.split('\n')
        .map(line => {
            // If it is copied from another dialog
            if (line.startsWith(`${syntax.dialog.sign}${syntax.dialog.sign}`)) {
                const newLine = line.split('');
                newLine.splice(0, 4, `${syntax.dialog.sign}`);
                return newLine.join('');
            } else {
                return line;
            }
        })
        .join('\n');
}

// Applies the "faded syntax" to the specified part of a string
const addFaded = (text: string, fadedIdx: number): string => {
    fadedIdx -= 1;
    const lines = text.split('\n');
    // If it isn't a one-line dialog slide
    if (fadedIdx !== -1) {
        const letters: string[] = lines[fadedIdx].split('');
        letters.splice(2, 0, syntax.faded.open);
        letters.push(syntax.faded.close);
        lines[fadedIdx] = letters.join('');
    }
    return lines.join('\n');
}

/**
 * Turns
 * ```
 * abc
 * def
 * ```
 * to
 * ```
 * Slide #1
 * - abc
 * - 
 * Slide #2
 * - <abc>
 * - def
 * ```
 */
export const dialogMacro = (slideGroup: SlideGroup): SlideGroup => {
    const slides: Slide[] = [];
    const startSlide = slideGroup.slides[0];

    const lines = slideGroup.slides[0].text.split('\n');

    /**
     * `abstractText` represents the whole dialog
     * 
     *  - with breakpoints and "- " expressions
     *  - without faded signs
     * 
     * as a normal string
     * 
     * This text will be forged into different slides later
     */
    let abstractText: string = '';
    for (let i: number = 0; i < lines.length; i++) {
        if (i !== 0) {
            abstractText = abstractText + '\n- ';
        } else {
            abstractText = abstractText + '- ';
        }
    }

    // Create the individual slides & push them
    for (let i = 0; i < lines.length; i++) {
        let text: string;
        text = i !== 0 ? { ...slides[i - 1] }.text : abstractText;
        const mLines = text.split('\n');
        mLines[i] = syntax.dialog.sign + lines[i];
        text = mLines.join('\n');

        slides.push(new Slide({
            id: uuid(),
            from: roundToPrecision(startSlide.from + (slideGroup.end - startSlide.from) * (i / lines.length), 0.1),
            text: addFaded(trimSlide(text), i),
            styles: startSlide.styles
        }));
    }

    slideGroup.slides = slides;

    return slideGroup;
}
