import * as React from 'react';

/**
 * Assists the user to use the "Click and Speak" mode more comfortably
 *
 * Using SVGs inline to be able to manipulate their style properties easier
 *
 * **Extra Classes:**
 *
 * The extra classes of the "wrapper classes" will affect the styling of the children by Sass code
 *
 * - Channel Type Manual Instructions
 *   - *not-clicked:*
 *     - white spacebar
 *     - pushed circle exists
 *     - hand is on the spacebar
 *     - text under the hand is 'Or hold "Space"'
 *   - *clicked:*
 *     - purple spacebar
 *     - pushed circle DOES NOT exist
 *     - hand is underneath the spacebar
 *     - text under the hand is 'Release'
 * - Channel Type Automatic Instructions
 *   - *not-clicked:*
 *     - at a vertically lower level
 *     - opacity 0
 *   - *clicked:*
 *     - at a vertically higher level
 *     - opacity 1
 */
export class ClickAndSpeakInstructions extends React.PureComponent<any, any> {
    el: HTMLDivElement;

    elSpacebarInst: HTMLElement;
    elMouseInst: HTMLElement;

    elSpacebarInstText: HTMLElement;

    componentDidMount(): void {
        this.initSpacebarAndMouseInst();
    }

    private initSpacebarAndMouseInst = () => {
        this.elSpacebarInst = document.getElementById('click-and-speak-spacebar-instructions');
        this.elMouseInst = document.getElementById('click-and-speak-mouse-instructions');
        this.elSpacebarInstText = this.elSpacebarInst
            .getElementsByClassName('hand-inst').item(0)
            .getElementsByTagName('p').item(0);
    }

    hide = () => {
        this.el.classList.add('hidden');
    }

    show = () => {
        this.el.classList.remove('hidden');
    }

    onSpacebarClick = () => {
        // Show if hidden, and change between clicked and not-clicked
        this.elSpacebarInst.classList.remove('hidden', 'not-clicked');
        this.elSpacebarInst.classList.add('clicked');

        // It seems disturbing to see the instruction with low opacity when i click the spacebar and have the mouse cursor on the input-area at the same time
        // Mouse behavior's effect on input-area is kinda restricted in general actually when using spacebar,
        // like input area will be light when spacebar is released but mouse is still hovering on the input area, but if mouse leaves and enters again, it is counted and there will be style changes
        this.incrementSpacebarInstOpacity();

        this.elSpacebarInstText.textContent = 'Release';
    }

    onSpacebarNotClick = () => {
        // Change between clicked and not-clicked
        this.elSpacebarInst.classList.remove('clicked');
        this.elSpacebarInst.classList.add('not-clicked');

        this.elSpacebarInstText.textContent = 'Or hold "Space"';
    }

    onMouseClick = () => {
        // First hide the spacebar instructions
        this.elSpacebarInst.classList.add('hidden');

        // Then change mouse instruction's status
        this.elMouseInst.classList.remove('not-clicked');
        this.elMouseInst.classList.add('clicked');
    }

    onMouseNotClick = () => {
        // First change mouse instruction's status
        this.elMouseInst.classList.remove('clicked');
        this.elMouseInst.classList.add('not-clicked');

        // Then show the spacebar instructions
        this.elSpacebarInst.classList.remove('hidden');
    }

    // Reduce the opacity of the spacebar inst, it will only take effect only if the spacebar inst is not already hidden, that logic is handled in the sass code
    reduceSpacebarInstOpacity = () => {
        this.elSpacebarInst.classList.add('reduced-opacity');
    }

    incrementSpacebarInstOpacity = () => {
        this.elSpacebarInst.classList.remove('reduced-opacity');
    }

    render(): React.ReactNode {
        return (
            <div id='click-and-speak-instructions' ref={c => (this.el = c)}>
                <div id='click-and-speak-spacebar-instructions' className='not-clicked'>
                    <svg className='keyboard-inst' width='101' height='24' xmlns='http://www.w3.org/2000/svg'>
                        <defs>
                            <radialGradient fx='50%' fy='50%' gradientTransform='matrix(0 1 -.91862 0 .96 0)' id='b'>
                                <stop stop-color='#EEE' offset='0%' />
                                <stop stop-color='#DFDFDF' stop-opacity='.47' offset='66.941%' />
                                <stop stop-color='#D8D8D8' stop-opacity='0' offset='100%' />
                            </radialGradient>
                            <ellipse id='a' cx='59.5' cy='25.5' rx='59.5' ry='25.5' />
                        </defs>
                        <g transform='translate(-9 -5)' fill='none' fill-rule='evenodd'>
                            <mask id='c' fill='#fff'>
                                <use href='#a' />
                            </mask>
                            <use fill='url(#b)' href='#a' />
                            <g mask='url(#c)' stroke='#10041E'>
                                <g transform='translate(8 5)'>
                                    <rect
                                        fill-opacity='.242'
                                        transform='matrix(1 0 0 -1 0 37)'
                                        x='17.5'
                                        y='13.5'
                                        width='67'
                                        height='10'
                                        rx='2'
                                    />
                                    <rect transform='matrix(1 0 0 -1 0 37)' x='4.5' y='13.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 37)' x='87.5' y='13.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 11)' x='.5' y='.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 11)' x='13.5' y='.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 11)' x='26.5' y='.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 11)' x='39.5' y='.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 11)' x='52.5' y='.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 11)' x='65.5' y='.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 11)' x='78.5' y='.5' width='10' height='10' rx='2' />
                                    <rect transform='matrix(1 0 0 -1 0 11)' x='91.5' y='.5' width='10' height='10' rx='2' />
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg className='pushed-circle-inst' width='25' height='25' xmlns='http://www.w3.org/2000/svg'>
                        <g fill='#201230' fill-rule='evenodd' fill-opacity='.795'>
                            <circle opacity='.204' cx='12.5' cy='12.5' r='12.5' />
                            <circle opacity='.384' cx='12.5' cy='12.5' r='7.5' />
                        </g>
                    </svg>
                    <div className='hand-inst'>
                        <svg width='28' height='43' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.67 11.49a2.589 2.589 0 0 0-2.561 2.279c-.011.095-.017 6.084-.017 6.182V3.82c0-1.412-1.163-2.567-2.579-2.567-1.418 0-2.58 1.158-2.58 2.566v21.753c0-.56-.442-1.513-.635-1.978a5.463 5.463 0 0 0-2.143-2.5c-.732-.45-1.874-.95-2.593-.219-.866.882-.4 2.979-.003 3.945.773 1.89 1.152 5.192 1.926 7.52 1.513 4.542 2.419 3.921 5.077 9.913h15c.398-2.59 2.101-5.76 2.997-8.78.648-2.185.479-4.196.485-6.448l.028-8.247a2.458 2.458 0 0 0-2.456-2.443 2.461 2.461 0 0 0-2.45 2.27c-.004.046-.006 1.651-.006 1.697v-3.887a2.457 2.457 0 0 0-2.456-2.444 2.465 2.465 0 0 0-2.437 2.144c-.011.085-.017 4.1-.019 4.187v-6.245a2.58 2.58 0 0 0-2.577-2.567z'
                                fill='#F9F9FB'
                                fill-rule='nonzero'
                                stroke='#201230'
                                stroke-opacity='.795'
                            />
                        </svg>
                        <p>{/* Text here will be handled by the handlers in the learning component */}</p>
                    </div>
                </div>
                <div id='click-and-speak-mouse-instructions' className='not-clicked'>
                    <div className='release-mouse-inst'>Release mouse</div>
                </div>
            </div>
        );
    }
}
