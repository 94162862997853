import * as _ from 'underscore';
import {RecognitionResultsFormat} from './recognition.results.format';
import {RecognitionStatus} from './recognition.status';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs/lib/stomp';
import {AuthService} from '../auth.service';
import {clearInterval, setInterval} from 'timers';
import {StreamChannel} from './stream.channel';
import {AudioChannelAnalyser} from './audio.channel.analyser';
import {AudioChannelVolumeAnalyser} from './audio.channel.volume.analyser';
import {AudioSampler} from './audio.sampler';

export class WsStreamChannel extends StreamChannel {

    private audioContext = null;
    private micStream = null;
    private device = null;
    private audioChannelAnalyser: AudioChannelAnalyser = null;
    private audioChannelVolumeAnalyser: AudioChannelVolumeAnalyser = null;

    private lang: string;
    private speechContext: Array<string>;
    private recognitionProcessor: (results: RecognitionResultsFormat) => void;
    private statusProcessor: (status: RecognitionStatus) => void;

    private client;
    private connectionPromise: Promise<any>;
    public channelId;

    private sampler: AudioSampler;
    private scriptNode: ScriptProcessorNode;
    private firstbuffer = true;
    private reconnector = null;
    private startInProgress = false;
    private connectionFailures = 0;
    private reconnections = 0;

    private static generateUUID() {
        let d = new Date().getTime();
        if (window.performance && typeof window.performance.now === 'function') {
            d += window.performance.now(); // use high-precision timer if available
        }
        /* tslint:disable */
        return 'xxxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r&0x3 | 0x8)).toString(16);
        });
        /* tslint:enable */
    }

    private static mapLanguageISO(lang: string): string {
        if (lang) {
            lang = lang.toLowerCase();
            if (lang === 'en') {
                lang = 'en-GB';
            } else if (lang === 'de') {
                lang = 'de-DE';
            } else if (lang === 'es') {
                lang = 'es-ES';
            } else if (lang === 'fr') {
                lang = 'fr-FR';
            } else if (lang === 'it') {
                lang = 'it-IT';
            } else if (lang === 'ru') {
                lang = 'ru-RU';
            } else if (lang === 'ar') {
                lang = 'ar-SA';
            } else if (lang === 'zh') {
                lang = 'zh-CN';
            } else if (lang === 'el') {
                lang = 'el-GR';
            } else if (lang === 'he') {
                lang = 'he-IL';
            } else if (lang === 'hi') {
                lang = 'hi-IN';
            } else if (lang === 'nl') {
                lang = 'nl-NL';
            } else if (lang === 'pl') {
                lang = 'pl-PL';
            } else if (lang === 'pt') {
                lang = 'pt-BR';
            } else if (lang === 'sv') {
                lang = 'sv-SE';
            } else if (lang === 'cs') {
                lang = 'cs-CZ';
            }
            return lang;
        } else {
            return 'en-GB';
        }
    }

    public constructor(
        langISO: string,
        speechContext: Array<string>,
        recognitionProcessor: (results: RecognitionResultsFormat) => void,
        statusProcessor: (status: RecognitionStatus) => void
    ) {
        super(langISO, speechContext, recognitionProcessor, statusProcessor);
        this.lang = WsStreamChannel.mapLanguageISO(langISO);
        this.speechContext = speechContext;
        this.recognitionProcessor = recognitionProcessor;
        this.statusProcessor = statusProcessor;
    }

    public getChannelId(): string {
        return this.channelId;
    }

    public getDeviceLabel(): string {
        return this.device && this.device.label ? this.device.label : null;
    }

    public open(): Promise<void> {
        // console.log('ws channel open', new Date().getTime());
        const self = this;
        let micInitTime = new Date().getTime();
        return new Promise<void>((resolve, reject) => {
            const AudioContext = (<any>window).AudioContext || (<any>window).webkitAudioContext || false;
            if (!AudioContext) {
                reject('Please upgrade your browser');
            } else {
                if (typeof (<any>navigator) === 'undefined' ||
                    typeof (<any>navigator).mediaDevices === 'undefined' ||
                    typeof (<any>navigator).mediaDevices.getUserMedia === 'undefined') {
                    reject('We cannot access your microphone.');
                } else {
                    if (self.audioContext === null) {
                        self.audioContext = new AudioContext();
                    }
                    // console.log('enumerateDevices devices', new Date().getTime());
                    (<any>navigator).mediaDevices.enumerateDevices().then((devices) => {
                        if (devices && devices.length > 0) {
                            const foundDevice = _.find(devices, (d: any) => d.kind === 'audioinput');
                            if (foundDevice) {
                                self.device = foundDevice;
                                // console.log('device found', JSON.stringify(foundDevice));
                            }
                        }

                        if (navigator.mediaDevices === undefined) {
                            (<any>navigator).mediaDevices = {};
                        }

                        if (navigator.mediaDevices.getUserMedia === undefined) {
                            navigator.mediaDevices.getUserMedia = function(constraints) {

                                // First get ahold of the legacy getUserMedia, if present
                                const getUserMedia = (<any>navigator).webkitGetUserMedia || (<any>navigator).mozGetUserMedia;

                                // Some browsers just don't implement it - return a rejected promise with an error
                                // to keep a consistent interface
                                if (!getUserMedia) {
                                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                                }

                                // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
                                return new Promise(function(resolve, reject) {
                                    getUserMedia.call(navigator, constraints, resolve, reject);
                                });
                            }
                        }

                        // console.log('getUserMedia', new Date().getTime());

                        const mediaPromise = (<any>navigator).mediaDevices.getUserMedia({audio: true});
                        mediaPromise.then((micStream) => {
                            self.micStream = micStream;
                            try {
                                // console.log('createMediaStreamSource', new Date().getTime());
                                const microphone = self.audioContext.createMediaStreamSource(self.micStream);
                                // console.log('init scriptNode', new Date().getTime());
                                self.scriptNode = (() => {
                                    let bufferSize = 2048;
                                    try {
                                        return self.audioContext.createScriptProcessor(bufferSize, 1, 1);
                                    } catch (error) {
                                        bufferSize = 2048;
                                        let audioSampleRate = self.audioContext.sampleRate;
                                        while (
                                            bufferSize < 16384 &&
                                            audioSampleRate >= (2 * 16000)
                                            ) {
                                            /* tslint:disable:no-bitwise */
                                            bufferSize <<= 1;
                                            audioSampleRate >>= 1;
                                            /* tslint:enable:no-bitwise */
                                        }
                                        try {
                                            const result = self.audioContext.createScriptProcessor(bufferSize, 1, 1);
                                            return null;
                                        } catch (e) {
                                            console.error(e);
                                            return null;
                                        }
                                    }
                                })();
                                // console.log('connect microphone', new Date().getTime());
                                microphone.connect(self.scriptNode);
                                micInitTime = new Date().getTime() - micInitTime;
                                // console.log('microphone opened', new Date().getTime(), 'total msecs:', micInitTime);
                                const w: any = window;
                                w.dataLayer.push({
                                    'event': 'micInitFinished',
                                    'micInitTime': micInitTime,
                                    'backerId': w.backerId
                                });

                                self.audioChannelVolumeAnalyser = new AudioChannelVolumeAnalyser(self.audioContext);
                                self.audioChannelVolumeAnalyser.connect(microphone);
                                // console.log('AudioChannelVolumeAnalyser connected', new Date().getTime());

                                // self.audioChannelAnalyser = new AudioChannelAnalyser(self.audioContext);
                                // self.audioChannelAnalyser.connect(microphone);
                                // console.log('AudioChannelAnalyser connected', new Date().getTime());

                                self.scriptNode.connect(self.audioContext.destination);
                                // console.log('scriptNode connected', new Date().getTime());

                                self.sampler = new AudioSampler({
                                    channel: 1,
                                    sampleRate: self.scriptNode.context.sampleRate
                                });
                                // console.log('AudioSampler created', new Date().getTime());
                                self.start().then(resolve).catch(reject);
                            } catch (error) {
                                reject(error);
                            }
                        }).catch(error => {
                            reject(error);
                        });
                    }).catch(error => {
                        reject(error);
                    });
                }
            }
        });
    }

    /**
     * Websocket implementation does realtime streaming, so there is nothing to flush
     */
    flush(): Promise<void> {
        return Promise.resolve();
    }

    public close(): Promise<void> {
        // console.log('ws channel close');
        const self = this;
        return new Promise<void>((resolve, reject) => {
            const finish = () => {
                if (self.audioContext) {
                    self.audioContext.close().then(() => {
                        for (const track of self.micStream.getTracks()) {
                            track.stop();
                        }
                        if (self.audioChannelAnalyser) {
                            self.audioChannelAnalyser.close();
                            self.audioChannelAnalyser = null;
                        }
                        if (self.audioChannelVolumeAnalyser) {
                            self.audioChannelVolumeAnalyser.close();
                            self.audioChannelVolumeAnalyser = null;
                        }
                        self.audioContext = null;
                        self.scriptNode = null;
                        self.lang = null;
                        self.speechContext = null;
                        resolve();
                    }).catch(resolve);
                } else {
                    resolve();
                }
            };
            self.stop().then(finish).catch(finish);
        });
    }

    public getAudioFrequencyData(): Uint8Array {
        return this.audioChannelAnalyser ? this.audioChannelAnalyser.getAudioFrequencyData() : new Uint8Array(0);
    }

    public getAudioVolumeData(): any {
        return this.audioChannelVolumeAnalyser ? this.audioChannelVolumeAnalyser.getAudioVolumeData() : {
            clipped: false,
            volume: 0
        };
    }

    private connect(channelId): Promise<void> {
        // console.log('ws channel connect', new Date().getTime());
        const self = this;
        return new Promise<void>((resolve, reject) => {
            const sock = new SockJS('/stomp-websocket');
            const client = new Stomp.Stomp.over(sock);
            client.debug = null;

            self.updateStatus(RecognitionStatus.MSG_CONNECT);
            client.connect({'channelId': channelId}, (event) => {
                /*
                if (sock._transport && sock._transport.ws) {
                    sock._transport.ws.onerror = function(err) {
                        console.error('ws error:', err);
                    };
                    sock._transport.ws.onclose = function(reason) {
                        console.error('ws closed', reason);
                    };
                } */

                self.client = client;
                self.updateStatus(RecognitionStatus.MSG_CONNECTED);
                resolve();
            }, (err) => {
                self.updateStatus(RecognitionStatus.MSG_NOT_CONNECTED, {error: err});
                reject(err);
            });
        });
    }

    private disconnect(): Promise<any> {
        // console.log('ws channel disconnect');
        const self = this;
        self.updateStatus(RecognitionStatus.MSG_DISCONNECT);
        return new Promise((resolve, reject) => {
            if (self.client) {
                self.client.disconnect();
                self.client = null;
                self.updateStatus(RecognitionStatus.MSG_DISCONNECTED);
                resolve();
            } else {
                resolve();
            }
        });
    };

    private start(): Promise<void> {
        // console.log('ws channel start', new Date().getTime());
        this.updateStatus(RecognitionStatus.MSG_START);
        const self = this;
        return new Promise<void>((resolve, reject) => {
            if (self.startInProgress) {
                reject('Start is already in progress');
            } else {
                self.startInProgress = true;
                self.channelId = WsStreamChannel.generateUUID();
                self.updateStatus(RecognitionStatus.MSG_NEW_CHANNEL);
                const connectionFailure = (err) => {
                    // console.log('ws connectionFailure');
                    self.connectionFailures++;
                    self.startInProgress = false;
                    self.updateStatus(RecognitionStatus.MSG_CONNECTION_FAILURE, {error: err});
                    if (self.connectionFailures < 10) {
                        self.stop().then(() => {
                            self.start().then(resolve).catch(reject);
                        }).catch(reject);
                    } else {
                        // console.error('websocket error', err);

                        reject('Failed connection attempts threshold exceeded');
                    }
                };
                const connectionSuccess = () => {
                    // console.log('ws connectionSuccess', new Date().getTime());
                    self.connectionFailures = 0;
                    self.startInProgress = false;
                    self.client.ws.onerror = connectionFailure;
                    self.client.ws.onclose = (reason) => {
                        if (reason.code === 1006) {
                            // console.error('websocked closed abnormally');
                        }
                    };

                    // self.client.ws.onclose = connectionFailure; // TODO : sure?
                    self.updateStatus(RecognitionStatus.MSG_SUBSCRIBE_TRANSCRIBED);
                    self.client.subscribe('/topic/speech/transcribed/' + self.channelId, (response) => self.receivedTranscribedEvent(response));
                    // Channel configuration
                    const configObj = {
                        languageCode: self.lang,
                        sampleRate: self.sampler.dstConfig.sampleRate,
                        speechContext: self.speechContext
                    };
                    const config = JSON.stringify(configObj);
                    let monitorObject = {};
                    setTimeout(() => {
                        if (monitorObject !== null) {
                            connectionFailure('Did don receive response for subscription');
                        }
                    }, 4000);
                    self.updateStatus(RecognitionStatus.MSG_SUBSCRIBE_CONFIGURED);
                    self.client.subscribe('/topic/speech/configured/' + self.channelId, (response) => {
                        self.updateStatus(RecognitionStatus.MSG_RECEIVE_CONFIGURED);
                        monitorObject = null;
                        self.firstbuffer = true;
                        if (self.scriptNode) {
                            self.scriptNode.onaudioprocess = (event) => self.processAudioEvent(event);
                        }
                        self.startReconnectTimer();
                        resolve();
                    });
                    self.updateStatus(RecognitionStatus.MSG_SEND_CONFIGURE, configObj);
                    self.client.send('/wss/speech/configure/' + self.channelId, {userReference: AuthService.instance.getReference() || 'na'}, config);
                    self.client.subscribe('/topic/speech/closed/' + self.channelId, (response) => {
                        self.updateStatus(RecognitionStatus.MSG_SERVER_CLOSED);
                        self.stop().then(() => {
                            self.start();
                        }).catch(() => {
                            self.start();
                        });
                    });
                };
                self.connect(self.channelId).then(connectionSuccess).catch(connectionFailure);
            }
        });
    }

    public stop(): Promise<void> {
        // console.log('ws channel stop');
        this.updateStatus(RecognitionStatus.MSG_STOP);
        const self = this;
        return new Promise<void>((resolve, reject) => {
            self.stopReconnectTimer();
            if (self.scriptNode) {
                self.scriptNode.onaudioprocess = (e) => {
                    e.inputBuffer.getChannelData(0);
                };
            }
            self.disconnect().then(resolve).catch(reject);
        });
    }

    private startReconnectTimer(): void {
        const self = this;
        self.stopReconnectTimer();
        self.updateStatus(RecognitionStatus.MSG_START_RECONNECTOR);
        self.reconnector = setInterval(() => {
            self.reconnections++;
            self.updateStatus(RecognitionStatus.MSG_RECONNECT, {reconnections: self.reconnections});
            if (self.reconnections < 10) {
                self.stop().then(() => {
                    self.start();
                }).catch(() => {
                    self.start();
                });
            } else {
                self.stopReconnectTimer();
                // TODO : maybe close completely?
            }
        }, 60 * 1000);
    }

    private stopReconnectTimer(): void {
        const self = this;
        if (self.reconnector) {
            self.updateStatus(RecognitionStatus.MSG_STOP_RECONNECTOR);
            clearInterval(self.reconnector);
            self.reconnector = null;
        }
    }

    private str2ab(str: string): ArrayBuffer {
        const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
        const bufView = new Uint16Array(buf);
        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    private receivedTranscribedEvent(event) {
        const self = this;
        const byteArr: ArrayBuffer = self.str2ab(event.body);
        const byteLength = byteArr ? byteArr.byteLength : 0;
        const data = JSON.parse(event.body);
        const results = [];
        if (data) {
            for (const alternative of data.alternatives_) {
                results.push({
                    transcript: alternative.transcript_,
                    confidence: alternative.confidence_
                });
            }
        }
        self.updateStatus(RecognitionStatus.MSG_RECEIVE_TRANSCRIBED, {
            response: data,
            results,
            byteLength
        });
        if (results.length) {
            self.recognitionProcessor(new RecognitionResultsFormat(results));
        } else {
            self.updateStatus(RecognitionStatus.MSG_TRANSCRIBE_EMPTY);
        }
    }

    private processAudioEvent(event: AudioProcessingEvent) {
        const self = this;
/*         const result = self.audioChannelAnalyser.analyseAudio();
        if (result && result.action) {
            if (result.action === 'start') {
                self.updateStatus(RecognitionStatus.MSG_START_VOICE);
            } else if (result.action === 'stop') {
                self.updateStatus(RecognitionStatus.MSG_END_VOICE);
            }
        } */
        const rawAudioBuffer: Float32Array = event.inputBuffer.getChannelData(0);
        const data = self.sampler.encode(rawAudioBuffer, self.firstbuffer);
        const byteLength = data.byteLength;
        if (self.firstbuffer) {
            self.firstbuffer = false;
        }
/*         self.updateStatus(RecognitionStatus.MSG_SEND_TRANSCRIBE, {
            request: data,
            firstBuffer: self.firstbuffer,
            srcSampleRate: self.sampler && self.sampler.srcConfig ? self.sampler.srcConfig.sampleRate : 0,
            dstSampleRate: self.sampler && self.sampler.dstConfig ? self.sampler.dstConfig.sampleRate : 0,
            byteLength
        }); */
        self.client.send('/wss/speech/transcribe/' + self.channelId, {}, data);
    }

    private updateStatus(message: string, data?: any) {
        const self = this;
        if (self.channelId && self.statusProcessor) {
            self.statusProcessor(new RecognitionStatus(self.channelId, message, data));
        }
    }
}
